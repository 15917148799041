import React, { ReactNode, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SortItemValue, SortSelectItem, SortVariables, SurveyTabProps } from '../SurveyTabs.types';
import * as Styles from '../SurveyTabs.styles';

import { SortSelect } from './SortSelect';

type TabsContainerProps = {
  children: ReactNode;
  sortingOptions: SortSelectItem[];
  onChangeSorting?: (sortingOptions: SortVariables) => void;
} & SurveyTabProps;

export const TabContainer = ({
  children,
  isActive,
  refetch,
  isListEmpty,
  sortingOptions,
  onChangeSorting,
}: TabsContainerProps) => {
  const { t } = useTranslation();
  const [sortOption, setSortOption] = useState('');

  const handleChangeSorting = async (variables: SortVariables, value: SortItemValue) => {
    setSortOption(value);
    onChangeSorting?.(variables);
    await refetch({
      sort: variables,
    });
  };

  return (
    <Box role="tabpanel" hidden={!isActive} sx={Styles.TabPanel(!isActive)}>
      {isActive &&
        (isListEmpty ? (
          <>
            <Typography>{t('surveysList.message.surveysListEmpty')}</Typography>
            {children}
          </>
        ) : (
          <Box sx={Styles.ListTemplates}>
            <SortSelect onChange={handleChangeSorting} items={sortingOptions} value={sortOption} />
            {children}
          </Box>
        ))}
    </Box>
  );
};
