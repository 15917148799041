import { CreateWeeklyFeedbackActionInput, UpdateActionInput } from 'generated/webapp_gql';

export type WeeklyFeedbackFormData = {
  satisfactionScore: number;
  summary: string | null;
  notes: string | null;
  realizationDate: Date | null;
};

export type CreateWeeklyFeedbackFormData = WeeklyFeedbackFormData & {
  actions?: CreateWeeklyFeedbackActionInput[];
  projectsSatisfaction?: {
    projectId: string;
    projectName: string;
    projectSatisfactionScore: number;
  }[];
};

export type UpdateWeeklyFeedbackFormData = WeeklyFeedbackFormData & {
  actions?: UpdateActionInput[];
  projectsSatisfaction?: {
    id: string;
    projectName: string;
    projectSatisfactionScore: number;
  }[];
};

export type WeeklyFeedbackFormProps = {
  onSubmit: VoidFunction;
  mutationLoading: boolean;
  action: WeeklyAction;
  giverId?: number;
};

export enum WeeklyAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}
