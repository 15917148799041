import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useS3UploadUrlLazyQuery } from 'documents/s3UploadUrl.generated';
import { Toast } from 'ui';
import { FileToUpload } from 'ui/uploadImageModal/UploadImageModal.types';

export const useUploadFile = (onFileUpload: (imgName: string) => void, successMessage?: string) => {
  const { t } = useTranslation();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fetchImgNameUrl] = useS3UploadUrlLazyQuery({
    onError: (error) => toast.error(error.message),
    fetchPolicy: 'no-cache',
  });

  const uploadFile = async (image?: FileToUpload | null) => {
    try {
      setIsFileUploading(true);

      const { data } = await fetchImgNameUrl();
      const uploadUrl = data?.s3UploadUrl?.uploadUrl;
      const imageName = data?.s3UploadUrl?.imageName;

      if (!uploadUrl || !image || !imageName) return;
      const uploadImgResponse = await fetch(uploadUrl, {
        body: image.imgFile,
        headers: {
          'Content-Type': image.imgFile.type,
        },
        method: 'PUT',
      });

      setIsFileUploading(false);

      if (!uploadImgResponse.ok) {
        throw new Error(uploadImgResponse.statusText);
      }

      toast.success(
        <Toast
          title={t('surveyBuilder.questions.addPhotoDialog.successMessage.survey')}
          message={successMessage || t('surveyBuilder.questions.addPhotoDialog.successMessage.photoWasAdded')}
        />,
      );

      onFileUpload(imageName);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return { isFileUploading, uploadFile, fetchImgNameUrl };
};
