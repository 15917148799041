import { Button, Tab, TabsProps } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Goal, GoalStatus } from 'generated/webapp_gql';
import { useModal, useReadOnlyEmployee, useUpdateGoal, useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { Accordion, Modal, TabPanel, Tabs, ToDoTable } from 'ui';
import { ToDoType } from 'ui/modal/Modal.enum';
import { useUpdateGoalContent } from 'hooks/useUpdateGoalContent/useUpdateGoalContent';

import { GoalsStatusKeys } from './GoalsTabs.types';
import { goalsTabsNames, goalsTabsTranslations } from './GoalsTabs.utils';
import { useGoalsTabs } from './useGoalsTabs';

export const GoalsTabs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);
  const { isReadOnly } = useReadOnlyEmployee(employeeId);

  const { goalsData, areGoalsLoading, goalStatus, setGoalStatus } = useGoalsTabs();

  const updateHandler = useUpdateGoalContent();

  const { sendGoal } = useUpdateGoal();
  const { handleItemClick, item, handleModalClose } = useModal<GoalStatus>({
    onConfirm: sendGoal,
    canceledStatus: GoalStatus.Canceled,
    doneStatus: GoalStatus.Done,
  });

  if (!goalsData && !areGoalsLoading) return null;

  const handleChangeTab = (_: SyntheticEvent, value: TabsProps['value']) => {
    setGoalStatus(value);
  };

  const onRedirectToQuarterlyReport = (quarterlyReportId?: string) =>
    quarterlyReportId ? { to: `/${AppRoute.PreviewReport}/${quarterlyReportId}/${employeeId}` } : null;

  const getCount = (type: GoalStatus) => {
    const formattedType = type.toLowerCase() as GoalsStatusKeys;
    return goalsData?.[formattedType] || 0;
  };

  const goals = (goalsData?.goalsCollection?.items as Goal[]) || [];

  return (
    <Accordion
      title={t('goalAccordion.title')}
      content={
        <>
          <Tabs
            value={goalStatus}
            onChange={handleChangeTab}
            button={
              <Link to={`/${AppRoute.EmployeeGoals}/${employeeId}`}>
                <Button variant="text">{t('goalAccordion.btnLabel')}</Button>
              </Link>
            }
          >
            {goalsTabsNames.map((type) => (
              <Tab
                key={type}
                label={t(goalsTabsTranslations[type], {
                  count: getCount(type),
                })}
                value={type}
              />
            ))}
          </Tabs>

          {goalsTabsNames.map((type) => (
            <TabPanel value={goalStatus} index={type} key={type} data-cy="goalsTabs">
              <ToDoTable
                isReadOnly={isReadOnly}
                onClick={handleItemClick}
                toDoData={goals}
                loading={areGoalsLoading}
                getOnArrowClickState={onRedirectToQuarterlyReport}
                seeMore={{
                  redirect: () => navigate(`/${AppRoute.EmployeeGoals}/${employeeId}`),
                  totalAmount: areGoalsLoading ? 0 : getCount(type),
                }}
                context={updateHandler}
              />
              {item.type && (
                <Modal onClose={handleModalClose} type={item.type} content={item.content} toDoType={ToDoType.Goal} />
              )}
            </TabPanel>
          ))}
        </>
      }
    />
  );
};
