import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Form: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  mb: 3,
};

export const DatePickerWrapper = (isError: boolean): Styles => ({
  width: 220,
  borderRadius: 2,
  border: '1px solid',
  borderColor: isError ? colors.tomato : 'transparent',

  '&:hover': {
    backgroundColor: colors.brightGray,
  },

  '&:has(.Mui-focused)': {
    backgroundColor: 'transparent',
    borderColor: colors.blueberry,
  },
});

export const DatePickerMessage: Styles = {
  color: colors.tomato,
  pl: 1,
};

export const ReportForm: Styles = {
  p: 1,
  backgroundColor: 'ghostWhite',
};

export const SubmitWrapper: Styles = {
  p: ({ spacing }) => spacing(0.25, 3, 1.25),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const ContentWrapper: Styles = {
  p: ({ spacing }) => spacing(2, 3),
};
