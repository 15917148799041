import { Question } from './question/Question';
import { QuestionsProps } from './Questions.types';

export const Questions = ({ nestIndex, questions }: QuestionsProps) => {
  return (
    <>
      {questions.map((question, index) => (
        <Question nestIndex={nestIndex} id={question.id} index={index} key={question.id} />
      ))}
    </>
  );
};
