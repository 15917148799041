import { Box, Typography } from '@mui/material';

import { ReactComponent as NoDataSvg } from 'assets/images/NoData.svg';
import { spreadMultipleStyles } from 'shared/utils';

import { NoDataHandlerProps } from './NoDataHandler.types';
import * as Styles from './NoDataHandler.styles';

export const NoDataHandler = ({ message, sx, topElement = <NoDataSvg /> }: NoDataHandlerProps) => {
  return (
    <Box sx={spreadMultipleStyles(Styles.Container, sx)}>
      {topElement}
      <Typography sx={Styles.Message} variant="body2">
        {message}
      </Typography>
    </Box>
  );
};
