import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Wrapper: Styles = {
  borderRadius: 2,
  p: 1.2,
  border: '1px solid transparent',

  '&:hover': {
    backgroundColor: colors.brightGray,
  },

  '&:has(.Mui-focused)': {
    backgroundColor: 'transparent',
    borderColor: colors.blueberry,
  },

  '&&& .MuiInput-root': {
    '&::before': {
      display: 'none',
    },
  },

  '& .MuiInputLabel-root': {
    color: colors.navy,
  },
};

export const Label: Styles = {
  '& label': {
    fontSize: 14,
    fontWeight: 500,
  },
};

export const Button: Styles = {
  fontWeight: 500,
};

export const OptionalTextField: Styles = {
  fontSize: 14,
  lineHeight: '143%',
  letterSpacing: 0.15,
  width: '100%',

  '&&& .MuiInput-root': {
    m: 0,
    p: 0,
  },
};
