import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WeeklyFeedbacksQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  pagination?: Types.InputMaybe<Types.PaginationInput>;
  sort?: Types.InputMaybe<Types.SortByRealizationDateInput>;
}>;


export type WeeklyFeedbacksQuery = { __typename?: 'Query', weeklyFeedbacks?: { __typename?: 'WeeklyFeedbackCollection', total: number, items: Array<{ __typename?: 'WeeklyFeedback', id: string, satisfactionScore: number, summary?: string | null, notes?: string | null, realizationDate: any, receiverId?: number | null, actionsCollection?: { __typename?: 'ActionCollection', items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null } | null> } | null } | null> } | null };


export const WeeklyFeedbacksDocument = gql`
    query weeklyFeedbacks($employeeId: Int!, $pagination: PaginationInput, $sort: SortByRealizationDateInput) {
  weeklyFeedbacks(employeeId: $employeeId, pagination: $pagination, sort: $sort) {
    items {
      id
      satisfactionScore
      summary
      notes
      realizationDate
      actionsCollection {
        items {
          id
          content
          actionStatus
        }
      }
      receiverId
    }
    total
  }
}
    `;

/**
 * __useWeeklyFeedbacksQuery__
 *
 * To run a query within a React component, call `useWeeklyFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyFeedbacksQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useWeeklyFeedbacksQuery(baseOptions: Apollo.QueryHookOptions<WeeklyFeedbacksQuery, WeeklyFeedbacksQueryVariables> & ({ variables: WeeklyFeedbacksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeeklyFeedbacksQuery, WeeklyFeedbacksQueryVariables>(WeeklyFeedbacksDocument, options);
      }
export function useWeeklyFeedbacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeeklyFeedbacksQuery, WeeklyFeedbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeeklyFeedbacksQuery, WeeklyFeedbacksQueryVariables>(WeeklyFeedbacksDocument, options);
        }
export function useWeeklyFeedbacksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WeeklyFeedbacksQuery, WeeklyFeedbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WeeklyFeedbacksQuery, WeeklyFeedbacksQueryVariables>(WeeklyFeedbacksDocument, options);
        }
export type WeeklyFeedbacksQueryHookResult = ReturnType<typeof useWeeklyFeedbacksQuery>;
export type WeeklyFeedbacksLazyQueryHookResult = ReturnType<typeof useWeeklyFeedbacksLazyQuery>;
export type WeeklyFeedbacksSuspenseQueryHookResult = ReturnType<typeof useWeeklyFeedbacksSuspenseQuery>;
export type WeeklyFeedbacksQueryResult = Apollo.QueryResult<WeeklyFeedbacksQuery, WeeklyFeedbacksQueryVariables>;