import { toast } from 'react-toastify';

import { useSurveyAnswersSummaryQuery } from 'documents/surveyAnswersSummary.generated';
import { SurveySummaryContent } from 'generated/webapp_gql';
import { useValidatedParams } from 'hooks';

export const useSurveyAnswersSummary = () => {
  const { surveyId } = useValidatedParams(['surveyId']);

  const { data, loading } = useSurveyAnswersSummaryQuery({
    variables: {
      surveyId,
    },
    onError: (error) => toast.error(error.message),
  });

  return {
    surveyCountsSummaryData: {
      openedCount: data?.survey?.openedSurveysCount || 0,
      respondedEmails: data?.survey?.respondedEmails || [],
    },
    surveyAnswersSummaryData: data?.survey?.summaryContent as SurveySummaryContent[],
    isSurveyAnswersSummaryLoading: loading,
  };
};
