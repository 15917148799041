import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { useEmployeeWithQuarterlyReportQuery } from 'documents/employeeWithQuarterlyReport.generated';

export const useEmployeeWithQuarterlyReport = () => {
  const { employeeId, reportId: quarterlyReportId } = useValidatedParams(['reportId', 'employeeId']);

  const { data, loading: isEmployeeWithQuarterlyReportLoading } = useEmployeeWithQuarterlyReportQuery({
    variables: { employeeId, quarterlyReportId },
    onError: (error) => toast.error(error.message),
  });

  return {
    employeeData: data?.employee,
    quarterlyReportData: data?.quarterlyReport,
    isEmployeeWithQuarterlyReportLoading,
  };
};
