import { useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';

import { SurveyLanguageController } from 'context/surveyLanguage/SurveyLanguageController';
import i18n from 'i18n/i18n';

import { SurveyTranslationProviderProps } from './SurveyTranslationProvider.types';

export const SurveyTranslationProvider = ({ children, language }: SurveyTranslationProviderProps) => {
  const i18nContext = useMemo(() => {
    const i18nContext = i18n.cloneInstance();

    i18nContext.changeLanguage(language.toLowerCase());

    return i18nContext;
  }, [language]);

  return (
    <I18nextProvider i18n={i18nContext}>
      <SurveyLanguageController language={language}>{children}</SurveyLanguageController>
    </I18nextProvider>
  );
};
