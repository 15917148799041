export const MuiAlert = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      icon: {
        padding: 0,
      },
      message: {
        padding: '1px 0',
      },
      standard: {
        color: colors.navy,
        padding: '7px',
      },
      standardError: {
        '& .MuiAlert-icon': {
          color: colors.tomato,
        },
        backgroundColor: colors.provincialPink,
        border: `1px solid ${colors.tomato}`,
      },
      standardWarning: {
        '& .MuiAlert-icon': {
          color: colors.orange,
        },
        backgroundColor: colors.serenade,
        border: `1px solid ${colors.orange}`,
      },
      standardSuccess: {
        '& .MuiAlert-icon': {
          color: colors.green,
        },
        backgroundColor: colors.offGreen,
        border: `1px solid ${colors.green}`,
      },
      standardInfo: {
        '& .MuiAlert-icon': {
          color: colors.dodgersBlue,
        },
        backgroundColor: colors.pattensBlue,
        border: `1px solid ${colors.dodgersBlue}`,
      },
    },
  };
};
