import { useSearchParams as reactRouterUseSearchParams } from 'react-router-dom';

import { SearchParam } from './useSearchParams.enum';
import { SearchParams } from './useSearchParams.types';

export const useSearchParams = () => {
  const [searchParams, setSearchParams] = reactRouterUseSearchParams();

  const params: SearchParams = {
    page: Number(searchParams.get(SearchParam.Page)),
  };

  return { searchParams: params, setSearchParams };
};
