import { useDropzone as useReactDropzone } from 'react-dropzone';

import { FileToUpload } from 'ui/uploadImageModal/UploadImageModal.types';

import { dropzoneConfig } from './useDropzone.utils';

export const useDropzone = (setImageToUpload: (file: FileToUpload) => void) => {
  const { getRootProps, getInputProps, open, isDragAccept, fileRejections, isDragReject } = useReactDropzone({
    ...dropzoneConfig,
    onDrop: (acceptedFiles) => {
      setImageToUpload({
        imgFile: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
  });

  return { getRootProps, getInputProps, open, isDragAccept, fileRejections, isDragReject };
};
