import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Container: Styles = {
  my: 8,
};
export const Question: Styles = {
  fontSize: 24,
  fontWeight: '600',
  textAlign: 'left',
};

export const UserAnswer: Styles = {
  '.MuiInput-underline::before': {
    borderBottom: 'none',
  },
  mt: 4,
};

export const SelectWrapper: Styles = {
  gap: 2,
  mt: 3,
};

export const SelectLabel = (checked?: boolean): Styles => ({
  mr: 0,
  p: '6px',
  border: `1px solid ${checked ? colors.blueberry : colors.hawkesBlue}`,
  borderRadius: 1,
  ml: 0,
  textAlign: 'left',
});

export const SelectInput: Styles = {
  '&.Mui-checked': {
    color: `${colors.blueberry} !important`,
  },
};
