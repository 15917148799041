import { IconButton, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';

import { VisitProfileButtonProps } from './VisitProfileButton.types';

export const VisitProfileButton = ({ employeeId }: VisitProfileButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton
      component={Link}
      to={`/${AppRoute.EmployeeDetails}/${employeeId}/${EmployeeDetailsViewParam.Weekly}?page=1`}
    >
      <Tooltip arrow placement="top" title={t('employeesTable.table.visitProfileTooltip')}>
        <ArrowForwardIcon />
      </Tooltip>
    </IconButton>
  );
};
