import { InternalRefetchQueriesInclude } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks';
import { Toast } from 'ui';
import { useUpdateGoalMutation } from 'documents/updateGoal.generated';
import { AllGoalsOfEmployeeDocument } from 'documents/allGoalsOfEmployee.generated';
import { TodoRowUpdate } from 'context/todoRow/TodoRowController.types';

import { UpdateGoalContentPayload } from './useUpdateGoalContent.types';

export const useUpdateGoalContent = (): TodoRowUpdate => {
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: AllGoalsOfEmployeeDocument,
      variables: {
        employeeId,
      },
    },
  ];

  const [updateGoal, { loading: isUpdateGoalLoading }] = useUpdateGoalMutation({
    refetchQueries,
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(<Toast title={t('goals.toast.heading')} message={t('goals.toast.goalUpdated')} />);
    },
  });

  const handleUpdateGoalContent = ({ id, content }: UpdateGoalContentPayload) => {
    updateGoal({
      variables: { input: { id, content } },
    });
  };

  return { onUpdateItem: handleUpdateGoalContent, isUpdateItemLoading: isUpdateGoalLoading };
};
