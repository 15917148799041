import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const QuestionsWrapper: Styles = {
  display: 'grid',
  gap: 4,
};

export const Title: Styles = {
  '&& .MuiInput-root': {
    padding: '0 !important',
  },

  '&& .MuiInput-input': {
    fontWeight: 600,
    fontSize: 24,
    pb: 1.5,
    textAlign: 'center',
    paddingTop: '16px',
  },
};

export const TitleFormControl: Styles = {
  '.MuiInputBase-root.Mui-focused:not(.Mui-error)': {
    outline: `1px solid ${colors.blueberry}`,
  },

  '.MuiInputBase-root.Mui-error': {
    outline: `1px solid ${colors.tomato}`,
  },

  '&& .MuiInputBase-root': {
    mt: 0,
    borderRadius: 1,
    paddingTop: '17.5px !important',
    marginTop: '0 !important',
  },
};

export const ControlledTextField: Styles = {
  fieldset: {
    border: 'none',
  },
};

export const Buttons: Styles = {
  display: 'flex',
  justifyContent: 'center',
  gap: 6,
};
