import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QueryOptions } from '@apollo/client';

import { useCopyPublishedSurveyTemplateMutation } from 'documents/copyPublishedSurveyTemplate.generated';
import {
  AvailableSurveyTemplatesDocument,
  AvailableSurveyTemplatesQueryVariables,
} from 'documents/availableSurveyTemplates.generated';
import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { Toast } from 'ui';

type UseCopyPublishedSurveyTemplateProps = {
  draft?: Omit<QueryOptions<AvailableSurveyTemplatesQueryVariables>['variables'], 'employeeId'>;
};
export const useCopyPublishedSurveyTemplate = (variables?: UseCopyPublishedSurveyTemplateProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { t } = useTranslation();

  const [copyPublishedSurveyTemplate] = useCopyPublishedSurveyTemplateMutation({
    refetchQueries: employeeId
      ? [
          {
            query: AvailableSurveyTemplatesDocument,
            variables: { employeeId, ...(variables?.draft ? variables.draft : {}) },
          },
        ]
      : undefined,
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(
        <Toast title={t('surveyTemplates.copyToast.title')} message={t('surveyTemplates.copyToast.subtitle')} />,
      );
    },
  });

  return { copyPublishedSurveyTemplate };
};
