import { alpha } from '@mui/material';

import { Styles } from 'shared/types';
import { theme } from 'shared/utils';

export const Switch: Styles = {
  mr: 1,

  '.MuiSwitch-thumb': {
    boxShadow: theme.shadows[2],
  },

  '.MuiSwitch-track': {
    backgroundColor: 'navy',
    opacity: 0.26,
  },

  '.MuiSwitch-switchBase:hover': {
    backgroundColor: alpha(theme.palette.border.light, 0.04),
  },
};
