import { Box, Typography } from '@mui/material';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { validateAnswers } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.utils';
import { useTranslateSurvey } from 'hooks/useTranslateSurvey/useTranslateSurvey';
import { spreadMultipleStyles } from 'shared/utils';
import { ControlledTextField } from 'ui';

import * as Styles from './LinearScale.styles';
import { LinearScaleProps } from './LinearScale.types';

export const MAX_DEFAULT_VALUE = 5;

export const MIN_OVERALL_VALUE = 1;
export const MAX_OVERALL_VALUE = 100;

export const LinearScale = ({ nestIndex, questionIndex }: LinearScaleProps) => {
  const { t } = useTranslation();
  const translateSurvey = useTranslateSurvey();

  const { control } = useFormContext<SurveyFormData>();

  useFieldArray({
    name: `sections.${nestIndex}.questions.${questionIndex}.answers`,
    control,
  });

  const minValueName = `sections.${nestIndex}.questions.${questionIndex}.answers[0].value`;
  const maxValueName = `sections.${nestIndex}.questions.${questionIndex}.answers[1].value`;

  const [answers, minValueForMaxField] = useWatch({
    name: [`sections.${nestIndex}.questions.${questionIndex}.answers`, minValueName],
  });
  const isValid = validateAnswers(answers);

  const minValue = answers?.[0].value || '';
  const maxValue = answers?.[1].value || '';

  return (
    <Box sx={Styles.LinearScaleWrapper}>
      <Box sx={Styles.LinearScaleValuesWrapper}>
        <ControlledTextField
          name={minValueName}
          control={control}
          placeholder={`${MIN_OVERALL_VALUE}`}
          type="number"
          multiline={false}
          value={minValue}
          InputProps={{ inputProps: { min: MIN_OVERALL_VALUE, max: MAX_OVERALL_VALUE - 1 } }}
          sx={spreadMultipleStyles(Styles.LinearScaleInput, Styles.LinearScaleInputValue)}
        />
        <Box sx={Styles.LinearScaleValueSeparator}>-</Box>
        <ControlledTextField
          name={maxValueName}
          control={control}
          placeholder={`${MAX_DEFAULT_VALUE}`}
          type="number"
          multiline={false}
          value={maxValue}
          InputProps={{ inputProps: { min: Number(minValueForMaxField ?? 0) + 1, max: MAX_OVERALL_VALUE } }}
          sx={spreadMultipleStyles(Styles.LinearScaleInput, Styles.LinearScaleInputValue)}
        />
      </Box>
      <Box sx={Styles.LinearScaleLabelsWrapper}>
        <ControlledTextField
          name={`sections.${nestIndex}.questions.${questionIndex}.answers[0].label`}
          control={control}
          placeholder={`${minValue || MIN_OVERALL_VALUE} ${translateSurvey(
            'surveyBuilder.questions.question.optionalLabel',
          )}`}
          multiline={false}
          value={answers?.[0].label || ''}
          sx={Styles.LinearScaleInput}
        />
        <ControlledTextField
          name={`sections.${nestIndex}.questions.${questionIndex}.answers[1].label`}
          control={control}
          placeholder={`${maxValue || MAX_DEFAULT_VALUE} ${translateSurvey(
            'surveyBuilder.questions.question.optionalLabel',
          )}`}
          multiline={false}
          value={answers?.[1].label || ''}
          sx={Styles.LinearScaleInput}
        />
      </Box>
      {!isValid && (
        <Typography variant="body2" color="error">
          {t('surveyBuilder.questions.question.twoNumberValues')}
        </Typography>
      )}
    </Box>
  );
};
