import { Box } from '@mui/material';

import GrumpyCatImg from 'assets/images/grumpy-cat.png';
import { GrumpyCatProps } from 'ui/grumpyCat/GrumpyCat.types';

import * as Styles from './GrumpyCat.styles';

export const GrumpyCat = ({ isGrumpyCatDisplayed }: GrumpyCatProps) => (
  <Box component="img" sx={Styles.GrumpyCat(isGrumpyCatDisplayed)} alt="grumpy cat" src={GrumpyCatImg} />
);
