import { Styles } from '../../../../shared/types';
import { colors } from '../../../../shared/utils';

export const Container: Styles = {
  marginBottom: '1rem',
};

export const EmployeeGoalSection: Styles = {
  width: '100%',
  maxWidth: '280px',
};

export const Heading: Styles = {
  color: colors.slateGray,
  fontWeight: 'bold',
  marginBottom: '0.25rem',
  fontSize: '0.90rem',
};

export const List: Styles = {
  padding: '0.5rem 0',
  fontWeight: 'normal',
};

export const ListItem: Styles = {
  padding: '0.5rem 0',
  borderBottom: `1px solid ${colors.lightGray}`,
  '&:last-child': {
    borderBottom: 'none',
  },
};

export const RedirectButton: Styles = {
  marginRight: '1rem',
};
