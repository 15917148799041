import { Styles } from 'shared/types';

export const TabsWrapper: Styles = {
  borderBottom: 1,
  borderColor: 'divider',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const Tabs: Styles = {
  '& .MuiTab-root': {
    width: 144,
    px: 1,
    py: 1.5,
    textTransform: 'none',
    fontWeight: 600,
  },
};
