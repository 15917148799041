import { Draggable } from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, IconButton, Typography } from '@mui/material';
import { KeyboardEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { ReactComponent as DeleteIcon } from 'assets/images/DeleteIcon.svg';
import { SurveyQuestionFormat } from 'generated/webapp_gql';
import { useTranslateSurvey } from 'hooks/useTranslateSurvey/useTranslateSurvey';
import { ControlledTextField, FilledIconAlert } from 'ui';
import { LinearScale } from 'app/questionsWrapper/questions/question/linearScale/LinearScale';
import { Answers } from 'app/questionsWrapper/questions/question/answers/Answers';
import { QuestionFormatDropdown } from 'app/questionsWrapper/questions/question/questionFormatDropdown/QuestionFormatDropdown';

import * as Styles from './Question.styles';
import { QuestionProps } from './Question.types';

export const Question = ({ nestIndex, index, id, onDelete }: QuestionProps) => {
  const { control, setValue } = useFormContext<SurveyFormData>();

  const { t } = useTranslation();

  const translateSurvey = useTranslateSurvey();

  const handleSetQuestionFormat = (format: SurveyQuestionFormat) => {
    setValue(`sections.${nestIndex}.questions.${index}.format`, format);
  };

  const handleResetAnswers = () => {
    setValue(`sections.${nestIndex}.questions.${index}.answers`, [{}, {}]);
  };

  const [format, question] = useWatch<SurveyFormData>({
    name: [`sections.${nestIndex}.questions.${index}.format`, `sections.${nestIndex}.questions.${index}.answers`],
  });

  const handleEnterPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Draggable key={id} draggableId={`item-${id}`} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <Box ref={innerRef} {...draggableProps} sx={Styles.Wrapper}>
          <Box sx={Styles.InputRow}>
            <IconButton color="primary" aria-label="Delete form" {...dragHandleProps} sx={Styles.DragIconButton}>
              <DragIndicatorIcon sx={Styles.DragIcon} />
            </IconButton>

            <Box sx={Styles.InputWrapper}>
              <ControlledTextField
                name={`sections.${nestIndex}.questions.${index}.question`}
                hiddenLabel
                control={control}
                placeholder={translateSurvey('surveyBuilder.questions.question.placeholder')}
                sx={Styles.TextField}
                formControlSx={Styles.FormControl}
                onKeyDown={handleEnterPress}
                InputProps={{
                  startAdornment: <Typography sx={Styles.Adornment}>{`${index + 1}. `}</Typography>,

                  endAdornment: (
                    <QuestionFormatDropdown
                      onSetQuestionFormat={handleSetQuestionFormat}
                      onResetAnswers={handleResetAnswers}
                      format={format}
                    />
                  ),

                  disableUnderline: true,
                }}
              />

              {!question.length && (
                <FilledIconAlert severity="info" sx={Styles.Alert}>
                  {`${t('surveyBuilder.questions.question.alert.partOne')} `}
                  <Box component="span" sx={Styles.TextBold}>
                    {`${t('surveyBuilder.questions.question.alert.partTwo')} `}
                  </Box>{' '}
                  {t('surveyBuilder.questions.question.alert.partThree')}
                </FilledIconAlert>
              )}
            </Box>

            <IconButton
              color="primary"
              aria-label="Delete form"
              {...dragHandleProps}
              sx={Styles.DeleteIconButton}
              onClick={() => {
                onDelete(index);
              }}
            >
              <DeleteIcon style={Styles.DeleteIcon} />
            </IconButton>
          </Box>

          <Box sx={Styles.AnswersFormatWrapper}>
            {(format === SurveyQuestionFormat.MultipleChoice || format === SurveyQuestionFormat.CloseEnded) && (
              <Box sx={Styles.AnswersWrapper}>
                <Answers nestIndex={nestIndex} questionIndex={index} format={format} />
              </Box>
            )}

            {format === SurveyQuestionFormat.LinearScale && <LinearScale nestIndex={nestIndex} questionIndex={index} />}
          </Box>
        </Box>
      )}
    </Draggable>
  );
};
