import { QueryOptions } from '@apollo/client';

import { EmployeeDocument, EmployeeQueryVariables } from '../../documents/employee.generated';

export const getRefetchOptionsForEmployee = (
  employeeId: EmployeeQueryVariables['employeeId'],
): QueryOptions<EmployeeQueryVariables> => ({
  query: EmployeeDocument,
  variables: {
    employeeId,
  },
});
