import { TabContainer } from '../components/TabContainer';
import { ArchivedSurveyListItem, SurveysListTab } from '../SurveyTemplatesTabs.types';
import { SortVariables, SurveyTabProps } from '../SurveyTabs.types';
import { SurveySortingOptions } from '../SurveyTabs.utils';
import { useSurveyTabsSortingContext } from '../SurveyTabsSorting/useSurveyTabsSortingContext';

import { ArchivedSurveyList } from './archivedSurveysList/ArchivedSurveyList';

type ArchivedTabProps = {
  items: ArchivedSurveyListItem[];
  onChangeCurrentTab: (value: SurveysListTab) => void;
} & SurveyTabProps;
export const ArchivedTab = ({ items, onChangeCurrentTab, ...rest }: ArchivedTabProps) => {
  const { setSortingParams } = useSurveyTabsSortingContext();

  const handleChangeSorting = (sortData: SortVariables) => {
    setSortingParams((prevTabsSortingData) => ({ ...prevTabsSortingData, [SurveysListTab.Archived]: sortData }));
  };

  return (
    <TabContainer sortingOptions={SurveySortingOptions} onChangeSorting={handleChangeSorting} {...rest}>
      <ArchivedSurveyList items={items.filter(Boolean)} changeCurrentTab={onChangeCurrentTab} />
    </TabContainer>
  );
};
