import { useSearchParams as reactRouterUseSearchParams } from 'react-router-dom';

import { SearchParams } from './useEmployeeActionsSearchParams.types';
import { paramsGetPageNumber } from './useEmployeeActionsSearchParams.utils';

export const useEmployeeActionsSearchParams = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = reactRouterUseSearchParams();

  const params: SearchParams = {
    in_progress: paramsGetPageNumber('in_progress'),
    done: paramsGetPageNumber('done'),
    canceled: paramsGetPageNumber('canceled'),
  };

  return { searchParams: params, setSearchParams };
};
