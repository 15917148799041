import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  onSearchParamsChange,
  validateArrayParam,
  validateBooleanParam,
  validateNumberParam,
  validateSortingParam,
} from 'hooks/utils';
import { SortDirection } from 'shared/types/enums/SortDirection.enum';

import {
  UseSearchParamOptions,
  UseSearchParamProps,
  UseSearchParamReturn,
  UseSearchParamTypeNames,
  UseSearchParamTypes,
} from './useSearchParam.types';

export function useSearchParam({
  paramName,
  type,
}: UseSearchParamProps<'array'>): UseSearchParamReturn<string[] | null>;
export function useSearchParam({ paramName, type }: UseSearchParamProps<'number'>): UseSearchParamReturn<number | null>;
export function useSearchParam({
  paramName,
  type,
  options,
}: UseSearchParamProps<'number', UseSearchParamOptions>): UseSearchParamReturn<number>;
export function useSearchParam({
  paramName,
  type,
  options,
}: UseSearchParamProps<'page', UseSearchParamOptions>): UseSearchParamReturn<number>;
export function useSearchParam({
  paramName,
  type,
  options,
}: UseSearchParamProps<'boolean', UseSearchParamOptions>): UseSearchParamReturn<boolean>;
export function useSearchParam({ paramName, type }: UseSearchParamProps<'string'>): UseSearchParamReturn<string | null>;
export function useSearchParam({
  paramName,
  type,
}: UseSearchParamProps<'sorting'>): UseSearchParamReturn<[string, SortDirection] | null>;

export function useSearchParam({
  paramName,
  additionalStateParamsToClear,
  type,
  options,
}: UseSearchParamProps<UseSearchParamTypeNames, UseSearchParamOptions | undefined>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRef = useRef(true);

  const paramValue = searchParams.get(paramName);

  const getParamReturnValue = <T extends UseSearchParamTypes>(value: T): UseSearchParamReturn<T | null> => {
    const validatedParamValue: UseSearchParamReturn<T | null> = [
      value ?? <T>options?.defaultValue ?? null,
      onSearchParamsChange<T>({
        searchParams,
        setSearchParams,
        paramName,
        additionalStateParamsToClear,
        options,
        isInitial: isInitialRef.current,
      }),
    ];

    isInitialRef.current = false;

    return validatedParamValue;
  };

  switch (type) {
    case 'boolean':
      return getParamReturnValue(validateBooleanParam(paramValue, options?.defaultValue));

    case 'page':
    case 'number':
      return getParamReturnValue(validateNumberParam(paramValue));

    case 'array':
      return getParamReturnValue(validateArrayParam(paramValue));

    case 'string':
      return getParamReturnValue(paramValue);

    case 'sorting':
      return getParamReturnValue(validateSortingParam(paramValue));
  }
}
