export enum AppRoute {
  Home = '/',
  Employees = 'employees',
  EmployeeDetails = 'employee-details',
  EmployeeActions = 'employee-actions',
  EmployeeSurveys = 'employee-surveys',
  EmployeeGoals = 'employee-goals',
  AddFeedback = 'add-feedback',
  EditFeedback = 'edit-feedback',
  PreviewFeedback = 'preview-feedback',
  Login = 'login',
  FormCreation = 'form-creation',
  SurveyForm = 'survey-form',
  SurveyTemplates = 'survey-templates',
  SurveyBuilder = 'survey-builder',
  SurveyEditor = 'survey-editor',
  PublishedSurvey = 'published-survey',
  Survey = 'survey',
  PreviewReport = 'preview-report',
  AddReport = 'add-report',
  EditReport = 'edit-report',
}

export enum AppRouteParam {
  EmployeeId = ':employeeId',
  EmployeeDetailsView = ':employeeDetailsView',
  FeedbackId = ':feedbackId',
  ReportId = ':reportId',
  SurveyId = ':surveyId',
  SurveyTemplateId = ':surveyTemplateId',
}

export enum EmployeeDetailsViewParam {
  Weekly = 'weekly',
  Quarterly = 'quarterly',
}
