import { useTranslation } from 'react-i18next';

import { useModal, useUpdateAction } from 'hooks';
import { ActionStatus } from 'generated/webapp_gql';
import { CardChecklist } from 'ui';
import { ToDoType } from 'ui/modal/Modal.enum';

import { CardActionsChecklistProps } from './CardActionsChecklist.types';

export const CardActionsChecklist = ({ actions, isReadOnly }: CardActionsChecklistProps) => {
  const { t } = useTranslation();

  const { sendAction, isUpdateActionLoading } = useUpdateAction();
  const modal = useModal<ActionStatus>({
    onConfirm: sendAction,
    canceledStatus: ActionStatus.Canceled,
    doneStatus: ActionStatus.Done,
  });

  return (
    <CardChecklist
      items={actions}
      isUpdateLoading={isUpdateActionLoading}
      modal={modal}
      toDoType={ToDoType.Action}
      label={t('feedback.feedbackCard.actions')}
      isReadOnly={isReadOnly}
    />
  );
};
