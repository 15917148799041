import { Styles } from 'shared/types';

export const Container: Styles = {
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 1024,
  pt: 4,
  pb: 6,
  px: { xs: 2, md: 4 },
  boxSizing: 'content-box',
};

export const LogoWrapper: Styles = {
  py: 4,
  display: 'flex',
  justifyContent: 'space-between',
};

export const SaveButton: Styles = {
  height: 51,
  width: 220,
};
