import { Box, Button } from '@mui/material';
import { useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { QuestionsSectionWrapper } from 'app/surveyBuilder/surveyBuilderQuestions/questionsSection/QuestionsSectionWrapper';
import { SurveyLanguageController } from 'context/surveyLanguage/SurveyLanguageController';
import { SurveyContent } from 'generated/webapp_gql';
import { useSurveyImages, useUpdateSurveyTemplate, useValidatedParams } from 'hooks';
import { AsyncStatus } from 'shared/utils/types';
import { ControlledTextField } from 'ui';
import { SurveyFormData } from '../surveyBuilderLayout/SurveyBuilderLayout.types';

import { LanguagePicker } from './languagePicker/LanguagePicker';
import { SectionWithImage } from './sectionWithImage/SectionWithImage';
import { Section } from './SurveyBuilderQuestions.enum';
import * as Styles from './SurveyBuilderQuestions.styles';
import { SurveyBuilderQuestionsProps } from './SurveyBuilderQuestions.types';
import { SurveyReceiverSection } from './surveyReceiverSection/SurveyReceiverSection';

export const SurveyBuilderQuestions = ({ goToPublicationPage }: SurveyBuilderQuestionsProps) => {
  const { t } = useTranslation();
  const { surveyTemplateId } = useValidatedParams(['surveyTemplateId']);
  const [, setForceUpdate] = useState(0);

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty, isValid },
  } = useFormContext<SurveyFormData>();
  const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>(AsyncStatus.Idle);
  const location = useLocation();
  const state = location.state as { isNewSurveyTemplate?: boolean };
  const hasBeenSavedRef = useRef(!state?.isNewSurveyTemplate);

  const {
    openedModal,
    setOpenedModal,
    introImage,
    outroImage,
    updateIntroImageName,
    updateOutroImageName,
    handleIntroImageChange,
    handleOutroImageChange,
  } = useSurveyImages(setValue, setForceUpdate);

  const language = useWatch({ name: 'language' });

  const handleOpenedModal = (section: Section | null) => {
    setOpenedModal(section);
  };

  const { updateSurveyTemplateMutation } = useUpdateSurveyTemplate();

  const onSaveSurveyTemplate = async ({
    beginning,
    conclusion,
    sections,
    title,
    introImageName,
    endImageName,
    language,
  }: SurveyFormData) => {
    setAsyncStatus(AsyncStatus.Loading);

    const content: SurveyContent = {
      beginning,
      conclusion,
      sections,
    };
    try {
      await updateSurveyTemplateMutation({
        variables: {
          input: {
            id: surveyTemplateId,
            title,
            content,
            introImageName: introImageName || '',
            language,
            endImageName: endImageName || '',
          },
        },
      });
      hasBeenSavedRef.current = true;
      setAsyncStatus(AsyncStatus.Success);
    } catch {
      setAsyncStatus(AsyncStatus.Failed);
    }
  };
  const isLoading = asyncStatus === AsyncStatus.Loading;

  const handleGoToPublicationPage = async () => {
    if (!surveyTemplateId || isDirty) {
      await onSaveSurveyTemplate(getValues());
      reset({}, { keepValues: true });
    }
    goToPublicationPage();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSaveSurveyTemplate)} sx={Styles.QuestionsWrapper}>
      <ControlledTextField
        control={control}
        name="title"
        placeholder={t('surveyBuilder.questions.surveyTitlePlaceholder')}
        sx={Styles.Title}
        formControlSx={Styles.TitleFormControl}
      />
      <SurveyReceiverSection />
      <LanguagePicker />
      <SectionWithImage
        label={t('surveyBuilder.questions.intro.title')}
        addImageLabel={t('surveyBuilder.questions.addPhoto')}
        image={introImage}
        imageSrc={introImage?.preview || getValues().introImageUrl}
        setImage={handleIntroImageChange}
        isModalOpened={openedModal === Section.Beginning}
        sectionName={Section.Beginning}
        setOpenedModal={handleOpenedModal}
        control={control}
        updateImageName={updateIntroImageName}
      />
      <SurveyLanguageController language={language}>
        <QuestionsSectionWrapper />
      </SurveyLanguageController>
      <SectionWithImage
        label={t('surveyBuilder.questions.outro.title')}
        addImageLabel={t('surveyBuilder.questions.addMeme.title')}
        editImageLabel={t('surveyBuilder.questions.addMeme.edit')}
        addImageDescription={t('surveyBuilder.questions.addMeme.description')}
        addImageSuccessMessage={t('surveyBuilder.questions.addMeme.successMessage')}
        image={outroImage}
        imageSrc={outroImage?.preview || getValues().endImageUrl}
        setImage={handleOutroImageChange}
        isModalOpened={openedModal === Section.Conclusion}
        sectionName={Section.Conclusion}
        setOpenedModal={handleOpenedModal}
        control={control}
        updateImageName={updateOutroImageName}
      />
      <Box sx={Styles.Buttons}>
        <Button type="submit" variant="secondary" disabled={asyncStatus === AsyncStatus.Loading}>
          {hasBeenSavedRef.current ? t('formCreator.btnSave') : t('formCreator.btnSaveAsTemplate')}
        </Button>
        <Button type="button" variant="contained" onClick={handleGoToPublicationPage} disabled={!isValid || isLoading}>
          {t('formCreator.btnPublish')}
        </Button>
      </Box>
    </Box>
  );
};
