import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const AvatarSection: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 1,
  pb: 2.5,
};

export const Heading: Styles = {
  color: colors.slateGray,
};

export const Name: Styles = {
  fontWeight: 700,
};
