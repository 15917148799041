import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Paper, TextField, Typography } from '@mui/material';

import * as Styles from '../SurveyAnswers.styles';
import { LocalPagination } from 'ui';

import { SurveyAnswersQuestionProps } from './SurveyAnswersQuestion.types';

export const SurveyAnswersQuestion = ({ questionsData }: SurveyAnswersQuestionProps) => {
  const { t } = useTranslation();

  const questions = useMemo(() => questionsData.map(({ question }) => question), [questionsData]);

  const [page, setPage] = useState(1);
  const [selectedListItem, setSelectedListItem] = useState(questions[0]);

  const changeRespondentByName = (newName: string | null) => {
    if (!newName) {
      return;
    }

    setSelectedListItem(newName);
    setPage(questions.indexOf(newName) + 1);
  };

  const changeRespondentByPage = (newPage: number) => {
    setSelectedListItem(questions[newPage - 1]);
    setPage(newPage);
  };

  const selectedQuestion = useMemo(
    () => questionsData.find(({ question }) => question === selectedListItem),
    [selectedListItem, questionsData],
  );

  return (
    <Box>
      <Box sx={Styles.ListWrapper}>
        <Autocomplete
          options={questions}
          value={selectedListItem}
          fullWidth
          onChange={(_, value) => {
            value && changeRespondentByName(value);
          }}
          renderInput={(params) => (
            <TextField {...params} label={t('publishedSurvey.answersTab.questionTab.questionLabel')} />
          )}
          PaperComponent={(props) => <Paper {...props} sx={Styles.AutocompletePaper} />}
        />
        <LocalPagination count={questions.length} page={page} onChange={changeRespondentByPage} />
      </Box>

      <Box sx={Styles.ScrollContainer}>
        <Box sx={Styles.QuestionWrapper}>
          <Typography variant="h6">{selectedQuestion?.question}</Typography>
          <Box>
            {selectedQuestion?.answers.map((value, index) => (
              <Box key={index} sx={Styles.Answers}>
                <Typography variant="body1" sx={Styles.AnswerTypography}>
                  {value?.answer.map((a, index) => <span key={index}>{a}</span>)}
                </Typography>
                <Typography variant="body2" sx={Styles.EmailTypography}>
                  {value?.email}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
