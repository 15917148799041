import { useCallback } from 'react';

import {
  LocalStorageParser,
  LocalStorageParserResponse,
  LocalStorageParserType,
  LocalStorageValueType,
} from './useLocalStorage.types';

const localStorageParsers: Record<LocalStorageParserType, LocalStorageParser> = {
  number: {
    onLoad: (value) => (value == null ? null : Number(value)),
    onSet: (value) => (typeof value === 'number' ? value.toString() : null),
  },
};

export const useLocalStorage = <TParserType extends LocalStorageParserType>(
  key: string,
  parserType: LocalStorageParserType,
): LocalStorageParserResponse<TParserType> => {
  const parser = localStorageParsers[parserType];

  const initialValue = parser.onLoad(localStorage.getItem(key)) as LocalStorageValueType[TParserType] | null;

  const handleSetValue = useCallback(
    (newValue: LocalStorageValueType[TParserType] | null) => {
      if (newValue == null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, parser.onSet(newValue) || '');
      }
    },
    [key, parser],
  );

  return [initialValue, handleSetValue];
};
