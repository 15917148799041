import { Styles } from 'shared/types';

export const Table = ({
  withEdit,
  withoutMutation,
  withoutCheckbox,
}: {
  withEdit?: boolean;
  withoutMutation?: boolean;
  withoutCheckbox?: boolean;
}): Styles => {
  const gridTemplateColumnsForMutation = [withEdit ? '52px' : '', !withoutMutation ? '52px' : ''].join(' ');
  const gridTemplateAreasForMutation = [!withoutMutation ? 'delete' : '', withEdit ? 'edit' : ''].join(' ');

  return {
    display: 'grid',
    gridTemplateColumns: `${withoutCheckbox ? '' : '46px'} auto auto ${gridTemplateColumnsForMutation} 48px`,
    minHeight: 52,
    gridTemplateRows: 'auto',
    gridTemplateAreas: `'${withoutCheckbox ? '' : 'checkbox'} title date ${gridTemplateAreasForMutation} moreButton'`,
    borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
  };
};

const CommonColumnStyle = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
};

const CommonBorderRight: Styles = {
  borderRight: ({ palette }) => `1px solid ${palette.divider}`,
};

const CommonCenterStyles = {
  placeSelf: 'center',
  display: 'flex',
  justifyContent: 'center',
};

const CommonAlignCenterStyles = {
  alignSelf: 'center',
  fontSize: 14,
};

export const TableRowDate = (withoutDelete?: boolean): Styles => ({
  ...CommonColumnStyle,
  ...CommonAlignCenterStyles,
  ...(!withoutDelete && CommonBorderRight),
  gridArea: 'date',
  justifySelf: 'end',
  justifyContent: 'flex-end',
  pr: 2,
  color: 'typography.lightText',
});

export const DeleteButton = {
  ...CommonColumnStyle,
  ...CommonCenterStyles,
  ...CommonBorderRight,
  gridArea: 'delete',
};

export const EditButton = {
  ...CommonColumnStyle,
  ...CommonCenterStyles,
  ...CommonBorderRight,
  gridArea: 'edit',
};

export const MoreButton: Styles = {
  ...CommonColumnStyle,
  ...CommonCenterStyles,
  gridArea: 'moreButton',
};

const CommonIconStyles = {
  cursor: 'pointer',
};

export const DeleteIcon: Styles = {
  ...CommonIconStyles,
  color: 'error.main',
};

export const MoreIcon: Styles = {
  ...CommonIconStyles,
  color: 'primary.main',
};
