import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SurveyIntroImg } from 'assets/images/SurveyIntro.svg';
import { AvatarWithFallback } from 'ui';

import * as Styles from './PreviewSurveyIntro.styles';
import { PreviewSurveyIntroProps } from './PreviewSurveyIntro.types';

export const PreviewSurveyIntro = ({ intro, employee, onStartBtnClick, surveyOwnerData }: PreviewSurveyIntroProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.Wrapper}>
      <Box sx={Styles.Container}>
        <Box sx={Styles.User}>
          <Typography variant="body1" sx={Styles.UserAvatarDescription}>
            {t('surveyPreview.intro.concerns')}
          </Typography>
          <AvatarWithFallback size="xl" src={employee.avatarUrl} alt={employee.fullName ?? ''} />
          <Typography variant="subtitle1" sx={Styles.FullName}>
            {employee.fullName ?? ''}
          </Typography>
        </Box>
        <Box sx={Styles.ContentContainer}>
          <Box sx={Styles.IntroContainer}>
            <Typography variant="h5" component="div" sx={Styles.Hello}>
              <span>👋</span>
              <span>{t('surveyPreview.intro.hi')}</span>
            </Typography>
            <Box sx={Styles.Intro}>{intro.intro}</Box>
            <Button endIcon={<ArrowForwardIcon />} variant="contained" onClick={onStartBtnClick}>
              {t('surveyPreview.intro.start')}
            </Button>
          </Box>
          <Box sx={Styles.IntroImg}>
            {intro.introImgUrl && <img src={intro.introImgUrl} alt={intro.introImgName} />}
            {!intro.introImgUrl && <SurveyIntroImg />}
          </Box>
        </Box>
        <Box sx={Styles.Creator}>
          <Typography variant="body1" sx={Styles.CreatorAvatarDescription}>
            {t('surveyPreview.intro.createdBy')}
          </Typography>
          <AvatarWithFallback
            size="m"
            src={surveyOwnerData.surveyOwnerAvatarUrl}
            alt={surveyOwnerData?.surveyOwnerFullName ?? ''}
          />
          <Typography variant="subtitle1" sx={Styles.FullName}>
            {surveyOwnerData?.surveyOwnerFullName ?? ''}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
