import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateSurveyResponsesCsvQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID']['input'];
}>;


export type GenerateSurveyResponsesCsvQuery = { __typename?: 'Query', generateCsvFromSurveyResponses?: string | null };


export const GenerateSurveyResponsesCsvDocument = gql`
    query GenerateSurveyResponsesCsv($surveyId: ID!) {
  generateCsvFromSurveyResponses(surveyId: $surveyId)
}
    `;

/**
 * __useGenerateSurveyResponsesCsvQuery__
 *
 * To run a query within a React component, call `useGenerateSurveyResponsesCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateSurveyResponsesCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateSurveyResponsesCsvQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGenerateSurveyResponsesCsvQuery(baseOptions: Apollo.QueryHookOptions<GenerateSurveyResponsesCsvQuery, GenerateSurveyResponsesCsvQueryVariables> & ({ variables: GenerateSurveyResponsesCsvQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateSurveyResponsesCsvQuery, GenerateSurveyResponsesCsvQueryVariables>(GenerateSurveyResponsesCsvDocument, options);
      }
export function useGenerateSurveyResponsesCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateSurveyResponsesCsvQuery, GenerateSurveyResponsesCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateSurveyResponsesCsvQuery, GenerateSurveyResponsesCsvQueryVariables>(GenerateSurveyResponsesCsvDocument, options);
        }
export function useGenerateSurveyResponsesCsvSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GenerateSurveyResponsesCsvQuery, GenerateSurveyResponsesCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenerateSurveyResponsesCsvQuery, GenerateSurveyResponsesCsvQueryVariables>(GenerateSurveyResponsesCsvDocument, options);
        }
export type GenerateSurveyResponsesCsvQueryHookResult = ReturnType<typeof useGenerateSurveyResponsesCsvQuery>;
export type GenerateSurveyResponsesCsvLazyQueryHookResult = ReturnType<typeof useGenerateSurveyResponsesCsvLazyQuery>;
export type GenerateSurveyResponsesCsvSuspenseQueryHookResult = ReturnType<typeof useGenerateSurveyResponsesCsvSuspenseQuery>;
export type GenerateSurveyResponsesCsvQueryResult = Apollo.QueryResult<GenerateSurveyResponsesCsvQuery, GenerateSurveyResponsesCsvQueryVariables>;