import { Styles } from 'shared/types';
import { colors, theme } from 'shared/utils';

export const CardWrapper: Styles = {
  px: 3,
  pt: 1,
  pb: 2,
  position: 'relative',
  mb: 3,
  backgroundColor: colors.ghostWhite,
};

export const CardHeader: Styles = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };

export const CardActions: Styles = { display: 'flex' };

export const CardDate: Styles = { color: theme.palette.text.primary, mr: 3 };

export const CardActionButton: Styles = {
  '&& svg': {
    fontSize: 24,
  },
};

export const CardInfoWrapper: Styles = { display: 'flex', alignItems: 'center' };

export const CardSection: Styles = { my: 2.5, position: 'relative' };

export const CardSectionLabel: Styles = { color: theme.palette.text.primary };

export const CardSectionContent = (isChecked?: boolean): Styles => ({
  color: isChecked ? colors.disabledGray : theme.palette.text.secondary,
  cursor: 'pointer',
  wordBreak: 'break-word',
});
export const CardDivider: Styles = { my: 1 };

export const Alert: Styles = { width: 'auto', my: 3 };

export const CardSectionAction: Styles = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,

  '&:hover': {
    backgroundColor: colors.brightGray,
  },
};

export const ActionsLoaderWrapper: Styles = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  backgroundColor: colors.ghostWhite,
  opacity: 0.8,

  div: {
    backgroundColor: 'transparent',

    span: {
      filter: 'blur(0)',
    },
  },
};

export const Chip = (isInProgress: boolean): Styles => ({
  border: '1px solid',
  fontWeight: 600,
  color: isInProgress ? colors.orangePeel : colors.green,
  backgroundColor: isInProgress ? colors.serenade : colors.offGreen,
  borderColor: isInProgress ? colors.orangePeel : colors.green,
});
