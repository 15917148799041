import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';
import { SurveyStatus } from 'generated/webapp_gql';
import { useUpdateSurveyMutation } from 'documents/updateSurvey.generated';

export type UseChangeSurveyStatusProps = {
  newSurveyStatus?: SurveyStatus.Closed | SurveyStatus.Opened;
  queryOptions?: Parameters<typeof useUpdateSurveyMutation>[number];
};

export const useChangeSurveyStatus = ({
  newSurveyStatus = SurveyStatus.Closed,
  queryOptions,
}: UseChangeSurveyStatusProps = {}) => {
  const { t } = useTranslation();

  const status = newSurveyStatus.toLowerCase() as Lowercase<SurveyStatus.Closed | SurveyStatus.Opened>;

  const [changeSurveyStatus] = useUpdateSurveyMutation({
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(
        <Toast
          title={t(`surveyBuilder.questions.surveyState.title.${status}`)}
          message={t(`surveyBuilder.questions.surveyState.toastSubtitle.${status}`)}
        />,
      );
    },
    ...queryOptions,
  });

  return { changeSurveyStatus };
};
