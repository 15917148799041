import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveyReceiversQueryVariables = Types.Exact<{
  searchedValue: Types.Scalars['searchedValue_String_NotNull_minLength_3']['input'];
  surveyId: Types.Scalars['String']['input'];
  getHasReceivedSurvey: Types.Scalars['Boolean']['input'];
  notificatorType: Types.NotificatorType;
}>;


export type SurveyReceiversQuery = { __typename?: 'Query', surveyReceivers?: { __typename?: 'SurveyReceiversCollection', total: number, items: Array<{ __typename?: 'SurveyReceiver', id: string, fullName: string, lastName?: string | null, email: string, firstName?: string | null, avatarUrl?: string | null, level?: string | null, receivedSurveyStatus?: Types.SurveyResponseStatus | null } | null> } | null };


export const SurveyReceiversDocument = gql`
    query SurveyReceivers($searchedValue: searchedValue_String_NotNull_minLength_3!, $surveyId: String!, $getHasReceivedSurvey: Boolean!, $notificatorType: NotificatorType!) {
  surveyReceivers(
    searchedValue: $searchedValue
    notificatorType: $notificatorType
  ) {
    total
    items {
      id
      fullName
      lastName
      email
      firstName
      avatarUrl
      level
      receivedSurveyStatus(surveyId: $surveyId) @include(if: $getHasReceivedSurvey)
    }
  }
}
    `;

/**
 * __useSurveyReceiversQuery__
 *
 * To run a query within a React component, call `useSurveyReceiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyReceiversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyReceiversQuery({
 *   variables: {
 *      searchedValue: // value for 'searchedValue'
 *      surveyId: // value for 'surveyId'
 *      getHasReceivedSurvey: // value for 'getHasReceivedSurvey'
 *      notificatorType: // value for 'notificatorType'
 *   },
 * });
 */
export function useSurveyReceiversQuery(baseOptions: Apollo.QueryHookOptions<SurveyReceiversQuery, SurveyReceiversQueryVariables> & ({ variables: SurveyReceiversQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyReceiversQuery, SurveyReceiversQueryVariables>(SurveyReceiversDocument, options);
      }
export function useSurveyReceiversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyReceiversQuery, SurveyReceiversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyReceiversQuery, SurveyReceiversQueryVariables>(SurveyReceiversDocument, options);
        }
export function useSurveyReceiversSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveyReceiversQuery, SurveyReceiversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveyReceiversQuery, SurveyReceiversQueryVariables>(SurveyReceiversDocument, options);
        }
export type SurveyReceiversQueryHookResult = ReturnType<typeof useSurveyReceiversQuery>;
export type SurveyReceiversLazyQueryHookResult = ReturnType<typeof useSurveyReceiversLazyQuery>;
export type SurveyReceiversSuspenseQueryHookResult = ReturnType<typeof useSurveyReceiversSuspenseQuery>;
export type SurveyReceiversQueryResult = Apollo.QueryResult<SurveyReceiversQuery, SurveyReceiversQueryVariables>;