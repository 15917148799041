import { useSearchParam } from 'hooks';

import { EmployeesSortingParam } from './useEmployeesSorting.enum';

export const useEmployeesSorting = () => {
  const sort = useSearchParam({ paramName: EmployeesSortingParam.Sort, type: 'sorting' });

  const employeesWithActionsSort = useSearchParam({
    paramName: EmployeesSortingParam.EmployeesWithActionsSort,
    type: 'sorting',
  });

  return {
    sort,
    employeesWithActionsSort,
  };
};
