import i18n from 'i18next';
import { pl } from 'date-fns/locale';
import { plPL } from '@mui/x-data-grid';

import { Locale } from './i18n.types';

const { language } = i18n;

export const getLocale = (): Locale => {
  switch (language) {
    case 'pl':
      return {
        dateTimeLocale: pl,
        dataGridLocaleText: plPL.components.MuiDataGrid.defaultProps.localeText,
      };
    default:
      return {
        dateTimeLocale: pl,
        dataGridLocaleText: plPL.components.MuiDataGrid.defaultProps.localeText,
      };
  }
};
