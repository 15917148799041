import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSurveyReceiverMutationVariables = Types.Exact<{
  surveyResponseId: Types.Scalars['String']['input'];
}>;


export type DeleteSurveyReceiverMutation = { __typename?: 'Mutation', deleteSurveyReceiver?: { __typename?: 'MessageObject', message?: string | null } | null };


export const DeleteSurveyReceiverDocument = gql`
    mutation DeleteSurveyReceiver($surveyResponseId: String!) {
  deleteSurveyReceiver(surveyResponseId: $surveyResponseId) {
    message
  }
}
    `;
export type DeleteSurveyReceiverMutationFn = Apollo.MutationFunction<DeleteSurveyReceiverMutation, DeleteSurveyReceiverMutationVariables>;

/**
 * __useDeleteSurveyReceiverMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyReceiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyReceiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyReceiverMutation, { data, loading, error }] = useDeleteSurveyReceiverMutation({
 *   variables: {
 *      surveyResponseId: // value for 'surveyResponseId'
 *   },
 * });
 */
export function useDeleteSurveyReceiverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyReceiverMutation, DeleteSurveyReceiverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyReceiverMutation, DeleteSurveyReceiverMutationVariables>(DeleteSurveyReceiverDocument, options);
      }
export type DeleteSurveyReceiverMutationHookResult = ReturnType<typeof useDeleteSurveyReceiverMutation>;
export type DeleteSurveyReceiverMutationResult = Apollo.MutationResult<DeleteSurveyReceiverMutation>;
export type DeleteSurveyReceiverMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyReceiverMutation, DeleteSurveyReceiverMutationVariables>;