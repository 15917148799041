import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Box, Checkbox, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ActionStatus, GoalStatus, SurveyStatus } from 'generated/webapp_gql';
import * as Styles from 'ui/itemTitleWithCheckbox/ItemTitleWithCheckbox.styles';
import { ItemTitleWithCheckboxProps } from 'ui/itemTitleWithCheckbox/ItemTitleWithCheckbox.types';

export const ItemTitleWithCheckbox = ({
  type,
  content,
  withTooltip,
  onClick,
  isReadOnly,
  withoutCheckbox,
}: ItemTitleWithCheckboxProps) => {
  const { t } = useTranslation();

  const isInProgress = type && [GoalStatus.InProgress, ActionStatus.InProgress, SurveyStatus.Opened].includes(type);
  const isDone = type && [GoalStatus.Done, ActionStatus.Done, SurveyStatus.Closed].includes(type);
  const isCanceled = type && [GoalStatus.Canceled, ActionStatus.Canceled, SurveyStatus.Canceled].includes(type);

  const getTooltipTitle = () => {
    if (!withTooltip) return '';

    if (isDone) return t('feedback.feedbackCard.actionDone');
    if (isCanceled) return t('feedback.feedbackCard.actionCanceled');

    return '';
  };

  return (
    <>
      {!withoutCheckbox && (
        <Box sx={Styles.CheckboxWrapper}>
          <Checkbox
            sx={Styles.Checkbox(type)}
            checkedIcon={isDone ? <CheckBoxRoundedIcon /> : <IndeterminateCheckBoxRoundedIcon />}
            disabled={!isInProgress || isReadOnly}
            checked={!isInProgress}
            onClick={(event) => {
              event.preventDefault();
              onClick && onClick();
            }}
            data-cy="toDoItemCheckbox"
          />
        </Box>
      )}
      <Tooltip title={getTooltipTitle()} placement="top" arrow followCursor>
        <Box sx={Styles.Title(type, withoutCheckbox)}>{content}</Box>
      </Tooltip>
    </>
  );
};
