import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Checkbox, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTodoRowContext } from 'hooks/useTodoRowContext/useTodoRowContext';

import * as Styles from './TableUpdateRowItem.styles';
import { TableUpdateRowItemProps } from './TableUpdateRowItem.types';

export const TableUpdateRowItem = ({ itemId, content, onCancel }: TableUpdateRowItemProps) => {
  const { t } = useTranslation();
  const [itemContent, setItemContent] = useState(content);
  const todoRowContext = useTodoRowContext();

  if (!todoRowContext) return null;

  return (
    <Box sx={Styles.Table}>
      <Box sx={Styles.Wrapper}>
        <Checkbox checked={false} sx={Styles.Checkbox} />
        <TextField
          placeholder={t('actions.inputEditPlaceholder')}
          value={itemContent}
          onChange={(e) => setItemContent(e.target.value)}
          sx={Styles.ActionInput}
        />
      </Box>
      <Box sx={Styles.TableButtonsWrapper}>
        <Button
          color="error"
          endIcon={<ClearIcon />}
          variant="secondary"
          size="small"
          disabled={todoRowContext.isUpdateItemLoading}
          onClick={onCancel}
        >
          {t('actions.cancelUpdate')}
        </Button>
        <Button
          color="primary"
          endIcon={<CheckIcon />}
          variant="secondary"
          size="small"
          disabled={todoRowContext.isUpdateItemLoading || content === itemContent}
          onClick={() => {
            todoRowContext.onUpdateItem?.({
              id: itemId,
              content: itemContent,
            });

            onCancel();
          }}
        >
          {t('actions.confirmUpdate')}
        </Button>
      </Box>
    </Box>
  );
};
