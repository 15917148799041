import { Box, Tabs as MuiTabs } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';

import * as Styles from './Tabs.styles';
import { TabsProps } from './Tabs.types';

export const Tabs = ({ value, onChange, children, button, sx = [] }: TabsProps) => (
  <Box sx={Styles.TabsWrapper}>
    <MuiTabs value={value} onChange={onChange} sx={spreadMultipleStyles(Styles.Tabs, sx)}>
      {children}
    </MuiTabs>
    {!!button && button}
  </Box>
);
