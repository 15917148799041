import { GoalsStatusKeys } from 'app/employeeDetails/employeeDetailsQuarterly/goalsTabs/GoalsTabs.types';
import { Goal, GoalStatus } from 'generated/webapp_gql';

import { AllGoalsDataLoaderProps, AllGoalsDataLoaderReturn } from './EmployeeGoals.types';

export const allGoalsDataLoader = ({
  allGoalsData,
  prevAllGoalsData,
  type,
}: AllGoalsDataLoaderProps): AllGoalsDataLoaderReturn => {
  const goalsData = allGoalsData?.[type.toLowerCase() as GoalsStatusKeys];
  const prevGoalsData = prevAllGoalsData?.[type.toLowerCase() as GoalsStatusKeys];

  if (!goalsData || (goalsData.items.length === 0 && goalsData.total > 0)) {
    return {
      items: prevGoalsData?.items as Goal[],
      total: goalsData?.total ?? prevGoalsData?.total ?? 0,
      loading: true,
    };
  }

  return {
    items: goalsData.items as Goal[],
    total: goalsData.total,
    loading: false,
  };
};

export const goalsTabsNames = [GoalStatus.InProgress, GoalStatus.Canceled, GoalStatus.Done];
