import { Button, Tab, TabsProps } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ActionStatus } from 'generated/webapp_gql';
import { useEmployeeSearchParams, useSearchParamsState, useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { Accordion, Tabs } from 'ui';

import { ActionsTable } from './actionsTable/ActionsTable';
import { ActionsAmountKeys, ActionsTabsProps } from './ActionsTabs.types';
import { actionsDataLoader, actionsTabsTranslations, actionTabsNames } from './ActionsTabs.utils';

export const ActionsTabs = ({ data, prevData, loading }: ActionsTabsProps) => {
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);
  const { searchParams, setSearchParams } = useEmployeeSearchParams();

  const [actionStatus, setActionStatus] = useSearchParamsState({
    searchParams,
    params: 'task-status',
    setSearchParams,
  });

  if (!data && !loading) return null;

  const handleChangeTab = (event: SyntheticEvent, value: TabsProps['value']) => {
    setActionStatus(value);
  };

  return (
    <Accordion
      title={t('actionAccordion.title')}
      content={
        <>
          <Tabs
            value={actionStatus}
            onChange={handleChangeTab}
            button={
              <Link to={`/${AppRoute.EmployeeActions}/${employeeId}`}>
                <Button variant="text">{t('actionAccordion.btnLabel')}</Button>
              </Link>
            }
          >
            {actionTabsNames.map((type) => (
              <Tab
                key={type}
                label={t(actionsTabsTranslations[type], {
                  count:
                    data?.[type.toLowerCase() as ActionsAmountKeys] ??
                    prevData?.[type.toLowerCase() as ActionsAmountKeys] ??
                    0,
                })}
                value={type}
              />
            ))}
          </Tabs>

          {actionTabsNames.map((type) => (
            <ActionsTable
              key={type}
              type={type}
              actionStatus={actionStatus as ActionStatus}
              {...actionsDataLoader({ data, prevData })}
            />
          ))}
        </>
      }
    />
  );
};
