import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useMe } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';

import * as Styles from './CellMenu.styles';
import { CellMenuProps } from './CellMenu.types';

export const CellMenu = ({ employeeId, supervisorId, isSubcontractor }: CellMenuProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const { isTM, isHeadOfDepartment, isSubcontractorAdmin, meData } = useMe();

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const open = Boolean(menuAnchorEl);

  const isEnabled = useMemo(() => {
    const hasSubcontractorAccess = isSubcontractor && isSubcontractorAdmin;
    const hasTMAccess = isTM && supervisorId === meData?.id;

    return hasSubcontractorAccess || hasTMAccess || isHeadOfDepartment;
  }, [isHeadOfDepartment, isSubcontractor, isSubcontractorAdmin, isTM, meData?.id, supervisorId]);

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleMenuOpen} sx={Styles.MenuButton(open)} disabled={!isEnabled}>
        <Tooltip arrow placement="top" title={t('employeesTable.table.addReportTooltip')}>
          <AddIcon />
        </Tooltip>
      </IconButton>

      <Menu
        anchorEl={menuAnchorEl}
        open={open}
        onClose={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={Styles.Menu}
      >
        <MenuItem component={Link} to={`/${AppRoute.AddFeedback}/${employeeId}`}>
          {t('employeesTable.menu.addFeedback')}
        </MenuItem>
        <MenuItem component={Link} to={`/${AppRoute.AddReport}/${employeeId}`}>
          {t('employeesTable.menu.addReport')}
        </MenuItem>
      </Menu>
    </Box>
  );
};
