import { Styles } from 'shared/types';

export const Container: Styles = {
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const Wrapper: Styles = {
  width: 471,
  padding: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};
