import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Accordion } from 'ui';
import { SurveyTabs } from '../surveyTabs/SurveyTabs';
import { useReadOnlyEmployee, useValidatedParams } from 'hooks';

export const SurveyAccordion = () => {
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);

  const { isReadOnly, isReadOnlyLoading } = useReadOnlyEmployee(employeeId);

  if (isReadOnlyLoading || isReadOnly) {
    return null;
  }

  return (
    <Accordion
      title={t('surveyAccordion.title')}
      content={
        <Box>
          <SurveyTabs />
        </Box>
      }
    />
  );
};
