import { useTranslation } from 'react-i18next';

import { useCopyPublishedSurveyTemplate } from 'hooks';
import { SurveysListTab } from '../../../SurveyTemplatesTabs.types';
import { useSurveyTabsSortingContext } from '../../../SurveyTabsSorting/useSurveyTabsSortingContext';
import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';

import { DuplicateSurveyDialogProps } from './DuplicateSurveyDialog.types';

export const DuplicateSurveyDialog = ({ handleCloseModal, changeCurrentTab, id }: DuplicateSurveyDialogProps) => {
  const { t } = useTranslation();
  const { sortingParams } = useSurveyTabsSortingContext();

  const getSortingVariable = (surveyTab: SurveysListTab) => {
    if (sortingParams?.[surveyTab]) {
      return {
        sort: sortingParams[surveyTab],
      };
    }
  };
  const { copyPublishedSurveyTemplate } = useCopyPublishedSurveyTemplate({
    draft: getSortingVariable(SurveysListTab.Draft),
  });

  const handleCopyTemplate = async () => {
    if (!id) return;

    await copyPublishedSurveyTemplate({
      variables: {
        input: {
          id,
        },
      },
    });

    handleCloseModal();
    changeCurrentTab(SurveysListTab.Draft);
  };

  return (
    <ConfirmModal
      isOpen={!!id}
      handleAccept={handleCopyTemplate}
      handleCloseModal={handleCloseModal}
      translations={{
        accept: t(`surveyBuilder.questions.surveyState.acceptButton`),
        cancel: t(`surveyBuilder.questions.surveyState.cancelButton`),
        description: t(`surveyTemplates.copyDialog.description`),
        title: t(`surveyTemplates.copyDialog.title`),
      }}
    />
  );
};
