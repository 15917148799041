import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QueryOptions } from '@apollo/client';

import {
  AvailableSurveyTemplatesDocument,
  AvailableSurveyTemplatesQueryVariables,
} from 'documents/availableSurveyTemplates.generated';
import { useValidatedParams } from 'hooks';
import { useDeleteSurveyTemplateMutation } from 'documents/deleteSurveyTemplate.generated';
import { Toast } from 'ui';

type UseDeleteSurveyTemplateProps = {
  draft?: Omit<QueryOptions<AvailableSurveyTemplatesQueryVariables>['variables'], 'employeeId'>;
};

export const useDeleteSurveyTemplate = (variables?: UseDeleteSurveyTemplateProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { t } = useTranslation();

  const [deleteSurveyTemplateMutation] = useDeleteSurveyTemplateMutation({
    refetchQueries: [
      {
        query: AvailableSurveyTemplatesDocument,
        variables: { employeeId, ...(variables?.draft ? variables.draft : {}) },
      },
    ],
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(
        <Toast title={t('surveyTemplates.deleteToast.title')} message={t('surveyTemplates.deleteToast.subtitle')} />,
      );
    },
  });

  return { deleteSurveyTemplateMutation };
};
