import { MIN_ANSWERS_COUNT } from 'app/questionsWrapper/questions/question/answers/Answers';
import { Section, SurveyQuestionFormat } from 'generated/webapp_gql';

export const defaultSection: Section = {
  title: '',
  description: '',
  questions: [
    {
      question: '',
      format: SurveyQuestionFormat.OpenEnded,
      answers: [{}, {}],
    },
  ],
};

export const validateAnswers = (answers: { value: string }[]): boolean => {
  const answersArr = answers?.length > MIN_ANSWERS_COUNT ? answers.slice(0, answers.length - 1) : answers;
  return answersArr?.every((answer: { value: string }) => !!answer?.value?.length);
};
