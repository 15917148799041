import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { useEmployeeWithWeeklyFeedbackQuery } from 'documents/employeeWithWeeklyFeedback.generated';

export const useEmployeeWithWeeklyFeedback = () => {
  const { employeeId, feedbackId } = useValidatedParams(['feedbackId', 'employeeId']);

  const { data, loading: isEmployeeWithWeeklyFeedbackLoading } = useEmployeeWithWeeklyFeedbackQuery({
    variables: { employeeId, feedbackId },
    onError: (error) => toast.error(error.message),
  });

  return {
    employeeData: data?.employee,
    weeklyFeedbackData: data?.weeklyFeedback,
    isEmployeeWithWeeklyFeedbackLoading,
  };
};
