import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Toast } from 'ui';
import { useUpdateSurveyTemplateMutation } from 'documents/updateSurveyTemplate.generated';
import { AvailableSurveyTemplatesDocument } from 'documents/availableSurveyTemplates.generated';
import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { SurveyTemplateDocument } from '../../documents/surveyTemplate.generated';

export const useUpdateSurveyTemplate = () => {
  const { t } = useTranslation();
  const { employeeId, surveyTemplateId } = useValidatedParams(['employeeId', 'surveyTemplateId']);

  const [updateSurveyTemplateMutation] = useUpdateSurveyTemplateMutation({
    refetchQueries: [
      {
        query: AvailableSurveyTemplatesDocument,
        variables: {
          employeeId,
        },
      },
      {
        query: SurveyTemplateDocument,
        variables: {
          surveyTemplateId,
        },
      },
    ],
    onError: (error) => toast.error(error.message),
    onCompleted: () =>
      toast.success(
        <Toast
          title={t('surveyBuilder.questions.saveTemplateToast.title')}
          message={t('surveyBuilder.questions.saveTemplateToast.subtitle')}
        />,
      ),
  });

  return { updateSurveyTemplateMutation };
};
