import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const QuestionsWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export const Question: Styles = {
  p: 2,
  backgroundColor: colors.white,
  borderRadius: 1,
};

export const AnswerWrapper: Styles = {
  display: 'flex',
  gap: 1.25,
  marginTop: 1.25,
  paddingLeft: 1.5,
};

export const IconColor: Styles = {
  color: colors.slateGray,
};
