import { differenceInDays, differenceInMonths, differenceInYears, format, formatDuration } from 'date-fns';
import i18n from 'i18next';

import { getLocale } from 'i18n/i18n.utils';

import { DateFormat } from './date.enum';

const HALF_OF_YEAR_IN_DAYS = 180;

export const getLongFormatDate = (date: Date | number) =>
  format(date, DateFormat.Long, {
    locale: getLocale().dateTimeLocale,
  });

export const getDurationTime = (date: Date): string => {
  const { t } = i18n;

  const dates: [Date, Date] = [new Date(), date];
  const daysFromNow = differenceInDays(...dates);
  const durationTime =
    daysFromNow < HALF_OF_YEAR_IN_DAYS
      ? { days: daysFromNow }
      : {
          years: differenceInYears(...dates),
          months: differenceInMonths(...dates) - differenceInYears(...dates) * 12,
        };

  return formatDuration(durationTime, {
    locale: getLocale().dateTimeLocale,
    delimiter: t('timeDurationDelimiter'),
  });
};

export const getFullFormatDate = (date: Date | number | null) => {
  if (!date) return null;

  return format(date, DateFormat.FullDate, {
    locale: getLocale().dateTimeLocale,
  });
};
