import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useCreateSurveyMutation } from 'documents/createSurvey.generated';
import { Toast } from 'ui';
import { PublishedSurveyDocument } from 'documents/publishedSurvey.generated';
import { SurveyResponsesDocument } from 'documents/surveyResponses.generated';
import { useValidatedParams } from '../useValidatedParams/useValidatedParams';
import { AvailableSurveyTemplatesDocument } from 'documents/availableSurveyTemplates.generated';
import { DepartmentsSentSurveysDocument } from 'documents/departmentSentSurveys.generated';

export const useCreateSurvey = () => {
  const { t } = useTranslation();
  const { employeeId, surveyId } = useValidatedParams();

  const [createSurveyMutation, { loading: isCreateSurveyLoading }] = useCreateSurveyMutation({
    onError: (error) => toast.error(error.message),
    refetchQueries: [
      {
        query: PublishedSurveyDocument,
        variables: {
          surveyId,
        },
      },
      {
        query: AvailableSurveyTemplatesDocument,
        variables: {
          employeeId,
        },
      },
      {
        query: DepartmentsSentSurveysDocument,
        variables: {
          employeeId,
        },
      },
      {
        query: SurveyResponsesDocument,
        variables: {
          surveyId,
        },
      },
    ],
    onCompleted: () => {
      toast.success(
        <Toast title={t('surveyBuilder.publishedToast.title')} message={t('surveyBuilder.publishedToast.subtitle')} />,
      );
    },
  });

  return { createSurveyMutation, isCreateSurveyLoading };
};
