import { ChipProps } from './SatisfactionLevelChip.types';

const MAX_NOT_SATISFIED_VALUE = 2.33;
const MAX_MODERATELY_SATISFIED_VALUE = 3.67;

export const getChipProps = (value?: number | null): ChipProps | null => {
  if (!value) {
    return null;
  }

  switch (true) {
    case value <= MAX_NOT_SATISFIED_VALUE:
      return {
        label: 'employeeData.satisfactionLevel.notSatisfied',
        color: 'error',
      };
    case value <= MAX_MODERATELY_SATISFIED_VALUE:
      return {
        label: 'employeeData.satisfactionLevel.moderatelySatisfied',
        color: 'warning',
      };
    default:
      return {
        label: 'employeeData.satisfactionLevel.satisfied',
        color: 'success',
      };
  }
};
