import { useNavigate } from 'react-router-dom';

import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';

export const useRedirectToEmployeeDetails = () => {
  const navigate = useNavigate();

  const redirectToEmployeeDetails = (
    employeeId: number,
    destination: EmployeeDetailsViewParam = EmployeeDetailsViewParam.Weekly,
  ) => {
    navigate(`/${AppRoute.EmployeeDetails}/${employeeId}/${destination}`, {
      replace: true,
    });
  };

  return { redirectToEmployeeDetails };
};
