import { Avatar } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';

import { AvatarWithFallbackProps } from './AvatarWithFallback.types';
import * as Styles from './AvatarWithFallback.styles';

export const AvatarWithFallback = ({ sx, alt, size = 's', src }: AvatarWithFallbackProps) => (
  <Avatar alt={alt} src={src || ''} sx={spreadMultipleStyles(Styles.Avatar(size), sx)}>
    {alt[0]}
  </Avatar>
);
