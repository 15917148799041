import { toast } from 'react-toastify';

import { useWeeklyOverviewQuery } from 'documents/weeklyOverview.generated';

export const useWeeklyOverview = (employeeId: number, skip: boolean) => {
  const { data, loading } = useWeeklyOverviewQuery({
    variables: {
      employeeId,
    },
    skip,
    onError: (error) => toast.error(error.message),
    fetchPolicy: 'cache-first',
  });

  return {
    weeklyOverview: data?.employee,
    isWeeklyOverviewLoading: loading,
  };
};
