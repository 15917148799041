import { Styles } from 'shared/types';

export const Button: Styles = {
  color: 'typography.common',
  fontSize: 16,
  display: 'flex',
  gap: 1.25,
  textTransform: 'initial',
  alignItems: 'center',

  '& .MuiButton-startIcon': {
    mr: 0,

    '& svg': {
      fontSize: 24,
    },
  },
};

export const Label: Styles = {
  transform: 'translateY(1px)',
};
