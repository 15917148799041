import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { Box } from '@mui/material';

import { QuestionsProps } from 'app/questionsWrapper/questions/Questions.types';

import { Question } from './Question';

export const Questions = ({ nestIndex, questions, move, remove }: QuestionsProps) => {
  const onDragEnd = ({ source, destination }: DropResult) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="form-questions">
        {({ droppableProps, innerRef, placeholder }) => (
          <Box {...droppableProps} ref={innerRef}>
            {questions.map((question, index) => (
              <Question
                nestIndex={nestIndex}
                id={question.id}
                index={index}
                key={question.id}
                onDelete={remove}
                move={move}
              />
            ))}
            {placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
