import { alpha } from '@mui/material';

export const MuiRadio = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      root: {
        color: colors.slateGray,
        '&:hover': {
          backgroundColor: colors.zumthorBlue,
        },
        '& .MuiTouchRipple-child, & .MuiTouchRipple-rippleVisible': {
          display: 'none',
        },
        '&.Mui-disabled': {
          color: alpha(colors.navy, 0.26),
        },
      },
    },
  };
};
