import { Styles } from 'shared/types';

export const DatePickerWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',

  '&&& .MuiInput-root': {
    paddingLeft: 3.25,
    mt: 0,
    pt: 0.8,
    '&::before': {
      display: 'none',
    },
  },

  '& .MuiInputAdornment-root': {
    marginLeft: 0,
  },
  '& .MuiButtonBase-root': {
    marginRight: 0,
    marginLeft: -2.75,
  },
  '& .MuiSvgIcon-root': {
    color: 'primary.main',
  },
};
