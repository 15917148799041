import { Styles } from 'shared/types';
import { grays, colors } from 'shared/utils';

export const Wrapper: Styles = { display: 'flex', gap: 12 };

export const Container: Styles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const ContentContainer: Styles = {
  display: 'flex',

  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};

export const IntroContainer: Styles = {
  textAlign: 'left',
  flex: 1,
  pt: 12,

  pb: {
    xs: 4,
    md: 12,
  },
};

export const User: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Creator: Styles = {
  display: 'flex',
  gap: 1,
  alignSelf: 'center',
};

export const FullName: Styles = {
  mt: 1,
  color: colors.slateGray,
};

export const UserAvatarDescription: Styles = {
  mb: 1,
  color: colors.slateGray,
};

export const CreatorAvatarDescription: Styles = {
  mr: 1,
  mt: 1,
  color: colors.slateGray,
};

export const Hello: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const Intro: Styles = {
  whiteSpace: 'pre-line',
  mt: 2,
  mb: 4,
  color: grays.slateGray,
};

export const IntroImg: Styles = {
  alignSelf: 'center',
  height: 300,

  width: {
    xs: 'auto',
    md: 350,
  },
  marginLeft: {
    xs: '0',
    md: 'auto',
  },

  mb: {
    xs: 4,
    md: 0,
  },

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
};
