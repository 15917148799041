import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const AnswersWrapper: Styles = {
  marginBottom: 4,
};

export const AnswerRow: Styles = {
  display: 'flex',
  alignItems: 'center',
};

export const AnswerInput: Styles = {
  display: 'flex',
  alignContent: 'center',
  marginTop: '0',

  '.MuiInput-root.MuiInputBase-formControl': {
    marginTop: 1.25,
    marginLeft: 1.5,
  },
};

export const DragHandle = (isDisabled: boolean): Styles => ({
  visibility: () => (isDisabled ? 'hidden' : 'visible'),
});

export const DragIcon: Styles = {
  marginRight: 0.25,
};

export const IconColor: Styles = {
  color: colors.slateGray,
};

export const CloseIcon: Styles = {
  color: colors.navy,
};

export const InfoMessage: Styles = {
  marginBottom: 2,
};
