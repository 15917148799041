import { useTranslation } from 'react-i18next';

import { CardChecklist } from 'ui';
import { ToDoType } from 'ui/modal/Modal.enum';
import { GoalStatus } from 'generated/webapp_gql';
import { useModal, useUpdateGoal } from 'hooks';

import { CardGoalsChecklistProps } from './CardGoalsChecklist.types';

export const CardGoalsChecklist = ({ goals, isReadOnly }: CardGoalsChecklistProps) => {
  const { t } = useTranslation();
  const { sendGoal, isUpdateGoalLoading } = useUpdateGoal();
  const modal = useModal<GoalStatus>({
    onConfirm: sendGoal,
    canceledStatus: GoalStatus.Canceled,
    doneStatus: GoalStatus.Done,
  });

  return (
    <CardChecklist
      items={goals}
      isUpdateLoading={isUpdateGoalLoading}
      modal={modal}
      toDoType={ToDoType.Goal}
      label={t('report.reportCard.goals')}
      isReadOnly={isReadOnly}
    />
  );
};
