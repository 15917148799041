import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { useTranslateSurvey } from 'hooks/useTranslateSurvey/useTranslateSurvey';
import { ControlledTextField } from 'ui';

import * as Styles from './SectionInfo.styles';
import { SectionInfoProps } from './SectionInfo.types';

export const SectionInfo = ({ nestIndex, questionCount }: SectionInfoProps) => {
  const { t } = useTranslation();
  const translateSurvey = useTranslateSurvey();

  const { control } = useFormContext<SurveyFormData>();
  return (
    <Box sx={Styles.Wrapper}>
      <Box>
        <ControlledTextField
          control={control}
          name={`sections.${nestIndex}.title`}
          label={translateSurvey('surveyBuilder.questions.section.title')}
          sx={Styles.Title}
          InputLabelProps={{}}
        />
        <ControlledTextField
          control={control}
          name={`sections.${nestIndex}.description`}
          placeholder={translateSurvey('surveyBuilder.questions.section.description')}
        />
      </Box>
      <Typography sx={Styles.QuestionCounter}>
        {t('surveyBuilder.questions.section.questionCount_interval', {
          count: questionCount,
          postProcess: 'interval',
        })}
      </Typography>
    </Box>
  );
};
