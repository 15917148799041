import { QueryOptions } from '@apollo/client';

import { useChangeSurveyStatus, UseChangeSurveyStatusProps, useValidatedParams } from '../../../hooks';
import { PublishedSurveyDocument, PublishedSurveyQueryVariables } from 'documents/publishedSurvey.generated';
import { SurveyTemplateDocument, SurveyTemplateQueryVariables } from 'documents/surveyTemplate.generated';
import { getRefetchOptionsForSurveys } from '../../../shared/utils/getRefetchOptionsForSurveys';

export const useCustomChangeSurveyStatus = (props: UseChangeSurveyStatusProps) => {
  const { surveyId } = useValidatedParams(['surveyId']);
  const { surveyTemplateId } = useValidatedParams(['surveyTemplateId']);
  const { employeeId } = useValidatedParams(['employeeId']);

  const publishedSurveyQuery: QueryOptions<PublishedSurveyQueryVariables> = {
    query: PublishedSurveyDocument,
    variables: {
      surveyId,
    },
  };

  const surveyTemplateDocument: QueryOptions<SurveyTemplateQueryVariables> = {
    query: SurveyTemplateDocument,
    variables: {
      surveyTemplateId,
    },
  };

  return useChangeSurveyStatus({
    queryOptions: {
      refetchQueries: [
        getRefetchOptionsForSurveys(employeeId),
        ...(!!surveyId ? [publishedSurveyQuery] : []),
        ...(!!surveyTemplateId ? [surveyTemplateDocument] : []),
      ],
    },
    ...props,
  });
};
