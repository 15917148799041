import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QueryOptions } from '@apollo/client';

import { defaultSection } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.utils';
import {
  AvailableSurveyTemplatesDocument,
  AvailableSurveyTemplatesQueryVariables,
} from 'documents/availableSurveyTemplates.generated';
import { useCreateInitialSurveyTemplateMutation } from 'documents/createInitialSurveyTemplate.generated';
import { useValidatedParams } from 'hooks';
import { Language } from '../../generated/webapp_gql';

type UseCreateInitialSurveyTemplateProps = {
  draft?: Omit<QueryOptions<AvailableSurveyTemplatesQueryVariables>['variables'], 'employeeId'>;
};
export const useCreateInitialSurveyTemplate = (department: string, variables?: UseCreateInitialSurveyTemplateProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { t } = useTranslation();

  const [createInitialSurveyTemplate] = useCreateInitialSurveyTemplateMutation({
    refetchQueries: [
      {
        query: AvailableSurveyTemplatesDocument,
        variables: { employeeId, ...(variables?.draft ? variables.draft : {}) },
      },
    ],
    onError: (error) => toast.error(error.message),
    variables: {
      input: {
        title: t('surveyBuilder.questions.surveyTitlePlaceholder'),
        language: Language.Pl,
        content: {
          beginning: t('surveyBuilder.questions.intro.defaultValue'),
          conclusion: t('surveyBuilder.questions.outro.defaultValue'),
          sections: [defaultSection],
        },
        department,
      },
    },
  });

  return { createInitialSurveyTemplate };
};
