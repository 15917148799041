import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CopyOutlinedIcon } from 'assets/images/CopyOutlinedIcon.svg';

import * as Styles from './CopySurveyLinkButton.styles';
import { CopySurveyLinkButtonProps } from './CopySurveyLinkButton.types';

export const CopySurveyLinkButton = ({ respondentId, onClick }: CopySurveyLinkButtonProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      role="tooltip"
      title={<Box whiteSpace="pre-line">{t('surveyBuilder.publicationSettings.copySurveyLink')}</Box>}
      arrow
      placement="top"
    >
      <Box component="span">
        <IconButton
          data-testid={`${respondentId}CopyLink`}
          onClick={onClick}
          aria-label="copy-link"
          sx={Styles.CopySurveyLinkButton}
        >
          <CopyOutlinedIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
