import { SurveyStatus } from 'generated/webapp_gql';
import type { TranslationsKeys } from 'i18n/i18n.types';

export const surveyTabsNames = [SurveyStatus.Opened, SurveyStatus.Closed] as const;

export const surveyTabsTranslations: Record<SurveyStatus, TranslationsKeys> = {
  [SurveyStatus.Opened]: 'surveyTabs.tab.inProgress',
  [SurveyStatus.Closed]: 'surveyTabs.tab.done',
  [SurveyStatus.Canceled]: 'surveyTabs.tab.done',
};
