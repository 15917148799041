import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Loader } from 'ui';
import { WeeklyFeedbackForm } from 'app/weeklyFeedbackForm/weeklyFeedbackForm/WeeklyFeedbackForm';
import { useUpdateWeeklyFeedback, useValidatedParams, useWeeklyFeedback } from 'hooks';
import { UpdateWeeklyFeedbackFormData, WeeklyAction } from '../weeklyFeedbackForm/WeeklyFeedbackForm.types';
import { getEditWeeklyFeedbackSchema } from '../weeklyFeedbackForm/WeeklyFeedbackForm.schema';
import { getFullFormatDate } from 'shared/utils';
import { ActionStatus } from 'generated/webapp_gql';
import { sortNewItems } from '../utils/sortNewItems/sortNewItems';
import { WeeklyFeedbackDocument, WeeklyFeedbackQuery } from 'documents/weeklyFeedback.generated';
import { client } from 'apollo-client';

export const EditWeeklyFeedbackFormContainer = () => {
  const { employeeId, feedbackId } = useValidatedParams(['employeeId', 'feedbackId']);
  const { updateWeeklyFeedback, isUpdateWeeklyFeedbackLoading } = useUpdateWeeklyFeedback(employeeId);

  const weeklyFeedbackQueryOptions = {
    query: WeeklyFeedbackDocument,
    variables: {
      feedbackId,
    },
  };
  const weeklyFeedbackCache = client.cache.readQuery<WeeklyFeedbackQuery>(weeklyFeedbackQueryOptions);
  const actionsCount =
    weeklyFeedbackCache && weeklyFeedbackCache.weeklyFeedback?.actionsCollection?.items
      ? weeklyFeedbackCache.weeklyFeedback?.actionsCollection.items?.length
      : 0;
  const projectScoresCount = weeklyFeedbackCache?.weeklyFeedback?.projectSatisfactionScores?.length ?? 0;

  const weeklyFormMethods = useForm<UpdateWeeklyFeedbackFormData>({
    resolver: yupResolver(getEditWeeklyFeedbackSchema(actionsCount, projectScoresCount)),
    mode: 'all',
    defaultValues: {
      realizationDate: new Date(),
    },
  });

  const { weeklyFeedbackData, isWeeklyFeedbackLoading } = useWeeklyFeedback(feedbackId, {
    onCompleted: ({ weeklyFeedback }) =>
      weeklyFormMethods.reset({
        summary: weeklyFeedback?.summary ?? '',
        notes: weeklyFeedback?.notes,
        satisfactionScore: weeklyFeedback?.satisfactionScore,
        realizationDate: weeklyFeedback?.realizationDate ? new Date(weeklyFeedback.realizationDate) : null,
        projectsSatisfaction: weeklyFeedback?.projectSatisfactionScores?.map((score) => ({
          id: score?.id,
          projectSatisfactionScore: score?.satisfactionScore,
          projectName: score?.project.projectName,
        })),
        actions: weeklyFeedback?.actionsCollection?.items?.length
          ? weeklyFeedback?.actionsCollection.items.map((action) => ({
              id: action?.id,
              content: action?.content,
              actionStatus: action?.actionStatus,
            }))
          : [{ content: '', actionStatus: ActionStatus.InProgress }],
      }),
    skip: false,
  });

  const onSubmit = ({
    summary,
    satisfactionScore,
    notes,
    realizationDate,
    actions,
    projectsSatisfaction,
  }: UpdateWeeklyFeedbackFormData) => {
    const { createdItems: createdActions, updatedItems: updatedActions } = sortNewItems({
      oldItems: weeklyFeedbackData.actionsCollection?.items,
      newItems: actions ?? [],
    });

    updateWeeklyFeedback({
      variables: {
        createActions: {
          weeklyFeedbackId: feedbackId || '',
          actions: createdActions,
        },
        updateWeeklyFeedback: {
          satisfactionScore,
          summary,
          notes,
          weeklyFeedbackId: feedbackId || '',
          realizationDate: getFullFormatDate(realizationDate),
          actions: updatedActions,
          projectsSatisfaction:
            projectsSatisfaction?.length === 0
              ? undefined
              : projectsSatisfaction?.map(({ id, projectSatisfactionScore }) => ({
                  id,
                  projectSatisfactionScore,
                })),
        },
      },
    });
    weeklyFormMethods.reset({}, { keepValues: true });
  };

  if (isWeeklyFeedbackLoading) return <Loader />;

  return (
    <FormProvider {...weeklyFormMethods}>
      <WeeklyFeedbackForm
        onSubmit={weeklyFormMethods.handleSubmit(onSubmit)}
        mutationLoading={isUpdateWeeklyFeedbackLoading}
        action={WeeklyAction.UPDATE}
        giverId={weeklyFeedbackCache?.weeklyFeedback?.giverId ?? -1}
      />
    </FormProvider>
  );
};
