import { Box, Button, FormControl, Typography } from '@mui/material';
import { KeyboardEvent } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CreateWeeklyFeedbackFormData,
  UpdateWeeklyFeedbackFormData,
} from 'app/weeklyFeedbackForm/weeklyFeedbackForm/WeeklyFeedbackForm.types';
import { ActionStatus } from 'generated/webapp_gql';
import { AddButton, OptionalTextField } from 'ui';

import * as Styles from './OptionalActions.styles';
import { OptionalActionsProps } from './OptionalActions.types';

export const OptionalActions = ({ mutationLoading, name, label }: OptionalActionsProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray<
    CreateWeeklyFeedbackFormData | UpdateWeeklyFeedbackFormData,
    'actions',
    'formId'
  >({
    name: 'actions',
    keyName: 'formId',
  });

  const lastActionIndex = fields.length - 1;
  const isDeleteAvailable = !(fields[lastActionIndex] && 'id' in fields[lastActionIndex]) && fields.length > 1;

  const addNewAction = () => {
    append({ content: '', actionStatus: ActionStatus.InProgress });
  };

  const removeLastAction = () => {
    remove(lastActionIndex);
  };

  const handleEnterPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addNewAction();
    }
  };

  return (
    <Box sx={Styles.Wrapper}>
      <Box sx={Styles.Label}>
        <Typography variant="subtitle2">{label}</Typography>
      </Box>
      {fields.map((field, index) => {
        const actionStatus = field.actionStatus;

        if (!actionStatus) return;

        const isInProgress = actionStatus === ActionStatus.InProgress;

        return (
          <FormControl variant="standard" key={field.formId}>
            <Controller
              name={`actions.${index}.content`}
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <OptionalTextField
                  sx={Styles.OptionalTextField}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  multiline
                  onKeyDown={handleEnterPress}
                  variant="standard"
                  placeholder={t('optionalActions.addActionPlaceholder')}
                  error={!!error}
                  helperText={
                    !!error?.message && error.type === 'min' ? t('weeklyForm.errorMessage.cantDeleteAnAction') : null
                  }
                  disabled={mutationLoading || !isInProgress}
                  setValue={setValue}
                  name={`${name}[${index}]`}
                  multiFields={true}
                  isMultiChecked={true}
                  inputRef={ref}
                  status={actionStatus}
                />
              )}
            />
          </FormControl>
        );
      })}
      <AddButton
        onClick={addNewAction}
        variant="text"
        title={t('optionalActions.addActionButton')}
        sx={Styles.Button}
      />
      {isDeleteAvailable && (
        <Button onClick={removeLastAction} sx={Styles.Button} color="error">
          {t('optionalActions.removeLastAction')}
        </Button>
      )}
    </Box>
  );
};
