import React from 'react';
import { Box, Button, Dialog, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FilledIconAlert } from 'ui';
import { GrumpyCat } from 'ui/grumpyCat/GrumpyCat';
import { useGrumpyCat } from 'hooks/useGrumpyCat/useGrumpyCat';

import * as Styles from './ConfirmationDialog.styles';
import { ConfirmationDialogProps } from './ConfirmationDialog.types';

export const ConfirmationDialog = ({
  title,
  content,
  open,
  onClose,
  onConfirmation,
  alertText,
  alertSeverity,
  children,
  acceptButtonText,
  cancelButtonText,
  isAcceptButtonActive = true,
  secondaryButtonVariant = 'standard',
}: ConfirmationDialogProps) => {
  const { showGrumpyCat, hideGrumpyCat, isGrumpyCatDisplayed } = useGrumpyCat();
  const { t } = useTranslation();

  const secondaryButton = {
    standard: Styles.Button,
    white: Styles.WhiteSecondaryButton,
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} data-cy="confirmationDialog">
      <DialogTitle variant="h5" sx={Styles.Title}>
        {title}
      </DialogTitle>
      <Box sx={Styles.ContentContainer}>
        <Typography>{content}</Typography>

        {alertText && <FilledIconAlert severity={alertSeverity || 'warning'}>{alertText}</FilledIconAlert>}
        {children}
        <Box sx={Styles.ButtonsContainer}>
          <Button
            onClick={onClose}
            variant="secondary"
            sx={{ ...secondaryButton[secondaryButtonVariant] }}
            data-cy="dialogCloseButton"
          >
            {cancelButtonText || t('confirmationDialog.no')}
          </Button>
          <Button
            onClick={onConfirmation}
            variant="contained"
            color="primary"
            sx={Styles.Button}
            onMouseEnter={showGrumpyCat}
            onMouseLeave={hideGrumpyCat}
            disabled={!isAcceptButtonActive}
          >
            {acceptButtonText || t('confirmationDialog.yes')}
          </Button>
        </Box>
      </Box>
      <GrumpyCat isGrumpyCatDisplayed={isGrumpyCatDisplayed} />
    </Dialog>
  );
};
