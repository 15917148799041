import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Container: Styles = {
  position: 'relative',
  overflow: 'hidden',
};

export const Title: Styles = {
  display: 'grid',
  gridTemplateColumns: ' repeat(3, 1fr)',
  justifyItems: 'center',
};

export const BackButton: Styles = {
  justifySelf: 'start',
};

export const SurveyBuilderContainer: Styles = {
  textAlign: 'center',
  maxWidth: 1024,
  marginLeft: 'auto',
  marginRight: 'auto',
  pt: 4,
  pb: 6,
  px: 4,
  boxSizing: 'content-box',
};

export const ClosePreviewButton: Styles = {
  justifySelf: 'end',
};

export const SurveyButtonsWrapper: Styles = {
  display: 'flex',
  gap: 1.5,
};

export const ReadOnlyAlert: Styles = {
  color: colors.orangePeel,
  justifySelf: 'flex-end',
};

export const EditSurveyButton: Styles = {
  p: 0,
  justifySelf: 'end',
  color: colors.orangePeel,
};
