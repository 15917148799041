import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FilledIconAlert } from 'ui';

import { PhotoUploadErrorInfoProps } from './PhotoUploadErrorInfo.types';
import { getErrorInfoTranslationKeys } from './PhotoUploadErrorInfo.utils';

export const PhotoUploadErrorInfo = ({ errorCode }: PhotoUploadErrorInfoProps) => {
  const { t } = useTranslation();

  const { heading, description, requirements } = getErrorInfoTranslationKeys(errorCode);

  return (
    <FilledIconAlert severity="error">
      <Typography variant="body2">{t(heading)}</Typography>
      <Typography variant="body2">
        {description && t(description)}
        <Box component="span" sx={{ fontWeight: 700 }}>
          {requirements && t(requirements)}
        </Box>
      </Typography>
    </FilledIconAlert>
  );
};
