import { ReportSection } from 'app/employeeDetails/employeeDetailsQuarterly/reportSection/ReportSection';
import { GoalStatus } from 'generated/webapp_gql';

import { GoalsTabs } from './goalsTabs/GoalsTabs';
import { SurveyAccordion } from './SurveyAccordion/SurveyAccordion';

export const QUARTERLY_REPORTS_PAGE_SIZE = 5;
export const GOALS_LIST_PAGE_SIZE = 5;
export const SURVEYS_LIST_PAGE_SIZE = 5;
export const DEFAULT_GOAL_TYPE = GoalStatus.InProgress;

export const EmployeeDetailsQuarterly = () => (
  <>
    <SurveyAccordion />
    <GoalsTabs />
    <ReportSection />
  </>
);
