import { Popper, PopperProps } from '@mui/material';

import * as Styles from './AutocompletePopper.styles';

export const AutocompletePopper = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      sx={Styles.CustomPopper}
      style={{
        ...Styles.PopperOverride,
      }}
      placement="bottom-start"
    />
  );
};
