import { useEffect, useState } from 'react';

export const useValueOnWindowFocus = <T>(getMaxDate: () => T) => {
  const [date, setDate] = useState(getMaxDate());

  useEffect(() => {
    const handleWindowFocus = () => {
      setDate(getMaxDate());
    };

    window.addEventListener('focus', handleWindowFocus);

    return () => window.removeEventListener('focus', handleWindowFocus);
  }, [getMaxDate]);

  return date;
};
