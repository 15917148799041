import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from 'shared/utils';
import { Indicator } from '../indicator/Indicator';

import * as Styles from './StatusInfo.styles';
import { statuses } from './StatusInfo.utils';

export const StatusInfo = () => {
  const { t } = useTranslation();

  return (
    <Box component="ul" sx={Styles.StatusInfoWrapper}>
      {statuses.map(({ type, name }) => (
        <Box key={type} component="li" sx={Styles.StatusName}>
          <Indicator type={type} />
          <Typography color={colors.slateGray} variant="caption">
            {t(name)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
