import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeSentSurveyToArchivedMutationVariables = Types.Exact<{
  input: Types.ChangeSentSurveyToArchivedInput;
}>;


export type ChangeSentSurveyToArchivedMutation = { __typename?: 'Mutation', changeSentSurveyToArchived?: { __typename?: 'SurveyTemplate', id: string } | null };


export const ChangeSentSurveyToArchivedDocument = gql`
    mutation ChangeSentSurveyToArchived($input: ChangeSentSurveyToArchivedInput!) {
  changeSentSurveyToArchived(input: $input) {
    id
  }
}
    `;
export type ChangeSentSurveyToArchivedMutationFn = Apollo.MutationFunction<ChangeSentSurveyToArchivedMutation, ChangeSentSurveyToArchivedMutationVariables>;

/**
 * __useChangeSentSurveyToArchivedMutation__
 *
 * To run a mutation, you first call `useChangeSentSurveyToArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSentSurveyToArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSentSurveyToArchivedMutation, { data, loading, error }] = useChangeSentSurveyToArchivedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeSentSurveyToArchivedMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSentSurveyToArchivedMutation, ChangeSentSurveyToArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSentSurveyToArchivedMutation, ChangeSentSurveyToArchivedMutationVariables>(ChangeSentSurveyToArchivedDocument, options);
      }
export type ChangeSentSurveyToArchivedMutationHookResult = ReturnType<typeof useChangeSentSurveyToArchivedMutation>;
export type ChangeSentSurveyToArchivedMutationResult = Apollo.MutationResult<ChangeSentSurveyToArchivedMutation>;
export type ChangeSentSurveyToArchivedMutationOptions = Apollo.BaseMutationOptions<ChangeSentSurveyToArchivedMutation, ChangeSentSurveyToArchivedMutationVariables>;