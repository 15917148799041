import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const TopSection: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const Label: Styles = {
  backgroundColor: colors.navy,
  color: colors.white,
  px: 2,
  py: 2.625,
  borderRadius: '4px 4px 0 0',
  fontWeight: 700,
};

export const TopSectionButtons: Styles = {
  display: 'flex',
  padding: 2,
  gap: 4.625,
};

export const ContentWrapper = (collapsed: boolean): Styles => ({
  p: 2,
  backgroundColor: colors.brightGray,
  borderRadius: '0px 4px 4px',
  boxSizing: 'content-box',
  '&': collapsed
    ? {
        overflow: 'hidden',
        height: '56px',
      }
    : {},
});

export const DeleteIcon: Styles = {
  width: 32,
  height: 32,
  color: colors.navy,

  '& svg': {
    width: 24,
    height: 24,
  },

  '&:disabled': {
    color: colors.disabledGray,
  },
};

export const DragIndicatorIcon: Styles = {
  width: 32,
  height: 32,

  '&:hover': {
    cursor: 'grab',
  },

  '&:active': {
    cursor: 'grabbing',
  },
};
