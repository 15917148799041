import { Styles } from 'shared/types';

export const UsersListContainer: Styles = {
  overflow: 'scroll',

  height: '100%',

  '.MuiChip-label': {
    color: ({ palette }) => palette.common.black,
  },
};

export const AutocompleteContainer: Styles = {
  display: 'flex',
  flex: 1,
  height: '100%',
  gap: '24px',
  overflow: 'hidden',
  flexDirection: 'row-reverse',
};
