export const MuiTextField = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      root: {
        '& .Mui-disabled': {
          WebkitTextFillColor: colors.slateGray,
          userSelect: 'none',
        },
      },
    },
  };
};
