import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SurveyReceiverSection } from 'app/surveyBuilder/surveyBuilderQuestions/surveyReceiverSection/SurveyReceiverSection';
import { Loader } from 'ui';
import { isPublishedTemplate } from '../surveyLayout/SurveyLayout.utils';
import { SurveyTemplateStatus } from 'generated/webapp_gql';

import { SectionWithImage } from './sectionWithImage/SectionWithImage';
import * as Styles from './SurveyQuestions.styles';
import { SectionEnum } from './SurveyQuestions.enum';
import { QuestionsSectionWrapper } from './questionsSection/QuestionsSectionWrapper';
import { SurveyQuestionsProps } from './SurveyQuestions.types';
import { EditAsTemplateDialog } from './editAsTemplateDialog/EditAsTemplateDialog';

export const SurveyQuestions = ({
  title,
  surveyTemplate,
  isLoading,
  surveyType,
  goToPublicationPage,
}: SurveyQuestionsProps) => {
  const { t } = useTranslation();

  const [isEditAsTemplateModalOpen, setIsEditAsTemplateModalOpen] = useState(false);

  const isSurveyArchived = surveyTemplate?.status === SurveyTemplateStatus.Archived;

  const closeEditAsTemplateModal = () => {
    setIsEditAsTemplateModalOpen(false);
  };

  const openEditAsTemplateModal = () => {
    setIsEditAsTemplateModalOpen(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={Styles.QuestionsWrapper}>
      <Typography sx={Styles.Title} variant="h5">
        {title}
      </Typography>
      <SurveyReceiverSection />

      <SectionWithImage
        label={t('surveyBuilder.questions.intro.title')}
        imageUrl={surveyTemplate?.introImageUrl}
        sectionName={SectionEnum.Beginning}
        fieldValue={surveyTemplate?.content?.beginning}
      />

      <QuestionsSectionWrapper sections={surveyTemplate?.content?.sections} />

      <SectionWithImage
        label={t('surveyBuilder.questions.outro.title')}
        imageUrl={surveyTemplate?.endImageUrl}
        sectionName={SectionEnum.Conclusion}
        fieldValue={surveyTemplate?.content?.conclusion}
      />

      {isPublishedTemplate(surveyType) && (
        <Box sx={Styles.Buttons}>
          <Button onClick={openEditAsTemplateModal} variant="secondary">
            {t('surveyBuilder.questions.editAsTemplate')}
          </Button>

          {!isSurveyArchived && (
            <Button variant="contained" onClick={goToPublicationPage}>
              {t('surveyBuilder.questions.btnPublish')}
            </Button>
          )}
        </Box>
      )}

      <EditAsTemplateDialog
        open={isEditAsTemplateModalOpen}
        handleCloseModal={closeEditAsTemplateModal}
        surveyTemplateId={surveyTemplate?.id}
      />
    </Box>
  );
};
