export enum EmployeesTableUnsortableField {
  AVATAR = 'avatar',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ACTIONS_TO_PERFORM = 'actionsInProgress',
  LAST_WEEKLY_FEEDBACK = 'latestWeeklyFeedback',
  ADD_REPORT = 'addReport',
  WEEKLY_OVERVIEW = 'weeklyOverview',
  VISIT_PROFILE = 'visitProfile',
}

export enum ReadOnlyEmployeesTableUnsortableField {
  AVATAR = 'avatar',
  CURRENT_PROJECTS = 'currentProjects',
  SETTINGS = 'settings',
}
