import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks';
import { useAllSurveysOfEmployeeQuery } from 'documents/allSurveysOfEmployee.generated';
import { SURVEYS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';

import { UseAllSurveysOfEmployeeProps } from './useAllSurveysOfEmployee.types';

export const useAllSurveysOfEmployee = (
  { pages }: UseAllSurveysOfEmployeeProps = {
    pages: {
      opened: 1,
      closed: 1,
    },
  },
) => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const {
    data: allSurveysData,
    previousData: prevAllSurveysData,
    loading: areSurveysLoading,
  } = useAllSurveysOfEmployeeQuery({
    variables: {
      employeeId,
      openedPagination: {
        offset: (pages.opened - 1) * SURVEYS_LIST_PAGE_SIZE,
        limit: SURVEYS_LIST_PAGE_SIZE,
      },
      closedPagination: {
        offset: (pages.closed - 1) * SURVEYS_LIST_PAGE_SIZE,
        limit: SURVEYS_LIST_PAGE_SIZE,
      },
    },
    onError: (error) => toast.error(error.message),
    fetchPolicy: 'cache-and-network',
  });

  return { allSurveysData, prevAllSurveysData, areSurveysLoading };
};
