import { Styles } from 'shared/types';

export const Wrapper: Styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '142px',
  height: '40px',
  margin: '-20px 0 0 -71px',
  opacity: '1',
  filter: 'contrast(20)',
  backgroundColor: '#fafafa',
};

export const Dot: Styles = {
  position: 'absolute',
  width: '16px',
  height: '16px',
  top: '12px',
  left: '15px',
  filter: 'blur(4px)',
  background: '#000',
  borderRadius: '50%',
  transform: 'translateX(0)',
  animation: 'dot 2.8s infinite',

  '@keyframes dot': {
    '50%': {
      transform: 'translateX(96px)',
    },
  },
};

export const Dots: Styles = {
  transform: 'translateX(0)',
  marginTop: '12px',
  marginLeft: '31px',
  animation: 'dots 2.8s infinite',

  '@keyframes dots': {
    '50%': {
      transform: 'translateX(-31px)',
    },
  },
};

export const DotsGooey: Styles = {
  display: 'block',
  float: 'left',
  width: '16px',
  height: '16px',
  marginLeft: '16px',
  filter: 'blur(4px)',
  background: '#000',
  borderRadius: '50%',
};
