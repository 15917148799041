import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Toast } from 'ui';
import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { SurveyTemplateDocument } from '../../documents/surveyTemplate.generated';
import { useEditSentSurveyMutation } from 'documents/editSentSurvey.generated';

export const useEditSentSurvey = () => {
  const { t } = useTranslation();
  const { surveyTemplateId } = useValidatedParams(['employeeId', 'surveyTemplateId', 'surveyId']);

  const [editSentSurveyMutation] = useEditSentSurveyMutation({
    refetchQueries: [
      {
        query: SurveyTemplateDocument,
        variables: {
          surveyTemplateId,
        },
      },
    ],
    onError: (error) => toast.error(error.message),
    onCompleted: () =>
      toast.success(
        <Toast
          title={t('surveyEditor.questions.editSentSurveyToast.title')}
          message={t('surveyEditor.questions.editSentSurveyToast.subtitle')}
        />,
      ),
  });

  return { editSentSurveyMutation };
};
