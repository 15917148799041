import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveyAnswersSummaryQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID']['input'];
}>;


export type SurveyAnswersSummaryQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', respondedEmails?: Array<string | null> | null, openedSurveysCount?: number | null, summaryContent?: Array<{ __typename?: 'SurveySummaryContent', question: string, answers: Array<{ __typename?: 'SurveySummaryAnswer', email: string, answer: Array<string> } | null> } | null> | null } | null };


export const SurveyAnswersSummaryDocument = gql`
    query SurveyAnswersSummary($surveyId: ID!) {
  survey(id: $surveyId) {
    respondedEmails
    openedSurveysCount
    summaryContent {
      question
      answers {
        email
        answer
      }
    }
  }
}
    `;

/**
 * __useSurveyAnswersSummaryQuery__
 *
 * To run a query within a React component, call `useSurveyAnswersSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyAnswersSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyAnswersSummaryQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveyAnswersSummaryQuery(baseOptions: Apollo.QueryHookOptions<SurveyAnswersSummaryQuery, SurveyAnswersSummaryQueryVariables> & ({ variables: SurveyAnswersSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyAnswersSummaryQuery, SurveyAnswersSummaryQueryVariables>(SurveyAnswersSummaryDocument, options);
      }
export function useSurveyAnswersSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyAnswersSummaryQuery, SurveyAnswersSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyAnswersSummaryQuery, SurveyAnswersSummaryQueryVariables>(SurveyAnswersSummaryDocument, options);
        }
export function useSurveyAnswersSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveyAnswersSummaryQuery, SurveyAnswersSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveyAnswersSummaryQuery, SurveyAnswersSummaryQueryVariables>(SurveyAnswersSummaryDocument, options);
        }
export type SurveyAnswersSummaryQueryHookResult = ReturnType<typeof useSurveyAnswersSummaryQuery>;
export type SurveyAnswersSummaryLazyQueryHookResult = ReturnType<typeof useSurveyAnswersSummaryLazyQuery>;
export type SurveyAnswersSummarySuspenseQueryHookResult = ReturnType<typeof useSurveyAnswersSummarySuspenseQuery>;
export type SurveyAnswersSummaryQueryResult = Apollo.QueryResult<SurveyAnswersSummaryQuery, SurveyAnswersSummaryQueryVariables>;