import { TranslationsKeys } from 'i18n/i18n.types';
import { Action, ActionStatus } from 'generated/webapp_gql';

import { ActionsDataLoaderProps, ActionsDataLoaderReturn } from './ActionsTabs.types';

export const actionTabsNames = [ActionStatus.InProgress, ActionStatus.Canceled, ActionStatus.Done];

export const actionsTabsTranslations: Record<ActionStatus, TranslationsKeys> = {
  [ActionStatus.InProgress]: 'actionTabs.tab.inProgress',
  [ActionStatus.Done]: 'actionTabs.tab.done',
  [ActionStatus.Canceled]: 'actionTabs.tab.canceled',
};

export const getActionsLength = (actions: Action[], type: string): number =>
  actions.filter((action) => action?.actionStatus === type).length;

export const actionsDataLoader = ({ data, prevData }: ActionsDataLoaderProps): ActionsDataLoaderReturn => {
  if (!data || ((data?.actionsCollection?.items.length ?? 0) === 0 && (data?.actionsCollection?.total ?? 0) > 0)) {
    return {
      data: prevData,
      loading: true,
    };
  }

  return {
    data: data,
    loading: false,
  };
};
