import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getDurationTime, getLongFormatDate } from 'shared/utils';
import { AvatarWithFallback, SatisfactionLevelChip } from 'ui';
import { GoalCollection } from '../../../generated/webapp_gql';

import { GoalsSection } from './goalsSection/GoalsSection';
import * as Styles from './EmployeeSection.styles';
import { EmployeeSectionProps } from './EmployeeSection.types';

const LEVEL_NAMES_TO_SKIP = ['senna'];
const SUBCONTRACTORS_EMPLOYMENT_TYPE_CODE = 'LTSH';

const shouldSkipLevel = (level: string | null | undefined) =>
  !level || LEVEL_NAMES_TO_SKIP.some((name) => name.toLowerCase() === level.toLowerCase());

export const EmployeeSection = ({ employee, withGoals = true }: EmployeeSectionProps) => {
  const { t } = useTranslation();

  const getGoals = (): GoalCollection => employee.latestReceivedQuarterlyReport?.goalsCollection as GoalCollection;

  const hasTeamManager = !!employee.supervisor || !!employee.headFullName;
  const isSupervisedByHead = !employee.supervisor && !!employee.headFullName;
  const teamManagerFullName = isSupervisedByHead
    ? employee.headFullName
    : `${employee.supervisor?.firstName} ${employee.supervisor?.lastName}`;

  return (
    <>
      <Box sx={Styles.Wrapper}>
        <Box sx={Styles.AvatarWrapper}>
          <AvatarWithFallback size="xl" src={employee.avatarUrl} alt={`${employee.firstName} ${employee.lastName}`} />
        </Box>
        <Box sx={Styles.User}>
          <Box sx={Styles.Name}>
            {employee.firstName} {employee.lastName}
          </Box>
          {!shouldSkipLevel(employee.level) && <Box sx={Styles.Level}>{employee.level}</Box>}
          <SatisfactionLevelChip value={employee.satisfactionLevel} />
        </Box>
        <Box sx={Styles.DetailsWrapper}>
          {hasTeamManager && (
            <Box>
              <Box sx={Styles.Label}>{t('employeeSection.teamManager')}</Box>
              <Box sx={Styles.Value}>{teamManagerFullName}</Box>
            </Box>
          )}

          {employee.currentProjects && employee.currentProjects.length > 0 && (
            <Box>
              <Box sx={Styles.Label}>
                {t(
                  employee.currentProjects.length === 1
                    ? 'employeeSection.currentProject'
                    : 'employeeSection.currentProjects',
                )}
              </Box>
              {employee.currentProjects.map((project, idx) => (
                <Box key={idx} sx={Styles.Value}>
                  {project}
                </Box>
              ))}
            </Box>
          )}
          {employee.email && (
            <Box>
              <Box sx={Styles.Label}>{t('employeeSection.email')}</Box>
              <Box sx={Styles.Value}>
                <a href={`mailto:${employee.email}`} rel="noreferrer">
                  {employee.email}
                </a>
              </Box>
            </Box>
          )}
          {employee.pieselyUrl && (
            <Box>
              <Box sx={Styles.Label}>{t('employeeSection.piesely')}</Box>
              <Box sx={Styles.Value}>
                <a href={employee.pieselyUrl} target="_blank" rel="noreferrer">
                  {employee.pieselyUrl}
                </a>
              </Box>
            </Box>
          )}
          {employee.luxUrl && (
            <Box>
              <Box sx={Styles.Label}>{t('employeeSection.lux')}</Box>
              <Box sx={Styles.Value}>
                <a href={employee.luxUrl} target="_blank" rel="noreferrer">
                  {employee.luxUrl}
                </a>
              </Box>
            </Box>
          )}
          {employee.employmentType === SUBCONTRACTORS_EMPLOYMENT_TYPE_CODE && (
            <Box>
              <Box sx={Styles.Label}>{t('employeeSection.employmentType')}</Box>
              <Box sx={Styles.Value}>{t('employeeSection.employmentType.subcontractor')}</Box>
            </Box>
          )}
          {employee.hiredDate && (
            <Box sx={{ mb: 4 }}>
              <Box sx={Styles.Label}>{t('employeeSection.dateHired')}</Box>
              <Box sx={Styles.Value}>
                {getLongFormatDate(new Date(employee.hiredDate ?? ''))}
                <br />
                {t('employeeSection.dateHiredDescription', {
                  time: getDurationTime(new Date(employee.hiredDate ?? '')),
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {withGoals ? <GoalsSection goalsData={getGoals()} employeeId={employee.id} /> : null}
    </>
  );
};
