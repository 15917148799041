import { AnySearchParams } from './useSearchParamsState.types';

export const isArray = <K extends AnySearchParams, T extends keyof K>(params: T | T[]): params is T[] => {
  return Array.isArray(params);
};

export const paramsToObject = <K extends AnySearchParams, T extends keyof K>(searchParams: K, params: T[]) =>
  params.reduce<Pick<K, T>>(
    (prev, curr) => {
      prev[curr] = searchParams[curr];

      return prev;
    },
    <Pick<K, T>>{},
  );
