import { Box, Card, Divider, Typography } from '@mui/material';

import { useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { getLongFormatDate } from 'shared/utils';
import { CardGoalsChecklist, CardHeader, CardNote, CardSummary } from 'ui';

import * as Styles from './ReportCard.styles';
import { ReportCardProps } from './ReportCard.types';

export const ReportCard = ({
  report: { id: reportId, summary, realizationDate, notes, goalsCollection },
  isReadOnly,
  variant = 'default',
}: ReportCardProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);

  return (
    <Card sx={Styles.CardWrapper}>
      <CardHeader
        editUrl={!isReadOnly && `/${AppRoute.EditReport}/${reportId}/${employeeId}`}
        previewUrl={`/${AppRoute.PreviewReport}/${reportId}/${employeeId}`}
        variant={variant}
      >
        <Box sx={Styles.CardInfoWrapper}>
          <Typography sx={Styles.CardDate} variant="subtitle1">
            {realizationDate && getLongFormatDate(new Date(realizationDate))}
          </Typography>
        </Box>
      </CardHeader>
      <Divider sx={Styles.CardDivider} />
      <CardSummary summary={summary} />
      <CardGoalsChecklist goals={goalsCollection?.items} isReadOnly={isReadOnly} />
      <CardNote notes={notes} />
    </Card>
  );
};
