import LanguageIcon from '@mui/icons-material/Language';
import { MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Language } from 'generated/webapp_gql';

import * as Styles from './LanguagePicker.styles';

export const LanguagePicker = () => {
  const { control, getFieldState, resetField, getValues, setValue } = useFormContext();
  const { t } = useTranslation();

  const handleChange = (language: Language, previousLanguage: Language) => {
    const introState = getFieldState('beginning');
    const outroState = getFieldState('conclusion');

    if (!introState.isDirty) {
      const value = t('surveyBuilder.questions.intro.defaultValue', { lng: language.toLowerCase() });

      resetField('beginning', {
        defaultValue: value,
        keepDirty: false,
      });
    }

    if (!outroState.isDirty) {
      const value = t('surveyBuilder.questions.outro.defaultValue', { lng: language.toLowerCase() });

      resetField('conclusion', {
        defaultValue: value,
        keepDirty: false,
      });
    }

    const title: string = getValues('title');

    if (language !== Language.Pl) {
      const titlePrefix = `[${language}]`;

      if (!title.toUpperCase().includes(titlePrefix)) {
        setValue('title', `${titlePrefix} ${title}`);
      }
    } else {
      const titlePrefix = `[${previousLanguage}]`;

      if (title.toUpperCase().includes(titlePrefix)) {
        setValue('title', title.replace(titlePrefix, '').trim());
      }
    }
  };

  return (
    <Controller
      name="language"
      control={control}
      render={({ field: { value, onChange, onBlur } }) => (
        <Select
          sx={Styles.SelectInput}
          startAdornment={<LanguageIcon sx={Styles.SelectLanguageIcon} />}
          MenuProps={{ sx: Styles.SelectMenu, BackdropProps: { sx: Styles.SelectBackdrop } }}
          value={value}
          onChange={(event) => {
            const newValue: Language = event.target.value;

            handleChange(newValue, value);
            onChange(newValue);
          }}
          onBlur={onBlur}
        >
          <MenuItem value={Language.Pl} sx={Styles.SelectMenuItem}>
            Język polski
          </MenuItem>
          <MenuItem value={Language.En} sx={Styles.SelectMenuItem}>
            Język angielski
          </MenuItem>
        </Select>
      )}
    />
  );
};
