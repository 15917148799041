import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllSurveyRespondentsQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID']['input'];
}>;


export type AllSurveyRespondentsQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', responsesByDate?: Array<{ __typename?: 'ResponsesByDate', sentAt: any, respondents: { __typename?: 'SurveyRespondentsCollection', total: number, items: Array<{ __typename?: 'SurveyResponse', status: Types.SurveyResponseStatus, respondentEmail: any, respondent?: { __typename?: 'SurveyReceiver', firstName?: string | null, lastName?: string | null, avatarUrl?: string | null } | null } | null> } } | null> | null } | null };


export const AllSurveyRespondentsDocument = gql`
    query AllSurveyRespondents($surveyId: ID!) {
  survey(id: $surveyId) {
    responsesByDate {
      respondents {
        items {
          status
          respondentEmail
          respondent {
            firstName
            lastName
            avatarUrl
          }
        }
        total
      }
      sentAt
    }
  }
}
    `;

/**
 * __useAllSurveyRespondentsQuery__
 *
 * To run a query within a React component, call `useAllSurveyRespondentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSurveyRespondentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSurveyRespondentsQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useAllSurveyRespondentsQuery(baseOptions: Apollo.QueryHookOptions<AllSurveyRespondentsQuery, AllSurveyRespondentsQueryVariables> & ({ variables: AllSurveyRespondentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSurveyRespondentsQuery, AllSurveyRespondentsQueryVariables>(AllSurveyRespondentsDocument, options);
      }
export function useAllSurveyRespondentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSurveyRespondentsQuery, AllSurveyRespondentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSurveyRespondentsQuery, AllSurveyRespondentsQueryVariables>(AllSurveyRespondentsDocument, options);
        }
export function useAllSurveyRespondentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllSurveyRespondentsQuery, AllSurveyRespondentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllSurveyRespondentsQuery, AllSurveyRespondentsQueryVariables>(AllSurveyRespondentsDocument, options);
        }
export type AllSurveyRespondentsQueryHookResult = ReturnType<typeof useAllSurveyRespondentsQuery>;
export type AllSurveyRespondentsLazyQueryHookResult = ReturnType<typeof useAllSurveyRespondentsLazyQuery>;
export type AllSurveyRespondentsSuspenseQueryHookResult = ReturnType<typeof useAllSurveyRespondentsSuspenseQuery>;
export type AllSurveyRespondentsQueryResult = Apollo.QueryResult<AllSurveyRespondentsQuery, AllSurveyRespondentsQueryVariables>;