import { useLocation, useNavigate } from 'react-router-dom';
import { useState, MouseEvent } from 'react';

import { useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';

export const useManageSurveyModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { employeeId } = useValidatedParams(['employeeId']);

  const [surveyModalId, setSurveyModalId] = useState<string | null>(null);

  const goToSurvey = (id?: string) => {
    navigate(`/${AppRoute.Survey}/${employeeId}/${id}`, {
      state: { backRoute: location.pathname },
    });
  };

  const handleSurveyCloseModal = () => {
    setSurveyModalId(null);
  };

  const handleSurveyAcceptModal = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    setSurveyModalId(id);
  };

  return {
    surveyModalId,
    goToSurvey,
    handleSurveyCloseModal,
    handleSurveyAcceptModal,
  };
};
