import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { useEmployees, useReadOnlyEmployees } from 'hooks';
import { EmployeeSortOptions } from 'generated/webapp_gql';

import { EmployeesTableContainerProps } from './EmployeesTable.types';
import { EmployeesTable } from './EmployeesTable';
import * as Styles from './EmployeesTable.styles';
import { handleSortModelChange } from './EmployeesTablesContainer.utils';

export const EmployeesTablesContainer = ({
  filters: {
    assignedToMe: [assignedToMe],
    filterByDepartments: [filterByDepartments],
    isAdminMode: [isAdminMode],
    isSubcontractorsMode: [isSubcontractorsMode],
    searchText: [searchText],
  },
  pagination: {
    employees: {
      page: [page, onPageChange],
      pageSize: [pageSize, onPageSizeChange],
    },
    employeesWithActions: {
      page: [employeesWithActionsPage, onEmployeesWithActionsPageChange],
      pageSize: [employeesWithActionsPageSize, onEmployeesWithActionsPageSizeChange],
    },
  },
  sorts: {
    sort: [sort, onSortChange],
    employeesWithActionsSort: [employeesWithActionsSort, onEmployeesWithActionsSortChange],
  },
}: EmployeesTableContainerProps) => {
  const { t } = useTranslation();
  const {
    employeesData: employeesWithActionsData,
    isEmployeesDataLoading: isEmployeesWithActionsDataLoading,
    prevEmployeesData: prevEmployeesWithActionsData,
  } = useEmployees({
    filters: {
      assignedToMe,
      filterByDepartments,
      isAdminMode,
      searchText,
    },
    paginationFilters: {
      page: employeesWithActionsPage,
      pageSize: employeesWithActionsPageSize,
    },
    sortFilters: employeesWithActionsSort,
    withPendingActions: true,
    defaultSortBy: EmployeeSortOptions.LatestReceivedWeeklyFeedbackRealizationDate,
  });

  const { employeesData, isEmployeesDataLoading, prevEmployeesData } = useEmployees({
    filters: {
      assignedToMe,
      filterByDepartments,
      isAdminMode,
      searchText,
    },
    paginationFilters: {
      page,
      pageSize,
    },
    sortFilters: sort,
    withPendingActions: false,
    defaultSortBy: EmployeeSortOptions.LatestReceivedWeeklyFeedbackRealizationDate,
  });

  const { isReadOnlyEmployeesDataLoading, prevReadOnlyEmployeesData, readOnlyEmployeesData } = useReadOnlyEmployees({
    filters: {
      filterByDepartments,
      isAdminMode,
      searchText,
      isSubcontractorsMode,
    },
    paginationFilters: {
      page,
      pageSize,
    },
    sortFilters: sort,
    defaultSortBy: EmployeeSortOptions.FirstName,
  });

  const employees = isEmployeesDataLoading ? prevEmployeesData : employeesData;
  const employeesWithActions = isEmployeesWithActionsDataLoading
    ? prevEmployeesWithActionsData
    : employeesWithActionsData;
  const readOnlyEmployees = isReadOnlyEmployeesDataLoading ? prevReadOnlyEmployeesData : readOnlyEmployeesData;

  return (
    <Box sx={Styles.Container}>
      {!isAdminMode && (
        <EmployeesTable
          accordionTitle={t('employeesTable.title.employeesWithAction')}
          isLoading={!!isEmployeesWithActionsDataLoading}
          data={employeesWithActions?.items}
          dataGridProps={{
            page: employeesWithActionsPage,
            onPageChange: onEmployeesWithActionsPageChange,
            pageSize: employeesWithActionsPageSize,
            onPageSizeChange: onEmployeesWithActionsPageSizeChange,
            rowCount: employeesWithActionsData?.total,
            onSortModelChange: handleSortModelChange(onEmployeesWithActionsSortChange),
          }}
          isPendingActionsColumnSortable={true}
        />
      )}
      <EmployeesTable
        accordionTitle={t('employeesTable.title.employees')}
        isLoading={isAdminMode ? isReadOnlyEmployeesDataLoading : isEmployeesDataLoading}
        data={isAdminMode ? readOnlyEmployees?.items : employees?.items}
        dataGridProps={{
          page,
          onPageChange,
          pageSize,
          onPageSizeChange,
          rowCount: isAdminMode ? readOnlyEmployees?.total : employees?.total,
          onSortModelChange: handleSortModelChange(onSortChange),
        }}
        isPendingActionsColumnSortable={false}
        isReadOnly={isAdminMode}
      />
    </Box>
  );
};
