import { Switch as MuiSwitch } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';

import { SwitchProps } from './Switch.types';
import * as Styles from './Switch.styles';

export const Switch = ({ color = 'primary', sx, ...props }: SwitchProps) => (
  <MuiSwitch color={color} sx={spreadMultipleStyles(Styles.Switch, sx)} {...props} />
);
