import { Styles } from 'shared/types';
import { theme } from 'shared/utils';

export const CardSection: Styles = { my: 2.5, position: 'relative' };

export const CardSectionLabel: Styles = { color: theme.palette.text.primary };

export const CardSectionContent: Styles = {
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  wordBreak: 'break-word',
};
