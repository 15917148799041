import { Box } from '@mui/material';

import { ItemTitleWithCheckbox } from '../../../../ui';

import * as Styles from './GoalsSection.styles';
import { GoalsListProps } from './types';

const MAX_GOAL_TEXT_LENGTH = 100;

export const GoalsList = ({ goals }: GoalsListProps) => {
  const handleClick = () => {};
  return (
    <Box sx={Styles.List}>
      {goals.map((item) => (
        <Box key={item.id} sx={Styles.ListItem}>
          <ItemTitleWithCheckbox
            withoutCheckbox
            onClick={handleClick}
            content={
              (item.content?.length || 0) > MAX_GOAL_TEXT_LENGTH
                ? `${item.content?.slice(0, MAX_GOAL_TEXT_LENGTH)}...`
                : item.content
            }
            type={item.goalStatus}
          />
        </Box>
      ))}
    </Box>
  );
};
