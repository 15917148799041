import { MouseEvent, useState } from 'react';
import { ArrowDropDown, ArrowDropUp, CheckBox, LinearScale, RadioButtonChecked, ShortText } from '@mui/icons-material';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DropDownMenu } from 'ui';
import { TranslationsKeys } from 'i18n/i18n.types';
import { SurveyQuestionFormat } from 'generated/webapp_gql';

import { QuestionFormatDropdownProps, QuestionTypesMenuItems } from './QuestionFormatDropdown.types';
import * as Styles from './QuestionFormatDropdown.styles';

const questionTypesMenuItems: QuestionTypesMenuItems[] = [
  {
    label: 'surveyBuilder.questions.question.type.closeEnded',
    icon: <RadioButtonChecked />,
    type: SurveyQuestionFormat.CloseEnded,
  },
  {
    label: 'surveyBuilder.questions.question.type.multipleChoice',
    icon: <CheckBox />,
    type: SurveyQuestionFormat.MultipleChoice,
  },
  {
    label: 'surveyBuilder.questions.question.type.openEnded',
    icon: <ShortText />,
    type: SurveyQuestionFormat.OpenEnded,
  },
  {
    label: 'surveyBuilder.questions.question.type.linearScale',
    icon: <LinearScale />,
    type: SurveyQuestionFormat.LinearScale,
  },
];

const dropdownMenuProps = {
  transformOrigin: {
    horizontal: 'right',
    vertical: 'top',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
};

export const QuestionFormatDropdown = ({
  onSetQuestionFormat,
  onResetAnswers,
  format,
}: QuestionFormatDropdownProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleMenuItemClick = (format: SurveyQuestionFormat) => {
    onSetQuestionFormat(format);
    handleClose();
    onResetAnswers();
  };

  return (
    <Box sx={Styles.DropdownWrapper}>
      <Button onClick={handleMenu} size="small" variant="text" color="inherit" sx={Styles.DropdownButton}>
        {t(questionTypesMenuItems.find((item) => item.type === format)?.label as TranslationsKeys)}
        {anchorEl === null ? <ArrowDropDown sx={Styles.ArrowIcon} /> : <ArrowDropUp sx={Styles.ArrowIcon} />}
      </Button>
      <DropDownMenu anchorEl={anchorEl} onMenuClose={handleClose} sx={Styles.DropDownList} {...dropdownMenuProps}>
        {questionTypesMenuItems.map((item, index) => (
          <MenuItem key={index} onClick={() => handleMenuItemClick(item.type)} sx={Styles.MenuItemHighlight}>
            {item.icon}
            <Typography sx={Styles.MenuItemLabel}>{t(item.label)}</Typography>
          </MenuItem>
        ))}
      </DropDownMenu>
    </Box>
  );
};
