import { colors } from 'shared/utils';

export const getDragDropPlaceholderColors = (isDragAccept: boolean, isDragReject: boolean) => {
  if (isDragAccept) {
    return {
      backgroundColor: colors.pattensBlue,
      borderColor: colors.blueberry,
    };
  }

  if (isDragReject) {
    return {
      backgroundColor: colors.provincialPink,
      borderColor: colors.tomato,
    };
  }

  return {
    backgroundColor: 'transparent',
    borderColor: colors.slateGray,
  };
};
