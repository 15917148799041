import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

import * as Styles from '../SurveyTabs.styles';

type SurveyListItemProps = {
  onClick: () => void;
  title: string;
  dateInfo: string;
  actionsElements?: ReactNode | null;
};

export const SurveyListItem = ({ onClick, title, dateInfo, actionsElements = null }: SurveyListItemProps) => {
  return (
    <Box sx={Styles.Template} onClick={onClick}>
      <Box sx={Styles.TemplateText}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="body2" sx={Styles.CreationDate}>
          {dateInfo}
        </Typography>
      </Box>
      {actionsElements || null}
    </Box>
  );
};
