import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeGoalsQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  goalStatus?: Types.InputMaybe<Types.GoalStatus>;
  goalsPagination?: Types.InputMaybe<Types.PaginationInput>;
  goalsSort?: Types.InputMaybe<Types.SortByRealizationDateInput>;
}>;


export type EmployeeGoalsQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, in_progress?: number | null, done?: number | null, canceled?: number | null, goalsCollection?: { __typename?: 'GoalCollection', total: number, items: Array<{ __typename?: 'Goal', id: string, content?: string | null, goalStatus?: Types.GoalStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, quarterlyReportId: string } | null> } | null } | null };


export const EmployeeGoalsDocument = gql`
    query employeeGoals($employeeId: Int!, $goalStatus: GoalStatus, $goalsPagination: PaginationInput, $goalsSort: SortByRealizationDateInput) {
  employee(id: $employeeId) {
    id
    in_progress: pendingGoalsAmount(goalStatus: IN_PROGRESS)
    done: pendingGoalsAmount(goalStatus: DONE)
    canceled: pendingGoalsAmount(goalStatus: CANCELED)
    goalsCollection(
      goalStatus: $goalStatus
      pagination: $goalsPagination
      sort: $goalsSort
    ) {
      items {
        id
        content
        goalStatus
        createdAt
        realizationDate
        updatedAt
        quarterlyReportId
      }
      total
    }
  }
}
    `;

/**
 * __useEmployeeGoalsQuery__
 *
 * To run a query within a React component, call `useEmployeeGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeGoalsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      goalStatus: // value for 'goalStatus'
 *      goalsPagination: // value for 'goalsPagination'
 *      goalsSort: // value for 'goalsSort'
 *   },
 * });
 */
export function useEmployeeGoalsQuery(baseOptions: Apollo.QueryHookOptions<EmployeeGoalsQuery, EmployeeGoalsQueryVariables> & ({ variables: EmployeeGoalsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeGoalsQuery, EmployeeGoalsQueryVariables>(EmployeeGoalsDocument, options);
      }
export function useEmployeeGoalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeGoalsQuery, EmployeeGoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeGoalsQuery, EmployeeGoalsQueryVariables>(EmployeeGoalsDocument, options);
        }
export function useEmployeeGoalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmployeeGoalsQuery, EmployeeGoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeeGoalsQuery, EmployeeGoalsQueryVariables>(EmployeeGoalsDocument, options);
        }
export type EmployeeGoalsQueryHookResult = ReturnType<typeof useEmployeeGoalsQuery>;
export type EmployeeGoalsLazyQueryHookResult = ReturnType<typeof useEmployeeGoalsLazyQuery>;
export type EmployeeGoalsSuspenseQueryHookResult = ReturnType<typeof useEmployeeGoalsSuspenseQuery>;
export type EmployeeGoalsQueryResult = Apollo.QueryResult<EmployeeGoalsQuery, EmployeeGoalsQueryVariables>;