import { SurveyResponsesQuery } from 'documents/surveyResponses.generated';

import { SurveyRespondent } from './SurveyRespondents.types';

export const formatSurveyRespondents = (surveyRespondents?: SurveyResponsesQuery): SurveyRespondent[] => {
  return (
    surveyRespondents?.survey?.responsesByDate?.flatMap((responsesByDate) =>
      responsesByDate
        ? responsesByDate.respondents.items.flatMap((item) =>
            item
              ? {
                  sentAt: responsesByDate?.sentAt,
                  id: item.id,
                  fullName: item.respondent ? `${item.respondent.firstName} ${item.respondent.lastName}` : null,
                  email: item.respondentEmail,
                  status: item.status,
                  surveyLink: item.surveyLink,
                  avatarUrl: item.respondent?.avatarUrl,
                }
              : [],
          )
        : [],
    ) || []
  );
};
