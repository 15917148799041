import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeWithGoalsQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
}>;


export type EmployeeWithGoalsQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, firstName: string, lastName: string, avatarUrl?: string | null, email: string, level?: string | null, hiredDate: any, pieselyUrl?: string | null, satisfactionLevel?: number | null, supervisor?: { __typename?: 'Employee', firstName: string, lastName: string } | null, goalsCollection?: { __typename?: 'GoalCollection', total: number, items: Array<{ __typename?: 'Goal', id: string, content?: string | null, createdAt: any, realizationDate?: any | null, updatedAt: any, goalStatus?: Types.GoalStatus | null, quarterlyReportId: string } | null> } | null } | null };


export const EmployeeWithGoalsDocument = gql`
    query employeeWithGoals($employeeId: Int!) {
  employee(id: $employeeId) {
    id
    firstName
    lastName
    avatarUrl
    email
    level
    supervisor {
      firstName
      lastName
    }
    hiredDate
    pieselyUrl
    satisfactionLevel
    goalsCollection {
      items {
        id
        content
        createdAt
        realizationDate
        updatedAt
        goalStatus
        quarterlyReportId
      }
      total
    }
  }
}
    `;

/**
 * __useEmployeeWithGoalsQuery__
 *
 * To run a query within a React component, call `useEmployeeWithGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeWithGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeWithGoalsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useEmployeeWithGoalsQuery(baseOptions: Apollo.QueryHookOptions<EmployeeWithGoalsQuery, EmployeeWithGoalsQueryVariables> & ({ variables: EmployeeWithGoalsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeWithGoalsQuery, EmployeeWithGoalsQueryVariables>(EmployeeWithGoalsDocument, options);
      }
export function useEmployeeWithGoalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeWithGoalsQuery, EmployeeWithGoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeWithGoalsQuery, EmployeeWithGoalsQueryVariables>(EmployeeWithGoalsDocument, options);
        }
export function useEmployeeWithGoalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmployeeWithGoalsQuery, EmployeeWithGoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeeWithGoalsQuery, EmployeeWithGoalsQueryVariables>(EmployeeWithGoalsDocument, options);
        }
export type EmployeeWithGoalsQueryHookResult = ReturnType<typeof useEmployeeWithGoalsQuery>;
export type EmployeeWithGoalsLazyQueryHookResult = ReturnType<typeof useEmployeeWithGoalsLazyQuery>;
export type EmployeeWithGoalsSuspenseQueryHookResult = ReturnType<typeof useEmployeeWithGoalsSuspenseQuery>;
export type EmployeeWithGoalsQueryResult = Apollo.QueryResult<EmployeeWithGoalsQuery, EmployeeWithGoalsQueryVariables>;