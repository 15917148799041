import { useEffect } from 'react';

import { useSearchParam } from 'hooks';
import { useLocalStorage } from '../useLocalStorage/useLocalStorage';

import { EmployeesPaginationParam } from './useEmployeesPagination.enum';

export const DEFAULT_ACTIVE_PAGE = 0;
export const PAGE_SIZE_OPTIONS = [10, 15, 30].sort();
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0];
export const DEFAULT_PAGE = 1;

export const useEmployeesPagination = () => {
  const [initialPageSize, setPageSize] = useLocalStorage(EmployeesPaginationParam.PageSize, 'number');
  const [initialEmployeesWithActionsPageSize, setEmployeesWithActionsPageSize] = useLocalStorage(
    EmployeesPaginationParam.EmployeesWithActionsPageSize,
    'number',
  );

  const page = useSearchParam({
    paramName: EmployeesPaginationParam.Page,
    type: 'page',
    options: {
      defaultValue: DEFAULT_PAGE,
    },
  });
  const employeesWithActionsPage = useSearchParam({
    paramName: EmployeesPaginationParam.EmployeesWithActionsPage,
    type: 'page',
    options: {
      defaultValue: DEFAULT_PAGE,
    },
  });

  const pageSize = useSearchParam({
    paramName: EmployeesPaginationParam.PageSize,
    type: 'number',
    options: {
      defaultValue: initialPageSize || DEFAULT_PAGE_SIZE,
    },
  });
  const employeesWithActionsPageSize = useSearchParam({
    paramName: EmployeesPaginationParam.EmployeesWithActionsPageSize,
    type: 'number',
    options: {
      defaultValue: initialEmployeesWithActionsPageSize || DEFAULT_PAGE_SIZE,
    },
  });

  useEffect(() => {
    setPageSize(pageSize[0]);
  }, [setPageSize, pageSize]);

  useEffect(() => {
    setEmployeesWithActionsPageSize(employeesWithActionsPageSize[0]);
  }, [setEmployeesWithActionsPageSize, employeesWithActionsPageSize]);

  return {
    employees: {
      page,
      pageSize,
    },
    employeesWithActions: {
      page: employeesWithActionsPage,
      pageSize: employeesWithActionsPageSize,
    },
  };
};
