import { Language, SurveyStatus, SurveyTemplate } from 'generated/webapp_gql';

export enum ReadOnlySurveyType {
  PUBLISHED_TEMPLATE = 'PUBLISHED_TEMPLATE',
  PUBLISHED_SURVEY = 'PUBLISHED_SURVEY',
  ARCHIVED = 'ARCHIVED',
}

export type ReadOnlySurvey = {
  template?: SurveyTemplate | null;
  templateId?: string;
  surveyId?: string;
  language: Language;
  status?: SurveyStatus;
  closeDate?: string;
  upcomingNotificationsDispatch?: string;
  isSurveyLoading: boolean;
  title?: string;
  answersCount?: number;
  type: ReadOnlySurveyType;
  slackNotification: number;
  isSent: boolean;
  isPublished: boolean;
  surveyCreatorFullName: string | undefined;
  surveyCreatorAvatarUrl: string | undefined;
};
