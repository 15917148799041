import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReadOnlyEmployeesQueryVariables = Types.Exact<{
  filterByDepartments?: Types.InputMaybe<Array<Types.DepartmentCodes> | Types.DepartmentCodes>;
  searchedValue?: Types.InputMaybe<Types.Scalars['searchedValue_String_minLength_1']['input']>;
  withSubcontractorsMode?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
  sort?: Types.InputMaybe<Types.SortByLastNameInput>;
}>;


export type ReadOnlyEmployeesQuery = { __typename?: 'Query', employees?: { __typename?: 'EmployeeCollection', total: number, items: Array<{ __typename?: 'Employee', id: number, fullName: string, firstName: string, lastName: string, avatarUrl?: string | null, department: string, currentProjects?: Array<string | null> | null } | null> } | null };


export const ReadOnlyEmployeesDocument = gql`
    query ReadOnlyEmployees($filterByDepartments: [DepartmentCodes!], $searchedValue: searchedValue_String_minLength_1, $withSubcontractorsMode: Boolean, $pagination: PaginationInput, $sort: SortByLastNameInput) {
  employees(
    searchedValue: $searchedValue
    withAdminMode: true
    withSubcontractorsMode: $withSubcontractorsMode
    filterByDepartments: $filterByDepartments
    pagination: $pagination
    sort: $sort
  ) {
    items {
      id
      fullName
      firstName
      lastName
      avatarUrl
      department
      currentProjects
    }
    total
  }
}
    `;

/**
 * __useReadOnlyEmployeesQuery__
 *
 * To run a query within a React component, call `useReadOnlyEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOnlyEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOnlyEmployeesQuery({
 *   variables: {
 *      filterByDepartments: // value for 'filterByDepartments'
 *      searchedValue: // value for 'searchedValue'
 *      withSubcontractorsMode: // value for 'withSubcontractorsMode'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useReadOnlyEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<ReadOnlyEmployeesQuery, ReadOnlyEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOnlyEmployeesQuery, ReadOnlyEmployeesQueryVariables>(ReadOnlyEmployeesDocument, options);
      }
export function useReadOnlyEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOnlyEmployeesQuery, ReadOnlyEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOnlyEmployeesQuery, ReadOnlyEmployeesQueryVariables>(ReadOnlyEmployeesDocument, options);
        }
export function useReadOnlyEmployeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReadOnlyEmployeesQuery, ReadOnlyEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadOnlyEmployeesQuery, ReadOnlyEmployeesQueryVariables>(ReadOnlyEmployeesDocument, options);
        }
export type ReadOnlyEmployeesQueryHookResult = ReturnType<typeof useReadOnlyEmployeesQuery>;
export type ReadOnlyEmployeesLazyQueryHookResult = ReturnType<typeof useReadOnlyEmployeesLazyQuery>;
export type ReadOnlyEmployeesSuspenseQueryHookResult = ReturnType<typeof useReadOnlyEmployeesSuspenseQuery>;
export type ReadOnlyEmployeesQueryResult = Apollo.QueryResult<ReadOnlyEmployeesQuery, ReadOnlyEmployeesQueryVariables>;