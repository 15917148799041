import { Box, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AvatarWithFallback, ConfirmationDialog } from 'ui';
import { ButtonNext } from 'ui/buttonNext/ButtonNext';
import { ButtonPrev } from 'ui/buttonPrev/ButtonPrev';
import { SurveyResponseStatus } from 'generated/webapp_gql';

import * as Styles from './PreviewSurveyQuestions.styles';
import { PreviewSurveyFormType, PreviewSurveyQuestionsProps, SurveyProgressType } from './PreviewSurveyQuestions.types';
import { PreviewSurveySection } from './previewSurveySection/PreviewSurveySection';
import { SectionCounterChip } from './sectionCounterChip/SectionCounterChip';
import { SurveyProgress } from './surveyProgress/SurveyProgress';
import { SurveyQuestion } from './surveyQuestion/SurveyQuestion';

export const PreviewSurveyQuestions = ({
  sections,
  response,
  responseStatus,
  backToIntro,
  goToOutro,
  employee,
  isLoading,
  onSubmit,
  shouldValidate,
}: PreviewSurveyQuestionsProps) => {
  const { t } = useTranslation();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [surveyProgress, setSurveyProgress] = useState<SurveyProgressType>({
    currentQuestion: 0,
    currentSection: 0,
    totalQuestions: 0,
  });

  const isEditMode = responseStatus === SurveyResponseStatus.Reattempted;

  const mapResponseToFormData = useMemo((): PreviewSurveyFormType => {
    const formData: PreviewSurveyFormType = {};

    if (response?.length < 1) {
      return formData;
    }

    let currentQuestion = 0;
    let currentSection = 0;
    let previousWasAnswered = false;

    sections.every((section, sectionIndex) => {
      return section.questions.every((surveyQuestion, questionIndex) => {
        const key: keyof PreviewSurveyFormType = `${sectionIndex}_${questionIndex}`;
        const answer = response.find((record) => record.question === surveyQuestion?.question)?.answer;

        if (!answer?.length && !previousWasAnswered) {
          return false;
        }

        currentQuestion = questionIndex;
        currentSection = sectionIndex;

        if (answer?.length) {
          previousWasAnswered = true;

          formData[key] = answer;
        }

        !isEditMode && setSurveyProgress((prev) => ({ ...prev, currentQuestion, currentSection }));
        setShowQuestion(true);

        if (!answer?.length && previousWasAnswered) {
          previousWasAnswered = false;
        }

        return true;
      });
    });

    return formData;
  }, [sections, response, isEditMode]);

  const previewSurveyMethods = useForm<PreviewSurveyFormType>({
    defaultValues: mapResponseToFormData,
  });

  useEffect(() => {
    setSurveyProgress((prev) => ({
      ...prev,
      totalQuestions: sections[prev.currentSection]?.questions.length ?? 0,
    }));
  }, [sections]);

  const { isDirty } = useFormState({
    control: previewSurveyMethods.control,
  });

  if (sections.length === 0) return null;

  const displayedSection = sections[surveyProgress.currentSection];

  const questionInSection = surveyProgress.currentQuestion;

  const currentQuestion = displayedSection?.questions[questionInSection];

  const isLastQuestion = questionInSection + 1 === displayedSection?.questions.length;
  const isLastSection = surveyProgress.currentSection === sections.length - 1;

  const changeCurrentSection = (direction: 1 | -1) => {
    setSurveyProgress((prev) => {
      const totalQuestions = sections[prev.currentSection + direction]?.questions.length ?? 0;

      return {
        totalQuestions,
        currentQuestion: direction === -1 ? totalQuestions - 1 : 0,
        currentSection: prev.currentSection + direction,
      };
    });
  };

  const handleBackBtn = () => {
    if (surveyProgress.currentSection === 0) {
      backToIntro();
      return;
    }

    changeCurrentSection(-1);
    setShowQuestion(true);
  };

  const handleStartBtn = () => {
    setShowQuestion(true);
  };

  const handleGoNextQuestion = () => {
    if (!isEditMode && (!isLastQuestion || (isLastQuestion && !isLastSection))) {
      handleSaveSurveyProgress();
    }

    if (isLastQuestion) {
      setShowQuestion(false);
      if (isLastSection) {
        goToOutro();
      } else {
        changeCurrentSection(1);
      }
      return;
    }

    setSurveyProgress((prev) => ({
      ...prev,
      currentQuestion: surveyProgress.currentQuestion + 1,
    }));
  };

  const handleSendSurvey = () => {
    onSubmit(previewSurveyMethods.getValues())
      .then(handleGoNextQuestion)
      .catch(() => {})
      .finally(() => setShowConfirmationModal(false));
  };

  const handleSaveSurveyProgress = () => {
    if (isDirty) {
      onSubmit(previewSurveyMethods.getValues(), true)
        .then(() => {
          toast.success(t('surveyPreview.toast.progressSaved'));
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  };

  const handlePreviousQuestion = () => {
    if (questionInSection === 0) {
      if (surveyProgress.currentSection === 0) {
        backToIntro();
      } else {
        changeCurrentSection(-1);
      }
      return;
    }

    setSurveyProgress((prev) => ({
      ...prev,
      currentQuestion: prev.currentQuestion - 1,
    }));
  };

  if (!showQuestion)
    return (
      <PreviewSurveySection
        currentSectionNumber={surveyProgress.currentSection}
        sectionsTotal={sections.length}
        employee={employee}
        section={displayedSection}
        onBackClick={handleBackBtn}
        onNextClick={handleStartBtn}
      />
    );

  const nextIsSend = isLastSection && isLastQuestion;

  const goNextHandler = shouldValidate ? previewSurveyMethods.handleSubmit(handleGoNextQuestion) : handleGoNextQuestion;
  const sendHandler = shouldValidate
    ? previewSurveyMethods.handleSubmit(() => setShowConfirmationModal(true))
    : handleSendSurvey;

  if (showQuestion && currentQuestion && employee)
    return (
      <Box sx={Styles.QuestionContainer} component="form">
        <Box sx={Styles.User}>
          <Typography variant="body1" sx={Styles.UserAvatarDescription}>
            {t('surveyPreview.intro.concerns')}
          </Typography>
          <AvatarWithFallback size="xl" src={employee.avatarUrl} alt={employee.fullName ?? ''} />
          <Typography variant="subtitle1" sx={Styles.FullName}>
            {employee.fullName ?? ''}
          </Typography>
        </Box>
        <FormProvider {...previewSurveyMethods}>
          <SurveyQuestion
            question={currentQuestion}
            questionIndex={questionInSection}
            sectionIndex={surveyProgress.currentSection}
          />
        </FormProvider>
        <Box sx={Styles.QuestionBottomControls}>
          <ButtonPrev onClick={handlePreviousQuestion}>{t('surveyPreview.questionPreview.back')}</ButtonPrev>
          <Box>
            <SectionCounterChip current={surveyProgress.currentSection + 1} total={sections.length} />
            <Typography sx={Styles.CurrentSectionTitle}>{displayedSection.title}</Typography>
            <SurveyProgress surveyProgress={surveyProgress} surveySectionsAmount={sections.length} />
          </Box>
          {nextIsSend && (
            <ButtonNext onClick={sendHandler} disabled={isLoading}>
              {t('surveyPreview.questionPreview.send')}
            </ButtonNext>
          )}
          {!nextIsSend && <ButtonNext onClick={goNextHandler}>{t('surveyPreview.questionPreview.next')}</ButtonNext>}
        </Box>
        <ConfirmationDialog
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          onConfirmation={handleSendSurvey}
          secondaryButtonVariant="white"
          title={t('surveyPreview.confirmModal.title')}
          content={t('surveyPreview.confirmModal.content')}
          cancelButtonText={t('surveyPreview.confirmModal.cancelButton')}
          acceptButtonText={t('surveyPreview.confirmModal.acceptButton')}
        />
      </Box>
    );

  return null;
};
