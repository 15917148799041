import { ReactNode } from 'react';

export type SurveyBuilderPublicationContainerProps = {
  children?: ReactNode;
  isClosed?: boolean;
  isSent?: boolean;
  isPublished?: boolean;
  changeSurveySwitchState?: (state: boolean) => void;
  openPreview: () => void;
};

export enum PublicationTabs {
  Edit = 'edit',
  Respondents = 'respondents',
}
