import { Box } from '@mui/material';

import * as Styles from './Loader.styles';

export const Loader = () => {
  return (
    <Box component="div" sx={Styles.Wrapper} data-testid="loader">
      <Box component="span" sx={Styles.Dot} />
      <Box component="div" sx={Styles.Dots}>
        <Box component="span" sx={Styles.DotsGooey} />
        <Box component="span" sx={Styles.DotsGooey} />
        <Box component="span" sx={Styles.DotsGooey} />
      </Box>
    </Box>
  );
};
