import { alpha } from '@mui/material';

export const MuiInputBase = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      root: {
        '&&.MuiInput-root': {
          fontSize: 14,
          padding: '0 0 6.5px',
          marginTop: 17.5,
        },
        '&&.MuiInputBase-sizeSmall': {
          padding: '0 0 5px',
          marginTop: 16,
        },
        '&&.MuiInputBase-sizeMedium': {},
        '&& .MuiInputAdornment-root .MuiTypography-root': {
          color: alpha(colors.navy, 0.54),
        },
        '&& .MuiInputAdornment-root': {
          height: 'auto',
          lineHeight: '24px',
        },
        '&&:after': {
          content: 'none',
        },
        '&&:before': {
          borderBottom: `1px solid ${alpha(colors.navy, 0.46)}`,
        },
        '&&:hover:not(.Mui-disabled):not(.Mui-focused):before': {
          borderBottom: `2px solid ${colors.navy}`,
        },
        '&&.MuiInput-root.Mui-focused:before': {
          borderBottom: `2px solid ${colors.blueberry}`,
        },
        '&&.MuiInput-root.Mui-error:before': {
          borderWidth: '2px',
          borderColor: colors.tomato,
        },
        '&&.MuiInput-root.Mui-focused .MuiInput-input::placeholder': {
          color: 'transparent',
        },
        '&&.Mui-error:before': {
          borderBottomColor: colors.tomato,
        },
      },
    },
  };
};
