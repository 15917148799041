import { Box, Typography } from '@mui/material';

import * as Styles from './SmilingFacesRating.styles';
import { SmilingFacesRatingProps } from './SmilingFacesRating.types';
import { smilingFacesRating } from './SmilingFacesRating.utils';

export const SmilingFacesRating = ({ value, type = 'default' }: SmilingFacesRatingProps) => {
  return smilingFacesRating[value] ? (
    <Box sx={type === 'filled' ? Styles.RatingWrapperFilled : Styles.RatingWrapperDefault}>
      <Box
        component="img"
        alt={smilingFacesRating[value].emoji}
        src={smilingFacesRating[value].emoji}
        className="emojiIcon"
      />
      <Typography variant="body2" className="RatingDescription">
        {smilingFacesRating[value].text}
      </Typography>
    </Box>
  ) : null;
};
