import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import { AppRoute } from 'routing/AppRoute.enum';
import { ReactComponent as AppLogo } from 'assets/images/AppLogo.svg';

import * as Styles from './LogoSection.styles';

export const LogoSection = () => (
  <Box component={Link} to={AppRoute.Home} sx={Styles.AppTitleWrapper}>
    <AppLogo />
  </Box>
);
