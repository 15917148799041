import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { Goal, GoalStatus } from 'generated/webapp_gql';
import { useModal, useUpdateGoal, useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { Accordion, Modal, ToDoTable } from 'ui';
import { ToDoType } from 'ui/modal/Modal.enum';
import { goalsTabsTranslations } from 'app/employeeDetails/employeeDetailsQuarterly/goalsTabs/GoalsTabs.utils';
import { GOALS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';
import { GoalsStatusKeys } from 'app/employeeDetails/employeeDetailsQuarterly/goalsTabs/GoalsTabs.types';
import { useUpdateGoalContent } from 'hooks/useUpdateGoalContent/useUpdateGoalContent';

import { GoalsAccordionProps } from './GoalsAccordion.types';

export const GoalsAccordion = ({ type, items, total, pages, loading, setPages }: GoalsAccordionProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { t } = useTranslation();

  const updateHandler = useUpdateGoalContent();

  const { sendGoal } = useUpdateGoal({ pages });
  const { handleItemClick, item, handleModalClose } = useModal<GoalStatus>({
    onConfirm: sendGoal,
    canceledStatus: GoalStatus.Canceled,
    doneStatus: GoalStatus.Done,
  });

  useEffect(() => {
    if (total) {
      const lastPage = Math.ceil(total / GOALS_LIST_PAGE_SIZE) || 1;

      if (lastPage < pages[type.toLowerCase() as GoalsStatusKeys]) {
        setPages({ ...pages, [type.toLowerCase() as GoalsStatusKeys]: lastPage });
      }
    }
  }, [pages, setPages, total, type]);

  const getRedirectToQuarterly = (quarterlyId?: string) =>
    quarterlyId ? { to: `/${AppRoute.PreviewReport}/${quarterlyId}/${employeeId}` } : null;

  return (
    <>
      <Accordion
        title={t(goalsTabsTranslations[type], {
          count: total,
        })}
        content={
          <ToDoTable
            onClick={handleItemClick}
            toDoData={items as Goal[]}
            getOnArrowClickState={getRedirectToQuarterly}
            loading={loading}
            pagination={{
              total,
              pageSize: GOALS_LIST_PAGE_SIZE,
              current: pages[type.toLowerCase() as GoalsStatusKeys],
              handlePageChange: (_, value) => {
                setPages({ ...pages, [type.toLowerCase() as GoalsStatusKeys]: value });
              },
            }}
            context={updateHandler}
          />
        }
      />
      {item.type && (
        <Modal onClose={handleModalClose} type={item.type} content={item.content} toDoType={ToDoType.Goal} />
      )}
    </>
  );
};
