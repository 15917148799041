import { Styles } from 'shared/types';

export const ControlledTextField: Styles = {
  div: {
    borderRadius: '0px 4px 4px',
  },
};

export const DialogContent: Styles = {
  pt: 2,
  pb: 3,
};

export const Sections: Styles = {
  display: 'flex',
  gap: 4,
  flexDirection: 'column',
};
