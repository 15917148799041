import { Box, Paper } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuestionsWrapper } from 'app/questionsWrapper/QuestionsWrapper';
import { QuestionsSection } from 'ui';
import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';

import * as Styles from './QuestionsSectionWrapper.styles';

export const QuestionsSectionWrapper = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<SurveyFormData>();
  const { fields } = useFieldArray({
    name: 'sections',
    control: control,
  });

  return (
    <>
      <Box sx={Styles.Sections}>
        {fields.map((_section, index) => (
          <Paper elevation={1} key={index}>
            <QuestionsSection
              label={t('surveyBuilder.questions.section.label', { count: index + 1 })}
              sectionsAmount={fields.length}
            >
              <QuestionsWrapper nestIndex={index} />
            </QuestionsSection>
          </Paper>
        ))}
      </Box>
    </>
  );
};
