import { useMsal } from '@azure/msal-react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CenteredMessageWrapper, LogoSection } from 'ui';
import { msalConfig } from 'msalConfig';

import * as Styles from './Login.styles';

export const Login = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect({
      redirectUri: msalConfig.auth.redirectUri,
      redirectStartPage: msalConfig.auth.redirectUri,
      scopes: [`${msalConfig.auth.clientId}/.default`],
    });
  };

  return (
    <CenteredMessageWrapper sx={Styles.Wrapper}>
      <LogoSection />
      <Typography variant="h5" sx={Styles.Header}>
        {t('login.heading')}
      </Typography>
      <Typography variant="body2" sx={Styles.WelcomeText}>
        {t('login.welcomeText')}
      </Typography>
      <Button variant="contained" fullWidth onClick={handleLogin}>
        {t('login.buttonText')}
      </Button>
    </CenteredMessageWrapper>
  );
};
