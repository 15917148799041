import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const CopySurveyLinkButton: Styles = {
  width: 36,
  height: 36,
  color: colors.blueberry,

  '& svg': {
    width: 24,
    height: 24,
  },
};
