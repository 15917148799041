import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from 'ui';

import { RemoveSectionModalProps } from './RemoveSectionModal.types';

export const RemoveSectionModal = ({ onClose, removeSection }: RemoveSectionModalProps) => {
  const { t } = useTranslation();
  return (
    <ConfirmationDialog
      onClose={onClose}
      onConfirmation={removeSection}
      open={true}
      isAcceptButtonActive={true}
      title={t('surveyBuilder.questions.removeSectionDialog.title')}
      content={t('surveyBuilder.questions.removeSectionDialog.description')}
      acceptButtonText={t('surveyBuilder.questions.removeSectionDialog.acceptButtonText')}
      cancelButtonText={t('surveyBuilder.questions.removeSectionDialog.cancelButtonText')}
      alertSeverity="warning"
      alertText={t('surveyBuilder.questions.removeSectionDialog.alertText')}
    />
  );
};
