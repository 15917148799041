import { SurveyTemplateListItem, SurveysListTab } from '../SurveyTemplatesTabs.types';
import { SortVariables, SurveyTabProps } from '../SurveyTabs.types';
import { TabContainer } from '../components/TabContainer';
import { TemplateSortingOptions } from '../SurveyTabs.utils';
import { useSurveyTabsSortingContext } from '../SurveyTabsSorting/useSurveyTabsSortingContext';

import { SurveysTemplateList } from './surveysTemplateList/SurveysTemplateList';

type DraftTabProps = {
  department: string;
  items: SurveyTemplateListItem[];
} & SurveyTabProps;

export const DraftsTab = ({ department, items, ...rest }: DraftTabProps) => {
  const { setSortingParams } = useSurveyTabsSortingContext();

  const handleChangeSorting = (sortData: SortVariables) => {
    setSortingParams((prevTabsSortingData) => ({ ...prevTabsSortingData, [SurveysListTab.Draft]: sortData }));
  };

  return (
    <>
      <TabContainer sortingOptions={TemplateSortingOptions} {...rest} onChangeSorting={handleChangeSorting}>
        <SurveysTemplateList items={items.filter(Boolean)} department={department} />
      </TabContainer>
    </>
  );
};
