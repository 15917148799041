import { QueryOptions } from '@apollo/client';

import { SurveySearchParams } from '../../hooks/useEmployeeSurveysSearchParams/useEmployeeSurveysSearchParams.types';
import {
  AllSurveysOfEmployeeDocument,
  AllSurveysOfEmployeeQueryVariables,
} from '../../documents/allSurveysOfEmployee.generated';
import { SURVEYS_LIST_PAGE_SIZE } from '../../app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';

export const getRefetchOptionsForSurveys = (
  employeeId: number,
  pages?: SurveySearchParams,
): QueryOptions<AllSurveysOfEmployeeQueryVariables> => ({
  query: AllSurveysOfEmployeeDocument,
  variables: {
    employeeId,
    closedPagination: {
      offset: (pages ? pages.closed - 1 : 0) * SURVEYS_LIST_PAGE_SIZE,
      limit: SURVEYS_LIST_PAGE_SIZE,
    },
    openedPagination: {
      offset: (pages ? pages.opened - 1 : 0) * SURVEYS_LIST_PAGE_SIZE,
      limit: SURVEYS_LIST_PAGE_SIZE,
    },
  },
});
