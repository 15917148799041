import { useChangeSurveyStatus, UseChangeSurveyStatusProps, useValidatedParams } from 'hooks';
import { getRefetchOptionsForSurveys } from 'shared/utils/getRefetchOptionsForSurveys';

export const useCustomChangeSurveyStatus = (props: UseChangeSurveyStatusProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  return useChangeSurveyStatus({
    queryOptions: {
      refetchQueries: [getRefetchOptionsForSurveys(employeeId)],
    },
    ...props,
  });
};
