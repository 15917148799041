import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from 'ui';

import * as Styles from './Modal.styles';
import { ModalProps, ModalText } from './Modal.types';

export const Modal = ({ type, content, onClose, toDoType, noAlert }: ModalProps) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState<ModalText>({
    title: t(`modal.${type}.title.${toDoType}`),
    content: {
      begin: t(`modal.${type}.content.begin.${toDoType}`),
      end: t(`modal.${type}.content.end.${toDoType}`),
    },
  });

  const handleModalClose = (isConfirmed: boolean) => {
    setModal({ title: '', content: { begin: '', end: '' } });
    onClose(isConfirmed);
  };

  const getFormattedContent = (content: string) => {
    const { begin, end } = modal.content;
    return (
      <>
        {begin}
        <Box component="span" sx={Styles.Content}>
          {content}
        </Box>
        {end}
      </>
    );
  };

  return (
    <ConfirmationDialog
      content={getFormattedContent(content)}
      onClose={() => handleModalClose(false)}
      onConfirmation={() => handleModalClose(true)}
      open={true}
      title={modal.title}
      alertText={noAlert ? undefined : t('modal.alertText')}
      alertSeverity={noAlert ? undefined : 'warning'}
    />
  );
};
