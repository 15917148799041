import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import { getLongFormatDate } from 'shared/utils';
import { ReactComponent as CopyIcon } from 'assets/images/CopyIcon.svg';
import * as Styles from '../../SurveyTabs.styles';
import { SentSurveysListProps } from '../../SurveyTemplatesTabs.types';
import { SurveyListItem } from '../../components/SurveyListItem';

import { DuplicateSurveyDialog } from './duplicateSurveyDialog/DuplicateSurveyDialog';
import { ArchiveSurveyDialog } from './archiveSurveyDialog/ArchiveSurveyDialog';
import { useManageSurveyModal } from './useManageSurveyModal';

export const SentSurveysList = ({ items, changeCurrentTab }: SentSurveysListProps) => {
  const { t } = useTranslation();

  const {
    goToSurvey,
    handleSurveyAcceptModal: handleArchive,
    handleSurveyCloseModal: handleArchiveSurveyCloseModal,
    surveyModalId: archiveSurveyModalId,
  } = useManageSurveyModal();

  const {
    handleSurveyAcceptModal: handleDuplicate,
    handleSurveyCloseModal: handleDuplicateSurveyCloseModal,
    surveyModalId: duplicateSurveyModalId,
  } = useManageSurveyModal();

  const getActionButtons = (surveyId: string) => (
    <>
      <Box>
        <Tooltip title={t('surveysList.message.moveToArchived')} arrow placement="top">
          <IconButton aria-label="archive-survey" sx={Styles.AssignmentReturnedIcon} onClick={handleArchive(surveyId)}>
            <AssignmentReturnedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title={t('surveyTemplates.copyTooltip')} arrow placement="top">
          <IconButton aria-label="duplicate" sx={Styles.EditIconButton} onClick={handleDuplicate(surveyId)}>
            <CopyIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );

  return (
    <Box data-testid="surveysActiveList" sx={Styles.SurveysListContainer}>
      {items &&
        items.map((survey) => (
          <SurveyListItem
            key={survey.id}
            onClick={() => goToSurvey(survey.id)}
            title={survey.title}
            dateInfo={
              survey.latestSendDate
                ? `${t('surveyTemplates.lastSent')}: ${getLongFormatDate(new Date(survey.latestSendDate))}`
                : ''
            }
            actionsElements={getActionButtons(survey.id)}
          />
        ))}
      <DuplicateSurveyDialog
        id={duplicateSurveyModalId}
        changeCurrentTab={changeCurrentTab}
        handleCloseModal={handleDuplicateSurveyCloseModal}
      />
      <ArchiveSurveyDialog id={archiveSurveyModalId} handleCloseModal={handleArchiveSurveyCloseModal} />
    </Box>
  );
};
