import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import enComponents from './data/en.json';
import plComponents from './data/pl.json';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  pl: { components: plComponents },
  en: { components: enComponents },
} as const;

export const defaultNS = 'components';

export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE || 'pl';

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources,
    lng: DEFAULT_LOCALE,
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
