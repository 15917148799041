import { Box, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ControlledTextField } from 'ui';

import { PaginationFormData, PaginationProps } from './Pagination.types';
import * as Styles from './Pagination.styles';

export const Pagination = ({ count, onChange, page }: PaginationProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue } = useForm<PaginationFormData>({
    defaultValues: { pageNumber: page },
  });
  const formPageValue = watch('pageNumber');
  const location = useLocation();

  useEffect(() => {
    if (page !== formPageValue) {
      setValue('pageNumber', page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleFormSubmit = (data: PaginationFormData) => {
    onChange(Number(data.pageNumber));
  };

  const handlePageChange = (pageOffset: number, ignorePageValue: number) => {
    if (page === ignorePageValue) {
      return;
    }

    const newValue = page + pageOffset;

    setValue('pageNumber', newValue);
    onChange(newValue);
  };

  const paginationElementsText = [t('pagination.of'), count];

  return (
    <Box sx={Styles.Wrapper}>
      <KeyboardArrowLeftIcon sx={Styles.Icon(page !== 1)} onClick={() => handlePageChange(-1, 1)} />
      <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <ControlledTextField
          control={control}
          name="pageNumber"
          multiline={false}
          type="number"
          inputProps={{ min: 1, max: count }}
          sx={Styles.Input}
          variant="standard"
          value={formPageValue}
        />
      </Box>
      {paginationElementsText.map((element, index) => (
        <Typography key={index} sx={Styles.Typography} variant="body2">
          {element}
        </Typography>
      ))}
      <KeyboardArrowRightIcon sx={Styles.Icon(page !== count)} onClick={() => handlePageChange(1, count)} />
    </Box>
  );
};
