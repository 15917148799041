import { Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { datePickerFormat } from 'shared/utils';
import { TextField } from 'ui';
import { getLocale } from 'i18n/i18n.utils';

import * as Styles from './DatePicker.styles';
import { DatePickerProps } from './DatePicker.types';

export const DatePicker = ({
  dateFormat = datePickerFormat,
  placeholder = '',
  ...props
}: DatePickerProps<Date, Date>) => {
  const tshEstablishmentDate = new Date(2012, 7, 11);

  return (
    <Box sx={Styles.DatePickerWrapper}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale().dateTimeLocale}>
        <MaterialDatePicker
          disableMaskedInput
          inputFormat={dateFormat}
          minDate={tshEstablishmentDate}
          components={{
            OpenPickerIcon: CalendarTodayIcon,
          }}
          renderInput={({ InputProps, ...params }) => {
            return (
              <TextField
                {...params}
                InputProps={{
                  startAdornment: InputProps?.endAdornment,
                  name: InputProps?.name || props.name,
                  id: InputProps?.id || props.id,
                }}
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                  'data-testid': props.name,
                }}
                multiline={false}
              />
            );
          }}
          {...props}
        />
      </LocalizationProvider>
    </Box>
  );
};
