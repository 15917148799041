import { Styles } from 'shared/types';
import { colors, grays } from 'shared/utils';

export const Wrapper: Styles = {
  mb: 3,
  display: 'flex',
  justifyContent: 'space-between',
};

const titleFontStyles: Styles = {
  fontSize: 24,
  fontWeight: 600,
  lineHeight: '32px',
};

export const Title: Styles = {
  '& .MuiInput-input': {
    ...titleFontStyles,
  },

  '& .MuiFormLabel-root': {
    ...titleFontStyles,
    transition: 'none',

    '&::after': {
      content: '"*"',
      color: colors.tomato,
    },

    '&[data-shrink="true"]': {
      opacity: '0',
    },
    '&.Mui-error': {
      color: 'inherit',
    },
  },
};

export const QuestionCounter: Styles = {
  color: grays.slateGray,
  mt: 2,
};
