import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllActionsOfEmployeeQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  inProgressPagination?: Types.InputMaybe<Types.PaginationInput>;
  donePagination?: Types.InputMaybe<Types.PaginationInput>;
  canceledPagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type AllActionsOfEmployeeQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, in_progress?: { __typename?: 'ActionCollection', total: number, items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, weeklyFeedbackId?: string | null } | null> } | null, done?: { __typename?: 'ActionCollection', total: number, items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, weeklyFeedbackId?: string | null } | null> } | null, canceled?: { __typename?: 'ActionCollection', total: number, items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, weeklyFeedbackId?: string | null } | null> } | null } | null };


export const AllActionsOfEmployeeDocument = gql`
    query allActionsOfEmployee($employeeId: Int!, $inProgressPagination: PaginationInput, $donePagination: PaginationInput, $canceledPagination: PaginationInput) {
  employee(id: $employeeId) {
    id
    in_progress: actionsCollection(
      actionStatus: IN_PROGRESS
      pagination: $inProgressPagination
    ) {
      items {
        id
        content
        actionStatus
        createdAt
        realizationDate
        updatedAt
        weeklyFeedbackId
      }
      total
    }
    done: actionsCollection(actionStatus: DONE, pagination: $donePagination) {
      items {
        id
        content
        actionStatus
        createdAt
        realizationDate
        updatedAt
        weeklyFeedbackId
      }
      total
    }
    canceled: actionsCollection(
      actionStatus: CANCELED
      pagination: $canceledPagination
    ) {
      items {
        id
        content
        actionStatus
        createdAt
        realizationDate
        updatedAt
        weeklyFeedbackId
      }
      total
    }
  }
}
    `;

/**
 * __useAllActionsOfEmployeeQuery__
 *
 * To run a query within a React component, call `useAllActionsOfEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActionsOfEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActionsOfEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      inProgressPagination: // value for 'inProgressPagination'
 *      donePagination: // value for 'donePagination'
 *      canceledPagination: // value for 'canceledPagination'
 *   },
 * });
 */
export function useAllActionsOfEmployeeQuery(baseOptions: Apollo.QueryHookOptions<AllActionsOfEmployeeQuery, AllActionsOfEmployeeQueryVariables> & ({ variables: AllActionsOfEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllActionsOfEmployeeQuery, AllActionsOfEmployeeQueryVariables>(AllActionsOfEmployeeDocument, options);
      }
export function useAllActionsOfEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllActionsOfEmployeeQuery, AllActionsOfEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllActionsOfEmployeeQuery, AllActionsOfEmployeeQueryVariables>(AllActionsOfEmployeeDocument, options);
        }
export function useAllActionsOfEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllActionsOfEmployeeQuery, AllActionsOfEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllActionsOfEmployeeQuery, AllActionsOfEmployeeQueryVariables>(AllActionsOfEmployeeDocument, options);
        }
export type AllActionsOfEmployeeQueryHookResult = ReturnType<typeof useAllActionsOfEmployeeQuery>;
export type AllActionsOfEmployeeLazyQueryHookResult = ReturnType<typeof useAllActionsOfEmployeeLazyQuery>;
export type AllActionsOfEmployeeSuspenseQueryHookResult = ReturnType<typeof useAllActionsOfEmployeeSuspenseQuery>;
export type AllActionsOfEmployeeQueryResult = Apollo.QueryResult<AllActionsOfEmployeeQuery, AllActionsOfEmployeeQueryVariables>;