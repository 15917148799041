import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Paper } from '@mui/material';

import { Layout } from 'app/layout/Layout';
import { PreviewSurvey } from 'app/previewSurvey/PreviewSurvey';
import { PreviewSurveyFormType } from 'app/previewSurvey/previewSurveyQuestions/PreviewSurveyQuestions.types';
import { formatPreviewSurveyData } from 'app/survey/surveyLayout/SurveyLayout.utils';
import { ReactComponent as AppLogo } from 'assets/images/AppLogo.svg';
import { SurveyResponseAnswersInput, SurveyResponseStatus } from 'generated/webapp_gql';
import { useAddSurveyResponse, useSurveyForRespondent } from 'hooks';
import { DEFAULT_LOCALE } from 'i18n/i18n';
import { SurveyTranslationProvider } from 'providers/SurveyTranslationProvider';
import { Loader } from 'ui';

import * as Styles from './SurveyForm.styles';
import { SurveyFormRedirect } from './SurveyFormRedirect/SurveyFormRedirect';
import { SurveyFormContinue } from './SurveyFormContinue/SurveyFormContinue';

export const SurveyForm = () => {
  const { isSurveyForRespondentLoading, surveyForRespondentData, urlHash, errorCode, errorLanguage, refetch } =
    useSurveyForRespondent();
  const { addSurveyResponse, isAddSurveyResponseLoading } = useAddSurveyResponse();
  const [showContinueSurveyScreen, setShowContinueSurveyScreen] = useState<boolean | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const isEditMode = surveyForRespondentData?.responseStatus === SurveyResponseStatus.Reattempted;

    if (!isSurveyForRespondentLoading) {
      setShowContinueSurveyScreen((prev) =>
        prev === null
          ? Boolean(!isEditMode && !errorCode && surveyForRespondentData?.response?.at(0)?.answer?.length)
          : prev,
      );
    }
  }, [
    surveyForRespondentData?.response,
    surveyForRespondentData?.responseStatus,
    errorCode,
    isSurveyForRespondentLoading,
    searchParams,
  ]);

  if (isSurveyForRespondentLoading) {
    return <Loader />;
  }

  const transformAnswer = (answerToQuestion: unknown): string[] => {
    if (answerToQuestion === undefined) {
      return [];
    } else if (Array.isArray(answerToQuestion)) {
      return answerToQuestion.map((answer) => String(answer));
    } else {
      return [String(answerToQuestion)];
    }
  };

  const onSubmit = async (data: PreviewSurveyFormType, isUpdateOnly?: boolean) => {
    if (!urlHash) {
      return {};
    }

    const response = surveyForRespondentData?.content.sections.reduce<SurveyResponseAnswersInput[]>(
      (prev, curr, sectionIndex) => {
        if (!curr) {
          return prev;
        }

        const questionsFromSection = curr?.questions.reduce<SurveyResponseAnswersInput[]>(
          (prevQ, currQ, questionIndex) => {
            if (!currQ) {
              return prevQ;
            }

            const answerToQuestion = data[`${sectionIndex}_${questionIndex}`];

            const formattedQuestion: SurveyResponseAnswersInput = {
              format: currQ.format,
              question: currQ.question,
              answer: transformAnswer(answerToQuestion),
            };

            return [...prevQ, formattedQuestion];
          },
          [],
        );

        return [...prev, ...questionsFromSection];
      },
      [],
    );

    if (!response) {
      return {};
    }

    const { errors, data: responseData } = await addSurveyResponse({
      variables: {
        input: {
          response,
          urlHash,
          isUpdateOnly,
        },
      },
    });

    if (errors) {
      return Promise.reject();
    }

    return {
      endImageUrl: responseData?.addSurveyResponse?.endImageUrl || undefined,
    };
  };

  return (
    <Layout noHeader>
      <Box sx={Styles.LogoWrapper}>
        <AppLogo />
      </Box>
      <Paper>
        <Box sx={Styles.Container}>
          {!surveyForRespondentData && errorCode && (
            <SurveyTranslationProvider language={errorLanguage || DEFAULT_LOCALE}>
              <SurveyFormRedirect type={errorCode} urlHash={urlHash} />
            </SurveyTranslationProvider>
          )}
          {!!surveyForRespondentData &&
            ((showContinueSurveyScreen && (
              <SurveyTranslationProvider language={surveyForRespondentData?.language || DEFAULT_LOCALE}>
                <SurveyFormContinue setShowContinueSurveyScreen={setShowContinueSurveyScreen} />
              </SurveyTranslationProvider>
            )) ||
              (showContinueSurveyScreen === false && (
                <PreviewSurvey
                  markAsSeen
                  survey={formatPreviewSurveyData(surveyForRespondentData)}
                  onSubmit={onSubmit}
                  isLoading={isAddSurveyResponseLoading}
                  refetchData={refetch}
                  shouldValidate
                />
              )))}
        </Box>
      </Paper>
    </Layout>
  );
};
