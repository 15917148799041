import { Styles } from 'shared/types';

export const Wrapper: Styles = {
  width: 471,
};

export const Link: Styles = {
  width: '100%',
};

export const Header: Styles = {
  mt: 3,
};

export const WelcomeText: Styles = {
  mt: 2,
  mb: 3,
  textAlign: 'center',
};
