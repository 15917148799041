import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EmployeeSection } from 'app/employeeDetails/employeeSection/EmployeeSection';
import { useValueOnWindowFocus } from 'hooks/useValueOnWindowFocus/useValueOnWindowFocus';
import { BackButton, CardSidebar, ControlledQuill, DatePicker, OptionalGoals } from 'ui';

import * as Styles from './QuarterlyReportForm.styles';
import {
  CreateQuarterlyReportFormData,
  QuarterlyReportFormProps,
  UpdateQuarterlyReportFormData,
} from './QuarterlyReportForm.types';

export const getQuarterlyReportMaxDate = () => new Date();

export const QuarterlyReportForm = ({
  onSubmit,
  mutationLoading,
  employeeData,
  hiredDate,
}: QuarterlyReportFormProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { isDirty, errors },
  } = useFormContext<CreateQuarterlyReportFormData | UpdateQuarterlyReportFormData>();
  const maxDate = useValueOnWindowFocus(getQuarterlyReportMaxDate);

  return (
    <CardSidebar button={<BackButton />} sidebar={<EmployeeSection employee={employeeData} />}>
      <Paper sx={Styles.ReportForm}>
        <Box sx={Styles.SubmitWrapper}>
          <Controller
            name="realizationDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box sx={Styles.DatePickerWrapper(!!error?.message)}>
                <DatePicker maxDate={maxDate} minDate={hiredDate} onChange={onChange} value={value} />
                {error?.message && (
                  <Typography sx={Styles.DatePickerMessage} variant="caption">
                    {error?.message}
                  </Typography>
                )}
              </Box>
            )}
          />
          <Button
            disabled={!isDirty || mutationLoading}
            startIcon={<CheckIcon />}
            variant="text"
            type="submit"
            form="quarterly-form"
          >
            {t('quarterlyForm.saveBtn')}
          </Button>
        </Box>
        <Divider />
        <Box sx={Styles.ContentWrapper}>
          <Box component="form" onSubmit={onSubmit} id="quarterly-form" noValidate sx={Styles.Form}>
            <ControlledQuill
              control={control}
              name="summary"
              placeholder={t('quarterlyForm.reportInputPlaceholder')}
              label={t('quarterlyForm.reportInputLabel')}
              readOnly={mutationLoading}
              errorMessage={errors?.summary?.message}
            />
            <Box>
              <OptionalGoals name="goals" mutationLoading={mutationLoading} label={t('quarterlyForm.goals')} />
            </Box>
            <ControlledQuill
              control={control}
              placeholder={t('quarterlyForm.notesInputPlaceholder')}
              readOnly={mutationLoading}
              label={t('quarterlyForm.notesInputLabel')}
              name="notes"
            />
          </Box>
        </Box>
      </Paper>
    </CardSidebar>
  );
};
