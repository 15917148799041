import { useChangeSurveyStatus, UseChangeSurveyStatusProps, useValidatedParams } from 'hooks';
import { SurveySearchParams } from 'hooks/useEmployeeSurveysSearchParams/useEmployeeSurveysSearchParams.types';
import { getRefetchOptionsForSurveys } from '../../../shared/utils/getRefetchOptionsForSurveys';

type CustomChangeSurveyStatusProps = {
  pages?: SurveySearchParams;
} & UseChangeSurveyStatusProps;
export const useCustomChangeSurveyStatus = ({ pages, ...restProps }: CustomChangeSurveyStatusProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  return useChangeSurveyStatus({
    queryOptions: {
      refetchQueries: [getRefetchOptionsForSurveys(employeeId, pages)],
    },
    ...restProps,
  });
};
