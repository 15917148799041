import { ActionStatus, WeeklyFeedbackCollection } from 'generated/webapp_gql';
import { useEmployeeDetailsWeekly } from 'hooks';

import { FeedbackSection } from './feedbackSection/FeedbackSection';
import { ActionsTabs } from './actionsTabs/ActionsTabs';

export const WEEKLY_FEEDBACKS_PAGE_SIZE = 10;
export const ACTIONS_LIST_PAGE_SIZE = 5;
export const DEFAULT_ACTION_TYPE = ActionStatus.InProgress;

export const EmployeeDetailsWeekly = () => {
  const { employeeDetailsWeeklyData, prevEmployeeDetailsWeeklyData, isEmployeeDetailsWeeklyLoading } =
    useEmployeeDetailsWeekly();

  return (
    <>
      <ActionsTabs
        data={employeeDetailsWeeklyData}
        prevData={prevEmployeeDetailsWeeklyData}
        loading={isEmployeeDetailsWeeklyLoading}
      />
      <FeedbackSection
        data={employeeDetailsWeeklyData?.receivedWeeklyFeedbacksCollection as WeeklyFeedbackCollection}
        prevData={prevEmployeeDetailsWeeklyData?.receivedWeeklyFeedbacksCollection as WeeklyFeedbackCollection}
        loading={isEmployeeDetailsWeeklyLoading}
      />
    </>
  );
};
