import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWeeklyFeedbackMutationVariables = Types.Exact<{
  input: Types.CreateWeeklyFeedbackInput;
}>;


export type CreateWeeklyFeedbackMutation = { __typename?: 'Mutation', createWeeklyFeedback?: { __typename?: 'WeeklyFeedback', id: string, createdAt: any, satisfactionScore: number, summary?: string | null, notes?: string | null, realizationDate: any, receiverId?: number | null, actionsCollection?: { __typename?: 'ActionCollection', items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null, createdAt: any, realizationDate?: any | null, weeklyFeedbackId?: string | null, updatedAt: any } | null> } | null } | null };


export const CreateWeeklyFeedbackDocument = gql`
    mutation createWeeklyFeedback($input: CreateWeeklyFeedbackInput!) {
  createWeeklyFeedback(input: $input) {
    id
    actionsCollection {
      items {
        id
        content
        actionStatus
        createdAt
        realizationDate
        weeklyFeedbackId
        updatedAt
      }
    }
    createdAt
    satisfactionScore
    summary
    notes
    realizationDate
    receiverId
  }
}
    `;
export type CreateWeeklyFeedbackMutationFn = Apollo.MutationFunction<CreateWeeklyFeedbackMutation, CreateWeeklyFeedbackMutationVariables>;

/**
 * __useCreateWeeklyFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateWeeklyFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWeeklyFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWeeklyFeedbackMutation, { data, loading, error }] = useCreateWeeklyFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWeeklyFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateWeeklyFeedbackMutation, CreateWeeklyFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWeeklyFeedbackMutation, CreateWeeklyFeedbackMutationVariables>(CreateWeeklyFeedbackDocument, options);
      }
export type CreateWeeklyFeedbackMutationHookResult = ReturnType<typeof useCreateWeeklyFeedbackMutation>;
export type CreateWeeklyFeedbackMutationResult = Apollo.MutationResult<CreateWeeklyFeedbackMutation>;
export type CreateWeeklyFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateWeeklyFeedbackMutation, CreateWeeklyFeedbackMutationVariables>;