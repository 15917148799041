import { Styles } from 'shared/types';

export const ContentWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: 88,
  px: 4,
  py: 2.5,
  boxSizing: 'border-box',
  position: 'relative',

  '.MuiBox-root': {
    borderBottom: 'none',
  },
};

export const Button: Styles = { color: 'black' };

export const TitleText: Styles = { display: 'flex', alignItems: 'center' };
