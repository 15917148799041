import { ApolloCache } from '@apollo/client';

import { Action, ActionStatus, Employee, WeeklyFeedback, WeeklyFeedbackCollection } from 'generated/webapp_gql';
import {
  ACTIONS_LIST_PAGE_SIZE,
  WEEKLY_FEEDBACKS_PAGE_SIZE,
} from 'app/employeeDetails/employeeDetailsWeekly/EmployeeDetailsWeekly';
import { EmployeesDocument, EmployeesQuery, EmployeesQueryVariables } from 'documents/employees.generated';
import {
  EmployeeDetailsWeeklyDocument,
  EmployeeDetailsWeeklyQuery,
  EmployeeDetailsWeeklyQueryVariables,
} from 'documents/employeeDetailsWeekly.generated';

const DEFAULT_OFFSET = 0;

export enum QueriesTypename {
  Employee = 'Employee',
  WeeklyFeedback = 'WeeklyFeedback',
  Action = 'Action',
  Goal = 'Goal',
}

export class UpdateFeedbacksList {
  private readonly cache: ApolloCache<unknown>;
  private readonly newFeedback: WeeklyFeedback;
  private readonly employeeId: number;
  private readonly newActions: Action[] | undefined;

  private actionsInProgressAmount = 0;

  constructor(cache: ApolloCache<unknown>, newFeedback: WeeklyFeedback, employeeId: number, newActions?: Action[]) {
    this.cache = cache;
    this.newFeedback = newFeedback;
    this.employeeId = employeeId;
    this.newActions = newActions;
  }

  private getQueryOptions() {
    const employeesQueryOptions = {
      query: EmployeesDocument,
      variables: <EmployeesQueryVariables>{
        assignedToMe: false,
      },
    };

    const employeeDetailsWeeklyQueryOptions = {
      query: EmployeeDetailsWeeklyDocument,
      variables: <EmployeeDetailsWeeklyQueryVariables>{
        employeeId: this.employeeId,
        actionStatus: ActionStatus.InProgress,
        actionsPagination: {
          offset: 0,
          limit: ACTIONS_LIST_PAGE_SIZE,
        },
        weeklyPagination: {
          limit: WEEKLY_FEEDBACKS_PAGE_SIZE,
          offset: DEFAULT_OFFSET,
        },
      },
    };

    return {
      employeeDetailsWeeklyQueryOptions,
      employeesQueryOptions,
    };
  }

  private getUpdatedActionsInProgress(employee: Employee) {
    const actionsWithDuplicates = [
      ...(employee.actionsCollection?.items ?? []),
      ...(this.newFeedback.actionsCollection?.items ?? []),
      ...(this.newActions ?? []),
    ];

    return Array.from(
      actionsWithDuplicates
        .reduce<Map<Action['id'], Action>>((map, obj) => {
          if (!obj || obj.actionStatus !== ActionStatus.InProgress) {
            return map;
          }
          return map.set(obj.id, obj);
        }, new Map())
        .values(),
    );
  }

  private getCachedEmployeeDetails() {
    const { employeeDetailsWeeklyQueryOptions } = this.getQueryOptions();
    return <Employee>this.cache.readQuery<EmployeeDetailsWeeklyQuery>(employeeDetailsWeeklyQueryOptions)?.employee;
  }

  private modifyWeeklyFeedbacksCache({ ...args }: Partial<WeeklyFeedbackCollection>) {
    const { employeeDetailsWeeklyQueryOptions } = this.getQueryOptions();
    const cachedEmployeeDetails = this.getCachedEmployeeDetails();

    this.cache.writeQuery<EmployeeDetailsWeeklyQuery>({
      ...employeeDetailsWeeklyQueryOptions,
      data: {
        employee: {
          ...cachedEmployeeDetails,
          receivedWeeklyFeedbacksCollection: cachedEmployeeDetails.receivedWeeklyFeedbacksCollection
            ? {
                ...cachedEmployeeDetails.receivedWeeklyFeedbacksCollection,
                ...args,
              }
            : undefined,
        },
      },
    });
  }

  private updateEmployeesCache() {
    const { employeesQueryOptions } = this.getQueryOptions();

    this.cache.updateQuery<EmployeesQuery>(employeesQueryOptions, (data) => {
      if (!data || !data.employees) {
        return;
      }

      const employeeArrayIndex = data.employees.items.findIndex((emp) => emp?.id === this.employeeId);

      if (!employeeArrayIndex || employeeArrayIndex < 0) {
        return;
      }

      const items = [...data.employees.items];

      items[employeeArrayIndex] = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...items[employeeArrayIndex]!,
        actionsInProgress: this.actionsInProgressAmount,
      };

      return {
        employees: {
          ...data.employees,
          items: items,
        },
      };
    });
  }

  private updateEmployeeCache() {
    const { employeeDetailsWeeklyQueryOptions } = this.getQueryOptions();

    this.cache.updateQuery<EmployeeDetailsWeeklyQuery>(employeeDetailsWeeklyQueryOptions, (data) => {
      if (!data || !data.employee) {
        return;
      }

      if (!data.employee.actionsCollection) {
        return {
          employee: {
            ...data.employee,
          },
        };
      }

      const actionsInProgress = this.getUpdatedActionsInProgress(<Employee>data.employee);

      this.actionsInProgressAmount =
        data.employee.actionsCollection.total +
        (this.newActions?.length ?? this.newFeedback.actionsCollection?.items?.length ?? 0);

      return {
        employee: {
          ...data.employee,
          actionsCollection: {
            ...data.employee.actionsCollection,
            total: this.actionsInProgressAmount,
            items: actionsInProgress,
          },
        },
      };
    });
  }

  public onUpdate() {
    const cachedEmployeeDetails = this.getCachedEmployeeDetails();

    if (!cachedEmployeeDetails) {
      return;
    }

    const items = [...(cachedEmployeeDetails.receivedWeeklyFeedbacksCollection?.items ?? [])];
    const updatedFeedbackIndex = items.findIndex((feedback) => feedback?.id === this.newFeedback.id);

    items[updatedFeedbackIndex] = {
      ...this.newFeedback,
      actionsCollection: this.newFeedback.actionsCollection
        ? {
            ...this.newFeedback.actionsCollection,
            items: [...this.newFeedback.actionsCollection.items, ...(this.newActions as Action[])],
          }
        : undefined,
    };

    items.sort((a, b) => new Date(b?.realizationDate).getTime() - new Date(a?.realizationDate).getTime());

    this.modifyWeeklyFeedbacksCache({ items });
    this.updateEmployeeCache();
    this.updateEmployeesCache();
  }

  public onCreate() {
    const cachedEmployeeDetails = this.getCachedEmployeeDetails();

    if (!cachedEmployeeDetails) {
      return;
    }

    const items = [
      this.newFeedback,
      ...(cachedEmployeeDetails.receivedWeeklyFeedbacksCollection?.items ?? []).slice(
        0,
        WEEKLY_FEEDBACKS_PAGE_SIZE - 1,
      ),
    ].sort((a, b) => new Date(b?.realizationDate).getTime() - new Date(a?.realizationDate).getTime());
    const total = (cachedEmployeeDetails.receivedWeeklyFeedbacksCollection?.total ?? 0) + 1;

    this.modifyWeeklyFeedbacksCache({ items, total });
    this.updateEmployeeCache();
    this.updateEmployeesCache();
  }
}
