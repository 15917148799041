import { useNavigate } from 'react-router-dom';

import { Action, ActionStatus } from 'generated/webapp_gql';
import { useModal, useReadOnlyEmployee, useUpdateAction, useUpdateActionContent, useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { Modal, TabPanel, ToDoTable } from 'ui';
import { ToDoType } from 'ui/modal/Modal.enum';
import { ActionsAmountKeys } from '../ActionsTabs.types';

import { ActionInput } from './actionInput/ActionInput';
import { ActionsTableProps } from './ActionsTable.types';

export const ActionsTable = ({ actionStatus, data, loading, type }: ActionsTableProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { isReadOnly } = useReadOnlyEmployee(employeeId);
  const navigate = useNavigate();

  const { sendAction } = useUpdateAction({
    actionStatus,
    offset: 0,
  });

  const updateHandler = useUpdateActionContent();

  const { handleItemClick, item, handleModalClose } = useModal<ActionStatus>({
    onConfirm: sendAction,
    canceledStatus: ActionStatus.Canceled,
    doneStatus: ActionStatus.Done,
  });

  const getRedirectToWeeklyFeedback = (weeklyFeedbackId?: string) =>
    weeklyFeedbackId ? { to: `/${AppRoute.PreviewFeedback}/${weeklyFeedbackId}/${employeeId}` } : null;

  return (
    <TabPanel value={actionStatus} index={type} key={type}>
      <ToDoTable
        isReadOnly={isReadOnly}
        onClick={handleItemClick}
        toDoData={data?.actionsCollection?.items as Action[]}
        loading={loading}
        getOnArrowClickState={getRedirectToWeeklyFeedback}
        seeMore={{
          redirect: () => navigate(`/${AppRoute.EmployeeActions}/${employeeId}`),
          totalAmount: loading ? 0 : data?.[type.toLowerCase() as ActionsAmountKeys] ?? 0,
        }}
        context={updateHandler}
      >
        {type === ActionStatus.InProgress && !isReadOnly && <ActionInput />}
      </ToDoTable>

      {item.type && (
        <Modal onClose={handleModalClose} type={item.type} content={item.content} toDoType={ToDoType.Action} />
      )}
    </TabPanel>
  );
};
