import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Container: Styles = { display: 'flex', flexDirection: 'column' };

export const LabelsContainer: Styles = { wordBreak: 'break-word', display: 'flex', justifyContent: 'space-between' };

export const SliderLabel = (active: boolean, align: 'left' | 'right'): Styles => ({
  color: active ? colors.navy : colors.slateGray,
  fontSize: '14px',
  textAlign: align,
});

export const SliderInput: Styles = {
  mt: 9,
  '& .MuiSlider-valueLabel': {
    backgroundColor: colors.blueberry,
    px: 1,
    borderRadius: 1,
  },
};
