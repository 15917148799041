import { Styles } from 'shared/types';
import { theme } from 'shared/utils';

export const Stepper: Styles = {
  maxWidth: 400,
  backgroundColor: 'transparent',

  '& .MuiMobileStepper-progress': {
    maxWidth: 192,
  },

  '&.MuiMobileStepper-root': {
    fontSize: 14,
  },
};

export const Button: Styles = {
  p: theme.spacing(5 / 8, 6 / 8),
  lineHeight: '22px',
  gap: 1,
};
