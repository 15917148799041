export const MuiDialog = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      paper: {
        border: `1px solid ${colors.hawkesBlue}`,
        boxShadow: 'none',
        padding: 32,
      },
      paperWidthSm: {
        maxWidth: 552,
      },
    },
  };
};
