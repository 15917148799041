import { Styles } from 'shared/types';
import { colors, theme } from 'shared/utils';

export const SelectInput: Styles = {
  maxWidth: '320px',
  borderRadius: '8px',
  backgroundColor: colors.ghostWhite,

  '&:hover': {
    backgroundColor: colors.brightGray,

    '&.MuiInputBase-adornedStart .MuiSvgIcon-root:not(.MuiSelect-icon)': {
      color: colors.blueberry,
    },
  },

  '.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    pl: 1,
  },

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    borderWidth: '1px',
  },

  '&.Mui-focused': {
    '&.MuiInputBase-adornedStart .MuiSvgIcon-root:not(.MuiSelect-icon)': {
      color: colors.blueberry,
    },

    '.MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blueberry,
    },
  },
};

export const SelectLanguageIcon: Styles = {
  color: colors.slateGray,
};

export const SelectMenu: Styles = {
  ml: '-20px',

  '.MuiList-root': {
    p: 0,
    minWidth: '320px',
  },

  '.MuiPaper-root': {
    boxShadow: theme.shadows[1],
  },
};

export const SelectMenuItem: Styles = {
  mx: 1,
  my: 1.5,
  padding: '8px',
  borderRadius: '4px',

  '.MuiSvgIcon-root': {
    mr: 1,
  },

  '&.Mui-selected': {
    backgroundColor: colors.brightGray,
  },
};

export const SelectBackdrop: Styles = {
  background: 'transparent',
};
