import { toast } from 'react-toastify';

import { ACTIONS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsWeekly/EmployeeDetailsWeekly';
import { ActionStatus, Maybe, WeeklyFeedback } from 'generated/webapp_gql';
import { useEmployeeDetailsWeeklyQuery } from '../../documents/employeeDetailsWeekly.generated';
import { useValidatedParams } from '../useValidatedParams/useValidatedParams';

export const LATEST_FEEDBACKS_PAGE_SIZE = 4;

export const useLatestFeedbacks = () => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const { data, loading } = useEmployeeDetailsWeeklyQuery({
    variables: {
      employeeId,
      actionStatus: ActionStatus.InProgress,
      actionsPagination: {
        limit: ACTIONS_LIST_PAGE_SIZE,
        offset: 0,
      },
      weeklyPagination: {
        limit: LATEST_FEEDBACKS_PAGE_SIZE,
        offset: 0,
      },
    },
    onError: (error) => {
      toast.error(error.message);
    },
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-and-network',
  });

  const feedbacks = data?.employee?.receivedWeeklyFeedbacksCollection;

  return {
    loading,
    totalFeedbacks: feedbacks?.items.length || 0,
    feedbacks: feedbacks?.items as Maybe<WeeklyFeedback>[] | undefined,
  };
};
