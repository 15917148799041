import { toast } from 'react-toastify';

import { useArchivedSurveyTemplatesQuery } from '../../documents/archivedSurveyTemplates.generated';
import { useValidatedParams } from '../useValidatedParams/useValidatedParams';

export const useArchivedSurveyTemplates = (skip = false) => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const {
    data,
    loading: areArchivedSurveyTemplatesLoading,
    previousData,
    refetch,
  } = useArchivedSurveyTemplatesQuery({
    variables: {
      employeeId,
    },
    skip,
    onError: (error) => toast.error(error.message),
  });

  return {
    areArchivedSurveyTemplatesLoading,
    archivedSurveyTemplates: data?.employee?.archivedSurveyTemplatesCollection,
    prevArchivedSurveyTemplates: previousData?.employee?.archivedSurveyTemplatesCollection,
    refetch,
  };
};
