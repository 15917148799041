import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Accordion, Loader, NoDataHandler, Pagination } from 'ui';
import { QUARTERLY_REPORTS_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';
import { useQuarterlyReports, useSearchParams } from 'hooks';
import { QuarterlyReport } from 'generated/webapp_gql';

import { Reports } from './reports/Reports';
import * as Styles from './ReportSection.styles';

const DEFAULT_REPORTS_PAGE = 1;

export const ReportSection = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { searchParams, setSearchParams } = useSearchParams();
  const [activePage, setActivePage] = useState(searchParams.page || DEFAULT_REPORTS_PAGE);
  const location = useLocation();

  useEffect(() => {
    if (searchParams.page && searchParams.page !== activePage) {
      setActivePage(searchParams.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { quarterlyReportsData, isQuarterlyReportsLoading, fetchQuarterlyReports, previousQuarterlyReportsData } =
    useQuarterlyReports(activePage);

  const handlePageChange = async (page: number) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    setActivePage(page);
    await fetchQuarterlyReports({ variables: { offset: (page - 1) * QUARTERLY_REPORTS_PAGE_SIZE } });
    setSearchParams({ page: String(page) });
  };

  const availableReports = quarterlyReportsData?.total || previousQuarterlyReportsData?.quarterlyReports?.total || 0;
  const feedbackPagesCount = Math.ceil(availableReports / QUARTERLY_REPORTS_PAGE_SIZE);

  const currentQuarterlyReportsData = isQuarterlyReportsLoading
    ? previousQuarterlyReportsData?.quarterlyReports
    : quarterlyReportsData;

  if (isQuarterlyReportsLoading && !currentQuarterlyReportsData) return <Loader />;

  if (!quarterlyReportsData && !currentQuarterlyReportsData) return null;

  return (
    <Grid ref={ref}>
      <Accordion
        title={`${t('report.sectionTitle.report')} (${currentQuarterlyReportsData?.total ?? 0})`}
        content={
          !!currentQuarterlyReportsData?.items.length ? (
            <Box sx={Styles.Reports}>
              {isQuarterlyReportsLoading && (
                <Box sx={Styles.Loader}>
                  <Loader />
                </Box>
              )}
              <Reports reports={currentQuarterlyReportsData.items as QuarterlyReport[]} />
              <Box sx={Styles.Pagination}>
                <Pagination count={feedbackPagesCount} page={activePage} onChange={handlePageChange} />
              </Box>
            </Box>
          ) : (
            <NoDataHandler message={t('employeeDetails.feedbackNoData')} />
          )
        }
      />
    </Grid>
  );
};
