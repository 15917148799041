import { Box } from '@mui/material';

import { TabPanelProps } from 'ui/tabPanel/TabPanel.types';

export const TabPanel = ({ children, value, index, ...props }: TabPanelProps) => {
  return (
    <Box role="tabpanel" hidden={value !== index} {...props}>
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </Box>
  );
};
