import { FormatPreviewSurveyReturnData } from 'app/survey/surveyLayout/SurveyLayout.types';
import { useEmployee } from 'hooks';

const getFullName = ({
  skip,
  employeeNameFromQuery,
  employeeNameFromSurvey,
}: {
  skip: boolean;
  employeeNameFromSurvey?: string;
  employeeNameFromQuery?: string;
}): string => {
  return skip && employeeNameFromSurvey ? employeeNameFromSurvey : employeeNameFromQuery || '';
};

export const useEmployeeSurveyData = (survey: FormatPreviewSurveyReturnData) => {
  const employeeFromSurvey = {
    fullName: survey.fullName,
    avatarUrl: survey.avatarUrl,
  };

  const skip = !Object.values(employeeFromSurvey).includes(undefined);

  const { employeeData, isEmployeeDataLoading } = useEmployee({ skip });

  return {
    employeeData: {
      fullName: getFullName({
        skip,
        employeeNameFromSurvey: employeeFromSurvey.fullName,
        employeeNameFromQuery: `${employeeData?.firstName} ${employeeData?.lastName}`,
      }),
      avatarUrl: (skip ? employeeFromSurvey : employeeData)?.avatarUrl,
    },
    surveryOwnerData: {
      surveyOwnerFullName: survey.surveyOwnerFullName,
      surveyOwnerAvatarUrl: survey.surveyOwnerAvatarUrl,
    },
    isEmployeeDataLoading,
  };
};
