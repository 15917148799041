import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveyTemplateEndImageQueryVariables = Types.Exact<{
  surveyTemplateId: Types.Scalars['ID']['input'];
}>;


export type SurveyTemplateEndImageQuery = { __typename?: 'Query', surveyTemplate?: { __typename?: 'SurveyTemplate', id: string, endImageUrl?: string | null } | null };


export const SurveyTemplateEndImageDocument = gql`
    query SurveyTemplateEndImage($surveyTemplateId: ID!) {
  surveyTemplate(id: $surveyTemplateId) {
    id
    endImageUrl
  }
}
    `;

/**
 * __useSurveyTemplateEndImageQuery__
 *
 * To run a query within a React component, call `useSurveyTemplateEndImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyTemplateEndImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyTemplateEndImageQuery({
 *   variables: {
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *   },
 * });
 */
export function useSurveyTemplateEndImageQuery(baseOptions: Apollo.QueryHookOptions<SurveyTemplateEndImageQuery, SurveyTemplateEndImageQueryVariables> & ({ variables: SurveyTemplateEndImageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyTemplateEndImageQuery, SurveyTemplateEndImageQueryVariables>(SurveyTemplateEndImageDocument, options);
      }
export function useSurveyTemplateEndImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyTemplateEndImageQuery, SurveyTemplateEndImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyTemplateEndImageQuery, SurveyTemplateEndImageQueryVariables>(SurveyTemplateEndImageDocument, options);
        }
export function useSurveyTemplateEndImageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveyTemplateEndImageQuery, SurveyTemplateEndImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveyTemplateEndImageQuery, SurveyTemplateEndImageQueryVariables>(SurveyTemplateEndImageDocument, options);
        }
export type SurveyTemplateEndImageQueryHookResult = ReturnType<typeof useSurveyTemplateEndImageQuery>;
export type SurveyTemplateEndImageLazyQueryHookResult = ReturnType<typeof useSurveyTemplateEndImageLazyQuery>;
export type SurveyTemplateEndImageSuspenseQueryHookResult = ReturnType<typeof useSurveyTemplateEndImageSuspenseQuery>;
export type SurveyTemplateEndImageQueryResult = Apollo.QueryResult<SurveyTemplateEndImageQuery, SurveyTemplateEndImageQueryVariables>;