import { Styles } from 'shared/types';

// TODO: All colors are from lux-components-library, not extracting them to global theme as they are used only in this place

export const WeeklyOverviewLoaderWrapper: Styles = {
  width: '100%',
  minHeight: 450,
};

export const WeeklyOverviewPaper: Styles = {
  p: 0,
  minWidth: 600,
};

export const WeeklyOverviewDialogHeader: Styles = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  py: 2.5,
  px: 3,
  pl: 5,
  borderBottom: 1,
  borderColor: '#CFD1D6',
};

export const WeeklyOverviewDialogContainer: Styles = {
  px: 3,
  py: 1.5,
  gap: 1.5,
};

export const WeeklyOverviewDateWrapper: Styles = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1.5,
  py: 0.5,
};

export const WeeklyOverviewEmpoyeeWrapper: Styles = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
  backgroundColor: '#F5F6F7',
  p: 2,
  borderRadius: 2,
};

export const WeeklyOverviewSatisfactionWrapper: Styles = {
  flexDirection: 'row',
  gap: 4,
  overflowX: 'auto',
};

export const WeeklyOverviewSatisfactionCard: Styles = {
  justifyContent: 'center',
  border: 1,
  borderColor: '#E9EDF7',
  borderRadius: 2,
  p: 2,
  textAlign: 'center',
  gap: 1.5,
  width: 160,

  '> div > .RatingDescription': {
    color: 'text.secondary',
    whiteSpace: 'unset',
  },
};

export const WeeklyOverviewSatisfactionCardTitle: Styles = {
  typography: 'subtitle1',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const WeeklyOverviewSummaryWrapper: Styles = {
  background: '#E5EDFD',
  p: 2,
  borderRadius: 4,
  gap: 1,
};

export const WeeklyOverviewActionsWrapper: Styles = {
  px: 2,
  gap: 1,
};

export const WeeklyOverviewCheckboxWrapper: Styles = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: 0.5,
  '> div': {
    color: '#797E8D',
    '> span > svg >  path': {
      fill: '#797E8D',
    },
  },
};

export const WeeklyOverviewCloseButton: Styles = {
  ml: 'auto',
  display: 'block',
};
