import { Styles } from 'shared/types';

export const ToastContainer = (hasTitle: boolean): Styles => ({
  display: 'grid',
  gap: 1,
  gridTemplateAreas: hasTitle
    ? `'icon title close'
  '. message .'`
    : "'icon message close'",
  gridTemplateColumns: 'auto 1fr auto',
});

export const Message: Styles = {
  gridArea: 'message',
};
export const Icon: Styles = {
  gridArea: 'icon',
};
export const CloseIcon: Styles = {
  gridArea: 'close',
};

export const Title: Styles = {
  gridArea: 'title',
};
