import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const TopButtonsContainer: Styles = {
  display: 'flex',
  justifyContent: 'end',
  marginBottom: '64px',
};

export const SwitchWrapper: Styles = {
  width: '100%',
  justifyContent: 'end',
  flexDirection: 'row-reverse',
  mr: 1,
  mb: 4,
};

export const TabsContainer: Styles = {
  marginBottom: 3,
  border: `1px solid ${colors.navy}`,

  '& .MuiTab-root': {
    color: colors.navy,
    textTransform: 'none',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },

  '& .MuiTab-root.Mui-selected': {
    color: colors.white,
    background: colors.navy,
  },
};
