import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { useEmployeeDetailsWeeklyQuery } from 'documents/employeeDetailsWeekly.generated';
import { useEmployeeSearchParams } from 'hooks/useEmployeeSearchParams/useEmployeeSearchParams';
import { ActionStatus } from 'generated/webapp_gql';
import {
  ACTIONS_LIST_PAGE_SIZE,
  WEEKLY_FEEDBACKS_PAGE_SIZE,
} from 'app/employeeDetails/employeeDetailsWeekly/EmployeeDetailsWeekly';

export const useEmployeeDetailsWeekly = () => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { searchParams } = useEmployeeSearchParams();
  const {
    data,
    loading: isEmployeeDetailsWeeklyLoading,
    previousData,
  } = useEmployeeDetailsWeeklyQuery({
    variables: {
      employeeId,
      actionStatus: searchParams['task-status'] as ActionStatus,
      actionsPagination: {
        limit: ACTIONS_LIST_PAGE_SIZE,
        offset: 0,
      },
      weeklyPagination: {
        limit: WEEKLY_FEEDBACKS_PAGE_SIZE,
        offset: (searchParams.page - 1) * WEEKLY_FEEDBACKS_PAGE_SIZE,
      },
    },
    onError: (error) => {
      toast.error(error.message);
    },
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-and-network',
  });

  return {
    employeeDetailsWeeklyData: data?.employee,
    prevEmployeeDetailsWeeklyData: previousData?.employee,
    isEmployeeDetailsWeeklyLoading,
  };
};
