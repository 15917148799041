import { CheckBoxOutlineBlank, RadioButtonUnchecked } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

import { SurveyQuestionFormat } from 'generated/webapp_gql';
import { PublishedQuestionsProps } from '../QuestionsSectionWrapper.types';

import * as Styles from './PublishedQuestions.styles';

export const PublishedQuestions = ({ questions }: PublishedQuestionsProps) => {
  const questionsWithFilteredAnswers = useMemo(
    () =>
      questions?.map((item) => ({
        question: item?.question,
        format: item?.format,
        answers: item?.answers.filter((answer: { value: string }) => !!answer?.value?.length),
      })),
    [questions],
  );

  return (
    <Box sx={Styles.QuestionsWrapper}>
      {questionsWithFilteredAnswers?.map(
        (item, index) =>
          item && (
            <Box key={`question-${index}`}>
              <Typography variant="body1" key={index} sx={Styles.Question}>{`${index + 1}. ${
                item.question
              }`}</Typography>

              {item.format === SurveyQuestionFormat.LinearScale ? (
                <Box key={`question-${index}-answer`} sx={Styles.AnswerWrapper}>
                  <Typography variant="body1">
                    {item.answers[0].value} {item.answers[0].label} - {item.answers[1].value} {item.answers[1].label}
                  </Typography>
                </Box>
              ) : (
                item.answers.map((answer: { value: string; label?: string }, answerIndex: number) => (
                  <Box key={`question-${index}-answer-${answerIndex}`} sx={Styles.AnswerWrapper}>
                    {item.format === SurveyQuestionFormat.CloseEnded && <RadioButtonUnchecked sx={Styles.IconColor} />}
                    {item.format === SurveyQuestionFormat.MultipleChoice && (
                      <CheckBoxOutlineBlank sx={Styles.IconColor} />
                    )}
                    <Typography variant="body1">{answer.value}</Typography>
                  </Box>
                ))
              )}
            </Box>
          ),
      )}
    </Box>
  );
};
