import { alpha } from '@mui/material';

import { Styles } from 'shared/types';
import { colors, theme } from 'shared/utils';

export const MenuButton = (isOpen: boolean): Styles => ({
  background: isOpen ? colors.zumthorBlue : 'transparent',
});

export const Menu: Styles = {
  marginTop: 1,

  '& .MuiList-root': {
    padding: 0,
  },

  '& .MuiMenu-paper': {
    boxShadow: theme.shadows[4],
    width: 205,
  },

  '& .MuiMenuItem-root': {
    marginX: 1,
    marginTop: 1,
    height: 38,
    borderRadius: 0.5,
    fontWeight: 500,
    fontSize: 13,
    color: colors.navy,

    '&:last-of-type': {
      marginBottom: 1,
    },

    '&:hover': {
      background: alpha(colors.navy, 0.08),
    },
  },

  '& .MuiBackdrop-root': {
    background: 'transparent',
  },
};
