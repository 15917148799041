import { GoalCollection, GoalStatus } from '../../../../generated/webapp_gql';

import { GoalPicked } from './types';

export const useGoals = (goalsData: GoalCollection | null | undefined) => {
  const getGoals = (type: GoalStatus): GoalPicked[] =>
    (goalsData?.items.filter((goal) => goal?.goalStatus === type) || []) as GoalPicked[];

  const getCount = (type: GoalStatus) => getGoals(type).length;

  const getTotal = () => goalsData?.total || 0;

  return { totalCount: getTotal(), getGoals, getCount };
};
