import { useSearchParams as reactRouterUseSearchParams } from 'react-router-dom';

import { SearchParams } from './useEmployeeActionsSearchParams.types';

export const paramsGetPageNumber = (param: keyof SearchParams) => {
  const [searchParams] = reactRouterUseSearchParams();

  const pageNumber = Number(searchParams.get(param));

  return pageNumber > 0 ? pageNumber : 1;
};
