import { Styles } from 'shared/types';

export const Wrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 0.75,
};

export const Icon = (isIconActive: boolean): Styles => ({
  width: 20,
  height: 20,
  m: 0.75,
  opacity: isIconActive ? 1 : 0.38,
  cursor: isIconActive ? 'pointer' : 'default',
  paddingBottom: 0.25,
});

export const Typography: Styles = {
  width: 32,
  textAlign: 'center',
  paddingBottom: 0.25,
};

export const Input: Styles = {
  width: 32,
  mt: 0,
  lineHeight: 1.43,

  '&& .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-formControl': {
    marginTop: 0,
    paddingBottom: 0,
  },

  '&& input': {
    m: 0,
    paddingTop: 0,
    paddingBottom: 3 / 8,
    textAlign: 'center',
  },

  '& input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '& input::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  'input[type=number]': {
    MozAppearance: 'textfield',
  },
};
