import { useCallback } from 'react';
import { Transition } from 'history';

import { useBlocker } from 'hooks';

export const usePrompt = (message: string, when = true) => {
  const blocker = useCallback(
    (tx: Transition) => {
      if (window.confirm(message)) tx.retry();
    },
    [message],
  );

  useBlocker(blocker, when);
};
