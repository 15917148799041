import { useTranslation } from 'react-i18next';

import { BackButton, CardSidebar, FeedbackCard, Loader } from 'ui';
import { useEmployeeWithWeeklyFeedback, useReadOnlyEmployee, useValidatedParams } from 'hooks';
import { EmployeeSection } from 'app/employeeDetails/employeeSection/EmployeeSection';

export const PreviewWeekly = () => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { t } = useTranslation();
  const { isEmployeeWithWeeklyFeedbackLoading, employeeData, weeklyFeedbackData } = useEmployeeWithWeeklyFeedback();
  const { isReadOnly } = useReadOnlyEmployee(employeeId);

  if (isEmployeeWithWeeklyFeedbackLoading) return <Loader />;

  if (!employeeData || !weeklyFeedbackData) return <div>{t('previewWeekly.noData')}</div>;

  return (
    <CardSidebar button={<BackButton />} sidebar={<EmployeeSection employee={employeeData} />}>
      <FeedbackCard feedback={weeklyFeedbackData} variant="preview" isReadOnly={isReadOnly} />
    </CardSidebar>
  );
};
