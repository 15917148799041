import { Styles } from 'shared/types';

export const Container: Styles = {
  minHeight: 'calc(100vh - 184px)',
};

export const Wrapper: Styles = {
  gap: 2,
  width: 506,
};

export const WarningIcon: Styles = {
  color: 'orange',
  width: 40,
  height: 40,
};

export const Link: Styles = {
  cursor: 'pointer',
};
