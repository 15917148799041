import { useState } from 'react';

import { Section } from 'generated/webapp_gql';

const WARNING_QUESTIONS_AMOUNT_LIMIT = 10;

export const useQuestionsAmountWarning = (sections: Section[]) => {
  const [shouldOmitWarning, setShouldOmitWarning] = useState(false);

  const totalNumberOfQuestions = sections.reduce(
    (previousValue, currentValue) => previousValue + currentValue.questions.length,
    0,
  );

  const isWarning = totalNumberOfQuestions === WARNING_QUESTIONS_AMOUNT_LIMIT && !shouldOmitWarning;
  const turnOffWarning = () => {
    setShouldOmitWarning(true);
  };

  return {
    isWarning,
    turnOffWarning,
  };
};
