import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { Box, Button, Dialog, Paper, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { defaultSection } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.utils';
import { useQuestionsAmountWarning } from 'hooks';
import { QuestionsSection } from 'ui';
import { SurveyFormData } from '../../surveyBuilderLayout/SurveyBuilderLayout.types';
import { DraggableQuestionsWrapper } from '../draggableQuestions/QuestionWrapper';

import * as Styles from './QuestionsSectionWrapper.styles';

export const QuestionsSectionWrapper = () => {
  const { t } = useTranslation();
  const { control, getValues } = useFormContext<SurveyFormData>();
  const { isWarning, turnOffWarning } = useQuestionsAmountWarning(getValues('sections'));
  const { fields, append, move, remove } = useFieldArray({
    name: `sections`,
    control: control,
  });

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="form-questions">
          {({ droppableProps, innerRef, placeholder }) => (
            <Box {...droppableProps} ref={innerRef} sx={Styles.Sections}>
              {fields.map((section, index) => (
                <Draggable key={section.id} draggableId={`item-${section.id}`} index={index}>
                  {({ draggableProps, dragHandleProps, innerRef }) => (
                    <Paper elevation={1} key={index} ref={innerRef} {...draggableProps}>
                      <QuestionsSection
                        label={t('surveyBuilder.questions.section.label', { count: index + 1 })}
                        sectionsAmount={fields.length}
                        removeSection={() => remove(index)}
                        dragHandleProps={dragHandleProps}
                        addSection={() => append(defaultSection)}
                      >
                        <DraggableQuestionsWrapper nestIndex={index} />
                      </QuestionsSection>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      <Dialog fullWidth open={isWarning} onClose={turnOffWarning}>
        <Typography variant="h5">{t('surveyBuilder.questions.questionsAmountWarning.title')}</Typography>
        <Typography variant="body1" sx={Styles.DialogContent}>
          {t('surveyBuilder.questions.questionsAmountWarning.content')}
        </Typography>
        <Button variant="contained" color="primary" onClick={turnOffWarning}>
          {t('surveyBuilder.questions.questionsAmountWarning.button')}
        </Button>
      </Dialog>
    </>
  );
};
