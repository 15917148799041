import { CardChecklistItem } from './CardChecklist.types';

export const getStatus = (item: NonNullable<CardChecklistItem>) => {
  if ('goalStatus' in item) {
    return item.goalStatus;
  }
  if ('actionStatus' in item) {
    return item.actionStatus;
  }
};
