import { useNavigate } from 'react-router-dom';
import { MouseEvent, useState } from 'react';

import { useCreateInitialSurveyTemplate, useDeleteSurveyTemplate, useValidatedParams } from '../../../../../hooks';
import { AppRoute } from '../../../../../routing/AppRoute.enum';
import { SurveysListTab, SurveyTemplatesListProps } from '../../SurveyTemplatesTabs.types';
import { useSurveyTabsSortingContext } from '../../SurveyTabsSorting/useSurveyTabsSortingContext';

export const useSurveyTemplatesList = (department: SurveyTemplatesListProps['department']) => {
  const navigate = useNavigate();
  const { sortingParams } = useSurveyTabsSortingContext();

  const { employeeId } = useValidatedParams(['employeeId']);
  const [modalTemplateId, setModalTemplateId] = useState<string | null>(null);

  const getSortingVariable = (surveyTab: SurveysListTab) => {
    if (sortingParams?.[surveyTab]) {
      return {
        sort: sortingParams[surveyTab],
      };
    }
  };

  const { deleteSurveyTemplateMutation } = useDeleteSurveyTemplate({
    draft: getSortingVariable(SurveysListTab.Draft),
  });
  const { createInitialSurveyTemplate } = useCreateInitialSurveyTemplate(department, {
    draft: getSortingVariable(SurveysListTab.Draft),
  });

  const createNewTemplate = async () => {
    const { data } = await createInitialSurveyTemplate();

    if (data?.createSurveyTemplate?.id) {
      navigate(`/${AppRoute.SurveyBuilder}/${employeeId}/${data.createSurveyTemplate.id}`, {
        state: { isNewSurveyTemplate: true },
      });
    }
  };

  const editTemplate = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    navigate(`/${AppRoute.SurveyBuilder}/${employeeId}/${id}`);
  };

  const deleteTemplate = (id: string) => (e: MouseEvent) => {
    e.stopPropagation();
    setModalTemplateId(id);
  };

  const closeModal = async (isConfirmed: boolean, surveyTemplateId: string) => {
    if (isConfirmed) {
      await deleteSurveyTemplateMutation({
        variables: {
          surveyTemplateId,
        },
      });
    }

    setModalTemplateId(null);
  };

  return {
    closeModal,
    deleteTemplate,
    createNewTemplate,
    editTemplate,
    modalTemplateId,
  };
};
