import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyPublishedSurveyTemplateMutationVariables = Types.Exact<{
  input: Types.CopySurveyTemplateInput;
}>;


export type CopyPublishedSurveyTemplateMutation = { __typename?: 'Mutation', copyPublishedSurveyTemplate?: { __typename?: 'SurveyTemplate', id: string, introImageName?: string | null, introImageUrl?: string | null, endImageName?: string | null, endImageUrl?: string | null, title: string, content?: { __typename?: 'SurveyContent', beginning?: string | null, conclusion?: string | null, sections: Array<{ __typename?: 'Section', title: string, description?: string | null, questions: Array<{ __typename?: 'SurveyQuestion', question: string, format: Types.SurveyQuestionFormat, answers?: any | null } | null> } | null> } | null } | null };


export const CopyPublishedSurveyTemplateDocument = gql`
    mutation CopyPublishedSurveyTemplate($input: CopySurveyTemplateInput!) {
  copyPublishedSurveyTemplate(input: $input) {
    id
    introImageName
    introImageUrl
    endImageName
    endImageUrl
    content {
      beginning
      conclusion
      sections {
        title
        description
        questions {
          question
          format
          answers
        }
      }
    }
    title
  }
}
    `;
export type CopyPublishedSurveyTemplateMutationFn = Apollo.MutationFunction<CopyPublishedSurveyTemplateMutation, CopyPublishedSurveyTemplateMutationVariables>;

/**
 * __useCopyPublishedSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useCopyPublishedSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyPublishedSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyPublishedSurveyTemplateMutation, { data, loading, error }] = useCopyPublishedSurveyTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyPublishedSurveyTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CopyPublishedSurveyTemplateMutation, CopyPublishedSurveyTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyPublishedSurveyTemplateMutation, CopyPublishedSurveyTemplateMutationVariables>(CopyPublishedSurveyTemplateDocument, options);
      }
export type CopyPublishedSurveyTemplateMutationHookResult = ReturnType<typeof useCopyPublishedSurveyTemplateMutation>;
export type CopyPublishedSurveyTemplateMutationResult = Apollo.MutationResult<CopyPublishedSurveyTemplateMutation>;
export type CopyPublishedSurveyTemplateMutationOptions = Apollo.BaseMutationOptions<CopyPublishedSurveyTemplateMutation, CopyPublishedSurveyTemplateMutationVariables>;