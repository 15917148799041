import { Action, ActionStatus, Goal, Scalars, Survey } from 'generated/webapp_gql';

import { ToDo } from './ToDoTable.types';

export const filterToDosByTypeName = (items: Action[] | Goal[] | Survey[]): ToDo[] => {
  return items.map((item) => {
    const actionStatus = 'actionStatus' in item && item.actionStatus;
    const goalStatus = 'goalStatus' in item && item.goalStatus;
    const weeklyFeedbackId = ('weeklyFeedbackId' in item && item.weeklyFeedbackId) as string | undefined;
    const quarterlyReportId = 'quarterlyReportId' in item && item.quarterlyReportId;
    const surveyStatus = 'status' in item && item.status;

    const createdAt = 'createdAt' in item ? item.createdAt : null;
    const realizationDate = 'realizationDate' in item ? (item.realizationDate as Goal['realizationDate']) : null;
    const content = 'content' in item ? (item.content as Scalars['String']['input']) : '';
    const title = 'title' in item ? item.title : null;

    return {
      id: item.id,
      content: title ?? content,
      toDoStatus: actionStatus || goalStatus || surveyStatus || ActionStatus.InProgress,
      createdAt: createdAt ?? realizationDate,
      realizationDate: realizationDate ?? '',
      updatedAt: item.updatedAt,
      parentId: actionStatus ? weeklyFeedbackId : quarterlyReportId || item.id,
    };
  });
};
