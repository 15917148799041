import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishedSurveyQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID']['input'];
}>;


export type PublishedSurveyQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', id: string, title: string, closeDate?: any | null, upcomingNotificationsDispatch?: any | null, status: Types.SurveyStatus, respondedEmails?: Array<string | null> | null, isSent?: boolean | null, reminderDaysBeforeSurveyClose: number, owner?: { __typename?: 'Employee', fullName: string, avatarUrl?: string | null } | null, surveyTemplate?: { __typename?: 'SurveyTemplate', id: string, language: Types.Language, endImageUrl?: string | null, endImageName?: string | null, introImageUrl?: string | null, introImageName?: string | null, title: string, creator?: { __typename?: 'Employee', fullName: string, avatarUrl?: string | null } | null, content?: { __typename?: 'SurveyContent', beginning?: string | null, conclusion?: string | null, sections: Array<{ __typename?: 'Section', title: string, description?: string | null, questions: Array<{ __typename?: 'SurveyQuestion', question: string, answers?: any | null, format: Types.SurveyQuestionFormat } | null> } | null> } | null } | null } | null };


export const PublishedSurveyDocument = gql`
    query PublishedSurvey($surveyId: ID!) {
  survey(id: $surveyId) {
    id
    title
    closeDate
    upcomingNotificationsDispatch
    status
    respondedEmails
    isSent
    reminderDaysBeforeSurveyClose
    owner {
      fullName
      avatarUrl
    }
    surveyTemplate {
      id
      language
      endImageUrl
      endImageName
      introImageUrl
      introImageName
      title
      creator {
        fullName
        avatarUrl
      }
      content {
        beginning
        conclusion
        sections {
          title
          description
          questions {
            question
            answers
            format
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePublishedSurveyQuery__
 *
 * To run a query within a React component, call `usePublishedSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSurveyQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function usePublishedSurveyQuery(baseOptions: Apollo.QueryHookOptions<PublishedSurveyQuery, PublishedSurveyQueryVariables> & ({ variables: PublishedSurveyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishedSurveyQuery, PublishedSurveyQueryVariables>(PublishedSurveyDocument, options);
      }
export function usePublishedSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishedSurveyQuery, PublishedSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishedSurveyQuery, PublishedSurveyQueryVariables>(PublishedSurveyDocument, options);
        }
export function usePublishedSurveySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublishedSurveyQuery, PublishedSurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublishedSurveyQuery, PublishedSurveyQueryVariables>(PublishedSurveyDocument, options);
        }
export type PublishedSurveyQueryHookResult = ReturnType<typeof usePublishedSurveyQuery>;
export type PublishedSurveyLazyQueryHookResult = ReturnType<typeof usePublishedSurveyLazyQuery>;
export type PublishedSurveySuspenseQueryHookResult = ReturnType<typeof usePublishedSurveySuspenseQuery>;
export type PublishedSurveyQueryResult = Apollo.QueryResult<PublishedSurveyQuery, PublishedSurveyQueryVariables>;