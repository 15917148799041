import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSurveyTemplateMutationVariables = Types.Exact<{
  surveyTemplateId: Types.Scalars['String']['input'];
}>;


export type DeleteSurveyTemplateMutation = { __typename?: 'Mutation', deleteSurveyTemplate?: { __typename?: 'SurveyTemplate', id: string } | null };


export const DeleteSurveyTemplateDocument = gql`
    mutation DeleteSurveyTemplate($surveyTemplateId: String!) {
  deleteSurveyTemplate(surveyTemplateId: $surveyTemplateId) {
    id
  }
}
    `;
export type DeleteSurveyTemplateMutationFn = Apollo.MutationFunction<DeleteSurveyTemplateMutation, DeleteSurveyTemplateMutationVariables>;

/**
 * __useDeleteSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSurveyTemplateMutation, { data, loading, error }] = useDeleteSurveyTemplateMutation({
 *   variables: {
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *   },
 * });
 */
export function useDeleteSurveyTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSurveyTemplateMutation, DeleteSurveyTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSurveyTemplateMutation, DeleteSurveyTemplateMutationVariables>(DeleteSurveyTemplateDocument, options);
      }
export type DeleteSurveyTemplateMutationHookResult = ReturnType<typeof useDeleteSurveyTemplateMutation>;
export type DeleteSurveyTemplateMutationResult = Apollo.MutationResult<DeleteSurveyTemplateMutation>;
export type DeleteSurveyTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteSurveyTemplateMutation, DeleteSurveyTemplateMutationVariables>;