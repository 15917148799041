import { toast } from 'react-toastify';

import { PublishedSurveyDocument } from 'documents/publishedSurvey.generated';
import { useUpdateSurveyMutation } from 'documents/updateSurvey.generated';
import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { SurveyResponsesDocument } from 'documents/surveyResponses.generated';
import { AvailableSurveyTemplatesDocument } from 'documents/availableSurveyTemplates.generated';
import { DepartmentsSentSurveysDocument } from 'documents/departmentSentSurveys.generated';

import type { UseUpdateSurveyProps } from './useUpdateSurvey.types';

export const useUpdateSurvey = ({ onCompleted }: UseUpdateSurveyProps) => {
  const { employeeId, surveyId } = useValidatedParams();

  const [updateSurvey, { loading }] = useUpdateSurveyMutation({
    refetchQueries: [
      {
        query: PublishedSurveyDocument,
        variables: {
          surveyId,
        },
      },
      {
        query: AvailableSurveyTemplatesDocument,
        variables: {
          employeeId,
        },
      },
      {
        query: DepartmentsSentSurveysDocument,
        variables: {
          employeeId,
        },
      },
      {
        query: SurveyResponsesDocument,
        variables: {
          surveyId,
        },
      },
    ],
    onError: (error) => toast.error(error.message),
    onCompleted,
  });

  return { updateSurvey, isUpdateSurveyLoading: loading };
};
