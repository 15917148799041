import React from 'react';
import { Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker as MaterialDateTimePicker } from '@mui/x-date-pickers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { datePickerWithTimeFormat } from 'shared/utils';
import { TextField } from 'ui';
import { getLocale } from 'i18n/i18n.utils';

import * as Styles from './DateTimePicker.styles';
import { DateTimePickerProps } from './DateTimePicker.types';

export const DateTimePicker = ({
  dateFormat = datePickerWithTimeFormat,
  ...props
}: DateTimePickerProps<Date, Date>) => {
  const tshEstablishmentDate = new Date(2012, 7, 11);

  return (
    <Box sx={Styles.DatePickerWrapper}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale().dateTimeLocale}>
        <MaterialDateTimePicker
          inputFormat={dateFormat}
          showToolbar={true}
          minDate={tshEstablishmentDate}
          toolbarPlaceholder={props.placeholder}
          components={{
            OpenPickerIcon: CalendarTodayIcon,
          }}
          renderInput={({ InputProps, ...params }) => (
            <TextField
              {...params}
              InputProps={{ startAdornment: InputProps?.endAdornment }}
              inputProps={{ ...params.inputProps, placeholder: props.placeholder }}
            />
          )}
          {...props}
        />
      </LocalizationProvider>
    </Box>
  );
};
