import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';

import { msalConfig } from 'msalConfig';

export const useAcquireTokenSilent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [loading, setLoading] = useState(true);
  const [fetchingToken, setFetchingToken] = useState(false);
  const [error, setError] = useState<unknown>();

  const fetchToken = useCallback(async () => {
    if (!accounts[0]) return undefined;
    setFetchingToken(true);
    setError(undefined);
    try {
      return instance.acquireTokenSilent({
        scopes: [`${msalConfig.auth.clientId}/.default`],
        account: accounts[0],
      });
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setFetchingToken(false);
    }
  }, [accounts, instance]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    } else {
      setLoading(fetchingToken || inProgress !== 'none');
    }
  }, [inProgress, error, accounts, fetchingToken]);

  return { loading, error, fetchToken };
};
