import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { TranslationsKeys } from 'i18n/i18n.types';

import * as Styles from './SurveyFormContinue.styles';

export const SurveyFormContinue = ({
  setShowContinueSurveyScreen,
}: {
  setShowContinueSurveyScreen: Dispatch<SetStateAction<boolean | null>>;
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.Wrapper}>
      <Typography variant="h5" sx={Styles.Title}>
        {t('surveyForm.continue.title' as TranslationsKeys)}
      </Typography>
      <Typography variant="body1" sx={Styles.Description}>
        {t('surveyForm.continue.description' as TranslationsKeys)}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => setShowContinueSurveyScreen(false)}>
        {t('surveyForm.continue.continueQuestionnaireBtn' as TranslationsKeys)}
      </Button>
    </Box>
  );
};
