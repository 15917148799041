import { useDepartmentsSentSurveysQuery } from 'documents/departmentSentSurveys.generated';
import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';

export const useDepartmentSentSurveys = () => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const {
    data,
    loading: areSentSurveysLoading,
    refetch,
  } = useDepartmentsSentSurveysQuery({
    variables: {
      employeeId,
    },
  });

  return {
    areSentSurveysLoading,
    departmentSentSurveys: data?.employee?.departmentsSentSurveys,
    refetch,
  };
};
