import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as Styles from './SectionCounterChip.styles';
import { SectionCounterChipProps } from './SectionCounterChip.types';

export const SectionCounterChip = ({ current, total }: SectionCounterChipProps) => {
  const { t } = useTranslation();

  return (
    <Chip
      sx={Styles.Counter}
      size="small"
      label={t('surveyPreview.step.section', {
        current,
        total,
      })}
    />
  );
};
