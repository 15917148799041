import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { Survey, SurveyStatus } from 'generated/webapp_gql';
import { useModal, useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { Accordion, Modal, ToDoTable } from 'ui';
import { ToDoType } from 'ui/modal/Modal.enum';
import { SURVEYS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';
import { SurveyStatusKeys } from '../EmployeeSurveys.types';
import { ModalState } from 'hooks/useModal/useModal.types';
import { surveyTabsTranslations } from 'app/employeeDetails/employeeDetailsQuarterly/surveyTabs/SurveyTabs.utils';
import { useUpdateSurveyTitle } from 'hooks/useUpdateSurveyTitle/useUpdateSurveyTitle';

import { SurveysAccordionProps } from './SurveysAccordion.types';
import { useCustomChangeSurveyStatus } from './useCustomChangeSurveyStatus';

export const SurveysAccordion = ({ type, items, total, loading, pages, setPages }: SurveysAccordionProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { t } = useTranslation();
  const location = useLocation();

  const { changeSurveyStatus } = useCustomChangeSurveyStatus({ pages });
  const updateHandler = useUpdateSurveyTitle();

  const updateStatus = (item: ModalState<SurveyStatus>) => {
    changeSurveyStatus({
      variables: {
        input: {
          surveyId: item.id,
          status: item.updatedActionStatus,
        },
      },
    });
  };

  const { handleItemClick, item, handleModalClose } = useModal<SurveyStatus>({
    onConfirm: updateStatus,
    canceledStatus: SurveyStatus.Closed,
    doneStatus: SurveyStatus.Closed,
  });

  const getRedirectToSurveyDetails = (id?: string) => ({
    to: `/${AppRoute.PublishedSurvey}/${employeeId}/${id}`,
    state: { backRoute: location.pathname },
  });

  useEffect(() => {
    if (total) {
      const lastPage = Math.ceil(total / SURVEYS_LIST_PAGE_SIZE) || 1;

      if (lastPage < pages[type.toLowerCase() as SurveyStatusKeys]) {
        setPages({ ...pages, [type.toLowerCase() as SurveyStatusKeys]: lastPage });
      }
    }
  }, [pages, setPages, total, type]);

  return (
    <>
      <Accordion
        title={t(surveyTabsTranslations[type], {
          count: total,
        })}
        content={
          <ToDoTable
            onClick={handleItemClick}
            withoutCheckbox
            toDoData={items as Survey[]}
            getOnArrowClickState={getRedirectToSurveyDetails}
            loading={loading}
            pagination={{
              total,
              pageSize: SURVEYS_LIST_PAGE_SIZE,
              current: pages[type.toLowerCase() as SurveyStatusKeys],
              handlePageChange: (_, value) => {
                setPages({ ...pages, [type.toLowerCase() as SurveyStatusKeys]: value });
              },
            }}
            context={updateHandler}
          />
        }
      />

      {item.type && (
        <Modal onClose={handleModalClose} type={item.type} content={item.content} toDoType={ToDoType.Survey} noAlert />
      )}
    </>
  );
};
