import { toast } from 'react-toastify';

import { useQuarterlyReportQuery } from 'documents/quarterlyReport.generated';
import { QuarterlyReport } from 'generated/webapp_gql';

export const useQuarterlyReport = (
  quarterlyReportId: string,
  options?: Parameters<typeof useQuarterlyReportQuery>[0],
) => {
  const { data, loading: isQuarterlyReportLoading } = useQuarterlyReportQuery({
    variables: { quarterlyReportId },
    onError: (error) => toast.error(error.message),
    ...options,
  });

  const quarterlyReportData = data?.quarterlyReport as QuarterlyReport;

  return {
    quarterlyReportData,
    isQuarterlyReportLoading,
  };
};
