import { FormControl, InputAdornment, TextField as MaterialTextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';

import { spreadMultipleStyles } from 'shared/utils';

import { ControlledTextFieldProps } from './ControlledTextField.types';
import * as Styles from './ControlledTextField.styles';

export const ControlledTextField = <T extends FieldValues>({
  multiline = true,
  variant = 'standard',
  iconStart,
  iconEnd,
  name,
  control,
  sx,
  formControlSx,
  ...props
}: ControlledTextFieldProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value, ref, name }, fieldState: { error } }) => {
      return (
        <FormControl variant="standard" sx={formControlSx}>
          <MaterialTextField
            multiline={multiline}
            variant={variant}
            sx={spreadMultipleStyles(Styles.DefaultTextField, sx)}
            InputProps={{
              startAdornment: iconStart && <InputAdornment position="start">{iconStart}</InputAdornment>,
              endAdornment: iconEnd && <InputAdornment position="end">{iconEnd}</InputAdornment>,
            }}
            onChange={onChange}
            value={value}
            name={name}
            helperText={!!error?.message ? error.message : null}
            InputLabelProps={{ shrink: true }}
            error={!!error}
            minRows={1}
            maxRows={6}
            inputRef={ref}
            {...props}
          />
        </FormControl>
      );
    }}
  />
);
