import { Box, Button, FormControl, Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KeyboardEvent } from 'react';

import { GoalStatus } from 'generated/webapp_gql';
import {
  CreateQuarterlyReportFormData,
  UpdateQuarterlyReportFormData,
} from 'app/quarterlyReportForm/quarterlyReportForm/QuarterlyReportForm.types';
import { AddButton, OptionalTextField } from 'ui';

import { OptionalGoalsProps } from './OptionalGoals.types';
import * as Styles from './OptionalGoals.styles';

export const OptionalGoals = ({ mutationLoading, name, label }: OptionalGoalsProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray<
    CreateQuarterlyReportFormData | UpdateQuarterlyReportFormData,
    'goals',
    'formId'
  >({
    name: 'goals',
    keyName: 'formId',
  });

  const lastGoalIndex = fields.length - 1;
  const isDeleteAvailable = !(fields[lastGoalIndex] && 'id' in fields[lastGoalIndex]) && fields.length > 1;

  const addNewGoal = () => {
    append({ content: '', goalStatus: GoalStatus.InProgress });
  };

  const removeLastGoal = () => {
    remove(lastGoalIndex);
  };

  const handleEnterPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addNewGoal();
    }
  };

  return (
    <Box sx={Styles.Wrapper}>
      <Box sx={Styles.Label}>
        <Typography variant="subtitle2">{label}</Typography>
      </Box>
      {fields.map((field, index) => {
        const goalStatus = field.goalStatus;

        if (!goalStatus) return;

        const isInProgress = goalStatus === GoalStatus.InProgress;

        return (
          <FormControl variant="standard" key={field.formId}>
            <Controller
              name={`goals.${index}.content`}
              control={control}
              render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <OptionalTextField
                  sx={Styles.OptionalTextField}
                  onChange={onChange}
                  onKeyDown={handleEnterPress}
                  value={value}
                  multiline
                  variant="standard"
                  placeholder={t('optionalGoals.addGoalPlaceholder')}
                  error={!!error}
                  helperText={
                    !!error?.message && error.type === 'min' ? t('quarterlyForm.errorMessage.cantDeleteAnGoal') : null
                  }
                  disabled={mutationLoading || !isInProgress}
                  setValue={setValue}
                  name={`${name}[${index}]`}
                  multiFields={true}
                  isMultiChecked={true}
                  status={goalStatus}
                  inputRef={ref}
                />
              )}
            />
          </FormControl>
        );
      })}
      <AddButton onClick={addNewGoal} variant="text" title={t('optionalGoals.addGoalButton')} sx={Styles.Button} />
      {isDeleteAvailable && (
        <Button onClick={removeLastGoal} sx={Styles.Button} color="error">
          {t('optionalGoals.removeLastGoal')}
        </Button>
      )}
    </Box>
  );
};
