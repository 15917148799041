import { FormControlLabel, Radio } from '@mui/material';

import { SmilingFacesRating } from '../smilingFacesRating/SmilingFacesRating';

import * as Styles from './SatisfactionScaleButton.styles';
import { SatisfactionScaleButtonProps } from './SatisfactionScaleButton.types';

export const SatisfactionScaleButton = ({ value, checked }: SatisfactionScaleButtonProps) => {
  return (
    <FormControlLabel
      value={value}
      checked={checked}
      control={<Radio />}
      label={<SmilingFacesRating value={value} />}
      labelPlacement="top"
      sx={checked ? Styles.ButtonCheckedWrapper : Styles.ButtonWrapper}
    />
  );
};
