import { Accordion as MaterialAccordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AccordionProps } from './Accordion.types';
import * as Styles from './Accordion.styles';

export const Accordion = ({ title, content, action, sx }: AccordionProps) => {
  return (
    <Box sx={Styles.AccordionWrapper}>
      {action && <Box sx={Styles.Actions}>{action}</Box>}
      <MaterialAccordion defaultExpanded={true} disableGutters={true} sx={sx}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={Styles.ArrowDownIcon} />}
          aria-controls="actions-content"
          id="actions-header"
          sx={Styles.AccordionSummary}
        >
          <Box sx={Styles.Title} data-testid="accordion-title">
            {title}
          </Box>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </MaterialAccordion>
    </Box>
  );
};
