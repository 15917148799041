import { toast } from 'react-toastify';
import { useState } from 'react';

import { useGenerateSurveyResponsesCsvLazyQuery } from 'documents/generateSurveyResponsesCsv.generated';
import { useValidatedParams } from 'hooks';

import { UseDownloadSurveyResponsesCsvProps } from './useDownloadSurveyResponsesCsv.types';
import { downloadFile, fetchBlob } from './useDownloadSurveyResponsesCsv.utils';

export const useDownloadSurveyResponsesCsv = ({ fileName }: UseDownloadSurveyResponsesCsvProps) => {
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const { surveyId } = useValidatedParams(['surveyId']);

  const getDownloadCsvLink = useGenerateSurveyResponsesCsvLazyQuery({
    variables: {
      surveyId,
    },
    fetchPolicy: 'no-cache',
  })[0];

  const downloadSurveyAnswers = async () => {
    try {
      setIsDownloadingCsv(true);
      const { data } = await getDownloadCsvLink();
      const downloadLink = data?.generateCsvFromSurveyResponses;

      if (downloadLink) {
        const blob = await fetchBlob(downloadLink);
        downloadFile(blob, fileName);
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setIsDownloadingCsv(false);
    }
  };

  return {
    downloadSurveyAnswers,
    isDownloadingCsv,
  };
};
