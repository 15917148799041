import { ButtonProps } from '@mui/material';

import { Styles } from 'shared/types';

const borderlessPaddings = {
  small: 1,
  medium: 0,
  large: 2,
};

const paddings = {
  small: 0.875,
  medium: 0,
  large: 1.875,
};

export const Button = (size: ButtonProps['size'] = 'medium'): Styles => ({
  minWidth: 0,
  p: (theme) => theme.spacing(paddings[size]),

  '& svg': {
    color: 'navy',
    width: 32,
    height: 32,
  },

  '& path': {
    transitionDuration: '0.4s',
  },

  '&:hover svg': {
    color: 'blueberry',
  },

  '&.MuiButton-contained, &.MuiButton-text': {
    p: (theme) => theme.spacing(borderlessPaddings[size]),
  },
});
