import { Box, FormHelperText, InputLabel, RadioGroup } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { smilingFacesRating } from 'ui/satisfactionScale/smilingFacesRating/SmilingFacesRating.utils';

import { SatisfactionScaleButton } from './satisfactionScaleButton/SatisfactionScaleButton';
import { SmilingFacesRatingType } from './smilingFacesRating/SmilingFacesRating.types';
import * as Styles from './SatisfactionScale.styles';
import { SatisfactionScaleProps } from './SatisfactionScale.types';

export const SatisfactionScale = <T extends FieldValues>({ control, name, projectName }: SatisfactionScaleProps<T>) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <InputLabel id={name} required>
              {t(projectName ? 'weeklyForm.projectSatisfactionInputLabel' : 'weeklyForm.satisfactionInputLabel', {
                projectName,
              })}
            </InputLabel>
            <Box sx={Styles.Satisfaction}>
              <RadioGroup aria-labelledby={name} row onChange={onChange} value={value ?? 0}>
                {Object.keys(smilingFacesRating).map((item, index) => {
                  return (
                    <SatisfactionScaleButton
                      key={`${name}-${index + 1}`}
                      value={(index + 1) as SmilingFacesRatingType}
                      checked={Number(value) === index + 1}
                    />
                  );
                })}
              </RadioGroup>
              {!!error && <FormHelperText error={!!error}>{error?.message ?? ''}</FormHelperText>}
            </Box>
          </>
        )}
      />
    </Box>
  );
};
