import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { parse } from 'date-fns';
import { Navigate, useNavigate } from 'react-router-dom';

import { useCreateQuarterlyReport, useEmployee, useReadOnlyEmployee, useValidatedParams } from 'hooks';
import { QuarterlyReportForm } from '../quarterlyReportForm/QuarterlyReportForm';
import { getAddQuarterlyReportFormSchema } from '../quarterlyReportForm/QuarterlyReportForm.schema';
import { CreateQuarterlyReportFormData } from '../quarterlyReportForm/QuarterlyReportForm.types';
import { getFullFormatDate } from 'shared/utils';
import { Loader } from 'ui';
import { AppRoute } from 'routing/AppRoute.enum';

export const AddQuarterlyReportFormContainer = () => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { createQuarterlyReport, isCreateQuarterlyReportLoading } = useCreateQuarterlyReport(employeeId);
  const { employeeData, isEmployeeDataLoading } = useEmployee({ onError: () => navigate(AppRoute.Home) });
  const { isReadOnly, isReadOnlyLoading } = useReadOnlyEmployee(employeeId);

  const currentDate = new Date();
  const hiredDate = employeeData?.hiredDate ? parse(employeeData.hiredDate, 'yyyy-MM-dd', currentDate) : currentDate;
  const navigate = useNavigate();

  const quarterlyReportFormMethods = useForm<CreateQuarterlyReportFormData>({
    resolver: yupResolver(getAddQuarterlyReportFormSchema(hiredDate)),
    defaultValues: {
      realizationDate: currentDate,
      goals: [{ content: '' }],
    },
  });

  const onSubmit = async ({ summary, notes, realizationDate, goals }: CreateQuarterlyReportFormData) => {
    await createQuarterlyReport({
      variables: {
        input: {
          summary,
          notes,
          receiverId: employeeId,
          realizationDate: getFullFormatDate(realizationDate),
          goals: goals?.filter((goal) => !!goal.content),
        },
      },
    });
  };

  if (isEmployeeDataLoading || isReadOnlyLoading) return <Loader />;

  if (!employeeData) return null;

  if (isReadOnly) return <Navigate to={AppRoute.Home} replace={true} />;

  return (
    <FormProvider {...quarterlyReportFormMethods}>
      <QuarterlyReportForm
        mutationLoading={isCreateQuarterlyReportLoading}
        onSubmit={quarterlyReportFormMethods.handleSubmit(onSubmit)}
        employeeData={employeeData}
        hiredDate={hiredDate}
      />
    </FormProvider>
  );
};
