import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const PaginationWrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  mt: 3,
};

export const SeeMoreWrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',

  '&& .MuiButton-root': {
    color: colors.navy,
  },
};

export const TableWrapper: Styles = {
  position: 'relative',
};

export const LoadingWrapper: Styles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};
