import { toast } from 'react-toastify';

import { useEmployeeSearchParams, useSearchParamsState, useValidatedParams } from 'hooks';
import { GoalStatus } from 'generated/webapp_gql';
import { GOALS_LIST_PAGE_SIZE } from '../EmployeeDetailsQuarterly';
import { useEmployeeGoalsQuery } from 'documents/employeeGoals.generated';

export const useGoalsTabs = () => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const { searchParams, setSearchParams } = useEmployeeSearchParams();
  const [goalStatus, setGoalStatus] = useSearchParamsState({
    searchParams,
    params: 'task-status',
    setSearchParams,
  });

  const {
    data,
    loading: areGoalsLoading,
    previousData,
  } = useEmployeeGoalsQuery({
    variables: {
      employeeId,
      goalStatus: searchParams['task-status'] as GoalStatus,
      goalsPagination: {
        limit: GOALS_LIST_PAGE_SIZE,
        offset: 0,
      },
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    goalsData: data ? data?.employee : previousData?.employee,
    areGoalsLoading,
    goalStatus,
    setGoalStatus,
  };
};
