import { Styles } from 'shared/types';

export const GrumpyCat = (isGrumpyCatDisplayed: boolean): Styles => ({
  height: 197,
  width: 158,
  position: 'fixed',
  right: 0,
  transition: '1s ease',
  overflow: 'hidden',
  zIndex: 999,
  bottom: isGrumpyCatDisplayed ? 0 : -197,
});
