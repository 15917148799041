import { useMemo } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Select } from 'ui';
import { SortOrder } from '../../../../generated/webapp_gql';
import * as Styles from '../SurveyTabs.styles';
import { SortSelectItem, SortSelectProps } from '../SurveyTabs.types';

export const SortSelect = ({ items, value, onChange }: SortSelectProps) => {
  const { t } = useTranslation();
  const handleSortChange = async (e: SelectChangeEvent<unknown>) => {
    const selectedOption = e.target.value as SortSelectItem['value'];
    const [sortBy, sortOrder] = selectedOption.split(':') as [string, SortOrder];
    const variables = {
      sortBy,
      sortOrder,
    };

    onChange(variables, selectedOption);
  };

  const mappedOptionsWithTranslations = useMemo(
    () => items.map((item) => ({ ...item, label: t(item.label) })),
    [items, t],
  );

  return (
    <Box sx={Styles.SortSelectContainer}>
      <Select
        items={mappedOptionsWithTranslations}
        onChange={handleSortChange}
        value={value}
        label={t('surveysList.sort.title')}
        variant="outlined"
        size="small"
        sx={Styles.SortSelect}
      />
    </Box>
  );
};
