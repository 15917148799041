import { useContext } from 'react';

import { SurveyViewContext } from 'context/surveyView/SurveyViewContext';

export const useSurveyView = () => {
  const context = useContext(SurveyViewContext);

  if (context === undefined) {
    throw new Error('SurveyViewContext must be within SurveyViewContextProvider');
  }

  return context;
};
