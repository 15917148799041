import { useSearchParam } from 'hooks';
import { EmployeesPaginationParam } from 'hooks/useEmployeesPagination/useEmployeesPagination.enum';

import { EmployeesFiltersParam } from './useEmployeesFilters.enum';
import { UseEmployeeFiltersProps } from './useEmployeesFilters.types';

export const useEmployeesFilters = ({ isTM }: UseEmployeeFiltersProps) => {
  const filterByDepartments = useSearchParam({
    paramName: EmployeesFiltersParam.FilterByDepartments,
    type: 'array',
    additionalStateParamsToClear: [EmployeesPaginationParam.Page],
  });
  const assignedToMe = useSearchParam({
    paramName: EmployeesFiltersParam.AssignedToMe,
    type: 'boolean',
    options: {
      defaultValue: isTM,
    },
    additionalStateParamsToClear: [EmployeesPaginationParam.Page],
  });
  const isAdminMode = useSearchParam({
    paramName: EmployeesFiltersParam.IsAdminMode,
    type: 'boolean',
    additionalStateParamsToClear: [
      EmployeesFiltersParam.FilterByDepartments,
      EmployeesPaginationParam.Page,
      EmployeesFiltersParam.IsSubcontractorsMode,
      EmployeesPaginationParam.EmployeesWithActionsPage,
    ],
  });
  const isSubcontractorsMode = useSearchParam({
    paramName: EmployeesFiltersParam.IsSubcontractorsMode,
    type: 'boolean',
    additionalStateParamsToClear: [EmployeesFiltersParam.FilterByDepartments, EmployeesPaginationParam.Page],
  });
  const searchText = useSearchParam({
    paramName: EmployeesFiltersParam.SearchText,
    type: 'string',
    additionalStateParamsToClear: [EmployeesPaginationParam.Page, EmployeesPaginationParam.EmployeesWithActionsPage],
  });

  return {
    assignedToMe,
    searchText,
    isAdminMode,
    isSubcontractorsMode,
    filterByDepartments,
  };
};
