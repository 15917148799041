import { Styles } from 'shared/types';

export const HeaderWrapper: Styles = {
  mt: 2,
  mb: 5,
};

export const ToolbarWrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const AppTitleWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  color: 'background.paper',
  textDecoration: 'none',
};

export const AppLogo: Styles = {
  width: 24,
  height: 24,
  border: (theme) => `4px solid ${theme.palette.typography.link}`,
  borderRadius: '50%',
};

export const AppTitle: Styles = {
  fontSize: 24,
  color: 'text.primary',
  fontWeight: 800,
  ml: 2,
};
