import { useTranslation } from 'react-i18next';

import { BackButton, CardSidebar, Loader, PageTitle } from 'ui';
import {
  useEmployee,
  useEmployeeActionsSearchParams as useEmployeeGoalsSearchParams,
  useSearchParamsState,
  useValidatedParams,
} from 'hooks';
import { EmployeeSection } from 'app/employeeDetails/employeeSection/EmployeeSection';
import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';
import { useAllGoalsOfEmployee } from 'hooks/useAllGoalsOfEmployee/useAllGoalsOfEmployee';

import { allGoalsDataLoader, goalsTabsNames } from './EmployeeGoals.utils';
import { GoalsAccordion } from './goalsAccordion/GoalsAccordion';

export const EmployeeGoals = () => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { t } = useTranslation();
  const { searchParams, setSearchParams } = useEmployeeGoalsSearchParams();
  const [pages, setPages] = useSearchParamsState({
    searchParams,
    params: ['in_progress', 'done', 'canceled'],
    setSearchParams,
  });

  const { employeeData, isEmployeeDataLoading } = useEmployee();
  const { allGoalsData, prevAllGoalsData } = useAllGoalsOfEmployee({
    pages: {
      canceled: pages.canceled,
      done: pages.done,
      inProgress: pages.in_progress,
    },
  });

  if (isEmployeeDataLoading) return <Loader />;

  if (!employeeData) return null;

  return (
    <CardSidebar
      button={<BackButton route={`${AppRoute.EmployeeDetails}/${employeeId}/${EmployeeDetailsViewParam.Quarterly}`} />}
      sidebar={<EmployeeSection employee={employeeData} withGoals={false} />}
    >
      <PageTitle title={t('employeeGoals.header')} />

      {goalsTabsNames.map((type) => (
        <GoalsAccordion
          {...allGoalsDataLoader({ allGoalsData, prevAllGoalsData, type })}
          type={type}
          key={type}
          pages={pages}
          setPages={setPages}
        />
      ))}
    </CardSidebar>
  );
};
