import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllSurveysOfEmployeeQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  openedPagination?: Types.InputMaybe<Types.PaginationInput>;
  closedPagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type AllSurveysOfEmployeeQuery = { __typename?: 'Query', opened?: { __typename?: 'SurveyCollection', total: number, items: Array<{ __typename?: 'Survey', id: string, createdAt: any, title: string, status: Types.SurveyStatus } | null> } | null, closed?: { __typename?: 'SurveyCollection', total: number, items: Array<{ __typename?: 'Survey', id: string, createdAt: any, updatedAt: any, title: string, status: Types.SurveyStatus } | null> } | null };


export const AllSurveysOfEmployeeDocument = gql`
    query allSurveysOfEmployee($employeeId: Int!, $openedPagination: PaginationInput, $closedPagination: PaginationInput) {
  opened: surveys(
    employeeId: $employeeId
    surveyStatus: OPENED
    pagination: $openedPagination
  ) {
    items {
      id
      createdAt
      title
      status
    }
    total
  }
  closed: surveys(
    employeeId: $employeeId
    surveyStatus: CLOSED
    pagination: $closedPagination
  ) {
    items {
      id
      createdAt
      updatedAt
      title
      status
    }
    total
  }
}
    `;

/**
 * __useAllSurveysOfEmployeeQuery__
 *
 * To run a query within a React component, call `useAllSurveysOfEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSurveysOfEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSurveysOfEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      openedPagination: // value for 'openedPagination'
 *      closedPagination: // value for 'closedPagination'
 *   },
 * });
 */
export function useAllSurveysOfEmployeeQuery(baseOptions: Apollo.QueryHookOptions<AllSurveysOfEmployeeQuery, AllSurveysOfEmployeeQueryVariables> & ({ variables: AllSurveysOfEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSurveysOfEmployeeQuery, AllSurveysOfEmployeeQueryVariables>(AllSurveysOfEmployeeDocument, options);
      }
export function useAllSurveysOfEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSurveysOfEmployeeQuery, AllSurveysOfEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSurveysOfEmployeeQuery, AllSurveysOfEmployeeQueryVariables>(AllSurveysOfEmployeeDocument, options);
        }
export function useAllSurveysOfEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllSurveysOfEmployeeQuery, AllSurveysOfEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllSurveysOfEmployeeQuery, AllSurveysOfEmployeeQueryVariables>(AllSurveysOfEmployeeDocument, options);
        }
export type AllSurveysOfEmployeeQueryHookResult = ReturnType<typeof useAllSurveysOfEmployeeQuery>;
export type AllSurveysOfEmployeeLazyQueryHookResult = ReturnType<typeof useAllSurveysOfEmployeeLazyQuery>;
export type AllSurveysOfEmployeeSuspenseQueryHookResult = ReturnType<typeof useAllSurveysOfEmployeeSuspenseQuery>;
export type AllSurveysOfEmployeeQueryResult = Apollo.QueryResult<AllSurveysOfEmployeeQuery, AllSurveysOfEmployeeQueryVariables>;