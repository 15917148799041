import { Styles } from 'shared/types';

export const Wrapper: Styles = {
  p: ({ spacing }) => spacing(3, 2),
  width: '100%',
  maxWidth: '280px',
};

export const DetailsWrapper: Styles = {
  gap: 3,
  display: 'flex',
  flexDirection: 'column',
};

export const AvatarWrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  mb: 1,
};

export const User: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mb: 4,
};

export const Name: Styles = {
  lineHeight: 1.5,
  mb: 0.5,
  fontWeight: 'bold',
  color: 'typography.common',
  textAlign: 'center',
};

export const Level: Styles = {
  fontSize: 14,
  lineHeight: 1.25,
  color: 'typography.lightText',
  mb: 1,
  textTransform: 'lowercase',

  '&::first-letter': {
    textTransform: 'Uppercase',
  },
};

export const Label: Styles = {
  mb: 1,
  fontSize: 12,
  lineHeight: 0.75,
  color: 'typography.lightText',
};

export const Value: Styles = {
  fontSize: 14,
  lineHeight: 1.5,
  color: 'typography.common',

  a: {
    display: 'block',
    color: 'typography.link',
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
