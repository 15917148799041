import { CSSProperties } from 'react';

import { Styles } from 'shared/types';
import { colors, theme } from 'shared/utils';

export const Container: Styles = {
  mt: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const FiltersContainer: Styles = {
  display: 'flex',
  gap: 4,
  alignItems: 'center',
};

export const TextField: Styles = {
  backgroundColor: colors.white,
  borderRadius: 2,
  width: 250,

  '.MuiOutlinedInput-root': {
    p: 1,
  },

  '.MuiInputBase-root:not(.Mui-focused):hover fieldset': {
    borderColor: colors.white,
  },

  input: {
    p: 0,
    fontSize: 14,
  },

  textarea: {
    fontSize: 14,
    lineHeight: '20px',
  },

  fieldset: {
    borderColor: colors.white,
  },

  '&& .Mui-focused fieldset': {
    borderWidth: 1,
  },
};

export const SearchIcon: Styles = {
  color: colors.blueberry,
  mr: 1,
};

export const ClearSearch: Styles = {
  ml: 1,
};

export const CloseIcon: Styles = {
  color: colors.slateGray,
  height: 16,
  width: 16,
};

export const DropdownFiltersButton: Styles = {
  display: 'flex',
  color: colors.navy,
};

export const FiltersLabel: Styles = {
  whiteSpace: 'pre',
};

export const ChosenFilters: Styles = {
  fontWeight: 600,
  maxWidth: 200,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const FilterIcon: Styles = {
  width: 24,
  height: 24,
  mr: 0.5,
  color: colors.slateGray,
};

export const SwitchesContainer: Styles = {
  display: 'flex',
  gap: 1,
};

export const CheckboxLabel: Styles = {
  mr: 3,
};

export const DropdownMenu: CSSProperties = {
  boxShadow: theme.shadows[3],
};

export const DropdownMenuList: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 2,
  padding: '8px',
};

export const DropdownMenuItem = ({ fullRow, selected }: { fullRow?: boolean; selected: boolean }): Styles => ({
  borderRadius: '4px',
  gridColumn: fullRow ? '1 / -1' : undefined,
  justifyContent: fullRow ? 'center' : undefined,
  backgroundColor: selected ? colors.brightGray : 'initial',
  '&:hover': {
    backgroundColor: colors.blueberry,
    color: colors.white,
  },
});
