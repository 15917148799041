import { toast } from 'react-toastify';

import { useAvailableSurveyTemplatesQuery } from 'documents/availableSurveyTemplates.generated';
import { useValidatedParams } from 'hooks';

export const useAvailableSurveyTemplates = (skip?: boolean) => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const {
    data,
    loading: areAvailableSurveyTemplatesLoading,
    previousData,
    refetch,
  } = useAvailableSurveyTemplatesQuery({
    variables: {
      employeeId,
    },
    skip,
    onError: (error) => toast.error(error.message),
  });

  return {
    areAvailableSurveyTemplatesLoading,
    department: data?.employee?.department,
    availableSurveyTemplates: data?.employee?.availableSurveyTemplatesCollection,
    prevAvailableSurveyTemplates: previousData?.employee?.availableSurveyTemplatesCollection,
    refetch,
  };
};
