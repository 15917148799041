import { usePublishedSurveyQuery } from 'documents/publishedSurvey.generated';
import { useSurveyTemplateQuery } from 'documents/surveyTemplate.generated';
import { Language, SurveyTemplate } from 'generated/webapp_gql';
import { useValidatedParams } from 'hooks';

import { ReadOnlySurvey, ReadOnlySurveyType } from './useSurvey.types';

export const useSurvey = () => {
  const { surveyId } = useValidatedParams(['surveyId']);
  const { surveyTemplateId } = useValidatedParams(['surveyTemplateId']);

  const { data: publishedSurvey, loading: isPublishedSurveyLoading } = usePublishedSurveyQuery({
    variables: {
      surveyId,
    },
    skip: !surveyId,
  });

  const { data: templateData, loading: isSurveyTemplateLoading } = useSurveyTemplateQuery({
    variables: {
      surveyTemplateId,
    },
    skip: !surveyTemplateId,
  });

  const survey: ReadOnlySurvey = {
    template: !!surveyTemplateId
      ? (templateData?.surveyTemplate as SurveyTemplate)
      : (publishedSurvey?.survey?.surveyTemplate as SurveyTemplate),
    templateId: !!surveyTemplateId ? templateData?.surveyTemplate?.id : publishedSurvey?.survey?.surveyTemplate?.id,
    surveyId: !!surveyId ? publishedSurvey?.survey?.id : undefined,
    status: !!surveyTemplateId ? undefined : publishedSurvey?.survey?.status,
    language:
      (!!surveyTemplateId
        ? templateData?.surveyTemplate?.language
        : publishedSurvey?.survey?.surveyTemplate?.language) || Language.Pl,
    closeDate: !!surveyTemplateId ? undefined : publishedSurvey?.survey?.closeDate,
    upcomingNotificationsDispatch: !!surveyTemplateId
      ? undefined
      : publishedSurvey?.survey?.upcomingNotificationsDispatch,
    isSurveyLoading: isPublishedSurveyLoading || isSurveyTemplateLoading,
    title: publishedSurvey?.survey?.title,
    answersCount: !!surveyTemplateId ? undefined : publishedSurvey?.survey?.respondedEmails?.length,
    type: !!surveyTemplateId ? ReadOnlySurveyType.PUBLISHED_TEMPLATE : ReadOnlySurveyType.PUBLISHED_SURVEY,
    slackNotification: publishedSurvey?.survey?.reminderDaysBeforeSurveyClose || 0,
    isSent: publishedSurvey?.survey?.isSent || false,
    isPublished: !surveyTemplateId,
    surveyCreatorFullName: !!publishedSurvey?.survey?.owner
      ? publishedSurvey.survey.owner.fullName
      : publishedSurvey?.survey?.surveyTemplate?.creator?.fullName,
    surveyCreatorAvatarUrl: !!publishedSurvey?.survey?.owner
      ? publishedSurvey.survey?.owner?.avatarUrl ?? undefined
      : publishedSurvey?.survey?.surveyTemplate?.creator?.avatarUrl ?? undefined,
  };

  return survey;
};
