import { Autocomplete as AutocompleteBase, Box, Chip } from '@mui/material';
import { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificatorType, SurveyReceiversCollection, SurveyResponseStatus } from 'generated/webapp_gql';
import { useSurveyReceivers } from 'hooks/useSurveyReceivers/useSurveyReceivers';
import { Loader } from 'ui';
import { SurveyReceiver } from '../SurveyPublicationModal.types';

import * as Styles from './Autocomplete.styles';
import { AutocompleteProps } from './Autocomplete.types';
import { AutocompleteInput, EXTERNAL_EMAIL_ID } from './AutocompleteInput/AutocompleteInput';
import { AutocompleteListbox } from './AutocompleteListbox/AutocompleteListbox';
import { AutocompleteOptions } from './AutocompleteOptions/AutocompleteOptions';
import { AutocompletePopper } from './AutocompletePopper/AutocompletePopper';

export const Autocomplete = ({ setCurrentReceivers, currentReceivers, sendMethod, surveyId }: AutocompleteProps) => {
  const { t } = useTranslation();
  const scrollPositionRef = useRef<number>(0);

  const { getSurveyReceiversBySearchValue, surveyReceiversData, surveyReceiversLoading } = useSurveyReceivers();

  const handleAutocompleteChange = (receivers: SurveyReceiver[]) => {
    setCurrentReceivers(receivers);
  };

  const getNoOptionsText = () => {
    if (!surveyReceiversData) {
      return 'surveyBuilder.surveyPublicationModal.startTypingToSearch';
    }

    if (sendMethod === NotificatorType.Email && surveyReceiversData) {
      return 'surveyBuilder.surveyPublicationModal.noResultsEmail';
    }

    return 'surveyBuilder.surveyPublicationModal.noResults';
  };

  return (
    <Box sx={Styles.AutocompleteContainer}>
      <AutocompleteBase
        open
        multiple
        fullWidth
        disablePortal={true}
        forcePopupIcon={false}
        sx={Styles.UsersListContainer}
        filterOptions={(options) => options}
        options={surveyReceiversData?.items?.map((item) => ({ ...item, notificator: [sendMethod] })) || []}
        value={currentReceivers}
        onChange={(_, receivers) => {
          handleAutocompleteChange(receivers as SurveyReceiver[]);
        }}
        renderInput={(params) => (
          <AutocompleteInput
            params={params}
            setCurrentReceivers={setCurrentReceivers}
            currentReceivers={currentReceivers}
            getSurveyReceivers={getSurveyReceiversBySearchValue}
            surveyReceiversData={surveyReceiversData as SurveyReceiversCollection}
            sendMethod={sendMethod}
            surveyId={surveyId}
          />
        )}
        noOptionsText={surveyReceiversLoading ? <Loader /> : t(getNoOptionsText())}
        PopperComponent={(props) => <AutocompletePopper {...props} />}
        ListboxProps={{
          onScroll: (e) => {
            scrollPositionRef.current = e.currentTarget.scrollTop;
          },
        }}
        ListboxComponent={forwardRef((props, ref) => (
          <AutocompleteListbox
            {...props}
            forwardedRef={ref}
            setCurrentReceivers={setCurrentReceivers}
            currentReceivers={currentReceivers}
            sendMethod={sendMethod}
            surveyReceiversData={surveyReceiversData as SurveyReceiversCollection}
            scrollPosition={scrollPositionRef.current}
          />
        ))}
        renderOption={(props, option, { selected }) =>
          option &&
          !!option.id && <AutocompleteOptions {...props} option={option as SurveyReceiver} selected={selected} />
        }
        getOptionDisabled={(option) =>
          !!(
            option?.receivedSurveyStatus &&
            [
              SurveyResponseStatus.Sent,
              SurveyResponseStatus.Seen,
              SurveyResponseStatus.Answered,
              SurveyResponseStatus.Sending,
              SurveyResponseStatus.Scheduled,
            ].includes(option?.receivedSurveyStatus)
          )
        }
        getOptionLabel={(option) => `${option?.email}`}
        placeholder={t('surveyBuilder.surveyPublicationModal.search')}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              label={option?.email}
              color={option?.id !== EXTERNAL_EMAIL_ID ? 'default' : 'warning'}
            />
          ))
        }
        isOptionEqualToValue={(a, b) => a?.email === b?.email}
      />
    </Box>
  );
};
