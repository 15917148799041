import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

const Divider: Styles = {
  position: 'absolute',
  content: "''",
  width: 'calc(100% + 8px)',
  height: 2,
  left: 0,
  backgroundImage: `linear-gradient(to right, ${colors.slateGray} 50%, transparent 0%)`,
  backgroundPosition: 'bottom',
  backgroundSize: '16px 1px',
  backgroundRepeat: 'repeat-x',
};

export const Container = (isIntro: boolean): Styles => {
  if (isIntro) {
    return {
      mb: 5,

      '&:after': {
        ...Divider,
        mt: 4,
      },
    };
  }

  return {
    mt: 5,

    '&:before': {
      ...Divider,
      mt: -5,
    },
  };
};

export const TopSection: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const Children: Styles = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid rgba(35, 41, 75, 0.23)`,
  borderRadius: `0px 4px 4px 4px`,
};

export const Label: Styles = {
  backgroundColor: 'navy',
  color: 'white',
  borderRadius: '4px 4px 0 0',
  fontWeight: 700,
  px: 2,
  py: 1.5,
};

export const UploadedImage: Styles = {
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'block',
  margin: '0 auto',
  pt: 5,
  pb: 4,
};

export const SectionDescription: Styles = {
  textAlign: 'start',
  p: 2,
};
