import { SortOrder } from '../../../generated/webapp_gql';

import { SortSelectItem } from './SurveyTabs.types';

export const TemplateSortingOptions: SortSelectItem[] = [
  {
    label: 'surveysList.sort.fromNewest',
    value: `createdAt:${SortOrder.Desc}`,
  },
  {
    label: 'surveysList.sort.fromLatest',
    value: `createdAt:${SortOrder.Asc}`,
  },
  {
    label: 'surveysList.sort.nameAZ',
    value: `title:${SortOrder.Asc}`,
  },
  {
    label: 'surveysList.sort.nameZA',
    value: `title:${SortOrder.Desc}`,
  },
];

export const SurveySortingOptions: SortSelectItem[] = [
  {
    label: 'surveysList.sort.fromNewest',
    value: `latestSendDate:${SortOrder.Desc}`,
  },
  {
    label: 'surveysList.sort.fromLatest',
    value: `latestSendDate:${SortOrder.Asc}`,
  },
  {
    label: 'surveysList.sort.nameAZ',
    value: `title:${SortOrder.Asc}`,
  },
  {
    label: 'surveysList.sort.nameZA',
    value: `title:${SortOrder.Desc}`,
  },
];
