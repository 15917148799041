import { autocompleteClasses } from '@mui/material';

import { Styles } from 'shared/types';

export const Tabs: Styles = {
  my: 3,
};

export const SendButtonWrapper: Styles = {
  marginTop: 1.5,
};

export const Dialog: Styles = {
  py: '32px',
};

export const Paper: Styles = {
  py: 3,
  px: 4,
  flex: 1,
  display: 'flex',
  height: '100%',
  maxHeight: '800px',
  overflow: 'hidden',
  width: '100%',
  maxWidth: '1024px',

  [`.${autocompleteClasses.root}`]: {
    overflow: 'auto',
    flex: 1,
    mb: 2,
  },

  [`.${autocompleteClasses.listbox}`]: {
    height: '100%',
    maxHeight: 'unset',
    pb: 0,
  },

  [`.${autocompleteClasses.popper}`]: {
    position: 'static !important',
    height: 'auto !important',
    transform: 'unset !important',
    overflow: 'auto',
    maxHeight: 'unset',
  },
};

export const ActionsWrapper: Styles = {
  mt: 2,
  backgroundColor: 'background.paper',
  height: 'fit-content',
};
