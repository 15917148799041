import { Box, Typography } from '@mui/material';

import { ErrorInfoProps } from './ErrorInfo.types';
import * as Styles from './ErrorInfo.styles';

export const ErrorInfo = ({ content, icon, textColor = 'default' }: ErrorInfoProps) => {
  return (
    <Box sx={Styles.Wrapper}>
      {icon}
      <Typography color={textColor} component="span" fontSize="inherit">
        {content}
      </Typography>
    </Box>
  );
};
