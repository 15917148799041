import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Action, WeeklyFeedback } from 'generated/webapp_gql';
import { useRedirectToEmployeeDetails } from 'hooks';
import { Toast } from 'ui';
import { useUpdateWeeklyFeedbackMutation } from 'documents/updateWeeklyFeedback.generated';
import { UpdateFeedbacksList } from 'hooks/utils/updateFeedbacksList';

export const useUpdateWeeklyFeedback = (employeeId: number) => {
  const { t } = useTranslation();
  const { redirectToEmployeeDetails } = useRedirectToEmployeeDetails();

  const [updateWeeklyFeedback, { loading: isUpdateWeeklyFeedbackLoading }] = useUpdateWeeklyFeedbackMutation({
    update: (cache, { data }) => {
      new UpdateFeedbacksList(
        cache,
        data?.updateWeeklyFeedback as WeeklyFeedback,
        employeeId,
        data?.createAction as Action[],
      ).onUpdate();
    },
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(
        <Toast title={t('weeklyForm.toast.weeklyReportHeading')} message={t('weeklyForm.toast.weeklyEdited')} />,
      );
      redirectToEmployeeDetails(employeeId);
    },
  });

  return { updateWeeklyFeedback, isUpdateWeeklyFeedbackLoading };
};
