import { Styles } from 'shared/types';
import { ActionStatus, GoalStatus } from 'generated/webapp_gql';

export const Label: Styles = {
  '& label': {
    fontSize: 14,
    fontWeight: 500,
  },
};

export const Wrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
};

export const Checkbox = (type: ActionStatus | GoalStatus): Styles => ({
  path: {
    fill: ({ palette }) =>
      type === ActionStatus.Canceled || type === GoalStatus.Canceled
        ? `${palette.text.secondary}`
        : `${palette.common.black}`,
    opacity: type === ActionStatus.Done || type === GoalStatus.Done ? 0.2 : 1,
  },
});
