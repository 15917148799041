import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LocalPagination } from 'ui';
import * as Styles from '../SurveyAnswers.styles';

import { AnswersPerRespondent, SurveyAnswersRespondentsProps } from './SurveyAnswersRespondents.types';

export const SurveyAnswersRespondents = ({ questionsData, respondentEmail }: SurveyAnswersRespondentsProps) => {
  const { t } = useTranslation();
  const respondents = useMemo(
    () =>
      questionsData.reduce<AnswersPerRespondent>((prev, curr) => {
        const prevCopy = { ...prev };

        curr.answers.forEach((a) => {
          if (!a) {
            return;
          }

          const { answer, email } = a;

          prevCopy[email] = [
            ...(prevCopy[email] ?? []),
            {
              answer,
              format: curr.format,
              question: curr.question,
            },
          ];
        });

        return prevCopy;
      }, {}),
    [questionsData],
  );

  const respondentsEmails = useMemo(() => Object.keys(respondents), [respondents]);

  const initialEmail =
    respondentEmail && respondentsEmails.includes(respondentEmail) ? respondentEmail : respondentsEmails[0];

  const [selectedRespondent, setSelectedRespondent] = useState(initialEmail);
  const [page, setPage] = useState(respondentsEmails.indexOf(initialEmail) + 1);

  const changeRespondentByName = (newName: string | null) => {
    if (!newName) {
      return;
    }

    setSelectedRespondent(newName);
    setPage(respondentsEmails.indexOf(newName) + 1);
  };

  const changeRespondentByPage = (newPage: number) => {
    setSelectedRespondent(respondentsEmails[newPage - 1]);
    setPage(newPage);
  };

  return (
    <Box>
      <Box sx={Styles.ListWrapper}>
        <Autocomplete
          options={respondentsEmails}
          value={selectedRespondent}
          fullWidth
          onChange={(_, value) => {
            value && changeRespondentByName(value);
          }}
          renderInput={(params) => (
            <TextField {...params} label={t('publishedSurvey.answersTab.respondentsTab.respondentLabel')} />
          )}
        />
        <LocalPagination count={respondentsEmails.length} page={page} onChange={changeRespondentByPage} />
      </Box>
      <Box sx={Styles.ScrollContainer}>
        {respondents[selectedRespondent].map(({ question, answer }, index) => (
          <Box sx={Styles.QuestionWrapper} key={`${selectedRespondent}_${index}`}>
            <Typography variant="h6">{`${index + 1}. ${question}`}</Typography>
            <Box>
              <Box sx={Styles.Answers}>
                <Typography variant="body1" sx={Styles.AnswerTypography}>
                  {answer.map((a, index) => (
                    <span key={index}>{a}</span>
                  ))}
                </Typography>
                <Typography variant="body2" sx={Styles.EmailTypography}>
                  {selectedRespondent}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
