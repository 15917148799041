import { Warning } from '@mui/icons-material';
import { Alert, Box, Button, Paper, Tab, Tooltip } from '@mui/material';
import { addWeeks } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { PreviewSurvey } from 'app/previewSurvey/PreviewSurvey';
import { SurveyBuilderPublicationContainer } from 'app/surveyBuilder/surveyBuilderPublicationContainer/SurveyBuilderPublicationContainer';
import { SurveyStatus, SurveyTemplateStatus } from 'generated/webapp_gql';
import { useSurvey, useValidatedParams } from 'hooks';
import { useFetchSurveyEndImage } from 'hooks/useFetchSurveyEndImage/useFetchSurveyEndImage';
import { AppRoute } from 'routing/AppRoute.enum';
import { BackButton, ClosePreviewButton, Loader, TabPanel, Tabs, Title } from 'ui';
import { SurveyAnswers } from '../surveyAnswers/SurveyAnswers';
import { SurveyQuestions } from '../surveyQuestions/SurveyQuestions';
import { SurveyViewController } from 'context/surveyView/SurveyViewController';
import { createEnumChecker } from 'shared/utils/enum-checker';
import { isString } from 'shared/utils/isString';

import { PublishedSurveyTab } from './SurveyLayout.enum';
import * as Styles from './SurveyLayout.styles';
import { CreateSurveyForm } from './SurveyLayout.types';
import { formatPreviewSurveyData, isPublishedSurvey } from './SurveyLayout.utils';

export const SurveyLayout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useValidatedParams(['employeeId']);
  const location = useLocation();
  const fetchSurveyEndImage = useFetchSurveyEndImage();
  const [params] = useSearchParams();

  const searchParamTab = params.get('tab');
  const isValidTabParam = isString(searchParamTab) && createEnumChecker(PublishedSurveyTab)(searchParamTab);

  const [currentTab, setCurrentTab] = useState(isValidTabParam ? searchParamTab : PublishedSurveyTab.Questions);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isSurveyClosed, setIsSurveyClosed] = useState<boolean | null>(null);

  const {
    isSurveyLoading,
    type,
    status,
    templateId,
    surveyId,
    answersCount,
    closeDate,
    template,
    title,
    upcomingNotificationsDispatch,
    slackNotification,
    isSent,
    isPublished,
  } = useSurvey();

  const editTemplate = (surveyTemplateId: string, surveyId: string) => (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    navigate(`/${AppRoute.SurveyEditor}/employee/${employeeId}/survey-template/${surveyTemplateId}/survey/${surveyId}`);
  };

  const getRightTabButton = ({
    setIsPreviewVisible,
    surveyTemplateStatus,
    currentTab,
    isPreviewVisible,
    templateId,
    surveyId,
  }: {
    currentTab: PublishedSurveyTab;
    isPreviewVisible: boolean;
    setIsPreviewVisible: (arg: boolean) => void;
    surveyTemplateStatus?: SurveyTemplateStatus;
    templateId: string;
    surveyId: string;
  }) => {
    if (surveyTemplateStatus === SurveyTemplateStatus.Archived) {
      return (
        <Alert severity="warning" icon={<Warning fontSize="inherit" />} sx={Styles.ReadOnlyAlert}>
          {t('surveyBuilder.questions.section.archived')}
        </Alert>
      );
    }

    if (currentTab === PublishedSurveyTab.Publication && isPreviewVisible) {
      return <ClosePreviewButton sx={Styles.ClosePreviewButton} onClick={() => setIsPreviewVisible(false)} />;
    }

    return surveyId ? (
      <Button onClick={editTemplate(templateId, surveyId)} variant="text" sx={Styles.EditSurveyButton}>
        <Tooltip title={t('surveyEditor.editSurvey.tooltip')} arrow placement="bottom">
          <Alert severity="warning" icon={<Warning fontSize="inherit" />} sx={Styles.ReadOnlyAlert}>
            {t('surveyEditor.editSurvey.button')}
          </Alert>
        </Tooltip>
      </Button>
    ) : (
      <Alert severity="warning" icon={<Warning fontSize="inherit" />} sx={Styles.ReadOnlyAlert}>
        {t('surveyBuilder.questions.section.readOnly')}
      </Alert>
    );
  };

  const changeSurveySwitchState = (state: boolean) => {
    setIsSurveyClosed(state);
  };

  const surveyPublicationForm = useForm<CreateSurveyForm>({
    defaultValues: {
      receivers: [],
      closeDate: addWeeks(new Date(), 2),
    },
  });

  useEffect(() => {
    if (isPublishedSurvey(type) && !isSurveyLoading) {
      changeSurveySwitchState(status !== SurveyStatus.Opened);
    }
  }, [isSurveyLoading, type, status]);

  useEffect(() => {
    if (!templateId) {
      return;
    }

    const { closeDate: defaultCloseDate } = surveyPublicationForm.getValues();

    surveyPublicationForm.reset({
      closeDate: closeDate ? new Date(closeDate) : defaultCloseDate,
      sendDate: upcomingNotificationsDispatch ? new Date(upcomingNotificationsDispatch) : null,
      surveyTemplateId: templateId,
      receivers: [],
      slackNotification,
    });
  }, [isSurveyLoading, surveyPublicationForm, closeDate, templateId, upcomingNotificationsDispatch, slackNotification]);

  const handleChangeTab = (tab: PublishedSurveyTab) => {
    setIsPreviewVisible(false);
    setCurrentTab(tab);
  };

  const goToPublicationPage = () => {
    setCurrentTab(PublishedSurveyTab.Publication);
  };

  const rightTabButton = getRightTabButton({
    currentTab,
    isPreviewVisible,
    setIsPreviewVisible,
    surveyTemplateStatus: template?.status,
    templateId: templateId || '',
    surveyId: surveyId || '',
  });

  const handleBack = () => {
    const { backRoute } = location.state ? (location.state as { backRoute?: string }) : { backRoute: '' };

    if (isPreviewVisible) {
      return setIsPreviewVisible(false);
    }

    if (backRoute) {
      return navigate(backRoute);
    }
    return navigate(`/${AppRoute.EmployeeDetails}/${employeeId}`);
  };

  if (isSurveyLoading) {
    return <Loader />;
  }

  return (
    <SurveyViewController isEditInputOnly={false}>
      <Paper sx={Styles.Container}>
        <Title
          button={<BackButton sx={Styles.BackButton} onClick={handleBack} />}
          rightButton={currentTab === PublishedSurveyTab.Questions && rightTabButton}
          sx={Styles.Title}
        >
          <Tabs value={currentTab} onChange={(_, value) => handleChangeTab(value)}>
            <Tab
              label={t('surveyBuilder.tabs.questions')}
              value={PublishedSurveyTab.Questions}
              key={'surveyBuilder.tabs.questions'}
            />
            {isPublishedSurvey(type) && (
              <Tab
                label={t('surveyBuilder.tabs.answers', { count: answersCount ?? 0 })}
                value={PublishedSurveyTab.Answers}
                key={'surveyBuilder.tabs.answers'}
              />
            )}

            {template?.status !== SurveyTemplateStatus.Archived && (
              <Tab
                label={t('surveyBuilder.tabs.settings')}
                value={PublishedSurveyTab.Publication}
                key={'surveyBuilder.tabs.settings'}
              />
            )}
          </Tabs>
        </Title>

        <Box sx={Styles.SurveyBuilderContainer}>
          <TabPanel value={currentTab} index={PublishedSurveyTab.Questions} key={'surveyBuilder.tabs.questions'}>
            <SurveyQuestions
              isLoading={isSurveyLoading}
              surveyTemplate={template || undefined}
              title={title}
              surveyType={type}
              goToPublicationPage={goToPublicationPage}
            />
          </TabPanel>
          {isPublishedSurvey(type) && (
            <TabPanel value={currentTab} index={PublishedSurveyTab.Answers} key={'surveyBuilder.tabs.answers'}>
              <SurveyAnswers />
            </TabPanel>
          )}
          <TabPanel value={currentTab} index={PublishedSurveyTab.Publication} key={'surveyBuilder.tabs.settings'}>
            {!isPreviewVisible && (
              <FormProvider {...surveyPublicationForm}>
                <SurveyBuilderPublicationContainer
                  openPreview={() => setIsPreviewVisible(true)}
                  changeSurveySwitchState={changeSurveySwitchState}
                  isClosed={!!isSurveyClosed}
                  isSent={isSent}
                  isPublished={isPublished}
                />
              </FormProvider>
            )}
            {isPreviewVisible && (
              <PreviewSurvey
                survey={formatPreviewSurveyData({ title: title || '', surveyTemplate: template })}
                onSubmit={fetchSurveyEndImage}
              />
            )}
          </TabPanel>
        </Box>
      </Paper>
    </SurveyViewController>
  );
};
