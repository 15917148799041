import { alpha } from '@mui/material';

import { Styles } from 'shared/types';
import { colors, theme } from 'shared/utils';

export const ButtonWrapper: Styles = {
  width: 192,
  height: 138,
  boxSizing: 'border-box',
  gap: 0.5,
  p: 0.5,
  mx: 0,
  border: `1px solid ${alpha(theme.palette.border.light, 0.26)}`,
  borderRadius: 1,
  backgroundColor: 'transparent',

  '&:hover': {
    backgroundColor: colors.ghostWhite,
    borderColor: 'primary.main',

    '& .MuiRadio-root': {
      backgroundColor: 'zumthorBlue',
    },
  },
};

export const ButtonCheckedWrapper: Styles = {
  ...ButtonWrapper,
  backgroundColor: colors.ghostWhite,
  borderColor: 'primary.main',

  '&& .MuiRadio-root': {
    backgroundColor: 'transparent',
  },
};
