import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRedirectToEmployeeDetails } from 'hooks';
import { Toast } from 'ui';
import { EmployeeDetailsViewParam } from 'routing/AppRoute.enum';
import { useUpdateQuarterlyReportMutation } from 'documents/updateQuarterlyReport.generated';
import { UpdateReportsList } from 'hooks/utils/updateReportsList';
import { Goal, QuarterlyReport } from 'generated/webapp_gql';
import { getRefetchOptionsForEmployee } from '../../shared/utils/getRefetchOptionsForEmployee';

export const useUpdateQuarterlyReport = (employeeId: number) => {
  const { t } = useTranslation();
  const { redirectToEmployeeDetails } = useRedirectToEmployeeDetails();

  const [updateQuarterlyReport, { loading: isUpdateQuarterlyReportLoading }] = useUpdateQuarterlyReportMutation({
    refetchQueries: [getRefetchOptionsForEmployee(employeeId)],
    update: (cache, { data }) => {
      new UpdateReportsList(
        cache,
        data?.updateQuarterlyReport as QuarterlyReport,
        employeeId,
        data?.createGoal as Goal[],
      ).onUpdate();
    },
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(
        <Toast
          title={t('quarterlyForm.toast.quarterlyReportHeading')}
          message={t('quarterlyForm.toast.quarterlyEdited')}
        />,
      );
      redirectToEmployeeDetails(employeeId, EmployeeDetailsViewParam.Quarterly);
    },
  });

  return { updateQuarterlyReport, isUpdateQuarterlyReportLoading };
};
