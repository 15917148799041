import { Alert, AlertProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

export const FilledIconAlert = (props: AlertProps) => (
  <Alert
    iconMapping={{
      error: <ErrorIcon />,
      warning: <WarningIcon />,
      success: <CheckCircleIcon />,
      info: <InfoIcon />,
    }}
    {...props}
  />
);
