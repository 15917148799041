import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const ImageIcon: Styles = {
  width: 132,
  height: 132,
  color: colors.hawkesBlue,
};

export const PlaceholderText: Styles = {
  lineHeight: '38px',
};
