import { Styles } from 'shared/types';
import { colors, theme } from 'shared/utils';

export const UploadedImageWRapper: Styles = {
  width: 'fit-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 600,
};

export const IconPositioner: Styles = {
  position: 'relative',
  zIndex: 1000,
};

export const UploadedImage: Styles = {
  maxWidth: '100%',
  maxHeight: '100%',
  display: 'block',
};

export const ImageMenuButton: Styles = {
  position: 'absolute',
  top: -20,
  right: -20,
  borderRadius: 20,
  width: 40,
  height: 40,
  boxShadow: theme.shadows[4],

  '&&': { backgroundColor: colors.white },
};

export const ImageMenuButtonIcon: Styles = {
  color: colors.navy,
};

export const CropIcon: Styles = {
  mr: 1,
  width: 24,
  height: 24,
};

export const TrashIcon = {
  marginRight: 8,
  width: 24,
  height: 24,
};

export const DeletePhoto: Styles = {
  color: colors.tomato,
};
