import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishedSurveyEndImageQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID']['input'];
}>;


export type PublishedSurveyEndImageQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', id: string, surveyTemplate?: { __typename?: 'SurveyTemplate', id: string, endImageUrl?: string | null } | null } | null };


export const PublishedSurveyEndImageDocument = gql`
    query PublishedSurveyEndImage($surveyId: ID!) {
  survey(id: $surveyId) {
    id
    surveyTemplate {
      id
      endImageUrl
    }
  }
}
    `;

/**
 * __usePublishedSurveyEndImageQuery__
 *
 * To run a query within a React component, call `usePublishedSurveyEndImageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedSurveyEndImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedSurveyEndImageQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function usePublishedSurveyEndImageQuery(baseOptions: Apollo.QueryHookOptions<PublishedSurveyEndImageQuery, PublishedSurveyEndImageQueryVariables> & ({ variables: PublishedSurveyEndImageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishedSurveyEndImageQuery, PublishedSurveyEndImageQueryVariables>(PublishedSurveyEndImageDocument, options);
      }
export function usePublishedSurveyEndImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishedSurveyEndImageQuery, PublishedSurveyEndImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishedSurveyEndImageQuery, PublishedSurveyEndImageQueryVariables>(PublishedSurveyEndImageDocument, options);
        }
export function usePublishedSurveyEndImageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublishedSurveyEndImageQuery, PublishedSurveyEndImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublishedSurveyEndImageQuery, PublishedSurveyEndImageQueryVariables>(PublishedSurveyEndImageDocument, options);
        }
export type PublishedSurveyEndImageQueryHookResult = ReturnType<typeof usePublishedSurveyEndImageQuery>;
export type PublishedSurveyEndImageLazyQueryHookResult = ReturnType<typeof usePublishedSurveyEndImageLazyQuery>;
export type PublishedSurveyEndImageSuspenseQueryHookResult = ReturnType<typeof usePublishedSurveyEndImageSuspenseQuery>;
export type PublishedSurveyEndImageQueryResult = Apollo.QueryResult<PublishedSurveyEndImageQuery, PublishedSurveyEndImageQueryVariables>;