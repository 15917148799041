import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, useMemo } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyQuestionFormat } from 'generated/webapp_gql';
import { TextField } from 'ui';
import { Slider } from 'ui/slider/Slider';
import { PreviewSurveyFormType } from '../PreviewSurveyQuestions.types';

import * as Styles from './SurveyQuestion.styles';
import { SliderValueAnswer, SurveyQuestionProps } from './SurveyQuestion.types';

export const SurveyQuestion = ({ question, questionIndex, sectionIndex }: SurveyQuestionProps) => {
  const { setValue, getValues, trigger, formState } = useFormContext<PreviewSurveyFormType>();
  const { t } = useTranslation();

  const filteredAnswers = useMemo(
    () => (question.answers as { value: string }[])?.filter((answer) => !!answer?.value?.length),
    [question.answers],
  );

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    value: string | string[],
    name: keyof PreviewSurveyFormType,
    setValue: ControllerRenderProps['onChange'],
    onChange: ControllerRenderProps['onChange'],
  ) => {
    let newValue: typeof value;

    if (typeof value === 'undefined') {
      setValue(name, [e.target.value]);
      return trigger(name);
    }

    if (e.target.checked) {
      newValue = [...(Array.isArray(value) ? value : [value]), e.target.value];
    } else {
      newValue = [...(Array.isArray(value) ? value : [value])].filter((v) => v !== e.target.value);
    }

    setValue(name, newValue);
    trigger(name);
    onChange(newValue);
  };

  const name = `${sectionIndex}_${questionIndex}` as const;

  const errorMessage = formState.errors[name]?.message || '';

  return (
    <Box sx={Styles.Container} key={name}>
      <Typography sx={Styles.Question}>{question.question}</Typography>
      {question.format === SurveyQuestionFormat.OpenEnded && (
        <Controller
          name={name}
          rules={{ required: t('surveyPreview.sectionPreview.answerRequired') }}
          render={({ field: { onChange, value } }) => (
            <TextField
              placeholder={t('surveyPreview.questionPreview.inputPlaceholder')}
              sx={Styles.UserAnswer}
              name={name}
              onChange={onChange}
              value={value}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          )}
        />
      )}
      {question.format === SurveyQuestionFormat.CloseEnded && (
        <Controller
          name={name}
          rules={{ required: t('surveyPreview.sectionPreview.answerRequired') }}
          render={({ field: { onChange, ref, value } }) => (
            <RadioGroup name={`question-close-ended-${questionIndex}`} sx={Styles.SelectWrapper} value={value || null}>
              {filteredAnswers.map((answer, index) => (
                <FormControlLabel
                  key={`close-ended-${questionIndex}-${index}`}
                  value={answer.value}
                  label={answer.value}
                  sx={Styles.SelectLabel(value === answer.value)}
                  control={
                    <Radio name={name} onChange={onChange} ref={ref} value={answer.value} sx={Styles.SelectInput} />
                  }
                />
              ))}
              {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
            </RadioGroup>
          )}
        />
      )}
      {question.format === SurveyQuestionFormat.MultipleChoice && (
        <FormGroup sx={Styles.SelectWrapper}>
          <Controller
            name={name}
            rules={{
              validate: (value) => !!value?.length || t('surveyPreview.sectionPreview.answerRequired'),
            }}
            render={({ field: { ref, value, onChange } }) => (
              <>
                {filteredAnswers.map((answer, index) => (
                  <FormControlLabel
                    key={index}
                    value={answer.value}
                    label={answer.value}
                    sx={Styles.SelectLabel(!!getValues(name)?.includes(answer.value))}
                    control={
                      <Checkbox
                        sx={Styles.SelectInput}
                        name={name}
                        checked={!!getValues(name)?.includes(answer.value)}
                        onChange={(e) => {
                          handleCheckboxChange(e, value, name, setValue, onChange);
                        }}
                        ref={ref}
                        value={answer.value}
                      />
                    }
                  />
                ))}
                {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
              </>
            )}
          />
        </FormGroup>
      )}
      {question.format === SurveyQuestionFormat.LinearScale && (
        <Controller
          name={name}
          defaultValue={Number(question.answers[0].value)}
          render={({ field: { onChange, value: fieldValue } }) => {
            const [minAnswer, maxAnswer] = question.answers as SliderValueAnswer[];
            return (
              <Slider
                onChange={onChange}
                value={fieldValue}
                name={name}
                min={Number(minAnswer.value) || undefined}
                minLabel={minAnswer.label}
                max={Number(maxAnswer.value) || undefined}
                maxLabel={maxAnswer.label}
              />
            );
          }}
        />
      )}
    </Box>
  );
};
