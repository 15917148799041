export const useValidateObjectByKeys = <T extends Record<string, string | number>>(
  objectToValidate: T,
  keysToValidate?: (keyof T)[],
): Partial<T> | T => {
  if (!keysToValidate) {
    return objectToValidate as Partial<T>;
  }

  if (!keysToValidate.every((keyToValidate) => !!objectToValidate[keyToValidate])) {
    return {} as never;
  }

  return objectToValidate as T;
};
