import { Styles } from 'shared/types';

export const AccordionWrapper: Styles = {
  position: 'relative',
};

export const AccordionSummary: Styles = {
  justifyContent: 'flex-start',

  '> div': {
    flexGrow: 0,
  },
};

export const Actions: Styles = {
  position: 'absolute',
  zIndex: 1,
  top: 6,
  right: 0,
};

export const Title: Styles = {
  fontSize: 20,
  fontWeight: 500,
  mr: 1,
};

export const ArrowDownIcon: Styles = {
  color: 'text.primary',
};
