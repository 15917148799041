import { Styles } from 'shared/types';
import { theme } from 'shared/utils';

export const Menu: Styles = {
  mt: 2,
  minWidth: 176,
  boxShadow: theme.shadows[3],

  '& .MuiMenuItem-root': {
    py: 1,
  },
};

export const Backdrop: Styles = {
  backgroundColor: 'transparent',
};
