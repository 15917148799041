import { Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getChipProps } from './SatisfactionLevel.utils';
import { SatisfactionLevelChipProps } from './SatisfactionLevelChip.types';
import * as Styles from './SatisfactionLevelChip.styles';

export const SatisfactionLevelChip = ({ value }: SatisfactionLevelChipProps) => {
  const chipProps = getChipProps(value);
  const { t } = useTranslation();

  return (
    chipProps && (
      <Tooltip
        PopperProps={{
          sx: Styles.SatisfactionTooltip,
        }}
        title={t('employeeData.satisfactionLevelTooltip')}
        arrow
        placement="right"
      >
        <Chip label={t(chipProps.label)} size="small" color={chipProps.color} />
      </Tooltip>
    )
  );
};
