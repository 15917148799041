import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Accordion } from '../../../../ui';
import { AppRoute } from '../../../../routing/AppRoute.enum';
import { goalsTabsNames } from '../../../employeeGoals/EmployeeGoals.utils';
import { goalsTabsTranslations } from '../../employeeDetailsQuarterly/goalsTabs/GoalsTabs.utils';
import { GoalStatus } from '../../../../generated/webapp_gql';

import { GoalsList } from './GoalsList';
import { useGoals } from './useGoals';
import * as Styles from './GoalsSection.styles';
import { GoalsSectionProps } from './types';

export const GoalsSection = ({ goalsData, employeeId }: GoalsSectionProps) => {
  const { t } = useTranslation();

  const { getCount, getGoals, totalCount } = useGoals(goalsData);

  const getSection = (type: GoalStatus) => {
    if (getCount(type) === 0) return null;

    return (
      <Box key={type} sx={Styles.Container}>
        <Box sx={Styles.Heading}>{t(goalsTabsTranslations[type], { count: getCount(type) })}</Box>

        <GoalsList goals={getGoals(type)} />
      </Box>
    );
  };

  if (totalCount === 0) return null;

  return (
    <Box sx={Styles.EmployeeGoalSection}>
      <Accordion
        title={t('employeeSection.sectionGoals')}
        action={
          <Link to={`/${AppRoute.EmployeeGoals}/${employeeId}`} target="_blank">
            <Button variant="text" size="small" sx={Styles.RedirectButton}>
              {t('actionAccordion.btnLabel')}
            </Button>
          </Link>
        }
        content={<>{goalsTabsNames.map((type) => getSection(type))}</>}
      />
    </Box>
  );
};
