import { Typography } from '@mui/material';

import { PageTitleProps } from './PageTitle.types';
import * as Styles from './PageTitle.styles';

export const PageTitle = ({ title }: PageTitleProps) => (
  <Typography variant="h4" sx={Styles.Title}>
    {title}
  </Typography>
);
