import { Action, ActionStatus, Maybe } from 'generated/webapp_gql';

import type { ActionWithContent } from './employeesTableColumns.types';

export const getNumberOfActionsInProgress = (actions: Partial<Maybe<Action>>[] | null | undefined): number =>
  actions?.filter((action) => action?.actionStatus === ActionStatus.InProgress).length ?? 0;

export const isActionWithContent = (action: Maybe<Action>): action is ActionWithContent => {
  return Boolean(action && action.content);
};
