import { PersonOutlined } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSurveyAnswersSummary } from 'hooks';
import { Loader } from 'ui';

import { SurveyReceiversModal } from './surveyReceiversModal/SurveyReceiversModal';
import * as Styles from './SurveyAnswers.styles';
import { SurveyAnswersButton, SurveyAnswersButtonValues } from './SurveyAnswers.utils';
import { SurveyAnswersTabs } from './surveyAnswersTabs/SurveyAnswersTabs';
import { ExportCsvButton } from './exportCsvButton/ExportCsvButton';

export const SurveyAnswers = () => {
  const { t } = useTranslation();
  const [isReceiversModalOpen, setIsReceiversModalOpen] = useState(false);
  const [tab, setTab] = useState<SurveyAnswersButtonValues>(SurveyAnswersButton.Summary);

  const closeReceiversModal = () => setIsReceiversModalOpen(false);

  const changeTab = (newTab: SurveyAnswersButtonValues) => setTab(newTab);

  const { surveyAnswersSummaryData, surveyCountsSummaryData, isSurveyAnswersSummaryLoading } =
    useSurveyAnswersSummary();

  if (isSurveyAnswersSummaryLoading) {
    return <Loader />;
  }

  const hasRespondents = surveyCountsSummaryData.respondedEmails?.length;

  return (
    <Box>
      <Box sx={Styles.UtilityButtonGroup}>
        <Tooltip
          title={!hasRespondents ? t('publishedSurvey.answersTab.utilityButtons.exportDisabled') : ''}
          placement="right"
        >
          <span>
            <ExportCsvButton disabled={!hasRespondents} />
          </span>
        </Tooltip>
        <Button variant="text" onClick={() => setIsReceiversModalOpen(true)} startIcon={<PersonOutlined />}>
          {t('publishedSurvey.answersTab.utilityButtons.respondents')}
        </Button>
      </Box>
      <Box>
        <SurveyReceiversModal open={isReceiversModalOpen} onClose={closeReceiversModal} />
      </Box>
      <ButtonGroup sx={Styles.Tabs}>
        {Object.values(SurveyAnswersButton).map((name, index) => (
          <Button variant={tab === name ? 'contained' : 'outlined'} onClick={() => setTab(name)} key={index} fullWidth>
            {t(`publishedSurvey.answersTab.tabs.${name}`)}
          </Button>
        ))}
      </ButtonGroup>
      <SurveyAnswersTabs
        tab={tab}
        changeTab={changeTab}
        summaryData={surveyAnswersSummaryData}
        surveyCountsSummaryData={surveyCountsSummaryData}
      />
    </Box>
  );
};
