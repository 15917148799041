import { Box, Paper } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';

import * as Styles from './CenteredMessageWrapper.styles';
import { CenteredMessageWrapperProps } from './CenteredMessageWrapper.types';

export const CenteredMessageWrapper = ({ children, sx, containerSx }: CenteredMessageWrapperProps) => {
  return (
    <Box sx={spreadMultipleStyles(Styles.Container, containerSx)}>
      <Paper sx={spreadMultipleStyles(Styles.Wrapper, sx)}>{children}</Paper>
    </Box>
  );
};
