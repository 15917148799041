import { Styles } from 'shared/types';

export const Container: Styles = {
  display: 'flex',
  justifyContent: 'center',
};

export const ProgressBar: Styles = {
  width: '40px',
  height: '4px',
  mt: 4,
  '&:not(:last-child)': {
    mr: 0.5,
  },
  '&.MuiLinearProgress-root': {
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },

  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: 'rgba(35, 41, 75, 0.54)',
    borderRadius: '8px',
  },
};
