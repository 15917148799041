import 'react-quill/dist/quill.snow.css';
import { Box, FormHelperText, IconButton, Typography } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import ReactQuill from 'react-quill';

import { ReactComponent as ListNumbered } from 'assets/images/quill/FormatListNumberedOutlined.svg';
import { ReactComponent as AlignCenter } from 'assets/images/quill/FormatAlignCenterFilled.svg';
import { ReactComponent as AlignJustify } from 'assets/images/quill/FormatAlignJustifyFilled.svg';
import { ReactComponent as AlignLeft } from 'assets/images/quill/FormatAlignLeftFilled.svg';
import { ReactComponent as AlignRight } from 'assets/images/quill/FormatAlignRightFilled.svg';
import { ReactComponent as Bold } from 'assets/images/quill/FormatBoldFilled.svg';
import { ReactComponent as Italic } from 'assets/images/quill/FormatItalicOutlined.svg';
import { ReactComponent as ListBullet } from 'assets/images/quill/FormatListBulletedOutlined.svg';
import { ReactComponent as Underlined } from 'assets/images/quill/FormatUnderlinedFilled.svg';

import { ControlledQuillProps } from './ControlledQuill.types';
import * as Styles from './ControlledQuill.styles';
import './ControlledQuill.css';

export const ControlledQuill = <T extends FieldValues>({
  control,
  name,
  label,
  readOnly,
  errorMessage,
  ...props
}: ControlledQuillProps<T>) => {
  const modules = {
    toolbar: {
      container: `#toolbar_${name}`,
    },
  };

  const icons = ReactQuill.Quill.import('ui/icons');

  icons['bold'] = undefined;
  icons['italic'] = undefined;
  icons['underline'] = undefined;
  icons['align'] = undefined;
  icons['list'] = undefined;

  const formats = ['bold', 'italic', 'underline', 'align', 'list', 'indent'];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Box>
          <Box sx={Styles.QuillContainer(name)}>
            {label && <Typography variant="subtitle2">{label}</Typography>}
            <ReactQuill
              {...props}
              modules={modules}
              formats={formats}
              onChange={onChange}
              value={value}
              readOnly={readOnly}
            />
            <Box id={`toolbar_${name}`} sx={Styles.QuillToolbar}>
              <Box className="ql-formats">
                <IconButton sx={Styles.StyledQuillButton} className="ql-bold">
                  <Bold />
                </IconButton>
                <IconButton sx={Styles.StyledQuillButton} className="ql-italic">
                  <Italic />
                </IconButton>
                <IconButton sx={Styles.StyledQuillButton} className="ql-underline">
                  <Underlined />
                </IconButton>
              </Box>
              <Box className="ql-formats">
                <IconButton sx={Styles.StyledQuillButton} className="ql-align" value="">
                  <AlignLeft />
                </IconButton>
                <IconButton sx={Styles.StyledQuillButton} className="ql-align" value="center">
                  <AlignCenter />
                </IconButton>
                <IconButton sx={Styles.StyledQuillButton} className="ql-align" value="right">
                  <AlignRight />
                </IconButton>
                <IconButton sx={Styles.StyledQuillButton} className="ql-align" value="justify">
                  <AlignJustify />
                </IconButton>
              </Box>
              <Box className="ql-formats">
                <IconButton sx={Styles.StyledQuillButton} className="ql-list" value="ordered">
                  <ListNumbered />
                </IconButton>
                <IconButton sx={Styles.StyledQuillButton} className="ql-list" value="bullet">
                  <ListBullet />
                </IconButton>
              </Box>
            </Box>
          </Box>
          {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Box>
      )}
    />
  );
};
