import FilterIcon from '@mui/icons-material/Filter';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from 'assets/images/DeleteIcon.svg';
import { spreadMultipleStyles } from 'shared/utils';
import { DropDownMenu } from 'ui';

import * as Styles from './ImageWithDropDown.styles';
import { ImageWithDropDownProps } from './ImageWithDropDown.types';

export const ImageWithDropDown = ({
  changePhotoLabel,
  imagePath,
  onImageDelete,
  open,
  imageSx,
}: ImageWithDropDownProps) => {
  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleIconButtonClick = (event: MouseEvent<HTMLElement>) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);
  const handleImageChange = () => {
    setMenuAnchorEl(null);
    open();
  };
  const handleImageDelete = () => {
    onImageDelete();
    setMenuAnchorEl(null);
  };

  return (
    <Box sx={spreadMultipleStyles(Styles.UploadedImageWRapper, imageSx)}>
      <Box sx={Styles.IconPositioner}>
        <Box component="img" src={imagePath} sx={Styles.UploadedImage} />
        <IconButton sx={Styles.ImageMenuButton} onClick={handleIconButtonClick}>
          <MoreVertIcon sx={Styles.ImageMenuButtonIcon} />
        </IconButton>
      </Box>
      <DropDownMenu anchorEl={menuAnchorEl} onMenuClose={handleMenuClose}>
        <MenuItem onClick={handleImageChange}>
          <FilterIcon sx={Styles.CropIcon} />
          {changePhotoLabel || t('surveyBuilder.questions.addPhotoDialog.imageMenu.changePhoto')}
        </MenuItem>
        <MenuItem onClick={handleImageDelete} sx={Styles.DeletePhoto}>
          <DeleteIcon style={Styles.TrashIcon} color="error" />
          {t('surveyBuilder.questions.addPhotoDialog.imageMenu.deletePhoto')}
        </MenuItem>
      </DropDownMenu>
    </Box>
  );
};
