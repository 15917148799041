import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddSurveyResponseMutationVariables = Types.Exact<{
  input: Types.SurveyResponseInput;
}>;


export type AddSurveyResponseMutation = { __typename?: 'Mutation', addSurveyResponse?: { __typename?: 'SurveyResponse', id: string, endImageUrl?: string | null } | null };


export const AddSurveyResponseDocument = gql`
    mutation AddSurveyResponse($input: SurveyResponseInput!) {
  addSurveyResponse(input: $input) {
    id
    endImageUrl
  }
}
    `;
export type AddSurveyResponseMutationFn = Apollo.MutationFunction<AddSurveyResponseMutation, AddSurveyResponseMutationVariables>;

/**
 * __useAddSurveyResponseMutation__
 *
 * To run a mutation, you first call `useAddSurveyResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyResponseMutation, { data, loading, error }] = useAddSurveyResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSurveyResponseMutation(baseOptions?: Apollo.MutationHookOptions<AddSurveyResponseMutation, AddSurveyResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSurveyResponseMutation, AddSurveyResponseMutationVariables>(AddSurveyResponseDocument, options);
      }
export type AddSurveyResponseMutationHookResult = ReturnType<typeof useAddSurveyResponseMutation>;
export type AddSurveyResponseMutationResult = Apollo.MutationResult<AddSurveyResponseMutation>;
export type AddSurveyResponseMutationOptions = Apollo.BaseMutationOptions<AddSurveyResponseMutation, AddSurveyResponseMutationVariables>;