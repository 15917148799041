import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Paper, Tab } from '@mui/material';
import { addWeeks } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PreviewSurvey } from 'app/previewSurvey/PreviewSurvey';
import { Language, Section } from 'generated/webapp_gql';
import { useSurveyTemplate, useValidatedParams } from 'hooks';
import { useFetchSurveyEndImage } from 'hooks/useFetchSurveyEndImage/useFetchSurveyEndImage';
import { AppRoute } from 'routing/AppRoute.enum';
import { BackButton, TabPanel, Tabs, Title } from 'ui';
import { ClosePreviewButton } from 'ui/closePreviewButton/ClosePreviewButton';
import { SurveyBuilderPublicationContainer } from '../surveyBuilderPublicationContainer/SurveyBuilderPublicationContainer';
import { SurveyBuilderQuestions } from '../surveyBuilderQuestions/SurveyBuilderQuestions';
import { SurveyViewController } from 'context/surveyView/SurveyViewController';

import { SurveyBuilderTab } from './SurveyBuilderLayout.enum';
import { surveyFormSchema } from './SurveyBuilderLayout.schema';
import * as Styles from './SurveyBuilderLayout.styles';
import { CreateSurveyForm, SurveyFormData } from './SurveyBuilderLayout.types';
import { defaultSection } from './SurveyBuilderLayout.utils';

export const SurveyBuilderLayout = () => {
  const { surveyTemplateId, employeeId } = useValidatedParams(['surveyTemplateId', 'employeeId']);
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(SurveyBuilderTab.Questions);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const featchSurveyEndImage = useFetchSurveyEndImage();
  const navigate = useNavigate();

  const surveyBuilderFormMethods = useForm<SurveyFormData>({
    resolver: yupResolver(surveyFormSchema),
    mode: 'all',
    defaultValues: {
      title: '',
      language: Language.Pl,
      beginning: t('surveyBuilder.questions.intro.defaultValue'),
      sections: [defaultSection],
      conclusion: t('surveyBuilder.questions.outro.defaultValue'),
    },
  });

  const surveyPublicationForm = useForm<CreateSurveyForm>({
    defaultValues: {
      closeDate: addWeeks(new Date(), 2),
      sendDate: null,
      surveyTemplateId,
      receivers: [],
    },
  });

  const { surveyTemplateData, isSurveyTemplateLoading } = useSurveyTemplate(surveyTemplateId);

  useEffect(() => {
    if (!isSurveyTemplateLoading) {
      surveyBuilderFormMethods.reset({
        title: surveyTemplateData?.surveyTemplate?.title ?? '',
        language: surveyTemplateData?.surveyTemplate?.language || Language.Pl,
        beginning: surveyTemplateData?.surveyTemplate?.content?.beginning ?? '',
        conclusion: surveyTemplateData?.surveyTemplate?.content?.conclusion ?? '',
        sections: (surveyTemplateData?.surveyTemplate?.content?.sections as Section[]) ?? [defaultSection],
        endImageName: surveyTemplateData?.surveyTemplate?.endImageName ?? undefined,
        endImageUrl: surveyTemplateData?.surveyTemplate?.endImageUrl,
        introImageName: surveyTemplateData?.surveyTemplate?.introImageName ?? undefined,
        introImageUrl: surveyTemplateData?.surveyTemplate?.introImageUrl,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyTemplateLoading]);

  const goToPublicationPage = () => {
    setCurrentTab(SurveyBuilderTab.Publication);
  };

  const handleChangeTab = (tab: SurveyBuilderTab) => {
    setIsPreviewVisible(false);
    setCurrentTab(tab);
  };

  const handleBack = () => {
    if (isPreviewVisible) {
      return setIsPreviewVisible(false);
    }

    navigate(`/${AppRoute.SurveyTemplates}/${employeeId}`);
  };

  const isPublicationTabDisabled =
    surveyBuilderFormMethods.formState.isDirty || !surveyBuilderFormMethods.formState.isValid;

  const getPreviewSurveyData = (): SurveyFormData => {
    return surveyBuilderFormMethods.getValues();
  };

  const rightTabButton =
    currentTab === SurveyBuilderTab.Publication && isPreviewVisible ? (
      <ClosePreviewButton sx={Styles.ClosePreviewButton} onClick={() => setIsPreviewVisible(false)} />
    ) : undefined;

  return (
    <SurveyViewController isEditInputOnly={false}>
      <Paper sx={Styles.Container}>
        <Title
          button={<BackButton sx={Styles.BackButton} onClick={handleBack} />}
          rightButton={rightTabButton}
          sx={Styles.Title}
        >
          <Tabs value={currentTab} onChange={(_, value) => handleChangeTab(value)}>
            <Tab
              label={t('surveyBuilder.tabs.questions')}
              value={SurveyBuilderTab.Questions}
              key={'surveyBuilder.tabs.questions'}
            />

            <Tab
              label={t('surveyBuilder.tabs.settings')}
              value={SurveyBuilderTab.Publication}
              key={'surveyBuilder.tabs.settings'}
              disabled={isPublicationTabDisabled}
            />
          </Tabs>
        </Title>

        <Box sx={Styles.SurveyBuilderContainer}>
          <TabPanel value={currentTab} index={SurveyBuilderTab.Questions} key={'surveyBuilder.tabs.questions'}>
            <FormProvider {...surveyBuilderFormMethods}>
              <SurveyBuilderQuestions goToPublicationPage={goToPublicationPage} />
            </FormProvider>
          </TabPanel>
          <TabPanel value={currentTab} index={SurveyBuilderTab.Publication} key={'surveyBuilder.tabs.settings'}>
            {!isPreviewVisible && (
              <FormProvider {...surveyPublicationForm}>
                <SurveyBuilderPublicationContainer openPreview={() => setIsPreviewVisible(true)} />
              </FormProvider>
            )}
            {isPreviewVisible && <PreviewSurvey survey={getPreviewSurveyData()} onSubmit={featchSurveyEndImage} />}
          </TabPanel>
        </Box>
      </Paper>
    </SurveyViewController>
  );
};
