import { Styles } from 'shared/types';

export const SectionWrapper: Styles = {
  display: 'flex',
  alignItems: 'flex-end',
  mt: 6,
  mb: 5,
  px: 3,
};

export const TextWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

export const Heading: Styles = {
  fontSize: 24,
  fontWeight: 600,
  mb: 1,
};

export const SubHeading: Styles = {
  fontSize: 16,
};

export const SwitchWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 2,
};
