import { InternalRefetchQueriesInclude } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ActionsOfEmployeeDocument } from 'documents/actionsOfEmployee.generated';
import { AllActionsOfEmployeeDocument } from 'documents/allActionsOfEmployee.generated';
import { useUpdateActionMutation } from 'documents/updateAction.generated';
import { useValidatedParams } from 'hooks';
import { UpdateAction } from 'hooks/utils/updateAction';
import { Toast } from 'ui';
import { TodoRowUpdate } from 'context/todoRow/TodoRowController.types';

import { UpdateActionContentPayload } from './useUpdateActionContent.types';

export const useUpdateActionContent = (): TodoRowUpdate => {
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: ActionsOfEmployeeDocument,
      variables: {
        employeeId,
      },
    },
    {
      query: AllActionsOfEmployeeDocument,
      variables: {
        employeeId,
      },
    },
  ];

  const [updateAction, { loading: isUpdateActionLoading }] = useUpdateActionMutation({
    refetchQueries,
    onError: (error) => toast.error(error.message),
    update: (cache, input) => {
      if (!input.data?.updateAction?.actionStatus) {
        return;
      }

      new UpdateAction(cache, employeeId, input.data?.updateAction?.actionStatus).onUpdate();
    },
    onCompleted: () => {
      toast.success(<Toast title={t('actions.toast.heading')} message={t('actions.toast.actionUpdated')} />);
    },
  });

  const handleUpdateAction = ({ id, content }: UpdateActionContentPayload) => {
    updateAction({
      variables: { input: { id, content } },
    });
  };

  return { onUpdateItem: handleUpdateAction, isUpdateItemLoading: isUpdateActionLoading };
};
