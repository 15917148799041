import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeDetailsWeeklyQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  actionStatus: Types.ActionStatus;
  actionsPagination?: Types.InputMaybe<Types.PaginationInput>;
  actionsSort?: Types.InputMaybe<Types.SortByRealizationDateInput>;
  weeklyPagination?: Types.InputMaybe<Types.PaginationInput>;
  weeklySort?: Types.InputMaybe<Types.SortByRealizationDateInput>;
}>;


export type EmployeeDetailsWeeklyQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, firstName: string, lastName: string, avatarUrl?: string | null, email: string, level?: string | null, hiredDate: any, pieselyUrl?: string | null, satisfactionLevel?: number | null, in_progress?: number | null, done?: number | null, canceled?: number | null, supervisor?: { __typename?: 'Employee', firstName: string, lastName: string } | null, actionsCollection?: { __typename?: 'ActionCollection', total: number, items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, weeklyFeedbackId?: string | null } | null> } | null, receivedWeeklyFeedbacksCollection?: { __typename?: 'WeeklyFeedbackCollection', total: number, items: Array<{ __typename?: 'WeeklyFeedback', id: string, satisfactionScore: number, summary?: string | null, notes?: string | null, realizationDate: any, receiverId?: number | null, actionsCollection?: { __typename?: 'ActionCollection', items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null } | null> } | null } | null> } | null } | null };


export const EmployeeDetailsWeeklyDocument = gql`
    query employeeDetailsWeekly($employeeId: Int!, $actionStatus: ActionStatus!, $actionsPagination: PaginationInput, $actionsSort: SortByRealizationDateInput, $weeklyPagination: PaginationInput, $weeklySort: SortByRealizationDateInput) {
  employee(id: $employeeId) {
    id
    firstName
    lastName
    avatarUrl
    email
    level
    supervisor {
      firstName
      lastName
    }
    hiredDate
    pieselyUrl
    satisfactionLevel
    in_progress: actionsInProgress
    done: actionsAmountByStatus(actionStatus: DONE)
    canceled: actionsAmountByStatus(actionStatus: CANCELED)
    actionsCollection(
      actionStatus: $actionStatus
      pagination: $actionsPagination
      sort: $actionsSort
    ) {
      items {
        id
        content
        actionStatus
        createdAt
        realizationDate
        updatedAt
        weeklyFeedbackId
      }
      total
    }
    receivedWeeklyFeedbacksCollection(
      pagination: $weeklyPagination
      sort: $weeklySort
    ) {
      items {
        id
        satisfactionScore
        summary
        notes
        realizationDate
        actionsCollection {
          items {
            id
            content
            actionStatus
          }
        }
        receiverId
      }
      total
    }
  }
}
    `;

/**
 * __useEmployeeDetailsWeeklyQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailsWeeklyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailsWeeklyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailsWeeklyQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      actionStatus: // value for 'actionStatus'
 *      actionsPagination: // value for 'actionsPagination'
 *      actionsSort: // value for 'actionsSort'
 *      weeklyPagination: // value for 'weeklyPagination'
 *      weeklySort: // value for 'weeklySort'
 *   },
 * });
 */
export function useEmployeeDetailsWeeklyQuery(baseOptions: Apollo.QueryHookOptions<EmployeeDetailsWeeklyQuery, EmployeeDetailsWeeklyQueryVariables> & ({ variables: EmployeeDetailsWeeklyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDetailsWeeklyQuery, EmployeeDetailsWeeklyQueryVariables>(EmployeeDetailsWeeklyDocument, options);
      }
export function useEmployeeDetailsWeeklyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailsWeeklyQuery, EmployeeDetailsWeeklyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDetailsWeeklyQuery, EmployeeDetailsWeeklyQueryVariables>(EmployeeDetailsWeeklyDocument, options);
        }
export function useEmployeeDetailsWeeklySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmployeeDetailsWeeklyQuery, EmployeeDetailsWeeklyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeeDetailsWeeklyQuery, EmployeeDetailsWeeklyQueryVariables>(EmployeeDetailsWeeklyDocument, options);
        }
export type EmployeeDetailsWeeklyQueryHookResult = ReturnType<typeof useEmployeeDetailsWeeklyQuery>;
export type EmployeeDetailsWeeklyLazyQueryHookResult = ReturnType<typeof useEmployeeDetailsWeeklyLazyQuery>;
export type EmployeeDetailsWeeklySuspenseQueryHookResult = ReturnType<typeof useEmployeeDetailsWeeklySuspenseQuery>;
export type EmployeeDetailsWeeklyQueryResult = Apollo.QueryResult<EmployeeDetailsWeeklyQuery, EmployeeDetailsWeeklyQueryVariables>;