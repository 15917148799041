import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const DatePickerContainer: Styles = {
  backgroundColor: colors.brightGray,
  borderRadius: 2,
  marginTop: 2,
};

export const OptionSettingContainer: Styles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const AddReceiversContainer: Styles = {
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const HiddenSelectItem: Styles = {
  display: 'none',
};
