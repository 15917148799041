import { Styles } from 'shared/types';
import { blues, grays } from 'shared/utils';

export const SwitchLabel: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: 1,
};

export const SwitchControl: Styles = {
  display: 'flex',
  justifyContent: 'start',
  width: '50%',
};

export const SwitchLabelTitle: Styles = {
  fontSize: 20,
  fontWeight: 500,
  color: blues.navy,
};

export const SwitchLabelSubTitle: Styles = {
  fontSize: 14,
  fontWeight: 400,
  color: grays.slateGray,
};

export const Container: Styles = {
  display: 'flex',
  padding: '24px 36px',
  marginBottom: '40px',
  minHeight: '130px',
  boxSizing: 'border-box',
  alignItems: 'start',
};
