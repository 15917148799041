import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const OptionLabel: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: '50%',
  pl: '54px',
  gap: 1,
};

export const OptionLabelTitle: Styles = {
  fontSize: 20,
  fontWeight: 500,
  color: colors.navy,
};

export const OptionLabelSubTitle: Styles = {
  fontSize: 14,
  fontWeight: 400,
  color: colors.slateGray,
};

export const Container: Styles = {
  display: 'flex',
  padding: '24px 36px',
  marginBottom: '40px',
  minHeight: '130px',
  boxSizing: 'border-box',
  alignItems: 'stretch',
};
