import { Circle, Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAllSurveyRespondents } from 'hooks/useAllSurveyRespondents/useAllSurveyRespondents';
import { getLongFormatDate } from 'shared/utils';
import { AvatarWithFallback, LoadingOverlay } from 'ui';

import { SurveyReceiversModalProps } from './SurveyReceiversModal.types';
import * as Styles from './SurveyReceiversModal.styles';
import { responseStatuses } from './SurveyReceiversModal.utils';

export const SurveyReceiversModal = ({ onClose, open }: SurveyReceiversModalProps) => {
  const { t } = useTranslation();
  const { allRespondents, firstPublicationDate, areAllSurveyRespondentsLoading } = useAllSurveyRespondents(!open);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: Styles.Dialog,
      }}
    >
      <IconButton onClick={onClose} sx={Styles.CloseButton}>
        <Close />
      </IconButton>
      {areAllSurveyRespondentsLoading === true && <LoadingOverlay />}
      {areAllSurveyRespondentsLoading === false && (
        <>
          <Typography variant="h5" sx={Styles.Title}>
            {t('publishedSurvey.answersTab.receiversDialog.title', { count: allRespondents?.length ?? 0 })}
          </Typography>
          <Typography variant="body1" sx={Styles.PublishedAt}>
            {t('publishedSurvey.answersTab.receiversDialog.publishedAt', {
              date: getLongFormatDate(firstPublicationDate),
            })}
          </Typography>
          <Divider />
          <Box sx={Styles.InfoWrapper}>
            {Object.values(responseStatuses).map(({ color, name }, index) => (
              <Box key={index} sx={Styles.InfoItem(color)}>
                <Circle />
                <Typography variant="caption">{t(name)}</Typography>
              </Box>
            ))}
          </Box>
          <Divider />
          <List sx={Styles.List}>
            {allRespondents?.map(({ respondent, status, respondentEmail }, index) => (
              <ListItem key={index} sx={Styles.ListItem} data-testid="respondent-item">
                <ListItemAvatar sx={Styles.Avatar}>
                  <AvatarWithFallback
                    src={respondent?.avatarUrl}
                    size="xs"
                    alt={respondent ? `${respondent.firstName} ${respondent.lastName}` : respondentEmail}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={respondent ? `${respondent.firstName} ${respondent.lastName}` : respondentEmail}
                  secondary={respondent ? respondentEmail : ''}
                  primaryTypographyProps={{ sx: Styles.RespondentName }}
                  secondaryTypographyProps={{ sx: Styles.RespondentEmail }}
                />
                <Circle sx={Styles.StatusCircle(responseStatuses[status]?.color)} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Dialog>
  );
};
