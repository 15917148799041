import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { TextField } from 'ui';

import { LocalPaginationProps } from './LocalPagination.types';
import * as Styles from './LocalPagination.styles';

export const LocalPagination = ({ count, page, onChange }: LocalPaginationProps) => {
  const { t } = useTranslation();

  const onChangeGuard = (newPage: number) => {
    if (!newPage || newPage < 1 || newPage > count) {
      return;
    }

    onChange(newPage);
  };

  return (
    <Box sx={Styles.Wrapper}>
      <KeyboardArrowLeftIcon sx={Styles.Icon(page !== 1)} onClick={() => onChangeGuard(page - 1)} />
      <TextField
        type="number"
        inputProps={{ min: 1, max: count, style: { textAlign: 'center' } }}
        sx={Styles.Input}
        variant="standard"
        onChange={(e) => onChangeGuard(Number(e.target.value))}
        value={page}
      />
      <Typography sx={Styles.Typography} variant="body2">
        {t('pagination.of')}
      </Typography>
      <Typography sx={Styles.Typography} variant="body2">
        {count}
      </Typography>
      <KeyboardArrowRightIcon sx={Styles.Icon(page !== count)} onClick={() => onChangeGuard(page + 1)} />
    </Box>
  );
};
