import { DepartmentCodesKeys, TranslatedDepartmentKeys } from './useDepartmentsData.types';

export const UNCATEGORIZED_DEPARTMENTS_KEYS = ['DepartmentName'] as const;

export const isCategorizedKey = (code: DepartmentCodesKeys): code is TranslatedDepartmentKeys => {
  if (!UNCATEGORIZED_DEPARTMENTS_KEYS.some((key) => key === code)) {
    return true;
  }

  return false;
};
