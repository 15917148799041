export enum ModalType {
  Delete = 'delete',
  Check = 'check',
}

export enum ToDoType {
  Action = 'action',
  Goal = 'goal',
  Survey = 'survey',
}
