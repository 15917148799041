import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog, ImageWithDropDown, PhotoUploadErrorInfo } from 'ui';

import { DragDropImagePlaceholder } from './dragDropImagePlaceholder/DragDropImagePlaceholder';
import * as Styles from './UploadImageModal.styles';
import { UploadImageModalProps } from './UploadImageModal.types';

export const UploadImageModal = ({
  title,
  description,
  editLabel,
  onClose,
  imageToUpload,
  setImageToUpload,
  isFileUploading,
  uploadFile,
  dropzoneState,
}: UploadImageModalProps) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, open, isDragAccept, fileRejections, isDragReject } = dropzoneState;

  const getAcceptanceButtonLabel = (isLoading: boolean) =>
    isLoading ? (
      <>
        {t('surveyBuilder.questions.addPhotoDialog.loadingButtonText')}
        <CircularProgress sx={Styles.Loader} size={24} />
      </>
    ) : (
      t('surveyBuilder.questions.addPhotoDialog.acceptButtonText')
    );

  return (
    <ConfirmationDialog
      onClose={() => {
        onClose();
      }}
      onConfirmation={() => {
        uploadFile(imageToUpload);
      }}
      open={true}
      isAcceptButtonActive={!!imageToUpload && !isFileUploading}
      title={title || t('surveyBuilder.questions.addPhotoDialog.title')}
      acceptButtonText={getAcceptanceButtonLabel(isFileUploading)}
      cancelButtonText={t('surveyBuilder.questions.addPhotoDialog.cancelButtonText')}
      content={description || t('surveyBuilder.questions.addPhotoDialog.description')}
    >
      <Box sx={Styles.FileUpload(isDragAccept, isDragReject)} {...getRootProps()}>
        {!imageToUpload ? (
          <DragDropImagePlaceholder onButtonClick={open} isDragAccept={isDragAccept} isDragReject={isDragReject} />
        ) : (
          <ImageWithDropDown
            changePhotoLabel={editLabel}
            imagePath={imageToUpload.preview}
            onImageDelete={() => setImageToUpload(null)}
            open={open}
          />
        )}
        <input {...getInputProps()} />
      </Box>
      {fileRejections.length !== 0 && <PhotoUploadErrorInfo errorCode={fileRejections?.[0]?.errors[0]?.code} />}
    </ConfirmationDialog>
  );
};
