import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeesQueryVariables = Types.Exact<{
  assignedToMe?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  withPendingActions?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  searchedValue?: Types.InputMaybe<Types.Scalars['searchedValue_String_minLength_1']['input']>;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
  sort?: Types.InputMaybe<Types.SortByLastNameInput>;
  actionsPagination?: Types.InputMaybe<Types.PaginationInput>;
  filterByDepartments?: Types.InputMaybe<Array<Types.DepartmentCodes> | Types.DepartmentCodes>;
}>;


export type EmployeesQuery = { __typename?: 'Query', employees?: { __typename?: 'EmployeeCollection', total: number, items: Array<{ __typename?: 'Employee', id: number, supervisorId?: number | null, fullName: string, firstName: string, lastName: string, avatarUrl?: string | null, employmentType?: string | null, latestReceivedWeeklyFeedbackRealizationDate?: any | null, latestReceivedQuarterlyReportRealizationDate?: any | null, actionsInProgress?: number | null, actionsCollection?: { __typename?: 'ActionCollection', items: Array<{ __typename?: 'Action', id: string, content?: string | null } | null> } | null } | null> } | null };


export const EmployeesDocument = gql`
    query Employees($assignedToMe: Boolean, $withPendingActions: Boolean, $searchedValue: searchedValue_String_minLength_1, $pagination: PaginationInput, $sort: SortByLastNameInput, $actionsPagination: PaginationInput, $filterByDepartments: [DepartmentCodes!]) {
  employees(
    assignedToMe: $assignedToMe
    withPendingActions: $withPendingActions
    searchedValue: $searchedValue
    filterByDepartments: $filterByDepartments
    withAdminMode: false
    pagination: $pagination
    sort: $sort
  ) {
    items {
      id
      supervisorId
      fullName
      firstName
      lastName
      avatarUrl
      employmentType
      latestReceivedWeeklyFeedbackRealizationDate
      latestReceivedQuarterlyReportRealizationDate
      actionsInProgress
      actionsCollection(pagination: $actionsPagination) {
        items {
          id
          content
        }
      }
    }
    total
  }
}
    `;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *      assignedToMe: // value for 'assignedToMe'
 *      withPendingActions: // value for 'withPendingActions'
 *      searchedValue: // value for 'searchedValue'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      actionsPagination: // value for 'actionsPagination'
 *      filterByDepartments: // value for 'filterByDepartments'
 *   },
 * });
 */
export function useEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
      }
export function useEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
        }
export function useEmployeesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
        }
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>;
export type EmployeesSuspenseQueryHookResult = ReturnType<typeof useEmployeesSuspenseQuery>;
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>;