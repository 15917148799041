import { useNavigate, useSearchParams } from 'react-router-dom';

import { SurveyFormErrorCode, SurveyFormParam } from 'app/surveyForm/SurveyForm.enum';
import { useSurveyForRespondentQuery } from 'documents/surveyForRespondent.generated';
import { Language, SurveyForRespondent } from 'generated/webapp_gql';
import { AppRoute } from 'routing/AppRoute.enum';

export const useSurveyForRespondent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const urlHash = searchParams.get(SurveyFormParam.Key) ?? '';

  const { data, loading, error, refetch } = useSurveyForRespondentQuery({
    variables: {
      urlHash,
    },
    skip: !urlHash.length,
  });

  if (!urlHash.length) {
    navigate(AppRoute.Home);

    return {};
  }

  const { code, language } = error?.graphQLErrors[0]?.extensions || {};

  return {
    surveyForRespondentData: <SurveyForRespondent>data?.surveyForRespondent,
    urlHash,
    isSurveyForRespondentLoading: loading,
    errorCode: code as SurveyFormErrorCode | undefined,
    errorLanguage: language as Language | undefined,
    refetch,
  };
};
