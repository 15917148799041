import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Dialog: Styles = {
  px: 3,
  py: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  textAlign: 'center',
  width: '100%',
  color: colors.slateGray,
  position: 'relative',
};

export const CloseButton: Styles = {
  position: 'absolute',
  top: 2,
  right: 2,
};

export const Title: Styles = {
  color: colors.navy,
};

export const PublishedAt: Styles = {
  pt: 1,
  pb: 3,
};

export const InfoWrapper: Styles = {
  width: '100%',
  px: 2,
  my: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const List: Styles = {
  mt: 3,
  py: 0,
  maxHeight: 410,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 1.5,

  '&::-webkit-scrollbar': {
    borderRadius: '8px',
    width: '8px',
    backgroundColor: colors.brightGray,
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundClip: 'padding-box',
    border: `2px solid ${colors.brightGray}`,
    backgroundColor: colors.slateGray,
  },
};

export const ListItem: Styles = {
  py: 0,
  pl: 1,
};

export const Avatar: Styles = {
  minWidth: 0,
  mr: 1,
};

export const StatusCircle = (color: string = colors.disabledGray): Styles => ({
  width: 10,
  height: 10,
  color,
});

export const InfoItem = (color: string): Styles => ({
  display: 'flex',
  alignItems: 'center',
  gap: 1,

  '.MuiSvgIcon-root': {
    width: 10,
    height: 10,
    color,
  },
});

export const RespondentName: Styles = {
  color: colors.navy,
  fontSize: 14,
  lineHeight: '24px',
};

export const RespondentEmail: Styles = {
  fontSize: 12,
  lineHeight: '20px',
};
