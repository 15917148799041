import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonPrev } from 'ui/buttonPrev/ButtonPrev';
import { ButtonNext } from 'ui/buttonNext/ButtonNext';
import { SectionCounterChip } from '../sectionCounterChip/SectionCounterChip';
import { AvatarWithFallback } from 'ui';

import { PreviewSurveySectionProps } from './PreviewSurveySection.types';
import * as Styles from './PreviewSurveySection.styles';

export const PreviewSurveySection = ({
  currentSectionNumber,
  sectionsTotal,
  section,
  employee,
  onBackClick,
  onNextClick,
}: PreviewSurveySectionProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.Container}>
      <Box sx={Styles.User}>
        <Typography variant="body1" sx={Styles.UserAvatarDescription}>
          {t('surveyPreview.intro.concerns')}
        </Typography>
        <AvatarWithFallback size="xl" src={employee.avatarUrl} alt={employee.fullName ?? ''} />
        <Typography variant="subtitle1" sx={Styles.FullName}>
          {employee.fullName ?? ''}
        </Typography>
      </Box>
      <Box sx={Styles.ContentContainer}>
        <SectionCounterChip current={currentSectionNumber + 1} total={sectionsTotal} />
        <Typography variant="h5">{section?.title}</Typography>
        <Box sx={Styles.Description}>{section?.description}</Box>
        <Box sx={Styles.Buttons}>
          <ButtonPrev onClick={onBackClick}>{t('surveyPreview.sectionPreview.back')}</ButtonPrev>
          <ButtonNext onClick={onNextClick}>{t('surveyPreview.sectionPreview.start')}</ButtonNext>
        </Box>
      </Box>
    </Box>
  );
};
