import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllGoalsOfEmployeeQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  inProgressPagination?: Types.InputMaybe<Types.PaginationInput>;
  donePagination?: Types.InputMaybe<Types.PaginationInput>;
  canceledPagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type AllGoalsOfEmployeeQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, in_progress?: { __typename?: 'GoalCollection', total: number, items: Array<{ __typename?: 'Goal', id: string, content?: string | null, goalStatus?: Types.GoalStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, quarterlyReportId: string } | null> } | null, done?: { __typename?: 'GoalCollection', total: number, items: Array<{ __typename?: 'Goal', id: string, content?: string | null, goalStatus?: Types.GoalStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, quarterlyReportId: string } | null> } | null, canceled?: { __typename?: 'GoalCollection', total: number, items: Array<{ __typename?: 'Goal', id: string, content?: string | null, goalStatus?: Types.GoalStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, quarterlyReportId: string } | null> } | null } | null };


export const AllGoalsOfEmployeeDocument = gql`
    query allGoalsOfEmployee($employeeId: Int!, $inProgressPagination: PaginationInput, $donePagination: PaginationInput, $canceledPagination: PaginationInput) {
  employee(id: $employeeId) {
    id
    in_progress: goalsCollection(
      goalStatus: IN_PROGRESS
      pagination: $inProgressPagination
    ) {
      items {
        id
        content
        goalStatus
        createdAt
        realizationDate
        updatedAt
        quarterlyReportId
      }
      total
    }
    done: goalsCollection(goalStatus: DONE, pagination: $donePagination) {
      items {
        id
        content
        goalStatus
        createdAt
        realizationDate
        updatedAt
        quarterlyReportId
      }
      total
    }
    canceled: goalsCollection(goalStatus: CANCELED, pagination: $canceledPagination) {
      items {
        id
        content
        goalStatus
        createdAt
        realizationDate
        updatedAt
        quarterlyReportId
      }
      total
    }
  }
}
    `;

/**
 * __useAllGoalsOfEmployeeQuery__
 *
 * To run a query within a React component, call `useAllGoalsOfEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGoalsOfEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGoalsOfEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      inProgressPagination: // value for 'inProgressPagination'
 *      donePagination: // value for 'donePagination'
 *      canceledPagination: // value for 'canceledPagination'
 *   },
 * });
 */
export function useAllGoalsOfEmployeeQuery(baseOptions: Apollo.QueryHookOptions<AllGoalsOfEmployeeQuery, AllGoalsOfEmployeeQueryVariables> & ({ variables: AllGoalsOfEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllGoalsOfEmployeeQuery, AllGoalsOfEmployeeQueryVariables>(AllGoalsOfEmployeeDocument, options);
      }
export function useAllGoalsOfEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllGoalsOfEmployeeQuery, AllGoalsOfEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllGoalsOfEmployeeQuery, AllGoalsOfEmployeeQueryVariables>(AllGoalsOfEmployeeDocument, options);
        }
export function useAllGoalsOfEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllGoalsOfEmployeeQuery, AllGoalsOfEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllGoalsOfEmployeeQuery, AllGoalsOfEmployeeQueryVariables>(AllGoalsOfEmployeeDocument, options);
        }
export type AllGoalsOfEmployeeQueryHookResult = ReturnType<typeof useAllGoalsOfEmployeeQuery>;
export type AllGoalsOfEmployeeLazyQueryHookResult = ReturnType<typeof useAllGoalsOfEmployeeLazyQuery>;
export type AllGoalsOfEmployeeSuspenseQueryHookResult = ReturnType<typeof useAllGoalsOfEmployeeSuspenseQuery>;
export type AllGoalsOfEmployeeQueryResult = Apollo.QueryResult<AllGoalsOfEmployeeQuery, AllGoalsOfEmployeeQueryVariables>;