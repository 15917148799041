import { Box, Button, Typography } from '@mui/material';
import { Controller, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addDays, isBefore } from 'date-fns';

import { DatePicker, DateTimePicker } from 'ui';
import { SurveyPublicationOption } from '../surveyPublicationOption/SurveyPublicationOption';
import { SurveyPublicationRequiredOption } from '../surveyPublicationRequiredOption/SurveyPublicationRequiredOption';
import { CreateSurveyForm } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { SurveyReceiver } from '../surveyPublicationModal/SurveyPublicationModal.types';

import { SurveyPublicationSettingsProps } from './SurveyPublicationSettings.types';
import * as Styles from './SurveyPublicationSettings.styles';
import { SurveyNotificationSetting } from './surveyNotificationSetting/SurveyNotificationSetting';

export const SurveyPublicationSettings = ({
  onAddReceiversClick,
  disabled,
  isSent = false,
}: SurveyPublicationSettingsProps) => {
  const { control, setValue, getValues } = useFormContext<CreateSurveyForm>();
  const { t } = useTranslation();

  const { sendDate } = getValues();
  const receivers = useWatch<CreateSurveyForm>({ name: 'receivers' }) as SurveyReceiver[];
  const closeDate = useWatch<CreateSurveyForm>({ name: 'closeDate' }) as Date;

  const minCloseDateModifier = (date: Date) => addDays(date, 1);

  const onDateTimePickerChange = (value: Date | null, onChange: ControllerRenderProps['onChange']) => {
    const newDate = value ?? new Date();
    onChange(value);

    if (closeDate && !isBefore(newDate, closeDate)) {
      handleCloseDateChange(minCloseDateModifier(newDate));
    }
  };

  const handleCloseDateChange = (value: Date | null) => {
    if (value) {
      setValue('slackNotification', 0, { shouldDirty: true });
      setValue('closeDate', value, { shouldDirty: true });
    }
  };

  return (
    <>
      <SurveyPublicationRequiredOption
        isSurveyClosed={disabled}
        title={t('surveyBuilder.publicationSettings.addReceivers.title')}
        subtitle={t('surveyBuilder.publicationSettings.addReceivers.subtitle')}
        disabled={disabled}
      >
        <Box sx={Styles.AddReceiversContainer}>
          <Typography variant="body1">
            {receivers.length
              ? t('surveyBuilder.publicationSettings.addReceivers.addedCount_interval', {
                  count: receivers.length,
                  postProcess: 'interval',
                })
              : ''}
          </Typography>
          {
            <Button variant="text" onClick={onAddReceiversClick}>
              {t(`surveyBuilder.publicationSettings.addReceivers.${receivers.length ? 'editButton' : 'addButton'}`)}
            </Button>
          }
        </Box>
      </SurveyPublicationRequiredOption>

      <Controller
        name="closeDate"
        control={control}
        render={({ field: { value, name } }) => (
          <SurveyPublicationRequiredOption
            isSurveyClosed={disabled}
            title={t('surveyBuilder.publicationSettings.closeSurveyDateSwitch.title')}
            subtitle={t('surveyBuilder.publicationSettings.closeSurveyDateSwitch.subtitle')}
            disabled={disabled}
          >
            <Box sx={Styles.OptionSettingContainer}>
              <Typography component="label" htmlFor={name}>
                {t('surveyBuilder.publicationSettings.closeSurveyDatePicker.label')}
              </Typography>
              <Box sx={Styles.DatePickerContainer}>
                <DatePicker
                  id={name}
                  name={name}
                  minDate={minCloseDateModifier(sendDate || new Date())}
                  onChange={handleCloseDateChange}
                  value={value ?? null}
                  placeholder={t('datePicker.selectDate')}
                />
              </Box>
            </Box>
          </SurveyPublicationRequiredOption>
        )}
      />

      <Controller
        name="slackNotification"
        control={control}
        render={(props) => <SurveyNotificationSetting {...props} disabled={!!disabled} key={closeDate.toString()} />}
      />

      <Controller
        name="sendDate"
        control={control}
        render={({ field: { onChange, value, name } }) => {
          const isNotPastSendDate = !!sendDate && !isBefore(sendDate, new Date());
          const isPastSendDate = !!sendDate && isBefore(sendDate, new Date());
          const wasSurveySent = isSent || isPastSendDate;

          return (
            <SurveyPublicationOption
              name={name}
              title={t('surveyBuilder.publicationSettings.sendDateSwitch.title')}
              subtitle={t('surveyBuilder.publicationSettings.sendDateSwitch.subtitle')}
              isDefaultChecked={isNotPastSendDate}
              disabled={wasSurveySent || disabled}
              showInfo={wasSurveySent}
              info={t('surveyBuilder.publicationSettings.sendDateSwitch.disabledInfo')}
            >
              <Box sx={Styles.OptionSettingContainer}>
                <Typography>{t('surveyBuilder.publicationSettings.sendDateTimePicker.label')}</Typography>
                <Box sx={Styles.DatePickerContainer}>
                  <DateTimePicker
                    value={value ?? null}
                    onChange={(newVal) => onDateTimePickerChange(newVal, onChange)}
                    placeholder={t('datePicker.selectDate')}
                    minDateTime={new Date()}
                    views={['day', 'hours', 'minutes']}
                    minutesStep={30}
                    disabled={disabled}
                  />
                </Box>
              </Box>
            </SurveyPublicationOption>
          );
        }}
      />
    </>
  );
};
