import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSurveyTemplateMutationVariables = Types.Exact<{
  input: Types.UpdateSurveyTemplateInput;
}>;


export type UpdateSurveyTemplateMutation = { __typename?: 'Mutation', updateSurveyTemplate?: { __typename?: 'SurveyTemplate', id: string } | null };


export const UpdateSurveyTemplateDocument = gql`
    mutation UpdateSurveyTemplate($input: UpdateSurveyTemplateInput!) {
  updateSurveyTemplate(input: $input) {
    id
  }
}
    `;
export type UpdateSurveyTemplateMutationFn = Apollo.MutationFunction<UpdateSurveyTemplateMutation, UpdateSurveyTemplateMutationVariables>;

/**
 * __useUpdateSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyTemplateMutation, { data, loading, error }] = useUpdateSurveyTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSurveyTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyTemplateMutation, UpdateSurveyTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyTemplateMutation, UpdateSurveyTemplateMutationVariables>(UpdateSurveyTemplateDocument, options);
      }
export type UpdateSurveyTemplateMutationHookResult = ReturnType<typeof useUpdateSurveyTemplateMutation>;
export type UpdateSurveyTemplateMutationResult = Apollo.MutationResult<UpdateSurveyTemplateMutation>;
export type UpdateSurveyTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyTemplateMutation, UpdateSurveyTemplateMutationVariables>;