import { createTheme, ThemeOptions } from '@mui/material';

import { shadows } from 'shared/utils/theme/shadows';
import {
  MuiButton,
  MuiInputBase,
  MuiChip,
  MuiAccordion,
  MuiPaper,
  MuiFormControl,
  MuiFormControlLabel,
  MuiTextField,
  MuiCheckbox,
  MuiBackdrop,
  MuiDialog,
  MuiAlert,
  MuiRadio,
  MuiButtonGroup,
  MuiTooltip,
} from 'shared/utils/theme/components';

export const grays = {
  disabledGray: '#8993AA',
  offlineGray: '#23294B42',
  slateGray: '#687A97',
  hawkesBlue: '#D0D9ED',
  lightGray: '#E0E0E0',
  brightGray: '#EBEFF8',
  ghostWhite: '#F8F9FD',
};

export const yellows = {
  orange: '#FFBF46',
  orangePeel: '#F49F00',
  serenade: '#FFF7E9',
  creamy: '#FFF5E1',
};

export const blues = {
  navy: '#23294B',
  blueberry: '#4785FF',
  blueRibbon: '#0052F4',
  zumthorBlue: '#E9F0FF',
  dodgersBlue: '#2DB5FF',
  cerulean: '#0092E1',
  pattensBlue: '#DDF3FF',
};

export const colors = {
  ...grays,
  ...yellows,
  ...blues,
  green: '#24C476',
  eucalyptus: '#1B9359',
  offGreen: '#E5F8EF',
  tomato: '#F25634',
  tiaMariaRed: '#CF300D',
  provincialPink: '#FDEBE7',
  white: '#FFFFFF',
};

export const theme = createTheme({
  shadows,
  palette: {
    background: {
      default: colors.brightGray,
      paper: colors.white,
    },
    border: {
      common: colors.hawkesBlue,
      light: colors.navy,
    },
    success: {
      main: colors.green,
      dark: colors.eucalyptus,
      light: colors.offGreen,
    },
    error: {
      main: colors.tomato,
      dark: colors.tiaMariaRed,
      light: colors.provincialPink,
    },
    warning: {
      main: colors.orange,
      dark: colors.orangePeel,
      light: colors.serenade,
    },
    info: {
      main: colors.dodgersBlue,
      dark: colors.cerulean,
      light: colors.pattensBlue,
    },
    typography: {
      common: colors.navy,
      link: colors.blueberry,
      lightText: colors.slateGray,
    },
    text: {
      primary: colors.navy,
      secondary: colors.slateGray,
      disabled: colors.hawkesBlue,
    },
    primary: {
      main: colors.blueberry,
      dark: colors.blueRibbon,
      light: colors.zumthorBlue,
    },
    ...colors,
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontWeight: 600,
      letterSpacing: '-0.00833em',
    },
    h4: {
      letterSpacing: '0.00735em',
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      letterSpacing: '0.0075em',
    },
    body1: {
      letterSpacing: '0.00938em',
    },
    body2: {
      letterSpacing: '0.01214em',
    },
    subtitle1: {
      letterSpacing: '0.00938em',
      fontWeight: 500,
    },
    subtitle2: {
      letterSpacing: '0.00714em',
    },
    caption: {
      letterSpacing: '0.03333em',
    },
    overline: {
      letterSpacing: '0.08333em',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ':root': {
          '--toastify-color-light': colors.navy,
          '--toastify-text-color-light': colors.white,
          '--toastify-color-success': colors.green,
          '--toastify-color-warning': colors.orange,
          '--toastify-color-error': colors.tomato,
          '--toastify-toast-width': '296px',

          '.Toastify__progress-bar': {
            height: '4px',
          },

          '.Toastify__toast-body': {
            padding: '4px 8px 9px',
          },
        },
        body: {
          color: colors.navy,
        },
      },
    },
    MuiChip: MuiChip(colors),
    MuiButton: MuiButton(colors),
    MuiAccordion: MuiAccordion(),
    MuiPaper: MuiPaper(),
    MuiInputBase: MuiInputBase(colors),
    MuiFormControl: MuiFormControl(),
    MuiFormControlLabel: MuiFormControlLabel(colors),
    MuiTextField: MuiTextField(colors),
    MuiCheckbox: MuiCheckbox(colors),
    MuiBackdrop: MuiBackdrop(colors),
    MuiDialog: MuiDialog(colors),
    MuiAlert: MuiAlert(colors),
    MuiRadio: MuiRadio(colors),
    MuiButtonGroup: MuiButtonGroup(colors),
    MuiTooltip: MuiTooltip(colors),
  } as ThemeOptions['components'],
});
