import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useLatestFeedbacks } from 'hooks/useLatestFeedbacks/useLatestFeedbacks';
import { Accordion, Loader, NoDataHandler } from 'ui';
import { Feedbacks } from '../feedbacks/Feedbacks';

import * as Styles from './LatestFeedbackSection.styles';

export const LatestFeedbackSection = () => {
  const { t } = useTranslation();
  const { loading, totalFeedbacks, feedbacks } = useLatestFeedbacks();

  if (loading && !feedbacks) return <Loader />;

  return (
    <Accordion
      sx={Styles.AccordionWrapper}
      title={`${t('feedback.previousFeedbacks.title')} (${totalFeedbacks})`}
      content={
        feedbacks?.length ? (
          <Box sx={Styles.Feedbacks}>
            <Feedbacks hideActions feedbacks={feedbacks} />
          </Box>
        ) : (
          <NoDataHandler message={t('feedback.previousFeedbacks.feedbackNoData')} />
        )
      }
    />
  );
};
