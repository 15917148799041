import { Styles } from 'shared/types';
import { theme } from 'shared/utils';

const RatingWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
};

export const RatingWrapperDefault: Styles = {
  ...RatingWrapper,
  flexDirection: 'column',
  '& .emojiIcon': {
    width: 40,
    height: 40,
  },
  '& .RatingDescription': {
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    color: theme.palette.text.primary,
  },
};

export const RatingWrapperFilled: Styles = {
  ...RatingWrapper,
  '& .emojiIcon': {
    width: 32,
    height: 32,
  },
  '& .RatingDescription': {
    color: theme.palette.text.secondary,
  },
};
