import { Styles } from 'shared/types';
import { colors, theme } from 'shared/utils';

export const CardSection: Styles = { my: 2.5, position: 'relative' };

export const CardSectionLabel: Styles = { color: theme.palette.text.primary };
export const Alert: Styles = { width: 'auto', my: 3 };

export const CardSectionItem: Styles = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,

  '&:hover': {
    backgroundColor: colors.brightGray,
  },
};

export const ItemsLoaderWrapper: Styles = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  backgroundColor: colors.ghostWhite,
  opacity: 0.8,

  div: {
    backgroundColor: 'transparent',

    span: {
      filter: 'blur(0)',
    },
  },
};
