import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInitialSurveyTemplateMutationVariables = Types.Exact<{
  input: Types.CreateSurveyTemplateInput;
}>;


export type CreateInitialSurveyTemplateMutation = { __typename?: 'Mutation', createSurveyTemplate?: { __typename?: 'SurveyTemplate', id: string, title: string, createdAt: any, language: Types.Language, content?: { __typename?: 'SurveyContent', beginning?: string | null, conclusion?: string | null } | null } | null };


export const CreateInitialSurveyTemplateDocument = gql`
    mutation CreateInitialSurveyTemplate($input: CreateSurveyTemplateInput!) {
  createSurveyTemplate(input: $input) {
    id
    title
    createdAt
    language
    content {
      beginning
      conclusion
    }
  }
}
    `;
export type CreateInitialSurveyTemplateMutationFn = Apollo.MutationFunction<CreateInitialSurveyTemplateMutation, CreateInitialSurveyTemplateMutationVariables>;

/**
 * __useCreateInitialSurveyTemplateMutation__
 *
 * To run a mutation, you first call `useCreateInitialSurveyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInitialSurveyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInitialSurveyTemplateMutation, { data, loading, error }] = useCreateInitialSurveyTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInitialSurveyTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateInitialSurveyTemplateMutation, CreateInitialSurveyTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInitialSurveyTemplateMutation, CreateInitialSurveyTemplateMutationVariables>(CreateInitialSurveyTemplateDocument, options);
      }
export type CreateInitialSurveyTemplateMutationHookResult = ReturnType<typeof useCreateInitialSurveyTemplateMutation>;
export type CreateInitialSurveyTemplateMutationResult = Apollo.MutationResult<CreateInitialSurveyTemplateMutation>;
export type CreateInitialSurveyTemplateMutationOptions = Apollo.BaseMutationOptions<CreateInitialSurveyTemplateMutation, CreateInitialSurveyTemplateMutationVariables>;