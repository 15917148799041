import { Button } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';

import { ClosePreviewButtonProps } from './ClosePreviewButton.types';

export const ClosePreviewButton = ({ onClick, sx = [] }: ClosePreviewButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button sx={sx} onClick={onClick} startIcon={<VisibilityOffIcon />}>
      {t('surveyPreview.intro.closePreview')}
    </Button>
  );
};
