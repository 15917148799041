import { toast } from 'react-toastify';

import { useMeQuery } from 'documents/me.generated';
import { EmployeeRole } from 'shared/types';

export const useMe = () => {
  const { data, error, loading } = useMeQuery({ onError: (error) => toast.error(error.message) });

  const isHeadOfDepartment = data?.me?.role === EmployeeRole.Head;
  const isTM = data?.me?.role === EmployeeRole.TeamManager;
  const isAdmin = !!data?.me?.hasAdminAccess;
  const isSubcontractorAdmin = data?.me?.hasSubcontractorsAdminAccess;

  return {
    meData: data?.me,
    isAdmin,
    isHeadOfDepartment,
    isTM,
    isSubcontractorAdmin,
    meError: error,
    isMeDataLoading: loading,
  };
};
