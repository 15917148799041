import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditSentSurveyMutationVariables = Types.Exact<{
  editSentSurveyInput: Types.EditSentSurveyInput;
}>;


export type EditSentSurveyMutation = { __typename?: 'Mutation', editSentSurvey?: { __typename?: 'SurveyTemplate', id: string, title: string } | null };


export const EditSentSurveyDocument = gql`
    mutation editSentSurvey($editSentSurveyInput: EditSentSurveyInput!) {
  editSentSurvey(input: $editSentSurveyInput) {
    id
    title
  }
}
    `;
export type EditSentSurveyMutationFn = Apollo.MutationFunction<EditSentSurveyMutation, EditSentSurveyMutationVariables>;

/**
 * __useEditSentSurveyMutation__
 *
 * To run a mutation, you first call `useEditSentSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSentSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSentSurveyMutation, { data, loading, error }] = useEditSentSurveyMutation({
 *   variables: {
 *      editSentSurveyInput: // value for 'editSentSurveyInput'
 *   },
 * });
 */
export function useEditSentSurveyMutation(baseOptions?: Apollo.MutationHookOptions<EditSentSurveyMutation, EditSentSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSentSurveyMutation, EditSentSurveyMutationVariables>(EditSentSurveyDocument, options);
      }
export type EditSentSurveyMutationHookResult = ReturnType<typeof useEditSentSurveyMutation>;
export type EditSentSurveyMutationResult = Apollo.MutationResult<EditSentSurveyMutation>;
export type EditSentSurveyMutationOptions = Apollo.BaseMutationOptions<EditSentSurveyMutation, EditSentSurveyMutationVariables>;