import { useTranslation } from 'react-i18next';

import { BackButton, CardSidebar, Loader, PageTitle } from 'ui';
import {
  useSearchParamsState,
  useUpdateAction,
  useEmployeeActionsSearchParams,
  useEmployee,
  useAllActionsOfEmployee,
  useValidatedParams,
} from 'hooks';
import { actionTabsNames } from 'app/employeeDetails/employeeDetailsWeekly/actionsTabs/ActionsTabs.utils';
import { EmployeeSection } from 'app/employeeDetails/employeeSection/EmployeeSection';
import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';

import { allActionsDataLoader } from './EmployeeActions.utils';
import { ActionsAccordion } from './actionsAccordion/actionsAccordion';

export const EmployeeActions = () => {
  const { isUpdateActionLoading } = useUpdateAction();
  const { employeeId } = useValidatedParams(['employeeId']);

  const { t } = useTranslation();

  const { searchParams, setSearchParams } = useEmployeeActionsSearchParams();
  const [pages, setPages] = useSearchParamsState({
    searchParams,
    params: ['in_progress', 'done', 'canceled'],
    setSearchParams,
  });

  const { employeeData, isEmployeeDataLoading } = useEmployee();
  const { allActionsData, prevAllActionsData } = useAllActionsOfEmployee({
    pages: {
      canceled: pages.canceled,
      done: pages.done,
      inProgress: pages.in_progress,
    },
  });

  if (isEmployeeDataLoading || isUpdateActionLoading) return <Loader />;

  if (!employeeData) return null;

  return (
    <CardSidebar
      button={<BackButton route={`${AppRoute.EmployeeDetails}/${employeeId}/${EmployeeDetailsViewParam.Weekly}`} />}
      sidebar={<EmployeeSection employee={employeeData} />}
    >
      <PageTitle title={t('employeeActions.header')} />
      {actionTabsNames.map((type) => (
        <ActionsAccordion
          {...allActionsDataLoader({ allActionsData, prevAllActionsData, type })}
          type={type}
          key={type}
          pages={pages}
          setPages={setPages}
        />
      ))}
    </CardSidebar>
  );
};
