import { Box, Typography } from '@mui/material';
import { KeyboardEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { SurveyQuestionFormat } from 'generated/webapp_gql';
import { useTranslateSurvey } from 'hooks/useTranslateSurvey/useTranslateSurvey';
import { ControlledTextField, FilledIconAlert } from 'ui';
import { useSurveyView } from 'hooks/useSurveyView/useSurveyView';

import { Answers } from './answers/Answers';
import { LinearScale } from './linearScale/LinearScale';
import * as Styles from './Question.styles';
import { QuestionProps } from './Question.types';
import { QuestionFormatDropdown } from './questionFormatDropdown/QuestionFormatDropdown';

export const Question = ({ nestIndex, index }: QuestionProps) => {
  const { control, setValue } = useFormContext<SurveyFormData>();
  const { t } = useTranslation();
  const translateSurvey = useTranslateSurvey();
  const { isEditInputOnly } = useSurveyView();

  const handleSetQuestionFormat = (format: SurveyQuestionFormat) => {
    setValue(`sections.${nestIndex}.questions.${index}.format`, format);
  };

  const handleResetAnswers = () => {
    setValue(`sections.${nestIndex}.questions.${index}.answers`, [{}, {}]);
  };

  const [format, question] = useWatch<SurveyFormData>({
    name: [`sections.${nestIndex}.questions.${index}.format`, `sections.${nestIndex}.questions.${index}.answers`],
  });

  const handleEnterPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <Box sx={Styles.InputWrapper}>
        <ControlledTextField
          name={`sections.${nestIndex}.questions.${index}.question`}
          hiddenLabel
          control={control}
          placeholder={translateSurvey('surveyBuilder.questions.question.placeholder')}
          sx={Styles.TextField}
          formControlSx={Styles.FormControl}
          onKeyDown={handleEnterPress}
          InputProps={{
            startAdornment: <Typography sx={Styles.Adornment}>{`${index + 1}. `}</Typography>,
            endAdornment: !isEditInputOnly && (
              <QuestionFormatDropdown
                onSetQuestionFormat={handleSetQuestionFormat}
                onResetAnswers={handleResetAnswers}
                format={format}
              />
            ),
            disableUnderline: true,
          }}
        />
        {!question.length && (
          <FilledIconAlert severity="info" sx={Styles.Alert}>
            {`${t('surveyBuilder.questions.question.alert.partOne')} `}
            <Box component="span" sx={Styles.TextBold}>
              {`${t('surveyBuilder.questions.question.alert.partTwo')} `}
            </Box>{' '}
            {t('surveyBuilder.questions.question.alert.partThree')}
          </FilledIconAlert>
        )}
      </Box>
      <Box sx={Styles.InputRow}></Box>
      <Box sx={Styles.AnswersFormatWrapper}>
        {(format === SurveyQuestionFormat.MultipleChoice || format === SurveyQuestionFormat.CloseEnded) && (
          <Box sx={Styles.AnswersWrapper}>
            <Answers nestIndex={nestIndex} questionIndex={index} format={format} />
          </Box>
        )}
        {format === SurveyQuestionFormat.LinearScale && <LinearScale nestIndex={nestIndex} questionIndex={index} />}
      </Box>
    </>
  );
};
