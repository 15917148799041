import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WeeklyOverviewQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
}>;


export type WeeklyOverviewQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', fullName: string, avatarUrl?: string | null, level?: string | null, latestReceivedWeeklyFeedback?: { __typename?: 'WeeklyFeedback', realizationDate: any, satisfactionScore: number, summary?: string | null, projectSatisfactionScores?: Array<{ __typename?: 'ProjectSatisfaction', satisfactionScore: number, project: { __typename?: 'Project', id: string, projectName: string } } | null> | null, actionsCollection?: { __typename?: 'ActionCollection', items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null } | null> } | null } | null } | null };


export const WeeklyOverviewDocument = gql`
    query weeklyOverview($employeeId: Int!) {
  employee(id: $employeeId) {
    fullName
    avatarUrl
    level
    latestReceivedWeeklyFeedback {
      realizationDate
      satisfactionScore
      projectSatisfactionScores {
        satisfactionScore
        project {
          id
          projectName
        }
      }
      summary
      actionsCollection {
        items {
          id
          content
          actionStatus
        }
      }
    }
  }
}
    `;

/**
 * __useWeeklyOverviewQuery__
 *
 * To run a query within a React component, call `useWeeklyOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyOverviewQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useWeeklyOverviewQuery(baseOptions: Apollo.QueryHookOptions<WeeklyOverviewQuery, WeeklyOverviewQueryVariables> & ({ variables: WeeklyOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeeklyOverviewQuery, WeeklyOverviewQueryVariables>(WeeklyOverviewDocument, options);
      }
export function useWeeklyOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeeklyOverviewQuery, WeeklyOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeeklyOverviewQuery, WeeklyOverviewQueryVariables>(WeeklyOverviewDocument, options);
        }
export function useWeeklyOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WeeklyOverviewQuery, WeeklyOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WeeklyOverviewQuery, WeeklyOverviewQueryVariables>(WeeklyOverviewDocument, options);
        }
export type WeeklyOverviewQueryHookResult = ReturnType<typeof useWeeklyOverviewQuery>;
export type WeeklyOverviewLazyQueryHookResult = ReturnType<typeof useWeeklyOverviewLazyQuery>;
export type WeeklyOverviewSuspenseQueryHookResult = ReturnType<typeof useWeeklyOverviewSuspenseQuery>;
export type WeeklyOverviewQueryResult = Apollo.QueryResult<WeeklyOverviewQuery, WeeklyOverviewQueryVariables>;