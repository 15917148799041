import { Box } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SurveyFormParam } from 'app/surveyForm/SurveyForm.enum';
import { useSetSurveyResponseStatusAsSeen } from 'hooks';
import { SurveyTranslationProvider } from 'providers/SurveyTranslationProvider';
import { Loader } from 'ui';

import * as Styles from './PreviewSurvey.style';
import { PreviewSurveyProps } from './PreviewSurvey.types';
import { useEmployeeSurveyData } from './PreviewSurvey.utils';
import { PreviewSurveyIntro } from './previewSurveyIntro/PreviewSurveyIntro';
import { PreviewSurveyOutro } from './previewSurveyOutro/PreviewSurveyOutro';
import { PreviewSurveyQuestions } from './previewSurveyQuestions/PreviewSurveyQuestions';
import { PreviewSurveyFormType } from './previewSurveyQuestions/PreviewSurveyQuestions.types';

enum PreviewSurveyStep {
  INTRO = 'INTRO',
  QUESTIONS = 'QUESTIONS',
  OUTRO = 'OUTRO',
}

export const PreviewSurvey = ({
  survey,
  markAsSeen,
  isLoading,
  onSubmit = async () => ({}),
  shouldValidate = false,
  refetchData,
}: PreviewSurveyProps) => {
  const [step, setStep] = useState<PreviewSurveyStep>(PreviewSurveyStep.INTRO);
  const [isInit, setIsInit] = useState<boolean>(true);

  const { employeeData, surveryOwnerData, isEmployeeDataLoading } = useEmployeeSurveyData(survey);
  const { setSurveyResponseStatusAsSeen } = useSetSurveyResponseStatusAsSeen();
  const [searchParams] = useSearchParams();
  const [rewardUrl, setRewardUrl] = useState<string | undefined>(undefined);

  const startSurvey = () => {
    setStep(PreviewSurveyStep.QUESTIONS);
    setIsInit(false);
  };

  useEffect(() => {
    if (!markAsSeen) {
      return;
    }

    const urlHash = searchParams.get(SurveyFormParam.Key);

    if (!urlHash) {
      return;
    }

    setSurveyResponseStatusAsSeen({
      variables: {
        urlHash,
      },
    });
  }, [markAsSeen, searchParams, setSurveyResponseStatusAsSeen]);

  useLayoutEffect(() => {
    if (isInit && !isEmployeeDataLoading && survey.response !== null && survey.response?.length) {
      startSurvey();
    }
  }, [isEmployeeDataLoading, survey.response, isInit]);

  const handleSubmit = (data: PreviewSurveyFormType, isUpdateOnly?: boolean) => {
    return onSubmit(data, isUpdateOnly)
      .then((data) => data.endImageUrl)
      .then(setRewardUrl);
  };

  const handleBackToIntro = () => {
    setStep(PreviewSurveyStep.INTRO);

    if (refetchData) {
      refetchData();
    }
  };

  if (isEmployeeDataLoading) {
    return <Loader />;
  }

  const intro = {
    intro: survey.beginning || '',
    introImgName: survey.introImageName || '',
    introImgUrl: survey.introImageUrl || '',
  };

  return (
    <SurveyTranslationProvider language={survey.language}>
      <Box sx={Styles.Container}>
        {step === PreviewSurveyStep.INTRO && (
          <PreviewSurveyIntro
            intro={intro}
            employee={employeeData}
            surveyOwnerData={surveryOwnerData}
            onStartBtnClick={startSurvey}
          />
        )}
        {step === PreviewSurveyStep.QUESTIONS && (
          <PreviewSurveyQuestions
            isLoading={!!isLoading}
            sections={survey.sections || []}
            response={survey.response || []}
            responseStatus={survey.responseStatus}
            employee={employeeData}
            onSubmit={handleSubmit}
            backToIntro={handleBackToIntro}
            goToOutro={() => setStep(PreviewSurveyStep.OUTRO)}
            shouldValidate={shouldValidate}
          />
        )}
        {step === PreviewSurveyStep.OUTRO && <PreviewSurveyOutro outro={survey.conclusion} rewardUrl={rewardUrl} />}
      </Box>
    </SurveyTranslationProvider>
  );
};
