import CloseIcon from '@mui/icons-material/Close';
import EastIcon from '@mui/icons-material/East';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ActionStatus, GoalStatus, SurveyStatus } from 'generated/webapp_gql';
import { getLongFormatDate } from 'shared/utils';
import { ItemTitleWithCheckbox, TableUpdateRowItem } from 'ui';
import { useTodoRowContext } from 'hooks/useTodoRowContext/useTodoRowContext';

import { ClickTypeEnum } from './TableRowItem.enum';
import * as Styles from './TableRowItem.styles';
import { TableRowItemProps } from './TableRowItem.types';
import { dateLabel } from './TableRowItem.utils';

export const TableRowItem = ({
  id,
  content,
  status,
  createdAt,
  updatedAt,
  arrowClickState,
  onClick,
  isReadOnly,
  withoutCheckbox,
  parentId,
  isEditable,
}: TableRowItemProps) => {
  const [editMode, setEditMode] = useState(false);
  const handleUpdate = useTodoRowContext();

  const { t } = useTranslation();

  const editWithDeletion =
    !isReadOnly && [GoalStatus.InProgress, ActionStatus.InProgress, SurveyStatus.Opened].includes(status);

  const isUpdated = [
    GoalStatus.Canceled,
    GoalStatus.Done,
    ActionStatus.Canceled,
    ActionStatus.Done,
    SurveyStatus.Canceled,
    SurveyStatus.Closed,
  ].includes(status);

  const dateTranslation = dateLabel[status];
  const translationPostProcess = dateTranslation.includes('interval') ? 'interval' : undefined;
  const currDate = new Date();
  const createdAtDate = new Date(createdAt);
  const updatedAtDate = new Date(updatedAt);

  if (editMode && handleUpdate)
    return <TableUpdateRowItem key={id} itemId={id} content={content || ''} onCancel={() => setEditMode(false)} />;

  return (
    <Box sx={Styles.Table({ withoutMutation: !editWithDeletion, withoutCheckbox, withEdit: isEditable })} key={id}>
      <ItemTitleWithCheckbox
        onClick={() => onClick(id, ClickTypeEnum.Check, content ?? '')}
        content={content}
        type={status}
        isReadOnly={isReadOnly}
        withoutCheckbox={withoutCheckbox}
      />
      <Box sx={Styles.TableRowDate(!editWithDeletion)}>
        {t(dateTranslation, {
          date: getLongFormatDate(isUpdated ? updatedAtDate : createdAtDate),
          count: differenceInCalendarDays(currDate, createdAtDate),
          postProcess: translationPostProcess,
        })}
      </Box>
      {editWithDeletion && (
        <Fragment>
          <Box sx={Styles.DeleteButton}>
            <IconButton onClick={() => onClick(id, ClickTypeEnum.Delete, content ?? '')} data-cy="toDoItemCancelButton">
              <CloseIcon sx={Styles.DeleteIcon} />
            </IconButton>
          </Box>
          {isEditable && (
            <Box sx={Styles.EditButton}>
              <IconButton onClick={() => setEditMode(true)}>
                <EditIcon sx={Styles.MoreIcon} />
              </IconButton>
            </Box>
          )}
        </Fragment>
      )}

      {parentId && (
        <Box sx={Styles.MoreButton}>
          <IconButton
            component={Link}
            state={arrowClickState?.state}
            to={arrowClickState?.to || ''}
            data-cy="toDoItemForwardButton"
          >
            <EastIcon sx={Styles.MoreIcon} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
