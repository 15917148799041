import { Box } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';
import { SurveyResponseStatus } from 'generated/webapp_gql';

import { IndicatorProps } from './Indicator.types';
import * as Styles from './Indicator.styles';

export const Indicator = ({ type }: IndicatorProps) => {
  switch (type) {
    case SurveyResponseStatus.Sent:
      return (
        <Box component="span" data-testid="noAction" sx={spreadMultipleStyles(Styles.Indicator, Styles.NoAction)} />
      );
    case SurveyResponseStatus.Seen:
      return <Box component="span" data-testid="seen" sx={spreadMultipleStyles(Styles.Indicator, Styles.Seen)} />;
    case SurveyResponseStatus.Answered:
    case SurveyResponseStatus.Reattempted:
      return (
        <Box component="span" data-testid="responded" sx={spreadMultipleStyles(Styles.Indicator, Styles.Responded)} />
      );
    default:
      return (
        <Box component="span" data-testid="noAction" sx={spreadMultipleStyles(Styles.Indicator, Styles.NoAction)} />
      );
  }
};
