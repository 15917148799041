import { useDropzone, useUploadFile } from 'hooks';
import { UploadImageModal } from 'ui';

import { UploadImageProps } from './UploadImage.types';

export const UploadImage = ({
  title,
  description,
  editLabel,
  successMessage,
  setImage,
  setOpenedModal,
  image,
  updateImageName,
}: UploadImageProps) => {
  const onFileUpload = (imgName: string) => {
    setOpenedModal(null);
    updateImageName(imgName);
  };

  const { isFileUploading, uploadFile } = useUploadFile(onFileUpload, successMessage);
  const dropzoneState = useDropzone(setImage);

  return (
    <UploadImageModal
      title={title}
      description={description}
      editLabel={editLabel}
      imageToUpload={image}
      setImageToUpload={setImage}
      onClose={() => setOpenedModal(null)}
      dropzoneState={dropzoneState}
      isFileUploading={isFileUploading}
      uploadFile={uploadFile}
    />
  );
};
