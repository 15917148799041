import { Styles } from 'shared/types';
import { colors, grays } from 'shared/utils';

export const QuestionContainer: Styles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const QuestionBottomControls: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
};

export const CurrentSectionTitle: Styles = {
  color: grays.slateGray,
  font: 20,
  fontWeight: '500',
};

export const User: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const FullName: Styles = {
  mt: 1,
  color: colors.slateGray,
};

export const UserAvatarDescription: Styles = {
  mb: 1,
  color: colors.slateGray,
};
