import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoDataHandler } from 'ui';
import { SurveyAnswersButton } from '../SurveyAnswers.utils';
import { SurveyAnswersQuestion } from '../surveyAnswersQuestion/SurveyAnswersQuestion';
import { SurveyAnswersRespondents } from '../surveyAnswersRespondents/SurveyAnswersRespondents';
import { SurveyAnswersSummary } from '../surveyAnswersSummary/SurveyAnswersSummary';

import { SurveyAnswersTabsProps } from './SurveyAnswersTabs.types';
import * as Styles from './SurveyAnswersTabs.styles';

export const SurveyAnswersTabs = ({ summaryData, tab, changeTab, surveyCountsSummaryData }: SurveyAnswersTabsProps) => {
  const { t } = useTranslation();
  const [initialRespondent, setInitialRespondent] = useState<string | null>(null);

  const goToRespondent = (email: string | null) => {
    if (!email) {
      return;
    }

    changeTab(SurveyAnswersButton.Respondent);
    setInitialRespondent(email);
  };

  if (!summaryData.length) {
    return <NoDataHandler message={t('publishedSurvey.answersTab.noData')} sx={Styles.NoData} />;
  }

  return (
    <>
      {tab === SurveyAnswersButton.Summary && (
        <SurveyAnswersSummary
          questionsData={summaryData}
          summaryData={surveyCountsSummaryData}
          goToRespondent={goToRespondent}
        />
      )}
      {tab === SurveyAnswersButton.Question && <SurveyAnswersQuestion questionsData={summaryData} />}
      {tab === SurveyAnswersButton.Respondent && (
        <SurveyAnswersRespondents questionsData={summaryData} respondentEmail={initialRespondent} />
      )}
    </>
  );
};
