import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { QueryOptions } from '@apollo/client';

import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { useChangeSentSurveyToArchivedMutation } from 'documents/changeSentSurveyToArchived.generated';
import {
  DepartmentsSentSurveysDocument,
  DepartmentsSentSurveysQueryVariables,
} from 'documents/departmentSentSurveys.generated';
import {
  ArchivedSurveyTemplatesDocument,
  ArchivedSurveyTemplatesQueryVariables,
} from 'documents/archivedSurveyTemplates.generated';

type UseChangeSentSurveyToArchivedProps = {
  archived?: Omit<QueryOptions<ArchivedSurveyTemplatesQueryVariables>['variables'], 'employeeId'>;
  sent?: Omit<QueryOptions<DepartmentsSentSurveysQueryVariables>['variables'], 'employeeId'>;
};

export const useChangeSentSurveyToArchived = (variables: UseChangeSentSurveyToArchivedProps) => {
  const { employeeId } = useValidatedParams();
  const { t } = useTranslation();

  const [changeSentSurveyStatus, { loading }] = useChangeSentSurveyToArchivedMutation({
    refetchQueries: [
      {
        query: ArchivedSurveyTemplatesDocument,
        variables: { employeeId, ...(variables.archived ? variables.archived : {}) },
      },
      {
        query: DepartmentsSentSurveysDocument,
        variables: { employeeId, ...(variables.sent ? variables.sent : {}) },
      },
    ],
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(t('surveysList.message.surveyArchived'));
    },
  });

  return { changeSentSurveyStatus, loading };
};
