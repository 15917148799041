import { Box, Button, FormControlLabel, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

import { Switch, TextField } from 'ui';
import { useMe, departmentsData } from 'hooks';
import { TranslatedDepartmentKeys } from 'hooks/useDepartmentsData/useDepartmentsData.types';

import * as Styles from './EmployeesFilters.styles';
import { EmployeesFiltersProps } from './EmployeesFilters.types';

export const EmployeesFilters = ({
  filters: {
    assignedToMe: [assignedToMe, onAssignedToMeChange],
    isAdminMode: [isAdminMode, onIsAdminModeChange],
    isSubcontractorsMode: [isSubcontractorsMode, onIsSubcontractorsModeChange],
    searchText: [searchText, onSearchTextChange],
    filterByDepartments: [filterByDepartments, onFilterByDepartmentsChange],
  },
}: EmployeesFiltersProps) => {
  const { t } = useTranslation();
  const { isAdmin } = useMe();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [searchTextState, setSearchTextState] = useState(searchText);

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      onSearchTextChange(searchTextState);
    }, 500);

    return () => {
      clearTimeout(debounceSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextState]);

  const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTextState(event.target.value);
  };

  const handleFilterDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string | null) => {
    if (!value) {
      onFilterByDepartmentsChange(null);
      return;
    }

    if (filterByDepartments?.includes(value)) {
      const filteredDepartments = filterByDepartments.filter((dep) => dep !== value);
      onFilterByDepartmentsChange(!!filteredDepartments.length ? filteredDepartments : null);
      return;
    }

    onFilterByDepartmentsChange([...(filterByDepartments ?? []), value]);
  };

  return (
    <Box sx={Styles.Container}>
      <Box sx={Styles.FiltersContainer}>
        <TextField
          variant="outlined"
          sx={Styles.TextField}
          InputProps={{
            startAdornment: <SearchIcon sx={Styles.SearchIcon} />,
            endAdornment: (
              <IconButton onClick={() => setSearchTextState(null)} sx={Styles.ClearSearch}>
                <CloseIcon sx={Styles.CloseIcon} />
              </IconButton>
            ),
          }}
          multiline={false}
          value={searchTextState || ''}
          onChange={onTextFieldChange}
          placeholder={t('employeesTopSection.searchPlaceholder')}
        />
        {isAdminMode && (
          <Box>
            <Button
              sx={Styles.DropdownFiltersButton}
              size="small"
              id="employees-filter-button"
              aria-controls={!!anchorEl ? 'employees-filter-dropdown' : undefined}
              endIcon={<ArrowDropDownIcon />}
              onClick={handleFilterDropdown}
            >
              <FilterListIcon sx={Styles.FilterIcon} />
              <Typography variant="body2" sx={Styles.FiltersLabel}>
                {t('employeesTopSection.filters.filterByDepartments')}
              </Typography>
              <Typography variant="body2" sx={Styles.ChosenFilters}>
                {filterByDepartments
                  ? filterByDepartments
                      .map((department) => t(`Departments.${department as TranslatedDepartmentKeys}`))
                      .join(', ')
                  : t(`Departments.All`)}
              </Typography>
            </Button>
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleCloseDropdown}
              id="employees-filter-dropdown"
              MenuListProps={{
                'aria-labelledby': 'employees-filter-button',
                style: Styles.DropdownMenuList,
              }}
              BackdropProps={{
                style: {
                  backgroundColor: 'transparent',
                },
              }}
              PaperProps={{
                style: Styles.DropdownMenu,
              }}
            >
              <MenuItem
                sx={Styles.DropdownMenuItem({ selected: !filterByDepartments, fullRow: true })}
                onClick={() => handleMenuItemClick(null)}
              >
                {t('Departments.All')}
              </MenuItem>
              {Object.entries(departmentsData).map(
                ([code, data]) =>
                  code !== 'Others' && (
                    <MenuItem
                      key={code}
                      sx={Styles.DropdownMenuItem({ selected: !!filterByDepartments?.includes(code) })}
                      onClick={() => handleMenuItemClick(code)}
                    >
                      {data.name}
                    </MenuItem>
                  ),
              )}
              {departmentsData.Others && (
                <MenuItem
                  sx={Styles.DropdownMenuItem({ selected: !!filterByDepartments?.includes('Others'), fullRow: true })}
                  onClick={() => handleMenuItemClick('Others')}
                >
                  {departmentsData.Others.name}
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
      </Box>

      <Box sx={Styles.SwitchesContainer}>
        {isAdmin && isAdminMode && (
          <FormControlLabel
            control={<Switch checked={isSubcontractorsMode} />}
            onChange={(e) => onIsSubcontractorsModeChange((e.target as HTMLInputElement).checked)}
            label={<Typography variant="body2">{t('employeesTopSection.subcontractorsMode')}</Typography>}
            labelPlacement="end"
            sx={Styles.CheckboxLabel}
            disabled={assignedToMe}
          />
        )}
        {isAdmin && (
          <FormControlLabel
            control={<Switch checked={isAdminMode} />}
            onChange={(e) => onIsAdminModeChange((e.target as HTMLInputElement).checked)}
            label={<Typography variant="body2">{t('employeesTopSection.adminMode')}</Typography>}
            labelPlacement="end"
            sx={Styles.CheckboxLabel}
            disabled={assignedToMe}
          />
        )}
        <FormControlLabel
          control={<Switch checked={assignedToMe} />}
          onChange={(e) => onAssignedToMeChange((e.target as HTMLInputElement).checked)}
          label={<Typography variant="body2">{t('employeesTopSection.assignedToMe')}</Typography>}
          labelPlacement="end"
          sx={Styles.CheckboxLabel}
          disabled={isAdminMode}
        />
      </Box>
    </Box>
  );
};
