import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';

import { TextField } from 'ui';
import { ActionStatus, GoalStatus } from 'generated/webapp_gql';

import { OptionalTextFieldProps } from './OptionalTextField.types';
import * as Styles from './OptionalTextField.styles';

export const OptionalTextField = ({
  onChange,
  name,
  error,
  helperText,
  value,
  setValue,
  label,
  multiFields = false,
  isMultiChecked = true,
  inputRef,
  status,
  ...props
}: OptionalTextFieldProps) => {
  const [isFieldEnabled, setIsFieldEnabled] = useState(isMultiChecked);
  const [crossedText, setCrossedText] = useState('');
  const [textBeforeCrossed, setTextBeforeCrossed] = useState('');
  const maxRows = String(value).split(/\r\n|\r|\n/).length;

  useEffect(() => {
    value && setCrossedText(crossText(value as string));
  }, [isFieldEnabled, value, isMultiChecked]);

  useEffect(() => {
    multiFields && handleCheck(!!isMultiChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultiChecked]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCrossedText(event.target.value);
    onChange && onChange(event.target.value);
  };

  const crossText = (text: string) => {
    if (!text) {
      return '';
    }

    setTextBeforeCrossed(text);

    return text
      .split('')
      .map((char: string) => char + '\u0336')
      .join('');
  };

  const handleCheck = (isChecked: boolean) => {
    setIsFieldEnabled(isChecked);

    if (isChecked && textBeforeCrossed) {
      setValue(name, textBeforeCrossed.trim());
    }

    if (!isChecked) {
      setValue(name, undefined);
    }
  };

  const isInProgress = status === ActionStatus.InProgress || status === GoalStatus.InProgress;
  const isDone = status === ActionStatus.Done || status === GoalStatus.Done;

  return (
    <Box sx={Styles.Wrapper}>
      {!multiFields && (
        <Box sx={Styles.Label}>
          <FormControlLabel
            control={
              <Checkbox checked={isFieldEnabled} onChange={(event) => handleCheck(event.currentTarget.checked)} />
            }
            label={label}
          />
        </Box>
      )}
      {isFieldEnabled ? (
        <Box sx={Styles.Wrapper}>
          <Checkbox
            checkedIcon={isDone ? <CheckBoxRoundedIcon /> : <IndeterminateCheckBoxRoundedIcon />}
            disabled={!isInProgress}
            checked={!isInProgress}
            sx={Styles.Checkbox(status)}
          />
          <TextField
            onChange={handleChange}
            onBlur={() => {
              onChange(String(value).trim());
            }}
            name={name}
            error={error}
            helperText={helperText}
            value={isFieldEnabled ? value : crossText(value as string)}
            inputRef={inputRef}
            multiline={false}
            maxRows={maxRows}
            fullWidth
            {...props}
          />
        </Box>
      ) : (
        <TextField
          error={error}
          helperText={helperText}
          onChange={handleChange}
          value={crossedText}
          disabled={true}
          multiline={false}
          maxRows={3}
        />
      )}
    </Box>
  );
};
