import { Styles } from 'shared/types';

export const CustomPopper: Styles = {
  height: '100%',
  maxHeight: '420px',
};

export const PopperOverride = {
  maxWidth: '486px',
  width: '100%',
};
