import { ErrorInfoTranslationKeys } from './PhotoUploadErrorInfo.types';

export const getErrorInfoTranslationKeys = (errorCode: string): ErrorInfoTranslationKeys => {
  if (errorCode === 'file-invalid-type')
    return {
      heading: 'surveyBuilder.questions.addPhotoDialog.errorMessage.wrongFormat',
      description: 'surveyBuilder.questions.addPhotoDialog.errorMessage.availableFormats',
      requirements: 'surveyBuilder.questions.addPhotoDialog.errorMessage.formats',
    };

  if (errorCode === 'file-too-large')
    return {
      heading: 'surveyBuilder.questions.addPhotoDialog.errorMessage.tooLargeFile',
      description: 'surveyBuilder.questions.addPhotoDialog.errorMessage.maxFileSize',
      requirements: 'surveyBuilder.questions.addPhotoDialog.errorMessage.fileSize',
    };

  return {
    heading: 'surveyBuilder.questions.addPhotoDialog.errorMessage.somethingWentWrong',
  };
};
