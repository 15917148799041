import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';

import { QuestionsWrapperProps } from 'app/questionsWrapper/QuestionsWrapper.types';
import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { SurveyQuestion, SurveyQuestionFormat } from 'generated/webapp_gql';
import { useSurveyView } from 'hooks/useSurveyView/useSurveyView';

import { Questions } from './questions/Questions';
import { SectionInfo } from './sectionInfo/SectionInfo';

const defaultQuestion: SurveyQuestion = {
  answers: [{}, {}],
  question: '',
  format: SurveyQuestionFormat.OpenEnded,
};

export const QuestionsWrapper = ({ nestIndex }: QuestionsWrapperProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<SurveyFormData>();
  const { isEditInputOnly } = useSurveyView();

  const { fields, append, move, remove } = useFieldArray({
    name: `sections.${nestIndex}.questions`,
    control,
  });

  return (
    <Box>
      <SectionInfo nestIndex={nestIndex} questionCount={fields.length} />

      <Questions nestIndex={nestIndex} questions={fields} remove={remove} move={move} />

      {!isEditInputOnly && (
        <Button
          startIcon={<AddCircleIcon />}
          variant="text"
          onClick={() => append(defaultQuestion)}
          onMouseDown={(event) => {
            event.preventDefault();
          }}
        >
          {t('surveyBuilder.questions.addQuestion')}
        </Button>
      )}
    </Box>
  );
};
