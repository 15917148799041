import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CardNoteProps } from './CardNote.types';
import * as Styles from './CardNote.styles';

export const CardNote = ({ notes }: CardNoteProps) => {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(true);

  if (!notes) {
    return null;
  }

  return (
    <Box sx={Styles.CardSection}>
      <Typography variant="subtitle2" sx={Styles.CardSectionLabel}>
        {t('report.card.note')}
      </Typography>
      <Typography
        variant="body2"
        sx={Styles.CardSectionContent(isHidden)}
        onClick={() => setIsHidden((prevState) => !prevState)}
        dangerouslySetInnerHTML={{ __html: notes || '' }}
      />
    </Box>
  );
};
