import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { WeeklyFeedbackForm } from 'app/weeklyFeedbackForm/weeklyFeedbackForm/WeeklyFeedbackForm';
import { ActionStatus } from 'generated/webapp_gql';
import { useCreateWeeklyFeedback, useEmployeeWeeklyCurrentProjects, useValidatedParams } from 'hooks';
import { getFullFormatDate } from 'shared/utils';
import { addWeeklyFeedbackFormSchema } from '../weeklyFeedbackForm/WeeklyFeedbackForm.schema';
import { CreateWeeklyFeedbackFormData, WeeklyAction } from '../weeklyFeedbackForm/WeeklyFeedbackForm.types';

export const AddWeeklyFeedbackFormContainer = () => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { createWeeklyFeedback, isCreateWeeklyFeedbackLoading } = useCreateWeeklyFeedback(employeeId);
  const { employeeCurrentProjectsData, isEmployeeCurrentProjectsLoading } = useEmployeeWeeklyCurrentProjects();
  const weeklyFeedbackFormMethods = useForm<CreateWeeklyFeedbackFormData>({
    resolver: yupResolver(addWeeklyFeedbackFormSchema),
    defaultValues: {
      realizationDate: new Date(),
      actions: [{ content: '', actionStatus: ActionStatus.InProgress }],
    },
  });

  const onSubmit = ({
    summary,
    satisfactionScore,
    notes,
    realizationDate,
    actions,
    projectsSatisfaction,
  }: CreateWeeklyFeedbackFormData) => {
    createWeeklyFeedback({
      variables: {
        input: {
          satisfactionScore,
          summary,
          notes,
          receiverId: employeeId,
          realizationDate: getFullFormatDate(realizationDate),
          actions: actions?.filter((action) => !!action.content),
          projectsSatisfaction:
            projectsSatisfaction?.length === 0
              ? undefined
              : projectsSatisfaction?.map(({ projectId, projectSatisfactionScore }) => ({
                  projectId,
                  projectSatisfactionScore,
                })),
        },
      },
    });
    weeklyFeedbackFormMethods.reset({}, { keepValues: true });
  };

  useEffect(() => {
    if (employeeCurrentProjectsData) {
      weeklyFeedbackFormMethods.setValue(
        'projectsSatisfaction',
        employeeCurrentProjectsData.currentProjectsDetails?.reduce<
          CreateWeeklyFeedbackFormData['projectsSatisfaction']
        >((prev, project) => {
          if (!project || !prev) return prev;

          return [
            ...prev,
            {
              projectId: project.id,
              projectSatisfactionScore: 0,
              projectName: project.projectName,
            },
          ];
        }, []) ?? [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeCurrentProjectsData]);

  if (isEmployeeCurrentProjectsLoading) return null;

  return (
    <FormProvider {...weeklyFeedbackFormMethods}>
      <WeeklyFeedbackForm
        onSubmit={weeklyFeedbackFormMethods.handleSubmit(onSubmit)}
        mutationLoading={isCreateWeeklyFeedbackLoading}
        action={WeeklyAction.CREATE}
      />
    </FormProvider>
  );
};
