import { alpha } from '@mui/material';

import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Container: Styles = {
  width: '100%',
  height: '100%',
  display: 'flex',
};

export const Backdrop: Styles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: alpha(colors.white, 0.8),
  zIndex: 10,
};

export const Label: Styles = {
  color: colors.blueberry,
  mr: 1,
  fontWeight: 600,
};

export const Loader: Styles = {
  color: colors.blueberry,
};
