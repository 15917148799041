import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const DeleteButton: Styles = {
  width: 36,
  height: 36,
  color: colors.tomato,

  '& svg': {
    width: 24,
    height: 24,
  },

  '&:disabled': {
    color: colors.disabledGray,
  },
};
