import { ActionStatus, GoalStatus, SurveyStatus } from 'generated/webapp_gql';
import { Styles } from 'shared/types';

export const Checkbox = (type: ActionStatus | GoalStatus | SurveyStatus | undefined | null): Styles => ({
  path: {
    fill: ({ palette }) =>
      type === ActionStatus.Canceled || type === GoalStatus.Canceled
        ? `${palette.text.secondary}`
        : `${palette.common.black}`,
    opacity: type && [GoalStatus.Done, ActionStatus.Done, SurveyStatus.Closed].includes(type) ? 0.2 : 1,
  },
});

export const Title = (
  type: ActionStatus | GoalStatus | SurveyStatus | undefined | null,
  withoutCheckbox?: boolean,
): Styles => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  alignSelf: 'center',
  fontSize: 14,
  gridArea: 'title',
  justifySelf: 'start',
  paddingLeft: withoutCheckbox ? 2 : '',
  ...(type === ActionStatus.Canceled && {
    textDecorationLine: 'line-through',
  }),
  color: ({ palette }) => (type === ActionStatus.InProgress ? `${palette.text.primary}` : `${palette.text.secondary}`),
  wordBreak: 'break-word',
});

export const CheckboxWrapper: Styles = {
  placeSelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
};
