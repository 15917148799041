import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReopenSurveyForRespondentMutationVariables = Types.Exact<{
  urlHash: Types.Scalars['String']['input'];
}>;


export type ReopenSurveyForRespondentMutation = { __typename?: 'Mutation', reopenSurveyForRespondent?: boolean | null };


export const ReopenSurveyForRespondentDocument = gql`
    mutation ReopenSurveyForRespondent($urlHash: String!) {
  reopenSurveyForRespondent(urlHash: $urlHash)
}
    `;
export type ReopenSurveyForRespondentMutationFn = Apollo.MutationFunction<ReopenSurveyForRespondentMutation, ReopenSurveyForRespondentMutationVariables>;

/**
 * __useReopenSurveyForRespondentMutation__
 *
 * To run a mutation, you first call `useReopenSurveyForRespondentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenSurveyForRespondentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenSurveyForRespondentMutation, { data, loading, error }] = useReopenSurveyForRespondentMutation({
 *   variables: {
 *      urlHash: // value for 'urlHash'
 *   },
 * });
 */
export function useReopenSurveyForRespondentMutation(baseOptions?: Apollo.MutationHookOptions<ReopenSurveyForRespondentMutation, ReopenSurveyForRespondentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReopenSurveyForRespondentMutation, ReopenSurveyForRespondentMutationVariables>(ReopenSurveyForRespondentDocument, options);
      }
export type ReopenSurveyForRespondentMutationHookResult = ReturnType<typeof useReopenSurveyForRespondentMutation>;
export type ReopenSurveyForRespondentMutationResult = Apollo.MutationResult<ReopenSurveyForRespondentMutation>;
export type ReopenSurveyForRespondentMutationOptions = Apollo.BaseMutationOptions<ReopenSurveyForRespondentMutation, ReopenSurveyForRespondentMutationVariables>;