import { CreateActionInput, CreateGoalInput } from 'generated/webapp_gql';

import { NewItemsReducerGeneric, UseSortNewItemsProps, UseSortNewItemsReturn } from './sortNewItems.type';

export const sortNewItems = ({ oldItems, newItems }: UseSortNewItemsProps): UseSortNewItemsReturn => {
  if (!oldItems) {
    return {
      updatedItems: [],
      createdItems: <CreateActionInput[] | CreateGoalInput[]>newItems,
    };
  }

  return newItems.reduce<NewItemsReducerGeneric>(
    ({ updatedItems, createdItems }, curr) => {
      if (!curr.content) {
        return {
          updatedItems,
          createdItems,
        };
      }

      if ('id' in curr && oldItems.some((oldAction) => curr.id === oldAction?.id)) {
        return {
          updatedItems: [...updatedItems, curr],
          createdItems,
        };
      }

      return {
        updatedItems,
        createdItems: [...createdItems, <CreateActionInput | CreateGoalInput>curr],
      };
    },
    { updatedItems: [], createdItems: [] },
  );
};
