import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as Styles from './NoEmployeesMessage.styles';

export const NoEmployeesMessage = () => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.Wrapper}>
      <Typography sx={Styles.Emoji} variant="h5">
        😊
      </Typography>
      <Typography variant="body2">{t('employeesTable.noData')}</Typography>
    </Box>
  );
};
