import { Styles } from 'shared/types';

export const Satisfaction: Styles = {
  '& label': {
    flex: '0 1 auto',
    textAlign: 'center',
  },
  '& .MuiFormGroup-root': {
    flex: 1,
    textAlign: 'center',
    mt: 1,
    gap: 2,
  },
};
