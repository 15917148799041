import { useState } from 'react';

export const useGrumpyCat = () => {
  const [isGrumpyCatDisplayed, setIsGrumpyCatDisplayed] = useState(false);

  const showGrumpyCat = () => {
    setIsGrumpyCatDisplayed(true);
  };

  const hideGrumpyCat = () => {
    setIsGrumpyCatDisplayed(false);
  };

  return { showGrumpyCat, hideGrumpyCat, isGrumpyCatDisplayed };
};
