import React from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'app/layout/Layout';
import { useMe } from 'hooks';
import { Loader, NotAuthenticatedMessage } from 'ui';

export function UserRoute() {
  const { meError: userDataError, isMeDataLoading: isUserDataLoading } = useMe();

  if (isUserDataLoading) {
    return <Loader />;
  }

  if (userDataError)
    return (
      <Layout>
        <NotAuthenticatedMessage />
      </Layout>
    );

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
