import { useTranslation } from 'react-i18next';

import { BackButton, CardSidebar, Loader, PageTitle } from 'ui';
import {
  useAllSurveysOfEmployee,
  useEmployee,
  useEmployeeSurveysSearchParams,
  useSearchParamsState,
  useValidatedParams,
} from 'hooks';
import { EmployeeSection } from 'app/employeeDetails/employeeSection/EmployeeSection';
import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';

import { allSurvyesDataLoader, surveysTabsNames } from './EmployeeSurveys.utils';
import { SurveysAccordion } from './surveysAccordion/SurveysAccordion';

export const EmployeeSurveys = () => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const { t } = useTranslation();

  const { params, setSearchParams } = useEmployeeSurveysSearchParams();
  const [pages, setPages] = useSearchParamsState({
    searchParams: params,
    params: ['opened', 'closed'],
    setSearchParams,
  });

  const { employeeData, isEmployeeDataLoading } = useEmployee();

  const { allSurveysData, prevAllSurveysData } = useAllSurveysOfEmployee({ pages });

  if (isEmployeeDataLoading) return <Loader />;

  if (!employeeData) return null;

  return (
    <CardSidebar
      button={<BackButton route={`${AppRoute.EmployeeDetails}/${employeeId}/${EmployeeDetailsViewParam.Quarterly}`} />}
      sidebar={<EmployeeSection employee={employeeData} />}
    >
      <PageTitle title={t('surveyTemplates.title')} />

      {surveysTabsNames.map((type) => (
        <SurveysAccordion
          {...allSurvyesDataLoader({ allSurveysData, prevAllSurveysData, type })}
          type={type}
          key={type}
          pages={pages}
          setPages={setPages}
        />
      ))}
    </CardSidebar>
  );
};
