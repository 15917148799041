import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

import { AddButtonProps } from 'ui/addButton/AddButton.types';

export const AddButton = ({ title, onClick, variant = 'contained', ...props }: AddButtonProps) => {
  return (
    <Button startIcon={<AddIcon />} variant={variant} color="primary" onClick={onClick} {...props}>
      {title}
    </Button>
  );
};
