import AvatarBg from 'assets/images/AvatarBg.svg';
import { Styles } from 'shared/types';

import { Size } from './AvatarWithFallback.types';

const SIZES: Record<Size, number> = {
  xs: 32,
  s: 40,
  m: 48,
  l: 64,
  xl: 88,
  xxl: 104,
};

const FONT_SIZES: Record<Size, number> = {
  xs: 16,
  s: 20,
  m: 24,
  l: 32,
  xl: 44,
  xxl: 56,
};

export const Avatar = (size: Size): Styles => ({
  width: SIZES[size],
  height: SIZES[size],
  backgroundImage: `url(${AvatarBg})`,
  backgroundClip: 'padding-box',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: 'text.primary',
  fontSize: FONT_SIZES[size],
});
