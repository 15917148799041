import { Styles } from 'shared/types';

export const Wrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 3,
  py: 10,
};

export const Title: Styles = {
  mt: 3,
};

export const Description: Styles = {
  width: 664,
};
