import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { parse } from 'date-fns';
import { Navigate, useNavigate } from 'react-router-dom';

import { Loader } from 'ui';
import { QuarterlyReportForm } from 'app/quarterlyReportForm/quarterlyReportForm/QuarterlyReportForm';
import {
  useEmployee,
  useQuarterlyReport,
  useReadOnlyEmployee,
  useUpdateQuarterlyReport,
  useValidatedParams,
} from 'hooks';
import { UpdateQuarterlyReportFormData } from '../quarterlyReportForm/QuarterlyReportForm.types';
import { getFullFormatDate } from 'shared/utils';
import { GoalStatus } from 'generated/webapp_gql';
import { client } from 'apollo-client';
import { QuarterlyReportDocument, QuarterlyReportQuery } from 'documents/quarterlyReport.generated';
import { sortNewItems } from 'app/weeklyFeedbackForm/utils/sortNewItems/sortNewItems';
import {
  getAddQuarterlyReportFormSchema,
  getEditQuarterlyReportFormSchema,
} from '../quarterlyReportForm/QuarterlyReportForm.schema';
import { AppRoute } from 'routing/AppRoute.enum';

export const EditQuarterlyReportFormContainer = () => {
  const { employeeId, reportId } = useValidatedParams(['employeeId', 'reportId']);
  const { updateQuarterlyReport, isUpdateQuarterlyReportLoading } = useUpdateQuarterlyReport(employeeId);

  const quarterlyReportQueryOptions = {
    query: QuarterlyReportDocument,
    variables: {
      quarterlyReportId: reportId,
    },
  };
  const navigate = useNavigate();

  const { employeeData, isEmployeeDataLoading } = useEmployee({ onError: () => navigate(AppRoute.Home) });
  const { isReadOnly, isReadOnlyLoading } = useReadOnlyEmployee(employeeId);

  const currentDate = new Date();
  const hiredDate = employeeData?.hiredDate ? parse(employeeData.hiredDate, 'yyyy-MM-dd', currentDate) : currentDate;

  const quarterlyReportCache = client.cache.readQuery<QuarterlyReportQuery>(quarterlyReportQueryOptions);

  const numberOfGoalsInReport =
    quarterlyReportCache && quarterlyReportCache.quarterlyReport?.goalsCollection?.total
      ? quarterlyReportCache.quarterlyReport?.goalsCollection.total
      : 0;

  const quarterlyReportFormMethods = useForm<UpdateQuarterlyReportFormData>({
    resolver: yupResolver(
      numberOfGoalsInReport === 0
        ? getAddQuarterlyReportFormSchema(hiredDate)
        : getEditQuarterlyReportFormSchema(hiredDate),
    ),
    defaultValues: {
      realizationDate: new Date(),
    },
  });

  const { quarterlyReportData, isQuarterlyReportLoading } = useQuarterlyReport(reportId, {
    onCompleted: ({ quarterlyReport }) =>
      quarterlyReportFormMethods.reset({
        summary: quarterlyReport?.summary,
        notes: quarterlyReport?.notes,
        realizationDate: quarterlyReport?.realizationDate ? new Date(quarterlyReport.realizationDate) : new Date(),
        goals: quarterlyReport?.goalsCollection?.items.length
          ? quarterlyReport?.goalsCollection.items.map((goal) => ({
              id: goal?.id,
              content: goal?.content,
              goalStatus: goal?.goalStatus,
            }))
          : [{ content: '', goalStatus: GoalStatus.InProgress }],
      }),
    skip: false,
  });

  const onSubmit = async ({ summary, notes, realizationDate, goals }: UpdateQuarterlyReportFormData) => {
    const { createdItems: createdGoals, updatedItems: updatedGoals } = sortNewItems({
      oldItems: quarterlyReportData.goalsCollection?.items,
      newItems: goals ?? [],
    });

    await updateQuarterlyReport({
      variables: {
        createGoals: {
          quarterlyReportId: reportId || '',
          goals: createdGoals,
        },
        updateQuarterlyReport: {
          summary,
          notes,
          quarterlyReportId: reportId || '',
          realizationDate: getFullFormatDate(realizationDate),
          goals: updatedGoals,
        },
      },
    });
  };

  if (isQuarterlyReportLoading || isEmployeeDataLoading || isReadOnlyLoading) return <Loader />;

  if (!employeeData) return null;

  if (isReadOnly) return <Navigate to={AppRoute.Home} replace={true} />;

  return (
    <FormProvider {...quarterlyReportFormMethods}>
      <QuarterlyReportForm
        onSubmit={quarterlyReportFormMethods.handleSubmit(onSubmit)}
        mutationLoading={isUpdateQuarterlyReportLoading}
        hiredDate={hiredDate}
        employeeData={employeeData}
      />
    </FormProvider>
  );
};
