import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';

import { SelectProps } from './Select.types';
import * as Styles from './Select.styles';

export const Select = ({ value, items, variant = 'standard', error, label, helperText, sx, ...props }: SelectProps) => {
  return (
    <FormControl variant={variant} size={props.size} fullWidth>
      <InputLabel className={props.size ? `Size-${props.size}` : ''} sx={error ? Styles.LabelError : Styles.Label}>
        {label}
      </InputLabel>
      <MuiSelect
        variant={variant}
        size={props.size}
        label={label}
        value={value}
        error={error}
        sx={spreadMultipleStyles(Styles.Select, sx)}
        {...props}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText ? <FormHelperText sx={Styles.HelperText}>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};
