import { useContext } from 'react';

import { SurveyTabsSortingContext } from './SurveyTabsSortingContext';

export const useSurveyTabsSortingContext = () => {
  const sortingContext = useContext(SurveyTabsSortingContext);

  if (sortingContext === null) throw new Error("SortingContext was used outside it's provider");

  return sortingContext;
};
