import { toast } from 'react-toastify';

import { useSetSurveyResponseStatusAsSeenMutation } from 'documents/setSurveyResponseStatusAsSeen.generated';

export const useSetSurveyResponseStatusAsSeen = () => {
  const [setSurveyResponseStatusAsSeen] = useSetSurveyResponseStatusAsSeenMutation({
    onError: (error) => toast.error(error.message),
  });

  return {
    setSurveyResponseStatusAsSeen,
  };
};
