import { MainContainer } from 'ui';
import { useEmployeesFilters, useEmployeesPagination, useEmployeesSorting, useMe } from 'hooks';

import { EmployeesTopSection } from './employeesTopSection/EmployeesTopSection';
import * as Styles from './Employees.styles';
import { EmployeesTablesContainer } from './employeesTable/EmployeesTablesContainer';

export const Employees = () => {
  const { isTM } = useMe();

  const filters = useEmployeesFilters({ isTM });
  const pagination = useEmployeesPagination();
  const sorts = useEmployeesSorting();

  return (
    <MainContainer topSection={<EmployeesTopSection filters={filters} />} sx={Styles.MainContainer}>
      <EmployeesTablesContainer filters={filters} pagination={pagination} sorts={sorts} />
    </MainContainer>
  );
};
