import { Box, Button, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextField } from 'ui';
import { QuestionsSectionWrapper } from 'app/surveyEditor/surveyEditorQuestions/questionsSection/QuestionsSectionWrapper';
import { SurveyLanguageController } from 'context/surveyLanguage/SurveyLanguageController';
import { useSurveyImages, useValidatedParams } from 'hooks';
import { AsyncStatus } from 'shared/utils/types';
import { useEditSentSurvey } from 'hooks/useUpdateSentSurvey/useUpdateSentSurvey';
import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { SectionWithImage } from 'app/surveyBuilder/surveyBuilderQuestions/sectionWithImage/SectionWithImage';

import { Section } from './SurveyEditorQuestions.enum';
import * as Styles from './SurveyEditorQuestions.styles';
import { SurveyReceiverSection } from './surveyReceiverSection/SurveyReceiverSection';
import { SurveyEditorQuestionsProps } from './SurveyEditorQuestions.types';

export const SurveyEditorQuestions = ({ goToPublicationPage }: SurveyEditorQuestionsProps) => {
  const { t } = useTranslation();
  const { surveyTemplateId, surveyId } = useValidatedParams(['surveyTemplateId', 'surveyId']);
  const [isCopyToDraftEnabled, setIsCopyToDraftEnabled] = useState(false);

  const {
    setValue,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { isDirty, isValid },
  } = useFormContext<SurveyFormData>();
  const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>(AsyncStatus.Idle);
  const [, setForceUpdate] = useState(0);

  const {
    openedModal,
    setOpenedModal,
    introImage,
    handleIntroImageChange,
    outroImage,
    handleOutroImageChange,
    updateIntroImageName,
    updateOutroImageName,
  } = useSurveyImages(setValue, setForceUpdate);

  const language = useWatch({ name: 'language' });

  const { editSentSurveyMutation } = useEditSentSurvey();

  const handleCopyToDraftChange = () => {
    setIsCopyToDraftEnabled(!isCopyToDraftEnabled);
  };

  const onEditSurveyTemplate = async ({
    title,
    beginning,
    conclusion,
    sections,
    endImageName,
    introImageName,
  }: SurveyFormData) => {
    setAsyncStatus(AsyncStatus.Loading);

    const content = {
      beginning,
      conclusion,
      sections: sections.map(({ __typename, ...section }) => ({
        ...section,
        questions: section.questions.map((question) => {
          if (question && question?.__typename) {
            const { __typename, ...rest } = question;
            return rest;
          }
          return question;
        }),
      })),
    };

    try {
      await editSentSurveyMutation({
        variables: {
          editSentSurveyInput: {
            title,
            content,
            endImageName,
            introImageName,
            id: surveyId,
            isCopyToDraftEnabled,
          },
        },
      });
      setAsyncStatus(AsyncStatus.Success);
    } catch {
      setAsyncStatus(AsyncStatus.Failed);
    }
  };
  const isLoading = asyncStatus === AsyncStatus.Loading;

  const handleEditSurveyTemplateInputs = async () => {
    if (!surveyTemplateId || isDirty) {
      await onEditSurveyTemplate(getValues());
      reset({}, { keepValues: true });
    }
    goToPublicationPage();
  };

  const handleOpenedModal = (section: Section | null) => {
    setOpenedModal(section);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onEditSurveyTemplate)} sx={Styles.QuestionsWrapper}>
      <ControlledTextField
        control={control}
        name="title"
        placeholder={t('surveyBuilder.questions.surveyTitlePlaceholder')}
        sx={Styles.Title}
        formControlSx={Styles.TitleFormControl}
      />

      <SurveyReceiverSection />
      <SectionWithImage
        label={t('surveyBuilder.questions.intro.title')}
        addImageLabel={t('surveyBuilder.questions.addPhoto')}
        image={introImage}
        imageSrc={introImage?.preview || getValues().introImageUrl}
        setImage={handleIntroImageChange}
        isModalOpened={openedModal === Section.Beginning}
        sectionName={Section.Beginning}
        setOpenedModal={handleOpenedModal}
        control={control}
        updateImageName={updateIntroImageName}
      />
      <SurveyLanguageController language={language}>
        <QuestionsSectionWrapper />
      </SurveyLanguageController>
      <SectionWithImage
        label={t('surveyBuilder.questions.outro.title')}
        addImageLabel={t('surveyBuilder.questions.addMeme.title')}
        editImageLabel={t('surveyBuilder.questions.addMeme.edit')}
        addImageDescription={t('surveyBuilder.questions.addMeme.description')}
        addImageSuccessMessage={t('surveyBuilder.questions.addMeme.successMessage')}
        image={outroImage}
        imageSrc={outroImage?.preview || getValues().endImageUrl}
        setImage={handleOutroImageChange}
        isModalOpened={openedModal === Section.Conclusion}
        sectionName={Section.Conclusion}
        setOpenedModal={handleOpenedModal}
        control={control}
        updateImageName={updateOutroImageName}
      />

      <Box sx={Styles.Buttons}>
        <Tooltip title={t('surveyEditor.editSurvey.createDraft.tooltip')} arrow placement="bottom">
          <FormControlLabel
            control={<Switch checked={isCopyToDraftEnabled} />}
            onChange={handleCopyToDraftChange}
            label={<Typography variant="body2">{t('surveyEditor.editSurvey.createDraft.title')}</Typography>}
            labelPlacement="end"
          />
        </Tooltip>
        <Button
          type="button"
          variant="contained"
          onClick={handleEditSurveyTemplateInputs}
          disabled={!isValid || isLoading}
        >
          {t('surveyEditor.editSurvey.confirmButton')}
        </Button>
      </Box>
    </Box>
  );
};
