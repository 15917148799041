import React, { useMemo, useState } from 'react';

import {
  SurveyTabsSortingContextProps,
  SurveyTabsSortingControllerProps,
  SurveyTabsSortVariables,
} from './SurveyTabsSortingController.types';
import { SurveyTabsSortingContext } from './SurveyTabsSortingContext';

export const SurveyTabsSortingController = ({ children }: SurveyTabsSortingControllerProps) => {
  const [sortingParams, setSortingParams] = useState<SurveyTabsSortVariables>({});

  const value: SurveyTabsSortingContextProps = useMemo(
    () => ({
      sortingParams,
      setSortingParams,
    }),
    [sortingParams],
  );

  return <SurveyTabsSortingContext.Provider value={value}>{children}</SurveyTabsSortingContext.Provider>;
};
