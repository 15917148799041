import { Box, Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';

import { CreateSurveyForm } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { SurveyReceiver } from '../../SurveyPublicationModal.types';

import * as Styles from './AutocompleteListbox.styles';
import { AutocompleteListboxProps } from './AutocompleteListbox.types';

export const AutocompleteListbox = ({
  setCurrentReceivers,
  currentReceivers,
  sendMethod,
  surveyReceiversData,
  forwardedRef,
  scrollPosition,
  ...props
}: AutocompleteListboxProps) => {
  const { t } = useTranslation();
  const { children, ...rest } = props;

  const innerRef = useRef<HTMLUListElement>(null);
  useImperativeHandle(forwardedRef, () => innerRef.current);

  const receivers = useWatch<CreateSurveyForm>({
    name: 'receivers',
  }) as SurveyReceiver[];

  useEffect(() => {
    if (innerRef?.current?.scrollTo) {
      innerRef.current.scrollTo({ top: scrollPosition });
    }
  }, [currentReceivers, scrollPosition]);

  const allPossibleSurveyReceivers = useMemo(() => {
    if (surveyReceiversData) {
      const filteredReceivers = surveyReceiversData?.items
        .filter((surveyReceiver) => surveyReceiver && !surveyReceiver.receivedSurveyStatus)
        .map((surveyReceiver) => ({
          ...surveyReceiver,
          notificator: [sendMethod],
        }));
      return filteredReceivers as SurveyReceiver[];
    }
    return receivers;
  }, [receivers, surveyReceiversData, sendMethod]);

  const handleCheckAll = useCallback(() => {
    setCurrentReceivers(uniqBy([...currentReceivers, ...allPossibleSurveyReceivers], 'id'));
  }, [allPossibleSurveyReceivers, setCurrentReceivers, currentReceivers]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={6} sx={Styles.PeopleListHeader}>
          <Typography variant="subtitle2">{t('surveyBuilder.surveyPublicationModal.people')}</Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Button variant="text" size="small" onClick={handleCheckAll} data-testid="checkAllTestId">
            {t('surveyBuilder.surveyPublicationModal.checkAll', { count: allPossibleSurveyReceivers.length })}
          </Button>
        </Grid>
      </Grid>
      <Box component="ul" {...rest} ref={innerRef} role="list-box" sx={Styles.ListboxContainer}>
        {children}
      </Box>
    </Box>
  );
};
