import { FileUploadOutlined } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDownloadSurveyResponsesCsv, useEmployee } from 'hooks';

import * as Styles from './ExportCsvButton.styles';
import { ExportCsvButtonProps } from './ExportCsvButton.types';

export const ExportCsvButton = ({ disabled }: ExportCsvButtonProps) => {
  const { t } = useTranslation();
  const { employeeData } = useEmployee();

  const { downloadSurveyAnswers, isDownloadingCsv } = useDownloadSurveyResponsesCsv({
    fileName: `${employeeData?.firstName} ${employeeData?.lastName} - Survey ${new Date().toLocaleDateString()}.csv`,
  });

  const isDownloadButtonDisabled = isDownloadingCsv || !employeeData || disabled;

  return (
    <Button
      variant="text"
      sx={Styles.DownloadButton}
      disabled={isDownloadButtonDisabled}
      startIcon={isDownloadingCsv ? null : <FileUploadOutlined />}
      onClick={downloadSurveyAnswers}
    >
      {isDownloadingCsv ? (
        <CircularProgress data-testid="downloadingIndicator" sx={Styles.Loader} size={24} />
      ) : (
        t('publishedSurvey.answersTab.utilityButtons.export')
      )}
    </Button>
  );
};
