export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Array: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  JSON: { input: any; output: any; }
  searchedValue_String_NotNull_minLength_1: { input: any; output: any; }
  searchedValue_String_NotNull_minLength_3: { input: any; output: any; }
  searchedValue_String_minLength_1: { input: any; output: any; }
};

export enum AccessType {
  Admin = 'admin',
  Employee = 'employee',
  SubcontractorsAdmin = 'subcontractors_admin',
  Supervisor = 'supervisor',
  TeamManager = 'team_manager'
}

export type Action = {
  __typename?: 'Action';
  actionStatus?: Maybe<ActionStatus>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  employee?: Maybe<Employee>;
  employeeId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  realizationDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  weeklyFeedback?: Maybe<WeeklyFeedback>;
  weeklyFeedbackId?: Maybe<Scalars['String']['output']>;
};

export type ActionCollection = {
  __typename?: 'ActionCollection';
  items: Array<Maybe<Action>>;
  total: Scalars['Int']['output'];
};

export enum ActionStatus {
  Canceled = 'CANCELED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export type Answer = {
  __typename?: 'Answer';
  answer?: Maybe<Array<Scalars['String']['output']>>;
  format: SurveyQuestionFormat;
  question: Scalars['String']['output'];
};

export enum AuthRole {
  PspAccess = 'psp_access',
  PspAdmin = 'psp_admin',
  PspManager = 'psp_manager',
  PspSubcontractorsAdmin = 'psp_subcontractors_admin'
}

export type ChangeSentSurveyToArchivedInput = {
  id: Scalars['String']['input'];
};

export type CopySurveyTemplateInput = {
  id: Scalars['String']['input'];
};

export type CreateActionInput = {
  actionStatus?: InputMaybe<ActionStatus>;
  content: Scalars['String']['input'];
  realizationDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateActionWithoutWeeklyInput = {
  action: CreateActionInput;
  employeeId: Scalars['Int']['input'];
};

export type CreateActionsInput = {
  actions: Array<CreateActionInput>;
  weeklyFeedbackId: Scalars['ID']['input'];
};

export type CreateGoalInput = {
  content: Scalars['String']['input'];
  goalStatus?: InputMaybe<GoalStatus>;
  realizationDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateGoalsInput = {
  goals: Array<CreateGoalInput>;
  quarterlyReportId: Scalars['ID']['input'];
};

export type CreateQuarterlyReportGoalInput = {
  content: Scalars['String']['input'];
  goalStatus?: InputMaybe<GoalStatus>;
};

export type CreateQuarterlyReportInput = {
  goals?: InputMaybe<Array<InputMaybe<CreateQuarterlyReportGoalInput>>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  realizationDate?: InputMaybe<Scalars['Date']['input']>;
  receiverId: Scalars['Int']['input'];
  status?: InputMaybe<QuarterlyReportStatus>;
  summary: Scalars['String']['input'];
};

export type CreateSurveyInput = {
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  concernedId: Scalars['Int']['input'];
  receivers?: InputMaybe<Array<NewSurveyReceiver>>;
  reminderDaysBeforeSurveyClose?: InputMaybe<Scalars['Int']['input']>;
  sendDate?: InputMaybe<Scalars['DateTime']['input']>;
  surveyTemplateId: Scalars['String']['input'];
};

export type CreateSurveyTemplateInput = {
  content: SurveyContentInput;
  department: Scalars['String']['input'];
  endImageName?: InputMaybe<Scalars['String']['input']>;
  introImageName?: InputMaybe<Scalars['String']['input']>;
  language: Language;
  status?: InputMaybe<SurveyTemplateStatus>;
  title: Scalars['String']['input'];
};

export type CreateWeeklyFeedbackActionInput = {
  actionStatus?: InputMaybe<ActionStatus>;
  content: Scalars['String']['input'];
};

export type CreateWeeklyFeedbackInput = {
  actions?: InputMaybe<Array<InputMaybe<CreateWeeklyFeedbackActionInput>>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  projectsSatisfaction?: InputMaybe<Array<ProjectSatisfactionInput>>;
  realizationDate?: InputMaybe<Scalars['Date']['input']>;
  receiverId: Scalars['Int']['input'];
  satisfactionScore: Scalars['Int']['input'];
  summary?: InputMaybe<Scalars['String']['input']>;
};

export enum DepartmentCodes {
  Admin = 'ADMIN',
  Android = 'ANDROID',
  Archit = 'ARCHIT',
  Board = 'BOARD',
  Data = 'DATA',
  DepartmentName = 'DEPARTMENT_NAME',
  Devops = 'DEVOPS',
  Fin = 'FIN',
  Front = 'FRONT',
  Hr = 'HR',
  Ios = 'IOS',
  Manage = 'MANAGE',
  Marketin = 'MARKETIN',
  Net = 'NET',
  Nmd = 'NMD',
  Node = 'NODE',
  Php = 'PHP',
  Pm = 'PM',
  Python = 'PYTHON',
  Rp = 'RP',
  Ruby = 'RUBY',
  Sales = 'SALES',
  Sysadmin = 'SYSADMIN',
  Test = 'TEST',
  Unity = 'UNITY'
}

export type EditSentSurveyInput = {
  content: SurveyContentInput;
  endImageName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  introImageName?: InputMaybe<Scalars['String']['input']>;
  isCopyToDraftEnabled: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type Employee = {
  __typename?: 'Employee';
  actionsAmountByStatus?: Maybe<Scalars['Int']['output']>;
  actionsCollection?: Maybe<ActionCollection>;
  actionsInProgress?: Maybe<Scalars['Int']['output']>;
  archivedSurveyTemplatesCollection?: Maybe<SurveyTemplateCollection>;
  availableSurveyTemplatesCollection?: Maybe<SurveyTemplateCollection>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdSurveyTemplatesCollection?: Maybe<SurveyTemplateCollection>;
  currentProjects?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  currentProjectsDetails?: Maybe<Array<Maybe<Project>>>;
  currentProjectsIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  department: Scalars['String']['output'];
  departmentsSentSurveys?: Maybe<SurveyTemplateCollection>;
  email: Scalars['String']['output'];
  employmentType?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  givenQuarterlyReportsCollection?: Maybe<QuarterlyReportCollection>;
  givenWeeklyFeedbacksCollection?: Maybe<WeeklyFeedbackCollection>;
  goalsCollection?: Maybe<GoalCollection>;
  headFullName?: Maybe<Scalars['String']['output']>;
  hiredDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  issuedProjectsSatisfactions?: Maybe<Array<Maybe<ProjectSatisfaction>>>;
  lastName: Scalars['String']['output'];
  latestReceivedQuarterlyReport?: Maybe<QuarterlyReport>;
  latestReceivedQuarterlyReportId?: Maybe<Scalars['String']['output']>;
  latestReceivedQuarterlyReportRealizationDate?: Maybe<Scalars['Date']['output']>;
  latestReceivedWeeklyFeedback?: Maybe<WeeklyFeedback>;
  latestReceivedWeeklyFeedbackId?: Maybe<Scalars['String']['output']>;
  latestReceivedWeeklyFeedbackRealizationDate?: Maybe<Scalars['Date']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  luxUrl?: Maybe<Scalars['String']['output']>;
  managedDepartment?: Maybe<Scalars['String']['output']>;
  pendingGoalsAmount?: Maybe<Scalars['Int']['output']>;
  pendingSurveysAmount?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pieselyUrl?: Maybe<Scalars['String']['output']>;
  receivedQuarterlyReportsCollection?: Maybe<QuarterlyReportCollection>;
  receivedWeeklyFeedbacksCollection?: Maybe<WeeklyFeedbackCollection>;
  requestedBy?: Maybe<Array<Maybe<AccessType>>>;
  role?: Maybe<Scalars['String']['output']>;
  satisfactionLevel?: Maybe<Scalars['Float']['output']>;
  subordinatesCollection?: Maybe<EmployeeCollection>;
  supervisor?: Maybe<Employee>;
  supervisorId?: Maybe<Scalars['Int']['output']>;
};


export type EmployeeActionsAmountByStatusArgs = {
  actionStatus?: InputMaybe<ActionStatus>;
};


export type EmployeeActionsCollectionArgs = {
  actionStatus?: ActionStatus;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};


export type EmployeeArchivedSurveyTemplatesCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByCreatedAtInput>;
};


export type EmployeeAvailableSurveyTemplatesCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByCreatedAtInput>;
};


export type EmployeeCreatedSurveyTemplatesCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByCreatedAtInput>;
};


export type EmployeeDepartmentsSentSurveysArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByCreatedAtInput>;
};


export type EmployeeGivenQuarterlyReportsCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};


export type EmployeeGivenWeeklyFeedbacksCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};


export type EmployeeGoalsCollectionArgs = {
  goalStatus?: GoalStatus;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};


export type EmployeePendingGoalsAmountArgs = {
  goalStatus?: InputMaybe<GoalStatus>;
};


export type EmployeePendingSurveysAmountArgs = {
  surveyStatus?: InputMaybe<SurveyStatus>;
};


export type EmployeeReceivedQuarterlyReportsCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};


export type EmployeeReceivedWeeklyFeedbacksCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};


export type EmployeeSubordinatesCollectionArgs = {
  assignedToMe?: InputMaybe<Scalars['Boolean']['input']>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByLastNameInput>;
};

export type EmployeeCollection = {
  __typename?: 'EmployeeCollection';
  items: Array<Maybe<Employee>>;
  total: Scalars['Int']['output'];
};

export enum EmployeeSortOptions {
  ActionsInProgress = 'actionsInProgress',
  CreatedAt = 'createdAt',
  Department = 'department',
  FirstName = 'firstName',
  FullName = 'fullName',
  HiredDate = 'hiredDate',
  LastName = 'lastName',
  LatestReceivedQuarterlyReportRealizationDate = 'latestReceivedQuarterlyReportRealizationDate',
  LatestReceivedWeeklyFeedbackRealizationDate = 'latestReceivedWeeklyFeedbackRealizationDate',
  Level = 'level',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export type Goal = {
  __typename?: 'Goal';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  goalStatus?: Maybe<GoalStatus>;
  id: Scalars['ID']['output'];
  quarterlyReport?: Maybe<QuarterlyReport>;
  quarterlyReportId: Scalars['String']['output'];
  realizationDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GoalCollection = {
  __typename?: 'GoalCollection';
  items: Array<Maybe<Goal>>;
  total: Scalars['Int']['output'];
};

export enum GoalStatus {
  Canceled = 'CANCELED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export enum Language {
  En = 'EN',
  Pl = 'PL'
}

export type MessageObject = {
  __typename?: 'MessageObject';
  message?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addSurveyResponse?: Maybe<SurveyResponse>;
  changeSentSurveyToArchived?: Maybe<SurveyTemplate>;
  copyPublishedSurveyTemplate?: Maybe<SurveyTemplate>;
  createAction?: Maybe<Array<Maybe<Action>>>;
  createActionWithoutWeekly?: Maybe<Action>;
  createGoal?: Maybe<Array<Maybe<Goal>>>;
  createQuarterlyReport?: Maybe<QuarterlyReport>;
  createSurvey?: Maybe<Survey>;
  createSurveyTemplate?: Maybe<SurveyTemplate>;
  createWeeklyFeedback?: Maybe<WeeklyFeedback>;
  deleteSurveyReceiver?: Maybe<MessageObject>;
  deleteSurveyTemplate?: Maybe<SurveyTemplate>;
  editSentSurvey?: Maybe<SurveyTemplate>;
  remindAboutUnfilledSurvey?: Maybe<RemindAboutUnfilledSurveyResult>;
  reopenSurveyForRespondent?: Maybe<Scalars['Boolean']['output']>;
  setSurveyResponseStatusAsSeen?: Maybe<SurveyResponse>;
  updateAction?: Maybe<Action>;
  updateGoal?: Maybe<Goal>;
  updateQuarterlyReport?: Maybe<QuarterlyReport>;
  updateSurvey?: Maybe<Survey>;
  updateSurveyTemplate?: Maybe<SurveyTemplate>;
  updateWeeklyFeedback?: Maybe<WeeklyFeedback>;
};


export type MutationAddSurveyResponseArgs = {
  input: SurveyResponseInput;
};


export type MutationChangeSentSurveyToArchivedArgs = {
  input: ChangeSentSurveyToArchivedInput;
};


export type MutationCopyPublishedSurveyTemplateArgs = {
  input: CopySurveyTemplateInput;
};


export type MutationCreateActionArgs = {
  input: CreateActionsInput;
};


export type MutationCreateActionWithoutWeeklyArgs = {
  input: CreateActionWithoutWeeklyInput;
};


export type MutationCreateGoalArgs = {
  input: CreateGoalsInput;
};


export type MutationCreateQuarterlyReportArgs = {
  input: CreateQuarterlyReportInput;
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


export type MutationCreateSurveyTemplateArgs = {
  input: CreateSurveyTemplateInput;
};


export type MutationCreateWeeklyFeedbackArgs = {
  input: CreateWeeklyFeedbackInput;
};


export type MutationDeleteSurveyReceiverArgs = {
  surveyResponseId: Scalars['String']['input'];
};


export type MutationDeleteSurveyTemplateArgs = {
  surveyTemplateId: Scalars['String']['input'];
};


export type MutationEditSentSurveyArgs = {
  input: EditSentSurveyInput;
};


export type MutationRemindAboutUnfilledSurveyArgs = {
  input: RemindAboutUnfilledSurveyInput;
};


export type MutationReopenSurveyForRespondentArgs = {
  urlHash: Scalars['String']['input'];
};


export type MutationSetSurveyResponseStatusAsSeenArgs = {
  urlHash: Scalars['String']['input'];
};


export type MutationUpdateActionArgs = {
  input: UpdateActionInput;
};


export type MutationUpdateGoalArgs = {
  input: UpdateGoalInput;
};


export type MutationUpdateQuarterlyReportArgs = {
  input: UpdateQuarterlyReportInput;
};


export type MutationUpdateSurveyArgs = {
  input: UpdateSurveyInput;
};


export type MutationUpdateSurveyTemplateArgs = {
  input: UpdateSurveyTemplateInput;
};


export type MutationUpdateWeeklyFeedbackArgs = {
  input: UpdateWeeklyFeedbackInput;
};

export type MyProfile = {
  __typename?: 'MyProfile';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  hasAdminAccess?: Maybe<Scalars['Boolean']['output']>;
  hasSubcontractorsAdminAccess?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  level?: Maybe<Scalars['String']['output']>;
  managedDepartment?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type NewSurveyReceiver = {
  email: Scalars['EmailAddress']['input'];
  notificator: Array<NotificatorType>;
};

export enum NotificatorType {
  Email = 'EMAIL',
  Slack = 'SLACK'
}

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Project = {
  __typename?: 'Project';
  activeAssignments: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  projectSatisfactionScores?: Maybe<Array<Maybe<ProjectSatisfaction>>>;
  updatedAt: Scalars['Date']['output'];
};

export type ProjectSatisfaction = {
  __typename?: 'ProjectSatisfaction';
  createdAt: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  issuer: Employee;
  issuerId: Scalars['Int']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  satisfactionScore: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  weeklyFeedback: WeeklyFeedback;
  weeklyFeedbackId: Scalars['String']['output'];
};

export type ProjectSatisfactionInput = {
  projectId: Scalars['String']['input'];
  projectSatisfactionScore: Scalars['Int']['input'];
};

export type QuarterlyReport = {
  __typename?: 'QuarterlyReport';
  createdAt: Scalars['DateTime']['output'];
  giver?: Maybe<Employee>;
  giverId?: Maybe<Scalars['Int']['output']>;
  goalsCollection?: Maybe<GoalCollection>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  realizationDate: Scalars['Date']['output'];
  receiver?: Maybe<Employee>;
  receiverId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<QuarterlyReportStatus>;
  summary: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type QuarterlyReportGoalsCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};

export type QuarterlyReportCollection = {
  __typename?: 'QuarterlyReportCollection';
  items: Array<Maybe<QuarterlyReport>>;
  total: Scalars['Int']['output'];
};

export enum QuarterlyReportStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export type Query = {
  __typename?: 'Query';
  /** Returns specific user. */
  employee?: Maybe<Employee>;
  /** Returns all employees of logged user. */
  employees?: Maybe<EmployeeCollection>;
  generateCsvFromSurveyResponses?: Maybe<Scalars['String']['output']>;
  /** Returns logged user. */
  me?: Maybe<MyProfile>;
  /** Returns specific quarterly report. */
  quarterlyReport?: Maybe<QuarterlyReport>;
  /** Returns all quarterly reports for given user. */
  quarterlyReports?: Maybe<QuarterlyReportCollection>;
  /** Returns url valid 120 seconds to upload image to s3 bucket. */
  s3UploadUrl?: Maybe<S3ImageData>;
  /** Returns slack users */
  slackUsers?: Maybe<SlackUserCollection>;
  survey?: Maybe<Survey>;
  /** Returns survey data for respondent */
  surveyForRespondent?: Maybe<SurveyForRespondent>;
  /** Returns possible receivers of given survey with limited data, since You can search through all employees */
  surveyReceivers?: Maybe<SurveyReceiversCollection>;
  /** Returns survey template of given id */
  surveyTemplate?: Maybe<SurveyTemplate>;
  /** Returns surveys of a given user based of survey status */
  surveys?: Maybe<SurveyCollection>;
  /** Returns specific weekly feedback. */
  weeklyFeedback?: Maybe<WeeklyFeedback>;
  /** Returns all weekly feedbacks for given user. */
  weeklyFeedbacks?: Maybe<WeeklyFeedbackCollection>;
};


export type QueryEmployeeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryEmployeesArgs = {
  assignedToMe?: InputMaybe<Scalars['Boolean']['input']>;
  filterByDepartments?: InputMaybe<Array<DepartmentCodes>>;
  pagination?: InputMaybe<PaginationInput>;
  searchedValue?: InputMaybe<Scalars['searchedValue_String_minLength_1']['input']>;
  sort?: InputMaybe<SortByLastNameInput>;
  withAdminMode?: InputMaybe<Scalars['Boolean']['input']>;
  withPendingActions?: InputMaybe<Scalars['Boolean']['input']>;
  withSubcontractorsMode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGenerateCsvFromSurveyResponsesArgs = {
  surveyId: Scalars['ID']['input'];
};


export type QueryQuarterlyReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuarterlyReportsArgs = {
  employeeId: Scalars['Int']['input'];
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};


export type QuerySlackUsersArgs = {
  pagination?: InputMaybe<PaginationInput>;
  searchedValue: Scalars['searchedValue_String_NotNull_minLength_1']['input'];
};


export type QuerySurveyArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySurveyForRespondentArgs = {
  urlHash: Scalars['String']['input'];
};


export type QuerySurveyReceiversArgs = {
  notificatorType?: InputMaybe<NotificatorType>;
  pagination?: InputMaybe<PaginationInput>;
  searchedValue: Scalars['searchedValue_String_NotNull_minLength_3']['input'];
  sort?: InputMaybe<SortByLastNameInput>;
};


export type QuerySurveyTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySurveysArgs = {
  employeeId: Scalars['Int']['input'];
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByCreatedAtInput>;
  surveyStatus?: InputMaybe<SurveyStatus>;
};


export type QueryWeeklyFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWeeklyFeedbacksArgs = {
  employeeId: Scalars['Int']['input'];
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};

export type RemindAboutUnfilledSurveyInput = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  surveyId: Scalars['String']['input'];
};

export type RemindAboutUnfilledSurveyResult = {
  __typename?: 'RemindAboutUnfilledSurveyResult';
  emailsThatReceivedReminder: Array<Maybe<Scalars['String']['output']>>;
};

export type ResponsesByDate = {
  __typename?: 'ResponsesByDate';
  respondents: SurveyRespondentsCollection;
  sentAt: Scalars['DateTime']['output'];
};

export type S3ImageData = {
  __typename?: 'S3ImageData';
  imageName?: Maybe<Scalars['String']['output']>;
  uploadUrl?: Maybe<Scalars['String']['output']>;
};

export type Section = {
  __typename?: 'Section';
  description?: Maybe<Scalars['String']['output']>;
  questions: Array<Maybe<SurveyQuestion>>;
  title: Scalars['String']['output'];
};

export type SectionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  questions: Array<InputMaybe<SurveyQuestionInput>>;
  title: Scalars['String']['input'];
};

export type SlackUser = {
  __typename?: 'SlackUser';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SlackUserCollection = {
  __typename?: 'SlackUserCollection';
  items: Array<Maybe<SlackUser>>;
  total: Scalars['Int']['output'];
};

export type SortByCreatedAtInput = {
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type SortByLastNameInput = {
  sortBy?: InputMaybe<EmployeeSortOptions>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type SortByRealizationDateInput = {
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Survey = {
  __typename?: 'Survey';
  closeDate?: Maybe<Scalars['Date']['output']>;
  concernedId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isSent?: Maybe<Scalars['Boolean']['output']>;
  openedSurveysCount?: Maybe<Scalars['Int']['output']>;
  owner?: Maybe<Employee>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  reminderDaysBeforeSurveyClose: Scalars['Int']['output'];
  respondedEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  respondedSurveysCount?: Maybe<Scalars['Int']['output']>;
  responseByEmail?: Maybe<SurveyResponseByEmail>;
  responsesByDate?: Maybe<Array<Maybe<ResponsesByDate>>>;
  sendDate?: Maybe<Scalars['DateTime']['output']>;
  status: SurveyStatus;
  summaryContent?: Maybe<Array<Maybe<SurveySummaryContent>>>;
  surveyTemplate?: Maybe<SurveyTemplate>;
  surveyTemplateId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  upcomingNotificationsDispatch?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type SurveyResponseByEmailArgs = {
  respondentEmail: Scalars['String']['input'];
};

export type SurveyCollection = {
  __typename?: 'SurveyCollection';
  items: Array<Maybe<Survey>>;
  total: Scalars['Int']['output'];
};

export type SurveyContent = {
  __typename?: 'SurveyContent';
  beginning?: Maybe<Scalars['String']['output']>;
  conclusion?: Maybe<Scalars['String']['output']>;
  sections: Array<Maybe<Section>>;
};

export type SurveyContentInput = {
  beginning?: InputMaybe<Scalars['String']['input']>;
  conclusion?: InputMaybe<Scalars['String']['input']>;
  sections: Array<InputMaybe<SectionInput>>;
};

export type SurveyForRespondent = {
  __typename?: 'SurveyForRespondent';
  concernedAvatarUrl?: Maybe<Scalars['String']['output']>;
  concernedFullName: Scalars['String']['output'];
  content: SurveyContent;
  endImageName?: Maybe<Scalars['String']['output']>;
  endImageUrl?: Maybe<Scalars['String']['output']>;
  introImageName?: Maybe<Scalars['String']['output']>;
  introImageUrl?: Maybe<Scalars['String']['output']>;
  language: Language;
  response?: Maybe<Array<Maybe<Answer>>>;
  responseStatus: SurveyResponseStatus;
  surveyOwnerAvatarUrl?: Maybe<Scalars['String']['output']>;
  surveyOwnerFullName: Scalars['String']['output'];
};

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  answers?: Maybe<Scalars['Array']['output']>;
  format: SurveyQuestionFormat;
  question: Scalars['String']['output'];
};

export enum SurveyQuestionFormat {
  CloseEnded = 'CLOSE_ENDED',
  LinearScale = 'LINEAR_SCALE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  OpenEnded = 'OPEN_ENDED'
}

export type SurveyQuestionInput = {
  answers?: InputMaybe<Scalars['Array']['input']>;
  format: SurveyQuestionFormat;
  question: Scalars['String']['input'];
};

export type SurveyReceiver = {
  __typename?: 'SurveyReceiver';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  department: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  receivedSurveyStatus?: Maybe<SurveyResponseStatus>;
  role?: Maybe<Scalars['String']['output']>;
};


export type SurveyReceiverReceivedSurveyStatusArgs = {
  surveyId: Scalars['String']['input'];
};

export type SurveyReceiversCollection = {
  __typename?: 'SurveyReceiversCollection';
  items: Array<Maybe<SurveyReceiver>>;
  total: Scalars['Int']['output'];
};

export type SurveyRespondentsCollection = {
  __typename?: 'SurveyRespondentsCollection';
  items: Array<Maybe<SurveyResponse>>;
  total: Scalars['Int']['output'];
};

export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  createdAt: Scalars['DateTime']['output'];
  endImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  notificator: Array<NotificatorType>;
  respondedAt?: Maybe<Scalars['DateTime']['output']>;
  respondent?: Maybe<SurveyReceiver>;
  respondentEmail: Scalars['EmailAddress']['output'];
  response?: Maybe<Array<Maybe<Answer>>>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status: SurveyResponseStatus;
  survey: Survey;
  surveyId: Scalars['String']['output'];
  surveyLink: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  urlHash: Scalars['String']['output'];
};

export type SurveyResponseAnswersInput = {
  answer: Array<Scalars['String']['input']>;
  format: SurveyQuestionFormat;
  question: Scalars['String']['input'];
};

export type SurveyResponseByEmail = {
  __typename?: 'SurveyResponseByEmail';
  email: Scalars['String']['output'];
  response: Array<Answer>;
};

export type SurveyResponseInput = {
  isUpdateOnly?: InputMaybe<Scalars['Boolean']['input']>;
  response: Array<SurveyResponseAnswersInput>;
  urlHash: Scalars['String']['input'];
};

export enum SurveyResponseStatus {
  Answered = 'ANSWERED',
  Canceled = 'CANCELED',
  Reattempted = 'REATTEMPTED',
  Scheduled = 'SCHEDULED',
  Seen = 'SEEN',
  Sending = 'SENDING',
  Sent = 'SENT'
}

export enum SurveyStatus {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export type SurveySummary = {
  __typename?: 'SurveySummary';
  openedSurveysCount: Scalars['Int']['output'];
  respondedEmails: Array<Maybe<Scalars['String']['output']>>;
  respondedSurveysCount: Scalars['Int']['output'];
  summaryContent: SurveySummaryContent;
};

export type SurveySummaryAnswer = {
  __typename?: 'SurveySummaryAnswer';
  answer: Array<Scalars['String']['output']>;
  email: Scalars['String']['output'];
};

export type SurveySummaryContent = {
  __typename?: 'SurveySummaryContent';
  answers: Array<Maybe<SurveySummaryAnswer>>;
  format: SurveyQuestionFormat;
  question: Scalars['String']['output'];
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  content?: Maybe<SurveyContent>;
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<Employee>;
  creatorId?: Maybe<Scalars['Int']['output']>;
  department: Scalars['String']['output'];
  endImageName?: Maybe<Scalars['String']['output']>;
  endImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  introImageName?: Maybe<Scalars['String']['output']>;
  introImageUrl?: Maybe<Scalars['String']['output']>;
  language: Language;
  latestSendDate?: Maybe<Scalars['Date']['output']>;
  status: SurveyTemplateStatus;
  survey?: Maybe<Survey>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type SurveyTemplateSurveyArgs = {
  id: Scalars['ID']['input'];
};

export type SurveyTemplateCollection = {
  __typename?: 'SurveyTemplateCollection';
  items?: Maybe<Array<Maybe<SurveyTemplate>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum SurveyTemplateStatus {
  Archived = 'ARCHIVED',
  EditedCopy = 'EDITED_COPY',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type UpdateActionInput = {
  actionStatus?: InputMaybe<ActionStatus>;
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  realizationDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateGoalInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  goalStatus?: InputMaybe<GoalStatus>;
  id: Scalars['ID']['input'];
  realizationDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateProjectSatisfactionInput = {
  id: Scalars['String']['input'];
  projectSatisfactionScore: Scalars['Int']['input'];
};

export type UpdateQuarterlyReportInput = {
  goals?: InputMaybe<Array<InputMaybe<UpdateGoalInput>>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  quarterlyReportId: Scalars['ID']['input'];
  realizationDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<QuarterlyReportStatus>;
  summary: Scalars['String']['input'];
};

export type UpdateSurveyInput = {
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  newSurveyReceivers?: InputMaybe<Array<NewSurveyReceiver>>;
  reminderDaysBeforeSurveyClose?: InputMaybe<Scalars['Int']['input']>;
  sendDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<SurveyStatus>;
  surveyId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSurveyTemplateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  endImageName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  introImageName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Language>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWeeklyFeedbackInput = {
  actions?: InputMaybe<Array<UpdateActionInput>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  projectsSatisfaction?: InputMaybe<Array<UpdateProjectSatisfactionInput>>;
  realizationDate?: InputMaybe<Scalars['Date']['input']>;
  satisfactionScore?: InputMaybe<Scalars['Int']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  weeklyFeedbackId: Scalars['ID']['input'];
};

export type WeeklyFeedback = {
  __typename?: 'WeeklyFeedback';
  actionsCollection?: Maybe<ActionCollection>;
  createdAt: Scalars['DateTime']['output'];
  giver?: Maybe<Employee>;
  giverId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  projectSatisfactionScores?: Maybe<Array<Maybe<ProjectSatisfaction>>>;
  realizationDate: Scalars['Date']['output'];
  receiver?: Maybe<Employee>;
  receiverId?: Maybe<Scalars['Int']['output']>;
  receiverProjects?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  satisfactionScore: Scalars['Int']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type WeeklyFeedbackActionsCollectionArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortByRealizationDateInput>;
};

export type WeeklyFeedbackCollection = {
  __typename?: 'WeeklyFeedbackCollection';
  items: Array<Maybe<WeeklyFeedback>>;
  total: Scalars['Int']['output'];
};
