export const MuiButton = (colors: Record<string, string>) => {
  return {
    variants: [
      {
        props: { variant: 'secondary' },
        style: {
          color: colors.blueberry,
          backgroundColor: colors.zumthorBlue,
          border: `1px solid ${colors.zumthorBlue}`,
          '&:hover': {
            borderColor: colors.blueberry,
            backgroundColor: colors.zumthorBlue,
          },
          '&.Mui-focusVisible': {
            borderColor: colors.blueberry,
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            borderColor: colors.hawkesBlue,
          },
        },
      },
      {
        props: { variant: 'secondary', color: 'error' },
        style: {
          color: colors.tomato,
          backgroundColor: colors.provincialPink,
          border: `1px solid ${colors.provincialPink}`,
          '&:hover': {
            borderColor: colors.tomato,
            backgroundColor: colors.provincialPink,
          },
          '&.Mui-focusVisible': {
            borderColor: colors.tomato,
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        boxShadow: 'none',
        textTransform: 'initial',
        '&:hover': {
          boxShadow: 'none',
        },
        '&.Mui-focusVisible': {
          boxShadow: 'none',
        },
      },
      sizeSmall: {
        fontSize: 13,
        padding: '7px 15px',
        lineHeight: 1.69,
      },
      containedSizeSmall: {
        padding: '8px 16px',
      },
      textSizeSmall: {
        padding: '8px 16px',
      },
      sizeMedium: {
        fontSize: 14,
        padding: '11px 23px',
        fontWeight: 600,
        lineHeight: 1.71,
      },
      containedSizeMedium: {
        padding: '12px 24px',
      },
      textSizeMedium: {
        padding: '12px 24px',
      },
      sizeLarge: {
        fontSize: 15,
        padding: '15px 23px',
        fontWeight: 600,
        lineHeight: 1.73,
      },
      containedSizeLarge: {
        padding: '16px 24px',
      },
      textSizeLarge: {
        padding: '16px 24px',
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: colors.zumthorBlue,
        },
      },
    },
  };
};
