import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const DatePickerWrapper: Styles = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  mt: 5.5,
};

export const NotificationDatePickerContainer: Styles = {
  border: `1px solid ${colors.blueberry}`,
  borderRadius: 2,
};

export const ButtonWrapper: Styles = {
  mt: 8,
  display: 'flex',
  gap: 2,
  '& > button': {
    flex: 1,
  },
};
