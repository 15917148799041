import { toast } from 'react-toastify';
import { ApolloError, QueryHookOptions } from '@apollo/client';

import {
  ReadOnlyEmployeesQuery,
  ReadOnlyEmployeesQueryVariables,
  useReadOnlyEmployeesQuery,
} from 'documents/readOnlyEmployees.generated';
import { validateSortByParam, validateSortOrderParam } from 'hooks/utils';
import { useDepartmentCodes } from 'hooks';

import { UseEmployeesProps } from './useReadOnlyEmployees.types';

export const useReadOnlyEmployees = ({
  filters: { searchText, filterByDepartments, isAdminMode, isSubcontractorsMode },
  paginationFilters: { page, pageSize },
  sortFilters,
  defaultSortBy,
}: UseEmployeesProps) => {
  const filterByDepartmentsCodes = useDepartmentCodes(filterByDepartments);

  const employeesQueryOptions: QueryHookOptions<ReadOnlyEmployeesQuery> = {
    variables: <ReadOnlyEmployeesQueryVariables>{
      filterByDepartments: filterByDepartmentsCodes,
      withAdminMode: isAdminMode,
      searchedValue: searchText,
      withSubcontractorsMode: isSubcontractorsMode,
      pagination: {
        limit: pageSize,
        offset: (page - 1 ?? 0) * pageSize,
      },
      sort: {
        sortBy: validateSortByParam({
          param: sortFilters?.[0],
          defaultSortBy,
        }),
        sortOrder: validateSortOrderParam({
          param: sortFilters?.[1],
          defaultSortBy,
        }),
      },
    },

    onError: (error: ApolloError) => toast.error(error.message),
    fetchPolicy: 'cache-and-network',
    skip: !isAdminMode,
  };

  const { data, previousData } = useReadOnlyEmployeesQuery({ ...employeesQueryOptions });

  return {
    readOnlyEmployeesData: data?.employees,
    prevReadOnlyEmployeesData: previousData?.employees,
    isReadOnlyEmployeesDataLoading:
      !data || ((data?.employees?.items.length ?? 0) === 0 && (data?.employees?.total ?? 0) > 0),
  };
};
