import { useTranslation } from 'react-i18next';

import { useChangeSentSurveyToArchived } from 'hooks/useChangeSentSurveyToArchived/useChangeSentSurveyToArchived';
import { ConfirmModal } from 'ui/confirmModal/ConfirmModal';
import { useSurveyTabsSortingContext } from 'app/surveyTemplates/surveyTemplatesTabs/SurveyTabsSorting/useSurveyTabsSortingContext';
import { SurveysListTab } from 'app/surveyTemplates/surveyTemplatesTabs/SurveyTemplatesTabs.types';

import { ArchiveSurveyDialogProps } from './ArchiveSurveyDialog.types';

export const ArchiveSurveyDialog = ({ handleCloseModal, id }: ArchiveSurveyDialogProps) => {
  const { t } = useTranslation();

  const { sortingParams } = useSurveyTabsSortingContext();

  const getSortingVariable = (surveyTab: SurveysListTab) => {
    if (sortingParams?.[surveyTab]) {
      return {
        sort: sortingParams[surveyTab],
      };
    }
  };

  const { changeSentSurveyStatus } = useChangeSentSurveyToArchived({
    archived: getSortingVariable(SurveysListTab.Archived),
    sent: getSortingVariable(SurveysListTab.Sent),
  });

  const handleArchiveSurvey = async () => {
    if (!id) return;

    await changeSentSurveyStatus({
      variables: {
        input: {
          id,
        },
      },
    });

    handleCloseModal();
  };

  return (
    <ConfirmModal
      isOpen={!!id}
      handleCloseModal={handleCloseModal}
      handleAccept={handleArchiveSurvey}
      translations={{
        accept: t('surveyBuilder.questions.surveyState.acceptButton'),
        cancel: t(`surveyBuilder.questions.surveyState.cancelButton`),
        description: t('surveyTemplates.archiveSurveyDialog.description'),
        title: t('surveyTemplates.archiveSurveyDialog.title'),
      }}
    />
  );
};
