import { useSurveyReceiversLazyQuery } from 'documents/surveyReceivers.generated';
import { NotificatorType } from 'generated/webapp_gql';

export const useSurveyReceivers = () => {
  const [getSurveyReceivers, { data, loading }] = useSurveyReceiversLazyQuery();

  const getSurveyReceiversBySearchValue = async (
    searchedValue: string,
    surveyId: string,
    getHasReceivedSurvey: boolean,
    notificator: NotificatorType,
  ) => {
    await getSurveyReceivers({
      variables: { searchedValue, surveyId, getHasReceivedSurvey, notificatorType: notificator },
      fetchPolicy: 'no-cache',
    });
  };

  return {
    getSurveyReceiversBySearchValue,
    surveyReceiversData: data?.surveyReceivers,
    surveyReceiversLoading: loading,
  };
};
