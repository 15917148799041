import { debounce, TextField } from '@mui/material';
import { KeyboardEvent, useMemo, useState } from 'react';
import { string } from 'yup';

import { NotificatorType } from 'generated/webapp_gql';
import { SurveyReceiver } from '../../SurveyPublicationModal.types';

import { AutocompleteInputProps } from './AutocompleteInput.types';

const START_SEARCH_THRESHOLD = 3;
export const DEBOUNCE_MS = 200;
export const EXTERNAL_EMAIL_ID = 'external';

export const AutocompleteInput = ({
  setCurrentReceivers,
  currentReceivers,
  params,
  getSurveyReceivers,
  surveyReceiversData,
  sendMethod,
  surveyId,
}: AutocompleteInputProps) => {
  const [searchValue, setSearchValue] = useState('');

  const setSearchValueDebounced = useMemo(
    () =>
      debounce((newValue) => {
        setSearchValue(newValue);

        if (newValue.length >= START_SEARCH_THRESHOLD) {
          getSurveyReceivers(newValue, surveyId || '', !!surveyId, sendMethod);
        }
      }, DEBOUNCE_MS),
    [setSearchValue, surveyId, sendMethod, getSurveyReceivers],
  );

  const handleManualAdding = async (e: KeyboardEvent<HTMLDivElement>) => {
    const isValidEmail = await string().email().isValid(searchValue);

    if (
      e.code === 'Enter' &&
      !!searchValue.length &&
      surveyReceiversData &&
      !surveyReceiversData.total &&
      isValidEmail &&
      sendMethod === NotificatorType.Email
    ) {
      setCurrentReceivers([
        ...currentReceivers,
        {
          email: searchValue,
          id: EXTERNAL_EMAIL_ID,
          notificator: [NotificatorType.Email],
        } as SurveyReceiver,
      ]);
      setSearchValue('');
    }
  };

  return (
    <TextField
      {...params}
      onChange={(e) => {
        {
          setSearchValueDebounced(e.target.value);
        }
      }}
      onKeyDown={handleManualAdding}
    />
  );
};
