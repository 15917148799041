import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuarterlyReportsQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  pagination?: Types.InputMaybe<Types.PaginationInput>;
}>;


export type QuarterlyReportsQuery = { __typename?: 'Query', quarterlyReports?: { __typename?: 'QuarterlyReportCollection', total: number, items: Array<{ __typename?: 'QuarterlyReport', id: string, summary: string, notes?: string | null, status?: Types.QuarterlyReportStatus | null, realizationDate: any, receiverId?: number | null, goalsCollection?: { __typename?: 'GoalCollection', items: Array<{ __typename?: 'Goal', content?: string | null, id: string, goalStatus?: Types.GoalStatus | null } | null> } | null } | null> } | null };


export const QuarterlyReportsDocument = gql`
    query QuarterlyReports($employeeId: Int!, $pagination: PaginationInput) {
  quarterlyReports(employeeId: $employeeId, pagination: $pagination) {
    items {
      id
      summary
      notes
      status
      realizationDate
      goalsCollection {
        items {
          content
          id
          goalStatus
        }
      }
      receiverId
    }
    total
  }
}
    `;

/**
 * __useQuarterlyReportsQuery__
 *
 * To run a query within a React component, call `useQuarterlyReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuarterlyReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuarterlyReportsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useQuarterlyReportsQuery(baseOptions: Apollo.QueryHookOptions<QuarterlyReportsQuery, QuarterlyReportsQueryVariables> & ({ variables: QuarterlyReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuarterlyReportsQuery, QuarterlyReportsQueryVariables>(QuarterlyReportsDocument, options);
      }
export function useQuarterlyReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuarterlyReportsQuery, QuarterlyReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuarterlyReportsQuery, QuarterlyReportsQueryVariables>(QuarterlyReportsDocument, options);
        }
export function useQuarterlyReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuarterlyReportsQuery, QuarterlyReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuarterlyReportsQuery, QuarterlyReportsQueryVariables>(QuarterlyReportsDocument, options);
        }
export type QuarterlyReportsQueryHookResult = ReturnType<typeof useQuarterlyReportsQuery>;
export type QuarterlyReportsLazyQueryHookResult = ReturnType<typeof useQuarterlyReportsLazyQuery>;
export type QuarterlyReportsSuspenseQueryHookResult = ReturnType<typeof useQuarterlyReportsSuspenseQuery>;
export type QuarterlyReportsQueryResult = Apollo.QueryResult<QuarterlyReportsQuery, QuarterlyReportsQueryVariables>;