import { Box, Divider } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';
import { BackButton } from 'ui';

import * as Styles from './Title.styles';
import { TitleProps } from './Title.types';

export const Title = ({ children, button = <BackButton />, sx = [], rightButton }: TitleProps) => (
  <>
    <Box sx={spreadMultipleStyles(Styles.ContentWrapper, sx)}>
      {button}
      {children}
      {rightButton}
    </Box>
    <Divider />
  </>
);
