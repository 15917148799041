import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useMemo } from 'react';
import { InteractionStatus } from '@azure/msal-browser';

import { AuthContext } from '../authContext/AuthContext';
import { authStorage } from '../authStorage/AuthStorage';
import { useAcquireTokenSilent } from 'hooks/useAcquireTokenSilent/useAcquireTokenSilent';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const { fetchToken, loading: isLoading } = useAcquireTokenSilent();
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    const loadingStates: InteractionStatus[] = ['none', 'startup'];
    if (!authStorage.accessToken && !loadingStates.includes(inProgress)) {
      fetchToken()
        .then((token) => {
          if (token) {
            authStorage.username = token.account.username;
            authStorage.accessToken = token.accessToken;
          }
        })
        .catch(() => {
          logout();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress, authStorage.accessToken]);

  const logout = useCallback(async () => {
    authStorage.accessToken = null;
    instance.setActiveAccount(null);
    instance.clearCache();
  }, [instance]);

  const contextValue = useMemo(
    () => ({
      isAuthenticated: !!accounts[0],
      isAuthenticating: isLoading,
      logout,
    }),
    [accounts, isLoading, logout],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
