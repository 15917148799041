import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetSurveyResponseStatusAsSeenMutationVariables = Types.Exact<{
  urlHash: Types.Scalars['String']['input'];
}>;


export type SetSurveyResponseStatusAsSeenMutation = { __typename?: 'Mutation', setSurveyResponseStatusAsSeen?: { __typename?: 'SurveyResponse', id: string } | null };


export const SetSurveyResponseStatusAsSeenDocument = gql`
    mutation SetSurveyResponseStatusAsSeen($urlHash: String!) {
  setSurveyResponseStatusAsSeen(urlHash: $urlHash) {
    id
  }
}
    `;
export type SetSurveyResponseStatusAsSeenMutationFn = Apollo.MutationFunction<SetSurveyResponseStatusAsSeenMutation, SetSurveyResponseStatusAsSeenMutationVariables>;

/**
 * __useSetSurveyResponseStatusAsSeenMutation__
 *
 * To run a mutation, you first call `useSetSurveyResponseStatusAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSurveyResponseStatusAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSurveyResponseStatusAsSeenMutation, { data, loading, error }] = useSetSurveyResponseStatusAsSeenMutation({
 *   variables: {
 *      urlHash: // value for 'urlHash'
 *   },
 * });
 */
export function useSetSurveyResponseStatusAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<SetSurveyResponseStatusAsSeenMutation, SetSurveyResponseStatusAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSurveyResponseStatusAsSeenMutation, SetSurveyResponseStatusAsSeenMutationVariables>(SetSurveyResponseStatusAsSeenDocument, options);
      }
export type SetSurveyResponseStatusAsSeenMutationHookResult = ReturnType<typeof useSetSurveyResponseStatusAsSeenMutation>;
export type SetSurveyResponseStatusAsSeenMutationResult = Apollo.MutationResult<SetSurveyResponseStatusAsSeenMutation>;
export type SetSurveyResponseStatusAsSeenMutationOptions = Apollo.BaseMutationOptions<SetSurveyResponseStatusAsSeenMutation, SetSurveyResponseStatusAsSeenMutationVariables>;