import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { ButtonNextProps } from './ButtonNext.types';

export const ButtonNext = ({ onClick, children, ...props }: ButtonNextProps) => {
  return (
    <Button endIcon={<ArrowForwardIcon />} variant="contained" onClick={onClick} {...props}>
      {children}
    </Button>
  );
};
