import { Button, Typography } from '@mui/material';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import CropFreeIcon from '@mui/icons-material/CropFree';
import { useTranslation } from 'react-i18next';

import * as Styles from './DragDropImagePlaceholder.styles';
import { DragDropImagePlaceholderProps } from './DragDropImagePlaceholder.types';

export const DragDropImagePlaceholder = ({
  isDragAccept,
  isDragReject,
  onButtonClick,
}: DragDropImagePlaceholderProps) => {
  const { t } = useTranslation();

  return !isDragAccept && !isDragReject ? (
    <>
      <PhotoSizeSelectActualOutlinedIcon sx={Styles.ImageIcon} />
      <Button size="small" variant="contained" onClick={onButtonClick}>
        {t('surveyBuilder.questions.addPhotoDialog.searchPhotoButton')}
      </Button>
    </>
  ) : (
    <>
      <CropFreeIcon sx={Styles.ImageIcon} />
      <Typography variant="subtitle2" color="slateGray" sx={Styles.PlaceholderText}>
        {isDragAccept
          ? t('surveyBuilder.questions.addPhotoDialog.dropHere')
          : t('surveyBuilder.questions.addPhotoDialog.fileError')}
      </Typography>
    </>
  );
};
