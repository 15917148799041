import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import { LogoSection } from 'ui';

import * as Styles from './Header.styles';
import { UserSection } from './userSection/UserSection';

export const Header = () => {
  return (
    <AppBar color="transparent" elevation={0} position="static" sx={Styles.HeaderWrapper}>
      <Toolbar sx={Styles.ToolbarWrapper}>
        <LogoSection />
        <UserSection />
      </Toolbar>
    </AppBar>
  );
};
