import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationsKeys } from 'i18n/i18n.types';
import { useSurveyLanguage } from '../useSurveyLanguage/useSurveyLanguage';

export const useTranslateSurvey = () => {
  const language = useSurveyLanguage();
  const { t } = useTranslation();

  return useCallback((key: TranslationsKeys) => t(key, { lng: language.toLowerCase() }), [t, language]);
};
