import { Container } from '@mui/material';

import { Header } from './header/Header';
import * as Styles from './Layout.styles';
import { LayoutProps } from './Layout.types';

export const Layout = ({ children, noHeader }: LayoutProps) => (
  <Container component="main" maxWidth="xl" sx={Styles.Main}>
    {!noHeader && <Header />}
    {children}
  </Container>
);
