import { InputAdornment, TextField as MaterialTextField } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';

import { TextFieldProps } from './TextField.types';
import * as Styles from './TextField.styles';

export const TextField = ({
  multiline = true,
  variant = 'standard',
  iconStart,
  iconEnd,
  sx,
  ...props
}: TextFieldProps) => {
  return (
    <MaterialTextField
      multiline={multiline}
      variant={variant}
      sx={spreadMultipleStyles(Styles.DefaultTextField, sx)}
      InputProps={{
        startAdornment: iconStart && <InputAdornment position="start">{iconStart}</InputAdornment>,
        endAdornment: iconEnd && <InputAdornment position="end">{iconEnd}</InputAdornment>,
      }}
      {...props}
    />
  );
};
