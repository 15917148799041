import { Styles } from 'shared/types';

export const StatusInfoWrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
  gap: 3,
  listStyle: 'none',
};

export const StatusName: Styles = {
  display: 'flex',
  alignItems: 'center',
};
