import { DepartmentCodes } from 'generated/webapp_gql';
import i18n from 'i18n/i18n';

import { DepartmentCodesKeys, DepartmentCodesUnion, DepartmentsObject } from './useDepartmentsData.types';
import { isCategorizedKey } from './useDepartmentsData.utils';

const { t } = i18n;

export const departmentsData = (<[DepartmentCodesKeys, DepartmentCodes][]>Object.entries(DepartmentCodes)).reduce(
  (departmentsObj, [key, value]) => {
    if (isCategorizedKey(key)) {
      departmentsObj[key] = {
        name: t(`Departments.${key}`),
        value,
      };
    } else {
      departmentsObj.Others = {
        name: t('Departments.Others'),
        value: [...(departmentsObj.Others?.value ?? []), value],
      };
    }

    return departmentsObj;
  },
  <DepartmentsObject>{},
);

export const useDepartmentCodes = (keys: string[] | null) => {
  return keys?.reduce(
    (prevCodes, key) => {
      const code = <DepartmentCodesUnion | DepartmentCodesUnion[] | undefined>(
        departmentsData[<keyof DepartmentsObject>key]?.value
      );

      if (!code) {
        return prevCodes;
      }

      if (typeof code === 'string') {
        return [...prevCodes, code];
      }

      return [...prevCodes, ...code];
    },
    <DepartmentCodesUnion[]>[],
  );
};
