export const MuiFormControlLabel = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: colors.disabledGray,
        },
      },
    },
  };
};
