import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuarterlyReportQueryVariables = Types.Exact<{
  quarterlyReportId: Types.Scalars['ID']['input'];
}>;


export type QuarterlyReportQuery = { __typename?: 'Query', quarterlyReport?: { __typename?: 'QuarterlyReport', id: string, summary: string, notes?: string | null, realizationDate: any, status?: Types.QuarterlyReportStatus | null, receiverId?: number | null, goalsCollection?: { __typename?: 'GoalCollection', total: number, items: Array<{ __typename?: 'Goal', id: string, content?: string | null, goalStatus?: Types.GoalStatus | null } | null> } | null } | null };


export const QuarterlyReportDocument = gql`
    query QuarterlyReport($quarterlyReportId: ID!) {
  quarterlyReport(id: $quarterlyReportId) {
    id
    summary
    notes
    realizationDate
    status
    goalsCollection {
      items {
        id
        content
        goalStatus
      }
      total
    }
    receiverId
  }
}
    `;

/**
 * __useQuarterlyReportQuery__
 *
 * To run a query within a React component, call `useQuarterlyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuarterlyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuarterlyReportQuery({
 *   variables: {
 *      quarterlyReportId: // value for 'quarterlyReportId'
 *   },
 * });
 */
export function useQuarterlyReportQuery(baseOptions: Apollo.QueryHookOptions<QuarterlyReportQuery, QuarterlyReportQueryVariables> & ({ variables: QuarterlyReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuarterlyReportQuery, QuarterlyReportQueryVariables>(QuarterlyReportDocument, options);
      }
export function useQuarterlyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuarterlyReportQuery, QuarterlyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuarterlyReportQuery, QuarterlyReportQueryVariables>(QuarterlyReportDocument, options);
        }
export function useQuarterlyReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuarterlyReportQuery, QuarterlyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuarterlyReportQuery, QuarterlyReportQueryVariables>(QuarterlyReportDocument, options);
        }
export type QuarterlyReportQueryHookResult = ReturnType<typeof useQuarterlyReportQuery>;
export type QuarterlyReportLazyQueryHookResult = ReturnType<typeof useQuarterlyReportLazyQuery>;
export type QuarterlyReportSuspenseQueryHookResult = ReturnType<typeof useQuarterlyReportSuspenseQuery>;
export type QuarterlyReportQueryResult = Apollo.QueryResult<QuarterlyReportQuery, QuarterlyReportQueryVariables>;