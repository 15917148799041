import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const UtilityButtonGroup: Styles = {
  display: 'flex',
  justifyContent: 'space-between',

  '.MuiButton-root': {
    color: colors.navy,
  },

  '.MuiSvgIcon-root': {
    color: colors.blueberry,
  },
};

export const Tabs: Styles = {
  width: '100%',
  pt: 4,
  pb: 5,
};

export const ScrollContainer: Styles = {
  mt: 4,
  pr: 0.5,
  maxHeight: 600,
  overflow: 'auto',
  textAlign: 'start',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,

  '&::-webkit-scrollbar': {
    borderRadius: 2,
    width: 14,
    backgroundColor: colors.brightGray,
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: 2,
    backgroundClip: 'padding-box',
    border: `4px solid ${colors.brightGray}`,
    backgroundColor: colors.slateGray,
  },
};

export const QuestionWrapper: Styles = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  backgroundColor: colors.ghostWhite,
  borderRadius: 1,
  gap: 2,
};

export const Answers: Styles = {
  width: '100%',
  py: 2,
  px: 3,
  display: 'flex',
  justifyContent: 'space-between',
  boxSizing: 'border-box',

  ':not(:first-of-type)': {
    borderTop: `1px solid ${colors.hawkesBlue}`,
  },
};

export const AnswerTypography: Styles = {
  display: 'flex',
  flexDirection: 'column',
  wordBreak: 'break-word',
  width: 640,
};

export const EmailTypography: Styles = {
  color: colors.slateGray,
};

export const ListWrapper: Styles = {
  display: 'flex',
  justifyContent: 'center',
};

export const EmailsList: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  mt: 1,
};

export const EmailsSubtitle: Styles = {
  mt: 2,
};

export const EmailButton: Styles = {
  justifyContent: 'start',
  color: colors.navy,
  backgroundColor: colors.brightGray,

  '&:hover': {
    backgroundColor: colors.hawkesBlue,
  },
};

export const SummaryWrapper: Styles = {
  display: 'flex',
  justifyContent: 'stretch',
  gap: 6,
};

export const SummaryItem: Styles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  border: `1px solid ${colors.offlineGray}`,
  p: 2,
  borderRadius: 2,
};

export const AutocompletePaper: Styles = {
  mt: 1,
  borderRadius: 2,
  boxShadow: 4,

  '& .MuiAutocomplete-option': {
    ':nth-of-type(odd)': {
      backgroundColor: colors.ghostWhite,
    },
    '&:hover': {
      backgroundColor: 'rgba(71, 133, 255, 0.12)',
    },
  },

  '& .MuiAutocomplete-listbox': {
    py: 0,
  },
};
