import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as Styles from './LoadingOverlay.styles';

export const LoadingOverlay = () => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.Container}>
      <Box sx={Styles.Backdrop}>
        <Typography sx={Styles.Label} variant="body2">
          {t('employeesTable.loader')}
        </Typography>
        <CircularProgress sx={Styles.Loader} size={24} />
      </Box>
    </Box>
  );
};
