import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AvatarWithFallback } from 'ui';
import { useEmployee } from 'hooks';

import * as Styles from './SurveyReceiverSection.styles';

export const SurveyReceiverSection = () => {
  const { t } = useTranslation();
  const { employeeData, isEmployeeDataLoading } = useEmployee();

  if (isEmployeeDataLoading) {
    return null;
  }

  const employeeFullName = `${employeeData?.firstName || ''} ${employeeData?.lastName || ''}`;

  return (
    <Box sx={Styles.AvatarSection}>
      <AvatarWithFallback size="xl" alt={employeeFullName} src={employeeData?.avatarUrl} />

      <Typography variant="subtitle1" sx={Styles.Heading}>
        {t('surveyBuilder.questions.heading')}
        <Typography component="span" sx={Styles.Name}>
          {` ${employeeFullName}`}
        </Typography>
      </Typography>
    </Box>
  );
};
