import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CardSidebar, BackButton } from 'ui';
import { ReactComponent as UndrawImage } from 'assets/images/undrawSurveyImage.svg';
import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';
import { useValidatedParams } from 'hooks';

import * as Styles from './SurveyTemplates.styles';
import { SurveyTemplatesTabs } from './surveyTemplatesTabs/SurveyTemplatesTabs';
import { SurveysListTab } from './surveyTemplatesTabs/SurveyTemplatesTabs.types';

export const SurveyTemplates = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { employeeId } = useValidatedParams(['employeeId']);

  return (
    <CardSidebar
      button={
        <BackButton
          onClick={() => navigate(`/${AppRoute.EmployeeDetails}/${employeeId}/${EmployeeDetailsViewParam.Quarterly}`)}
        />
      }
    >
      <Box sx={Styles.Wrapper}>
        <Box sx={Styles.DescriptionWrapper}>
          <Typography variant="h2" sx={Styles.ActionsHeader}>
            {t('surveyTemplates.title')}
          </Typography>
          <Typography variant="body1">{t('surveyTemplates.description')}</Typography>
          <UndrawImage style={Styles.UndrawImage} />
        </Box>
        <Box sx={Styles.InteractiveSideWrapper}>
          <SurveyTemplatesTabs defaultTab={SurveysListTab.Draft} />
        </Box>
      </Box>
    </CardSidebar>
  );
};
