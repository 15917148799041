import { ActionsAmountKeys } from 'app/employeeDetails/employeeDetailsWeekly/actionsTabs/ActionsTabs.types';
import { Action } from 'generated/webapp_gql';

import { AllActionsDataLoaderReturn, AllActionsDataLoaderProps } from './EmployeeActions.types';

export const allActionsDataLoader = ({
  allActionsData,
  prevAllActionsData,
  type,
}: AllActionsDataLoaderProps): AllActionsDataLoaderReturn => {
  const actionsData = allActionsData?.[<ActionsAmountKeys>type.toLowerCase()];
  const prevActionsData = prevAllActionsData?.[<ActionsAmountKeys>type.toLowerCase()];

  if (!actionsData || (actionsData.items.length === 0 && actionsData.total > 0)) {
    return {
      items: <Action[]>prevActionsData?.items,
      total: actionsData?.total ?? prevActionsData?.total ?? 0,
      loading: true,
    };
  }

  return {
    items: <Action[]>actionsData.items,
    total: actionsData.total,
    loading: false,
  };
};
