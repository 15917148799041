export const MuiCheckbox = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      root: {
        '&.Mui-checked': {
          color: colors.navy,
        },
        '&.Mui-disabled .MuiSvgIcon-root': {
          color: colors.disabledGray,
        },
      },
    },
  };
};
