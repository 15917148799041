import { Box, Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';

import { useAuth, useMe } from 'hooks';
import { AvatarWithFallback, DropDownMenu } from 'ui';
import { AppRoute } from 'routing/AppRoute.enum';
import { authStorage } from 'context';

import * as Styles from './UserSection.styles';

export const UserSection = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const handleMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
    navigate(`/${AppRoute.Login}`);
  };

  const { meData } = useMe();

  const notAuthorizedUsername = authStorage.username ?? '';

  const username = meData ? `${meData.firstName} ${meData.lastName}` : notAuthorizedUsername;

  return (
    <Box sx={Styles.Wrapper}>
      <Button
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        sx={Styles.UserNameButton}
      >
        <AvatarWithFallback alt={username} src={meData?.avatarUrl} />
        {username}
        <KeyboardArrowDownIcon sx={!!anchorEl ? Styles.ExpandIconClicked : Styles.ExpandIcon} />
      </Button>
      <DropDownMenu anchorEl={anchorEl} onMenuClose={handleClose}>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={Styles.LogoutIcon} />
          {t('userSection.logout')}
        </MenuItem>
      </DropDownMenu>
    </Box>
  );
};
