import { array, object, string } from 'yup';

import i18n from 'i18n/i18n';
import { SurveyQuestionFormat } from 'generated/webapp_gql';

import { validateAnswers } from './SurveyBuilderLayout.utils';

const { t } = i18n;

export const surveyFormSchema = object({
  title: string().required(t('surveyBuilder.questions.errorMessage.titleIsRequired')),
  beginning: string().trim().required(t('surveyBuilder.questions.errorMessage.introIsRequired')),
  sections: array(
    object().shape({
      title: string()
        .trim()
        .required(t('surveyBuilder.questions.errorMessage.titleIsRequired'))
        .max(50, t('surveyBuilder.questions.errorMessage.titleMaxLength')),
      description: string().max(500, t('surveyBuilder.questions.errorMessage.descriptionMaxLength')),
      questions: array(
        object().when((values) => {
          if (values.format !== SurveyQuestionFormat.OpenEnded) {
            return object().shape({
              question: string().required(t('surveyBuilder.questions.errorMessage.questionRequired')),
              answers: array()
                .of(object().shape({ value: string() }))
                .test({
                  test: () => validateAnswers(values.answers),
                }),
            });
          }
          return object().shape({
            question: string().required(t('surveyBuilder.questions.errorMessage.questionRequired')),
          });
        }),
      ),
    }),
  ),
  conclusion: string().trim().required(t('surveyBuilder.questions.errorMessage.outroIsRequired')),
});
