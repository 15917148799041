import { ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { EmployeeSection } from 'app/employeeDetails/employeeSection/EmployeeSection';
import { useEmployee, useReadOnlyEmployee, useValidatedParams } from 'hooks';
import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';
import { SessionStorageKey } from 'shared/types';
import { AddButton, BackButton, ButtonGroupButton, CardSidebar, Loader } from 'ui';

import * as Styles from './EmployeeDetails.styles';
import { EmployeeDetailsQuarterly } from './employeeDetailsQuarterly/EmployeeDetailsQuarterly';
import { EmployeeDetailsWeekly } from './employeeDetailsWeekly/EmployeeDetailsWeekly';

export const EmployeeDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeDetailsView, employeeId } = useValidatedParams(['employeeDetailsView', 'employeeId']);

  const { employeeData, isEmployeeDataLoading } = useEmployee({
    onError: () => {
      navigate(AppRoute.Home);
    },
  });
  const { isReadOnly, isReadOnlyLoading } = useReadOnlyEmployee(employeeId);

  if (isEmployeeDataLoading || isReadOnlyLoading) return <Loader />;

  if (!employeeData) return null;

  const isEmployeeDetailsWeeklyViewActive = employeeDetailsView === EmployeeDetailsViewParam.Weekly;
  const handleButtonGroupClick = (route: EmployeeDetailsViewParam) =>
    navigate(`/${AppRoute.EmployeeDetails}/${employeeId}/${route}?page=1`);

  const homePageSearchParams = sessionStorage.getItem(SessionStorageKey.Search);

  return (
    <CardSidebar
      titleContent={
        <>
          <ButtonGroup sx={isReadOnly ? Styles.ButtonGroup : {}}>
            <ButtonGroupButton
              isActive={isEmployeeDetailsWeeklyViewActive}
              onClick={() => handleButtonGroupClick(EmployeeDetailsViewParam.Weekly)}
              text={t('employeeDetails.weekly.title')}
            />
            <ButtonGroupButton
              isActive={employeeDetailsView === EmployeeDetailsViewParam.Quarterly}
              onClick={() => handleButtonGroupClick(EmployeeDetailsViewParam.Quarterly)}
              text={t('employeeDetails.quarterly.title')}
            />
          </ButtonGroup>
          {!isReadOnly &&
            (isEmployeeDetailsWeeklyViewActive ? (
              <Link to={`/${AppRoute.AddFeedback}/${employeeId}`}>
                <AddButton title={t('employeeDetails.weekly.addNewReport.title')} />
              </Link>
            ) : (
              <Link to={`/${AppRoute.AddReport}/${employeeId}`}>
                <AddButton title={t('employeeDetails.quarterly.addNewReport.title')} />
              </Link>
            ))}
        </>
      }
      button={<BackButton route={`${homePageSearchParams || AppRoute.Employees}`} />}
      sidebar={<EmployeeSection employee={employeeData} withGoals={isEmployeeDetailsWeeklyViewActive} />}
    >
      {isEmployeeDetailsWeeklyViewActive ? <EmployeeDetailsWeekly /> : <EmployeeDetailsQuarterly />}
    </CardSidebar>
  );
};
