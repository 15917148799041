import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const DropdownWrapper: Styles = {
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '178px',
  width: '100%',
  borderLeft: `1px solid ${colors.brightGray}`,
};

export const DropdownButton: Styles = {
  py: 0,
  px: 0,
};

export const ArrowIcon: Styles = {
  fontSize: 24,
};

export const DropDownList: Styles = {
  px: 1,
};

export const MenuItemHighlight: Styles = {
  my: 1,
  paddingLeft: 1.25,
  paddingRight: 1,
  borderRadius: '4px',
};

export const MenuItemLabel: Styles = {
  fontSize: 13,
  fontWeight: 500,
  marginLeft: 0.75,
};
