import { List, ListItem } from '@mui/material';

import i18n from 'i18n/i18n';

import type { EmployeeActionsTooltipTitleProps } from './EmployeeActionsTooltipTitle.types';

const { t } = i18n;

export const EmployeeActionsTooltipTitle = ({ actions, numberOfActions }: EmployeeActionsTooltipTitleProps) => (
  <div>
    <List disablePadding>
      {actions.map((action) => (
        <ListItem key={action.id} disablePadding>
          ● {action.content}
        </ListItem>
      ))}
    </List>
    {numberOfActions > actions.length && (
      <>+ {t('employeesTable.table.moreActions', { count: numberOfActions - actions.length })}</>
    )}
  </div>
);
