import { InternalRefetchQueriesInclude } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Toast } from 'ui';
import { useUpdateActionMutation } from 'documents/updateAction.generated';
import { useValidatedParams } from 'hooks';
import { ActionStatus } from 'generated/webapp_gql';
import { ACTIONS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsWeekly/EmployeeDetailsWeekly';
import { UpdateAction } from 'hooks/utils/updateAction';
import { ModalState } from 'hooks/useModal/useModal.types';
import { ActionsOfEmployeeDocument, ActionsOfEmployeeQueryVariables } from 'documents/actionsOfEmployee.generated';
import {
  AllActionsOfEmployeeDocument,
  AllActionsOfEmployeeQueryVariables,
} from 'documents/allActionsOfEmployee.generated';

import { UseUpdateActionProps } from './useUpdateAction.types';

export const useUpdateAction = (props?: UseUpdateActionProps) => {
  const { actionStatus, offset, pages } = props || {};

  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);

  const refetchQueries: InternalRefetchQueriesInclude = [];

  if (actionStatus && offset !== undefined) {
    refetchQueries.push({
      query: ActionsOfEmployeeDocument,
      variables: {
        employeeId,
        actionStatus: actionStatus ?? ActionStatus.InProgress,
        pagination: {
          limit: ACTIONS_LIST_PAGE_SIZE,
          offset: offset ?? 0,
        },
      } as ActionsOfEmployeeQueryVariables,
    });
  }

  if (pages) {
    refetchQueries.push({
      query: AllActionsOfEmployeeDocument,
      variables: {
        employeeId,
        canceledPagination: {
          offset: (pages.canceled - 1) * ACTIONS_LIST_PAGE_SIZE,
          limit: ACTIONS_LIST_PAGE_SIZE,
        },
        donePagination: {
          offset: (pages.done - 1) * ACTIONS_LIST_PAGE_SIZE,
          limit: ACTIONS_LIST_PAGE_SIZE,
        },
        inProgressPagination: {
          offset: (pages.in_progress - 1) * ACTIONS_LIST_PAGE_SIZE,
          limit: ACTIONS_LIST_PAGE_SIZE,
        },
      } as AllActionsOfEmployeeQueryVariables,
    });
  }

  const [updateAction, { loading: isUpdateActionLoading }] = useUpdateActionMutation({
    refetchQueries,
    onError: (error) => toast.error(error.message),
    update: (cache, input) => {
      if (!input.data?.updateAction?.actionStatus) {
        return;
      }

      new UpdateAction(cache, employeeId, input.data?.updateAction?.actionStatus).onUpdate();
    },
    onCompleted: ({ updateAction }) => {
      toast.success(
        <Toast
          title={t('actions.toast.heading')}
          message={
            updateAction?.actionStatus === ActionStatus.Done
              ? t('actions.toast.actionDone')
              : t('actions.toast.actionCanceled')
          }
        />,
      );
    },
  });

  const sendAction = ({ id, updatedActionStatus }: ModalState<ActionStatus>) => {
    updateAction({
      variables: { input: { id, actionStatus: updatedActionStatus } },
    });
  };

  return { sendAction, isUpdateActionLoading };
};
