import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ButtonPrevProps } from './ButtonPrev.types';

export const ButtonPrev = ({ onClick, children }: ButtonPrevProps) => {
  return (
    <Button startIcon={<ArrowBackIcon />} variant="text" onClick={onClick}>
      {children}
    </Button>
  );
};
