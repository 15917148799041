import { Box, Typography } from '@mui/material';

import { colors } from 'shared/utils';

import { SurveyPublicationRequiredOptionProps } from './SurveyPublicationRequiredOption.types';
import * as Styles from './SurveyPublicationRequiredOption.styles';

export const SurveyPublicationRequiredOption = ({
  title,
  subtitle,
  isSurveyClosed,
  disabled,
  children,
}: SurveyPublicationRequiredOptionProps) => {
  return (
    <Box sx={{ ...Styles.Container, bgcolor: isSurveyClosed ? 'inherit' : colors.ghostWhite }}>
      <Box sx={Styles.OptionLabel}>
        <Typography sx={{ ...Styles.OptionLabelTitle, color: isSurveyClosed ? colors.slateGray : colors.navy }}>
          {title}
        </Typography>
        <Typography sx={Styles.OptionLabelSubTitle}>{subtitle}</Typography>
      </Box>
      {!disabled && children}
    </Box>
  );
};
