import { ApolloCache } from '@apollo/client';

import { ActionsAmountKeys } from 'app/employeeDetails/employeeDetailsWeekly/actionsTabs/ActionsTabs.types';
import {
  ACTIONS_LIST_PAGE_SIZE,
  WEEKLY_FEEDBACKS_PAGE_SIZE,
} from 'app/employeeDetails/employeeDetailsWeekly/EmployeeDetailsWeekly';
import {
  EmployeeDetailsWeeklyDocument,
  EmployeeDetailsWeeklyQuery,
  EmployeeDetailsWeeklyQueryVariables,
} from 'documents/employeeDetailsWeekly.generated';
import { EmployeesDocument, EmployeesQuery } from 'documents/employees.generated';
import { ActionStatus } from 'generated/webapp_gql';

export class UpdateAction {
  private readonly cache: ApolloCache<unknown>;
  private readonly employeeId: number;
  private readonly newStatus: ActionStatus;

  constructor(cache: ApolloCache<unknown>, employeeId: number, newStatus: ActionStatus) {
    this.cache = cache;
    this.employeeId = employeeId;
    this.newStatus = newStatus;
  }

  private getQueryOptions() {
    const employeeDetailsWeeklyQuery = {
      query: EmployeeDetailsWeeklyDocument,
      variables: {
        employeeId: this.employeeId,
        actionStatus: ActionStatus.InProgress,
        actionsPagination: {
          limit: ACTIONS_LIST_PAGE_SIZE,
          offset: 0,
        },
        weeklyPagination: {
          limit: WEEKLY_FEEDBACKS_PAGE_SIZE,
          offset: 0,
        },
      } as EmployeeDetailsWeeklyQueryVariables,
    };

    const employeesQueryOptions = {
      query: EmployeesDocument,
      variables: {
        assignedToMe: false,
      },
    };

    return {
      employeeDetailsWeeklyQuery,
      employeesQueryOptions,
    };
  }

  private updateCachedActionsTabsAmount() {
    const { employeeDetailsWeeklyQuery } = this.getQueryOptions();

    this.cache.updateQuery<EmployeeDetailsWeeklyQuery>(employeeDetailsWeeklyQuery, (data) => {
      if (!data || !data.employee) {
        return;
      }

      const in_progress = (data.employee.in_progress ?? 1) - 1;
      const updatedStatus = (data.employee[<ActionsAmountKeys>this.newStatus.toLowerCase()] ?? 0) + 1;

      return {
        ...data,
        employee: {
          ...data.employee,
          in_progress,
          [<ActionsAmountKeys>this.newStatus.toLowerCase()]: updatedStatus,
        },
      };
    });
  }

  private updateCachedEmployeesActionsAmount() {
    const { employeeDetailsWeeklyQuery, employeesQueryOptions } = this.getQueryOptions();

    const actionsData = this.cache.readQuery<EmployeeDetailsWeeklyQuery>(employeeDetailsWeeklyQuery);

    if (!actionsData || !actionsData.employee) {
      return;
    }

    this.cache.updateQuery<EmployeesQuery>(employeesQueryOptions, (data) => {
      if (!data || !data.employees) {
        return;
      }

      const employeeArrayIndex = data.employees.items.findIndex((emp) => emp?.id === this.employeeId);

      if (employeeArrayIndex < 0) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      data.employees.items[employeeArrayIndex]!.actionsInProgress = actionsData.employee?.in_progress;

      return data;
    });
  }

  public onUpdate() {
    this.updateCachedActionsTabsAmount();
    this.updateCachedEmployeesActionsAmount();
  }
}
