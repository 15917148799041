import { Dispatch, SetStateAction, useState } from 'react';

import { AnySearchParams, UpdateStateParamsValue, UseSearchParamsStateProps } from './useSearchParamsState.types';
import { isArray, paramsToObject } from './useSearchParamsState.utils';

export function useSearchParamsState<K extends AnySearchParams, T extends keyof K>({
  params,
  searchParams,
  setSearchParams,
}: UseSearchParamsStateProps<K, T>): [K[T], Dispatch<SetStateAction<K[T]>>];
export function useSearchParamsState<K extends AnySearchParams, T extends keyof K>({
  params,
  searchParams,
  setSearchParams,
}: UseSearchParamsStateProps<K, T[]>): [Pick<K, T>, Dispatch<SetStateAction<Pick<K, T>>>];

export function useSearchParamsState<K extends AnySearchParams, T extends keyof K>({
  params,
  searchParams,
  setSearchParams,
}: UseSearchParamsStateProps<K, T | T[]>) {
  const [stateParam, _setStateParam] = useState(
    isArray<K, T>(params) ? paramsToObject(searchParams, params) : searchParams[params],
  );

  const updateStateParams = (updateValue: UpdateStateParamsValue<K, T>) => {
    const searchParamsArr = Object.entries(
      isArray<K, T>(params)
        ? { ...searchParams, ...paramsToObject(<Pick<K, T>>updateValue, params) }
        : { ...searchParams, [params]: updateValue },
    ).reduce<[string, string][]>((prev, [key, value]) => {
      if (value === null) {
        return prev;
      }

      return [...prev, [key, String(value)]];
    }, []);

    setSearchParams(searchParamsArr);
  };

  const setStateParam: Dispatch<SetStateAction<K[T] | Pick<K, T>>> = (value) => {
    updateStateParams(value);
    _setStateParam(value);
  };

  return [stateParam, setStateParam];
}
