import { Box, Card } from '@mui/material';

import { Title } from 'ui';

import * as Styles from './CardSidebar.styles';
import { CardSidebarProps } from './CardSidebar.types';

export const CardSidebar = ({ children, sidebar, titleContent, button }: CardSidebarProps) => {
  return (
    <Card>
      <Box>
        <Title button={button}>{titleContent}</Title>
        <Box sx={Styles.Wrapper}>
          {sidebar && <Box sx={Styles.Sidebar}>{sidebar}</Box>}
          <Box sx={Styles.ChildrenWrapper}>{children}</Box>
        </Box>
      </Box>
    </Card>
  );
};
