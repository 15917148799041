import { Typography } from '@mui/material';

import { EmojiProps } from './Emoji.types';

export const Emoji = ({ label, symbol, size }: EmojiProps) => (
  <Typography
    className="emoji"
    role="img"
    aria-label={label ? label : ''}
    aria-hidden={label ? 'false' : 'true'}
    fontSize={size}
  >
    {symbol}
  </Typography>
);
