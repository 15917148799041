import { useSearchParams } from 'react-router-dom';

import { UseSearchParamOptions, UseSearchParamTypes } from 'hooks/useSearchParam/useSearchParam.types';

export type OnInputChangeSharedProps = {
  searchParams: ReturnType<typeof useSearchParams>[0];
  setSearchParams: ReturnType<typeof useSearchParams>[1];
  paramName: string;
  additionalStateParamsToClear?: string[];
  options?: UseSearchParamOptions;
  isInitial: boolean;
};

export const onSearchParamsChange =
  <T extends UseSearchParamTypes>({
    searchParams,
    setSearchParams,
    paramName,
    additionalStateParamsToClear,
    isInitial,
  }: OnInputChangeSharedProps) =>
  (param: T | null) => {
    if (param !== null && param !== '') {
      const newValue = Array.isArray(param) ? param.join(',') : param;
      searchParams.set(paramName, String(newValue));
    } else {
      searchParams.delete(paramName);
    }

    if (!isInitial) {
      additionalStateParamsToClear?.forEach((additionalParamName) => {
        searchParams.delete(additionalParamName);
      });
    }

    setSearchParams(searchParams);
  };
