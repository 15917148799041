import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';

import { CenteredMessageWrapper } from '../CenteredMessageWrapper';
import { useAuth } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';

import * as Styles from './NotAuthenticatedMessage.styles';

export const NotAuthenticatedMessage = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <CenteredMessageWrapper sx={Styles.Wrapper} containerSx={Styles.Container}>
      <WarningIcon sx={Styles.WarningIcon} />
      <Typography variant="h5">{t('login.userNotAuthenticated.heading')}</Typography>
      <Typography variant="body2">{t('login.userNotAuthenticated.content')}</Typography>
      <Link
        sx={Styles.Link}
        onClick={() => {
          logout();
          navigate(AppRoute.Login);
        }}
      >
        {t('login.userNotAuthenticated.link')}
      </Link>
    </CenteredMessageWrapper>
  );
};
