import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingOverlay } from 'ui';

import { DraftsTab } from './draftsTab/DraftsTab';
import { SurveysListTab, TabsProps } from './SurveyTemplatesTabs.types';
import * as Styles from './SurveyTabs.styles';
import { SentTab } from './sentTab/SentTab';
import { ArchivedTab } from './archivedTab/ArchivedTab';
import { useTabs } from './useTabs';
import { SurveyTabsSortingController } from './SurveyTabsSorting/SurveyTabsSortingController';

export const SurveyTemplatesTabs = ({ defaultTab }: TabsProps) => {
  const { t } = useTranslation();
  const { isLoading, changeCurrentTab, currentTab, draftsQueryContext, sentQueryContext, archivedQueryContext } =
    useTabs(defaultTab);

  if (isLoading) return <LoadingOverlay />;

  return (
    <>
      <Tabs value={currentTab} onChange={(_, value) => changeCurrentTab(value)} sx={Styles.TabsWrapper}>
        <Tab
          label={`${t('surveysList.tab.template')} (${draftsQueryContext.total})`}
          value={SurveysListTab.Draft}
          sx={Styles.Tab}
        />
        <Tab
          label={`${t('surveysList.tab.sent')} (${sentQueryContext.total})`}
          value={SurveysListTab.Sent}
          sx={Styles.Tab}
        />
        <Tab
          label={`${t('surveysList.tab.archive')} (${archivedQueryContext.total})`}
          value={SurveysListTab.Archived}
          sx={Styles.Tab}
        />
      </Tabs>
      <Box sx={Styles.TabsContentWrapper}>
        <SurveyTabsSortingController>
          <DraftsTab
            isActive={currentTab === SurveysListTab.Draft}
            department={draftsQueryContext.department}
            refetch={draftsQueryContext.refetch}
            items={draftsQueryContext.items}
            isListEmpty={!Boolean(draftsQueryContext.total)}
          />
          <SentTab
            onChangeCurrentTab={changeCurrentTab}
            items={sentQueryContext.items}
            isActive={currentTab === SurveysListTab.Sent}
            refetch={sentQueryContext.refetch}
            isListEmpty={!Boolean(sentQueryContext.total)}
          />
          <ArchivedTab
            items={archivedQueryContext.items}
            isActive={currentTab === SurveysListTab.Archived}
            refetch={archivedQueryContext.refetch}
            onChangeCurrentTab={changeCurrentTab}
            isListEmpty={!Boolean(archivedQueryContext.total)}
          />
        </SurveyTabsSortingController>
      </Box>
    </>
  );
};
