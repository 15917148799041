import { differenceInCalendarDays } from 'date-fns';
import { useWatch } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CreateSurveyForm } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { SurveyPublicationOption } from '../../surveyPublicationOption/SurveyPublicationOption';
import * as Styles from '../SurveyPublicationSettings.styles';

import { SurveyNotificationSettingProps } from './SurveyNotificationSetting.types';
import { SurveyNotificationCustomDateDialog } from './surveyNotificationCustomDateDialog/SurveyNotificationCustomDateDialog';

const notificationDefaultTimeOptions = [1, 2, 3, 7];

export const SurveyNotificationSetting = ({
  field: { value, name, onChange },
  disabled,
}: SurveyNotificationSettingProps) => {
  const { t } = useTranslation();
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
  const [isNotificationTimeDialogOpen, setIsNotificationTimeDialogOpen] = useState(false);
  const closeDate = useWatch<CreateSurveyForm>({ name: 'closeDate' }) as Date;

  const notificationTimeOptions = useMemo(() => {
    const options = [...notificationDefaultTimeOptions];

    if (value && !notificationDefaultTimeOptions.includes(value)) {
      options.push(value);
    }

    return options.filter((amountOfDays) => amountOfDays < differenceInCalendarDays(closeDate, new Date()));
  }, [value, closeDate]);

  const notificationDefaultTime = notificationTimeOptions.includes(3) ? 3 : notificationTimeOptions[0]; // 3 days or first option
  const isValueInOptions = Boolean(value && notificationTimeOptions.includes(value || 0));

  const onNotificationTimeDialogClose = () => {
    setIsNotificationTimeDialogOpen(false);
  };

  const onNotificationTimeDialogOpen = () => {
    setIsNotificationDropdownOpen(false);
    setIsNotificationTimeDialogOpen(true);
  };

  return (
    <SurveyPublicationOption
      name={name}
      title={t('surveyBuilder.publicationSettings.slackNotificationSwitch.title')}
      subtitle={t('surveyBuilder.publicationSettings.slackNotificationSwitch.subtitle')}
      isDefaultChecked={isValueInOptions}
      defaultValue={notificationDefaultTime}
      disabled={!notificationTimeOptions.length || disabled}
      uncheckedValue={0}
    >
      <Box sx={Styles.OptionSettingContainer}>
        <Select
          value={value}
          name={name}
          onChange={onChange}
          open={isNotificationDropdownOpen}
          onClose={() => setIsNotificationDropdownOpen(false)}
          onOpen={() => setIsNotificationDropdownOpen(true)}
          aria-label={t('surveyBuilder.publicationSettings.slackNotificationSwitch.otherOption.title')}
        >
          {notificationTimeOptions.map((v, key) => (
            <MenuItem value={v} key={key} selected={v === value}>
              {t('surveyBuilder.publicationSettings.slackNotificationSwitch.options_interval', {
                count: v,
                postProcess: 'interval',
              })}
            </MenuItem>
          ))}
          <MenuItem onClick={onNotificationTimeDialogOpen}>
            {t('surveyBuilder.publicationSettings.slackNotificationSwitch.otherOption.placeholder')}
          </MenuItem>
        </Select>
      </Box>
      {isNotificationTimeDialogOpen && (
        <SurveyNotificationCustomDateDialog
          closeSurveyDate={closeDate}
          onClose={onNotificationTimeDialogClose}
          onSubmit={onChange}
          selectedDate={value}
        />
      )}
    </SurveyPublicationOption>
  );
};
