import { EmployeeSortOptions, SortOrder } from 'generated/webapp_gql';
import { UseSearchParamTypes } from 'hooks/useSearchParam/useSearchParam.types';
import { SortDirection } from 'shared/types/enums/SortDirection.enum';

type SortValidationParams = {
  param?: string | null;
  defaultSortBy: EmployeeSortOptions;
};

// sortBy and sortOrder
const SORTING_PARAMS_LENGTH = 2;

export const validateBooleanParam = (param: string | null, defaultValue?: UseSearchParamTypes) => {
  if (defaultValue && param === null) {
    return null;
  }

  return param === 'true';
};

export const validateSortOrderParam = ({ param, defaultSortBy }: SortValidationParams) => {
  const paramUppercase = param?.toUpperCase();

  if (paramUppercase && Object.values(SortOrder).includes(paramUppercase as SortOrder)) {
    return paramUppercase as SortOrder;
  }

  return defaultSortBy === EmployeeSortOptions.ActionsInProgress ? SortOrder.Desc : SortOrder.Asc;
};

export const validateSortByParam = ({ param, defaultSortBy }: SortValidationParams) => {
  if (param && Object.values(EmployeeSortOptions).includes(param as EmployeeSortOptions)) {
    return param as EmployeeSortOptions;
  }

  return defaultSortBy;
};

export const validateNumberParam = (param: string | null) => Number(param) || null;

export const validateArrayParam = (param: string | null) => param?.split(',') || null;

export const validateSortingParam = (param: string | null): [string, SortDirection] | null => {
  const sorting = param?.split(',');

  if (
    !sorting ||
    sorting.length !== SORTING_PARAMS_LENGTH ||
    !Object.values(SortDirection).includes(<SortDirection>sorting[1])
  ) {
    return null;
  }

  return <[string, SortDirection]>sorting;
};
