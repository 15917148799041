import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Emoji } from 'ui';
import { useMe } from 'hooks';

import { EmployeesFilters } from './employeesFilters/EmployeesFilters';
import { EmployeesTopSectionProps } from './EmployeesTopSection.types';
import * as Styles from './EmployeesTopSection.styles';

export const EmployeesTopSection = ({ filters }: EmployeesTopSectionProps) => {
  const { t } = useTranslation();

  const { meData } = useMe();

  return (
    <Box sx={Styles.SectionWrapper}>
      <Box sx={Styles.TextWrapper}>
        <Emoji symbol="👋" label="wave" size={34} />
        <Typography sx={Styles.Heading}>
          {t('employeesTopSection.heading', { firstName: meData?.firstName })}
        </Typography>
        <Typography sx={Styles.SubHeading}>{t('employeesTopSection.subHeading')}</Typography>
        <EmployeesFilters filters={filters} />
      </Box>
    </Box>
  );
};
