import { Styles } from 'shared/types';

export const Wrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
};

export const UserNameButton: Styles = {
  textTransform: 'unset',
  fontSize: 16,
  gap: 1.5,
  display: 'flex',
  p: 0,
  fontWeight: 400,
};

export const ExpandIcon: Styles = {
  // transition set to match MUI <Menu /> component
  transition: '300ms cubic-bezier(0.4, 0, 0.2, 1)',
};

export const ExpandIconClicked: Styles = {
  ...ExpandIcon,
  transform: 'rotate(-180deg) ',
};

export const LogoutIcon: Styles = {
  mr: 1,
};
