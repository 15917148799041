export type RatingData = {
  emoji: string;
  text: string;
};

export type Rating = Record<number, RatingData>;

export type SmilingFacesRatingType = 1 | 2 | 3 | 4 | 5;

export enum SmilingFacesRatingValue {
  VeryBad = 1,
  Bad = 2,
  Moderate = 3,
  Fine = 4,
  Super = 5,
}

export type SmilingFacesRatingProps = {
  value: SmilingFacesRatingType;
  type?: 'default' | 'filled';
};
