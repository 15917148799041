import { isObject } from 'lodash';

import {
  Language,
  Section,
  Survey,
  SurveyForRespondent,
  SurveyQuestionFormat,
  SurveyResponseAnswersInput,
} from 'generated/webapp_gql';
import { ReadOnlySurveyType } from 'hooks/useSurvey/useSurvey.types';

import { FormatPreviewSurveyReturnData } from './SurveyLayout.types';

export const defaultSection: Section = {
  title: '',
  description: '',
  questions: [
    {
      question: '',
      format: SurveyQuestionFormat.OpenEnded,
      answers: [],
    },
  ],
};

const isSurveyType = (data: unknown): data is Pick<Survey, 'title' | 'surveyTemplate'> =>
  !!data && isObject(data) && 'surveyTemplate' in data;

export const formatPreviewSurveyData = (
  data: Pick<Survey, 'title' | 'surveyTemplate'> | SurveyForRespondent,
): FormatPreviewSurveyReturnData => {
  if (isSurveyType(data)) {
    return {
      title: data.title,
      language: data.surveyTemplate?.language ?? Language.Pl,
      beginning: data.surveyTemplate?.content?.beginning ?? '',
      conclusion: data.surveyTemplate?.content?.conclusion ?? '',
      sections: (data.surveyTemplate?.content?.sections ?? []) as Section[],
      endImageUrl: data.surveyTemplate?.endImageUrl,
      introImageUrl: data.surveyTemplate?.introImageUrl,
      endImageName: data.surveyTemplate?.endImageName ?? '',
      introImageName: data.surveyTemplate?.introImageName ?? '',
      surveyOwnerFullName: data.surveyTemplate?.creator?.fullName ?? '',
      surveyOwnerAvatarUrl: data.surveyTemplate?.creator?.avatarUrl ?? '',
    };
  }

  return {
    title: '',
    language: data.language ?? Language.Pl,
    beginning: data.content.beginning ?? '',
    conclusion: data.content.conclusion ?? '',
    sections: data.content.sections as Section[],
    response: (data.response ?? []) as SurveyResponseAnswersInput[],
    endImageUrl: data.endImageUrl,
    introImageUrl: data.introImageUrl,
    endImageName: data.endImageName ?? '',
    introImageName: data.introImageName ?? '',
    avatarUrl: data.concernedAvatarUrl ?? '',
    fullName: data.concernedFullName,
    surveyOwnerFullName: data.surveyOwnerFullName,
    surveyOwnerAvatarUrl: data.surveyOwnerAvatarUrl ?? '',
    responseStatus: data.responseStatus,
  };
};

export const isPublishedSurvey = (type: ReadOnlySurveyType) => type === ReadOnlySurveyType.PUBLISHED_SURVEY;
export const isPublishedTemplate = (type: ReadOnlySurveyType) => type === ReadOnlySurveyType.PUBLISHED_TEMPLATE;
