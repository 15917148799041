import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeWeeklyCurrentProjectsQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
}>;


export type EmployeeWeeklyCurrentProjectsQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', currentProjectsDetails?: Array<{ __typename?: 'Project', id: string, projectName: string } | null> | null } | null };


export const EmployeeWeeklyCurrentProjectsDocument = gql`
    query EmployeeWeeklyCurrentProjects($employeeId: Int!) {
  employee(id: $employeeId) {
    currentProjectsDetails {
      id
      projectName
    }
  }
}
    `;

/**
 * __useEmployeeWeeklyCurrentProjectsQuery__
 *
 * To run a query within a React component, call `useEmployeeWeeklyCurrentProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeWeeklyCurrentProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeWeeklyCurrentProjectsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useEmployeeWeeklyCurrentProjectsQuery(baseOptions: Apollo.QueryHookOptions<EmployeeWeeklyCurrentProjectsQuery, EmployeeWeeklyCurrentProjectsQueryVariables> & ({ variables: EmployeeWeeklyCurrentProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeWeeklyCurrentProjectsQuery, EmployeeWeeklyCurrentProjectsQueryVariables>(EmployeeWeeklyCurrentProjectsDocument, options);
      }
export function useEmployeeWeeklyCurrentProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeWeeklyCurrentProjectsQuery, EmployeeWeeklyCurrentProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeWeeklyCurrentProjectsQuery, EmployeeWeeklyCurrentProjectsQueryVariables>(EmployeeWeeklyCurrentProjectsDocument, options);
        }
export function useEmployeeWeeklyCurrentProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmployeeWeeklyCurrentProjectsQuery, EmployeeWeeklyCurrentProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeeWeeklyCurrentProjectsQuery, EmployeeWeeklyCurrentProjectsQueryVariables>(EmployeeWeeklyCurrentProjectsDocument, options);
        }
export type EmployeeWeeklyCurrentProjectsQueryHookResult = ReturnType<typeof useEmployeeWeeklyCurrentProjectsQuery>;
export type EmployeeWeeklyCurrentProjectsLazyQueryHookResult = ReturnType<typeof useEmployeeWeeklyCurrentProjectsLazyQuery>;
export type EmployeeWeeklyCurrentProjectsSuspenseQueryHookResult = ReturnType<typeof useEmployeeWeeklyCurrentProjectsSuspenseQuery>;
export type EmployeeWeeklyCurrentProjectsQueryResult = Apollo.QueryResult<EmployeeWeeklyCurrentProjectsQuery, EmployeeWeeklyCurrentProjectsQueryVariables>;