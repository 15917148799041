import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { InternalRefetchQueriesInclude } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { Toast } from 'ui';
import { useUpdateGoalMutation } from 'documents/updateGoal.generated';
import { GoalStatus } from 'generated/webapp_gql';
import { ModalState } from 'hooks/useModal/useModal.types';
import { useValidatedParams } from 'hooks';
import { GOALS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';
import { AllGoalsOfEmployeeDocument, AllGoalsOfEmployeeQueryVariables } from 'documents/allGoalsOfEmployee.generated';
import { EmployeeGoalsDocument } from 'documents/employeeGoals.generated';

import { UseUpdateGoalProps } from './useUpdateGoal.types';

export const useUpdateGoal = ({ pages }: UseUpdateGoalProps = {}) => {
  const { t } = useTranslation();

  const { employeeId } = useValidatedParams(['employeeId']);

  const refetchQueries: InternalRefetchQueriesInclude = [];

  if (pages) {
    refetchQueries.push({
      query: AllGoalsOfEmployeeDocument,
      variables: {
        employeeId,
        canceledPagination: {
          offset: (pages.canceled - 1) * GOALS_LIST_PAGE_SIZE,
          limit: GOALS_LIST_PAGE_SIZE,
        },
        donePagination: {
          offset: (pages.done - 1) * GOALS_LIST_PAGE_SIZE,
          limit: GOALS_LIST_PAGE_SIZE,
        },
        inProgressPagination: {
          offset: (pages.in_progress - 1) * GOALS_LIST_PAGE_SIZE,
          limit: GOALS_LIST_PAGE_SIZE,
        },
      } as AllGoalsOfEmployeeQueryVariables,
    });
  } else {
    refetchQueries.push(getOperationName(EmployeeGoalsDocument) || '');
  }

  const [updateGoal, { loading: isUpdateGoalLoading }] = useUpdateGoalMutation({
    refetchQueries,
    onError: (error) => toast.error(error.message),
    onCompleted: ({ updateGoal }) => {
      toast.success(
        <Toast
          title={t('goals.toast.heading')}
          message={
            updateGoal?.goalStatus === GoalStatus.Done ? t('goals.toast.goalDone') : t('goals.toast.goalCanceled')
          }
        />,
      );
    },
  });

  const sendGoal = ({ id, updatedActionStatus }: ModalState<GoalStatus>) => {
    updateGoal({
      variables: { input: { id, goalStatus: updatedActionStatus } },
    });
  };

  return { sendGoal, isUpdateGoalLoading };
};
