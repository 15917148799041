import i18next from 'i18next';

import Terrible from 'assets/images/satisfactionRating/Terrible.png';
import Sad from 'assets/images/satisfactionRating/Sad.png';
import Neutral from 'assets/images/satisfactionRating/Neutral.png';
import Happy from 'assets/images/satisfactionRating/Happy.png';
import Love from 'assets/images/satisfactionRating/Love.png';

import { Rating } from './SmilingFacesRating.types';

const { t } = i18next;

export const smilingFacesRating: Rating = {
  1: {
    emoji: Terrible,
    text: t('smilingFacesRating.rating1'),
  },
  2: {
    emoji: Sad,
    text: t('smilingFacesRating.rating2'),
  },
  3: {
    emoji: Neutral,
    text: t('smilingFacesRating.rating3'),
  },
  4: {
    emoji: Happy,
    text: t('smilingFacesRating.rating4'),
  },
  5: {
    emoji: Love,
    text: t('smilingFacesRating.rating5'),
  },
};
