import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styles from './PreviewSurveyOutro.styles';
import { PreviewSurveyOutroProps } from './PreviewSurveyOutro.types';

export const PreviewSurveyOutro = ({ outro, rewardUrl }: PreviewSurveyOutroProps) => {
  const { t } = useTranslation();

  const [showMeme, setShowMeme] = useState(false);

  return (
    <Box sx={Styles.Container}>
      <Box component="img" sx={Styles.OutroImage} alt="meme" src={rewardUrl} hidden={!showMeme} />
      {!showMeme && (
        <Fragment>
          <Typography variant="h5" component="div" sx={Styles.WellDone}>
            <span>🥳</span>
            <span>{t('surveyPreview.outro.wellDone')}</span>
          </Typography>
          <Box sx={Styles.OutroText}>{outro}</Box>
          {rewardUrl && (
            <Button endIcon={<ArrowForwardIcon />} variant="contained" onClick={() => setShowMeme(true)}>
              {t('surveyPreview.outro.receiveMeme')}
            </Button>
          )}
        </Fragment>
      )}
    </Box>
  );
};
