export enum SurveyFormParam {
  Key = 'hash',
}

export const SurveyFormErrorCodes = {
  AnsweredButOpen: 'answeredButOpen',
  Closed: 'closed',
  Outdated: 'outdated',
} as const;

export type SurveyFormErrorCode = (typeof SurveyFormErrorCodes)[keyof typeof SurveyFormErrorCodes];
