import { useState } from 'react';

import { useAvailableSurveyTemplates } from '../../../hooks';
import { useDepartmentSentSurveys } from '../../../hooks/useDepartmentSentSurveys/useDepartmentSentSurveys';
import { useArchivedSurveyTemplates } from '../../../hooks/useArchivedSurveyTemplates/useArchivedSurveyTemplates';

import {
  ArchivedSurveyListItem,
  SentSurveyListItem,
  SurveysListTab,
  SurveyTemplateListItem,
  TabsProps,
} from './SurveyTemplatesTabs.types';
export const useTabs = (defaultTab: TabsProps['defaultTab']) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);

  const {
    availableSurveyTemplates,
    areAvailableSurveyTemplatesLoading,
    department = '',
    refetch: refetchTemplates,
  } = useAvailableSurveyTemplates();
  const { departmentSentSurveys, areSentSurveysLoading, refetch: refetchSent } = useDepartmentSentSurveys();

  const {
    areArchivedSurveyTemplatesLoading,
    archivedSurveyTemplates,
    refetch: refetchArchived,
  } = useArchivedSurveyTemplates();

  const isLoading = areSentSurveysLoading || areAvailableSurveyTemplatesLoading || areArchivedSurveyTemplatesLoading;

  const changeCurrentTab = (value: SurveysListTab) => {
    setCurrentTab(value);
  };

  return {
    isLoading,
    changeCurrentTab,
    currentTab,
    draftsQueryContext: {
      total: availableSurveyTemplates?.total ?? 0,
      department,
      refetch: refetchTemplates,
      items: (availableSurveyTemplates?.items || []) as SurveyTemplateListItem[],
    },
    sentQueryContext: {
      total: departmentSentSurveys?.total ?? 0,
      refetch: refetchSent,
      items: (departmentSentSurveys?.items || []) as SentSurveyListItem[],
    },
    archivedQueryContext: {
      total: archivedSurveyTemplates?.total ?? 0,
      refetch: refetchArchived,
      items: (archivedSurveyTemplates?.items || []) as ArchivedSurveyListItem[],
    },
  };
};
