import { Box, FormControlLabel, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { blues, colors, grays } from 'shared/utils';
import { Switch } from 'ui';

import * as Styles from './SurveyPublicationOption.styles';
import { SurveyPublicationOptionProps } from './SurveyPublicationOption.types';

export const SurveyPublicationOption = ({
  name,
  title,
  subtitle,
  children,
  isDefaultChecked,
  defaultValue,
  info,
  showInfo,
  disabled,
  uncheckedValue = null,
}: SurveyPublicationOptionProps) => {
  const [isChecked, setIsChecked] = useState(isDefaultChecked);
  const { setValue } = useFormContext();

  return (
    <Box
      sx={{
        ...Styles.Container,
        background: !disabled ? grays.ghostWhite : 'inherit',
      }}
    >
      <FormControlLabel
        sx={Styles.SwitchControl}
        disabled={disabled}
        label={
          <Box sx={Styles.SwitchLabel}>
            <Typography sx={{ ...Styles.SwitchLabelTitle, color: isChecked ? blues.navy : grays.slateGray }}>
              {title}
            </Typography>
            <Typography sx={Styles.SwitchLabelSubTitle}>{subtitle}</Typography>
          </Box>
        }
        control={
          <Switch
            onChange={(e) => {
              const { checked } = e.target;
              setValue(name, checked ? defaultValue : uncheckedValue, {
                shouldDirty: true,
              });
              setIsChecked(checked);
            }}
            checked={isChecked}
            disabled={disabled}
          />
        }
      />
      {!disabled && isChecked && children}
      {showInfo && info && (
        <Typography variant="body2" color={colors.slateGray}>
          {info}
        </Typography>
      )}
    </Box>
  );
};
