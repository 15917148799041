import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsAmountKeys } from 'app/employeeDetails/employeeDetailsWeekly/actionsTabs/ActionsTabs.types';
import { actionsTabsTranslations } from 'app/employeeDetails/employeeDetailsWeekly/actionsTabs/ActionsTabs.utils';
import { ACTIONS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsWeekly/EmployeeDetailsWeekly';
import { Action, ActionStatus } from 'generated/webapp_gql';
import { useModal, useUpdateAction, useUpdateActionContent, useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { Accordion, Modal, ToDoTable } from 'ui';
import { ToDoType } from 'ui/modal/Modal.enum';

import { ActionsAccordionProps } from './actionsAccordion.types';

export const ActionsAccordion = ({ type, items, total, loading, pages, setPages }: ActionsAccordionProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { t } = useTranslation();

  const updateHandler = useUpdateActionContent();

  const { sendAction } = useUpdateAction({ pages });
  const { handleItemClick, item, handleModalClose } = useModal<ActionStatus>({
    onConfirm: sendAction,
    canceledStatus: ActionStatus.Canceled,
    doneStatus: ActionStatus.Done,
  });

  useEffect(() => {
    const lastPage = Math.ceil(total / ACTIONS_LIST_PAGE_SIZE) || 1;

    if (lastPage < pages[type.toLowerCase() as ActionsAmountKeys]) {
      setPages({ ...pages, [type.toLowerCase() as ActionsAmountKeys]: lastPage });
    }
  }, [pages, setPages, type, items, total, loading]);

  const getRedirectToWeeklyFeedback = (weeklyFeedbackId?: string) =>
    weeklyFeedbackId ? { to: `/${AppRoute.PreviewFeedback}/${weeklyFeedbackId}/${employeeId}` } : null;

  return (
    <>
      <Accordion
        title={t(actionsTabsTranslations[type], {
          count: total,
        })}
        content={
          <ToDoTable
            onClick={handleItemClick}
            toDoData={items as Action[]}
            getOnArrowClickState={getRedirectToWeeklyFeedback}
            loading={loading}
            pagination={{
              total: total,
              pageSize: ACTIONS_LIST_PAGE_SIZE,
              current: pages[type.toLowerCase() as ActionsAmountKeys],
              handlePageChange: (event, value) => {
                setPages({ ...pages, [type.toLowerCase() as ActionsAmountKeys]: value });
              },
            }}
            context={updateHandler}
          />
        }
      />
      {item.type && (
        <Modal onClose={handleModalClose} type={item.type} content={item.content} toDoType={ToDoType.Action} />
      )}
    </>
  );
};
