import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const QuestionsWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
};

export const Title: Styles = {
  pb: 1.5,
};

export const TitleFormControl: Styles = {
  '.MuiInputBase-root.Mui-focused:not(.Mui-error)': {
    border: `1px solid ${colors.blueberry}`,
  },

  '.MuiInputBase-root.Mui-error': {
    border: `1px solid ${colors.tomato}`,
  },

  '&& .MuiInputBase-root': {
    mt: 0,
    pt: '17.5px',
    borderRadius: 1,
  },
};

export const ControlledTextField: Styles = {
  fieldset: {
    border: 'none',
  },
};

export const Buttons: Styles = {
  display: 'flex',
  justifyContent: 'center',
  gap: 1,
};

export const SwitchWrapper: Styles = {
  position: 'absolute',
  right: 32,
};
