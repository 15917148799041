import { endOfDay } from 'date-fns';
import { array, date, lazy, object, string } from 'yup';

import i18n from 'i18n/i18n';
import { validateWithoutHtml } from 'shared/utils/validateWithoutHtml/validateWithoutHtml';

import { getQuarterlyReportMaxDate } from './QuarterlyReportForm';

const { t } = i18n;

const getQuarterlyReportFormSchemaObject = (minDate: Date) => ({
  summary: validateWithoutHtml(t('quarterlyForm.errorMessage.summaryIsRequired'))
    .trim()
    .required(t('quarterlyForm.errorMessage.summaryIsRequired')),
  goals: array().of(
    object().shape({
      content: string(),
    }),
  ),
  notes: string().trim().nullable(),
  realizationDate: lazy(() =>
    date()
      .typeError(t('quarterlyForm.errorMessage.realizationDateInvalidType'))
      .nullable()
      .required(t('quarterlyForm.errorMessage.realizationDateRequired'))
      .min(endOfDay(minDate), t('quarterlyForm.errorMessage.realizationDateMaxDate'))
      .max(endOfDay(getQuarterlyReportMaxDate()), t('quarterlyForm.errorMessage.realizationDateMaxDate')),
  ),
});

export const getAddQuarterlyReportFormSchema = (minDate: Date) => object(getQuarterlyReportFormSchemaObject(minDate));

export const getEditQuarterlyReportFormSchema = (minDate: Date) =>
  object({
    ...getQuarterlyReportFormSchemaObject(minDate),
    goals: array().of(
      object().shape({
        content: string().min(1),
      }),
    ),
  });
