import { Button, Tab, TabsProps } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { SurveyStatusKeys } from 'app/employeeSurveys/EmployeeSurveys.types';
import { Survey, SurveyStatus } from 'generated/webapp_gql';
import { useAllSurveysOfEmployee, useModal, useValidatedParams } from 'hooks';
import { ModalState } from 'hooks/useModal/useModal.types';
import { AppRoute } from 'routing/AppRoute.enum';
import { Modal, TabPanel, Tabs, ToDoTable } from 'ui';
import { ToDoType } from 'ui/modal/Modal.enum';
import { useUpdateSurveyTitle } from 'hooks/useUpdateSurveyTitle/useUpdateSurveyTitle';

import { surveyTabsNames, surveyTabsTranslations } from './SurveyTabs.utils';
import { useCustomChangeSurveyStatus } from './useCustomChangeSurveyStatus';

export const SurveyTabs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);
  const [currentTab, setCurrentTab] = useState(SurveyStatus.Opened);
  const location = useLocation();

  const { allSurveysData, areSurveysLoading } = useAllSurveysOfEmployee();

  const { changeSurveyStatus } = useCustomChangeSurveyStatus({});

  const updateHandler = useUpdateSurveyTitle();

  const updateStatus = (item: ModalState<SurveyStatus>) => {
    changeSurveyStatus({
      variables: {
        input: {
          surveyId: item.id,
          status: item.updatedActionStatus ?? SurveyStatus.Closed,
        },
      },
    });
  };

  const handleChangeTab = (_: SyntheticEvent, value: TabsProps['value']) => setCurrentTab(value);

  const { handleItemClick, item, handleModalClose } = useModal<SurveyStatus>({
    onConfirm: updateStatus,
    canceledStatus: SurveyStatus.Closed,
    doneStatus: SurveyStatus.Closed,
  });

  const getRedirectToSurveyDetails = (id?: string) => ({
    to: `/${AppRoute.PublishedSurvey}/${employeeId}/${id}`,
    state: { backRoute: location.pathname },
  });

  const redirectToEmployeeSurveys = () => navigate(`/${AppRoute.EmployeeSurveys}/${employeeId}`);

  const getTotalCount = (type: SurveyStatusKeys) => {
    return allSurveysData?.[type]?.total || 0;
  };

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        button={
          <Link to={`/${AppRoute.SurveyTemplates}/${employeeId}`}>
            <Button variant="text">{t('actionAccordion.createNewSurvey')}</Button>
          </Link>
        }
      >
        {surveyTabsNames.map((statusName) => (
          <Tab
            key={statusName}
            label={t(surveyTabsTranslations[statusName], {
              count: getTotalCount(statusName.toLowerCase() as SurveyStatusKeys),
            })}
            value={statusName}
          />
        ))}
      </Tabs>
      {surveyTabsNames.map((statusName) => (
        <TabPanel value={currentTab} index={statusName} key={statusName}>
          <ToDoTable
            onClick={handleItemClick}
            loading={areSurveysLoading}
            toDoData={(allSurveysData?.[statusName.toLowerCase() as SurveyStatusKeys]?.items as Survey[]) || []}
            getOnArrowClickState={getRedirectToSurveyDetails}
            seeMore={{
              redirect: redirectToEmployeeSurveys,
              totalAmount: getTotalCount(statusName.toLowerCase() as SurveyStatusKeys),
            }}
            context={updateHandler}
            withoutCheckbox
            isEditable={false}
          />

          {item.type && (
            <Modal
              onClose={handleModalClose}
              type={item.type}
              content={item.content}
              toDoType={ToDoType.Survey}
              noAlert
            />
          )}
        </TabPanel>
      ))}
    </>
  );
};
