import { CSSProperties } from 'react';

import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Wrapper: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: '5%',
  py: '64px',
  height: '80vh',
  gap: '5%',
};

export const DescriptionWrapper: Styles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
};

export const UndrawImage: CSSProperties = {
  width: '80%',
  height: 'auto',
  marginTop: '64px',
  alignSelf: 'center',
};

export const InteractiveSideWrapper: Styles = {
  flex: 1,
  height: '100%',
  backgroundColor: colors.ghostWhite,
  p: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  alignItems: 'center',
};

export const ActionsHeader: Styles = {
  fontSize: 24,
  p: '16px 0',
};
