import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Pagination: Styles = { display: 'flex', justifyContent: 'center' };

export const Reports: Styles = { position: 'relative' };

export const Loader: Styles = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  backgroundColor: colors.ghostWhite,
  opacity: 0.6,
};
