import { useParams } from 'react-router';

import { useValidateObjectByKeys } from 'hooks';
import { PartialObjectWithRequiredKeys } from 'shared/types';

import { Params, ParamsKeys, ValidatedParams } from './useValidatedParams.types';

export function useValidatedParams<T extends ParamsKeys[]>(
  paramsToValidate: T,
): PartialObjectWithRequiredKeys<ValidatedParams, T>;
export function useValidatedParams<T extends ParamsKeys[]>(paramsToValidate?: T): Partial<ValidatedParams>;
export function useValidatedParams<T extends ParamsKeys[]>(paramsToValidate?: T) {
  const params = useParams<Params>();
  const validatedParams = useValidateObjectByKeys(params, paramsToValidate);

  return { ...validatedParams, employeeId: Number(validatedParams.employeeId) };
}
