import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const StyledQuillButton: Styles = {
  width: '24px !important',
  height: '24px !important',
  boxSizing: 'content-box',
  p: 0.5,
  color: colors.slateGray,
};

export const QuillToolbar: Styles = {
  border: 'none !important',
  px: '0 !important',
  display: 'none', // will change to flex when quill is focused, check QuillContainer
  alignItems: 'center',
  gap: 1.5,
};

export const QuillContainer = (name: string): Styles => ({
  p: 1,
  '&': {
    border: `1px solid ${colors.white}`,
    borderRadius: 2,
    backgroundColor: colors.white,

    [`& > #toolbar_${name}`]: {
      display: 'flex',
    },
  },
  '&:focus-within': {
    borderColor: colors.blueberry,
  },
});
