import { Styles } from 'shared/types';
import { colors, grays } from 'shared/utils';

export const Container: Styles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: 2,
};

export const ContentContainer: Styles = {
  background: grays.ghostWhite,
  margin: '0 auto',
  py: 4,
  px: { xs: 2, md: 5 },
  maxWidth: '670px',
  boxSizing: 'border-box',
  my: 8,
};

export const User: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Creator: Styles = {
  display: 'flex',
  gap: 1,
  alignSelf: 'center',
};

export const FullName: Styles = {
  mt: 1,
  color: colors.slateGray,
};

export const UserAvatarDescription: Styles = {
  mb: 1,
  color: colors.slateGray,
};

export const CreatorAvatarDescription: Styles = {
  mr: 1,
  mt: 1,
  color: colors.slateGray,
};

export const SectionCounter: Styles = {
  background: grays.slateGray,
  fontWeight: '500',
  mb: 2,
};

export const Description: Styles = {
  color: grays.slateGray,
  mt: 2,
  mb: 4,
};

export const Buttons: Styles = {
  display: 'flex',
  gap: 1,
  justifyContent: 'space-between',
  flexDirection: {
    xs: 'column-reverse',
    md: 'row',
  },
};
