import { Typography, Box, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircle from '@mui/icons-material/CheckCircle';

import { colors } from 'shared/utils';
import { SurveyResponseStatus } from 'generated/webapp_gql';
import { AvatarWithFallback } from 'ui';

import { AutocompleteOptionsProps } from './AutocompleteOptions.types';
import * as Styles from './AutocompleteOptions.styles';

export const AutocompleteOptions = ({ option, selected, ...props }: AutocompleteOptionsProps) => {
  const { t } = useTranslation();

  const getStatusIndicator = () => {
    switch (option.receivedSurveyStatus) {
      case SurveyResponseStatus.Sent:
      case SurveyResponseStatus.Seen:
      case SurveyResponseStatus.Reattempted:
      case SurveyResponseStatus.Answered:
        return (
          <Box sx={Styles.SentBadge}>
            <Typography variant="caption" sx={Styles.SentBadgeText}>
              {t('surveyBuilder.surveyPublicationModal.sent')}
            </Typography>
            <CheckCircle sx={Styles.CheckIcon} />
          </Box>
        );
      case SurveyResponseStatus.Sending:
        return (
          <Typography variant="caption" color="slategray" sx={Styles.SendingBadgeText}>
            {t('surveyBuilder.surveyPublicationModal.sending')}
          </Typography>
        );
      case SurveyResponseStatus.Scheduled:
        return (
          <Typography variant="caption" color="slategray" sx={Styles.SendingBadgeText}>
            {t('surveyBuilder.surveyPublicationModal.scheduled')}
          </Typography>
        );
      default:
        return <Checkbox checked={selected} sx={Styles.CheckboxBase} color="primary" />;
    }
  };

  return (
    <Box
      component="li"
      {...props}
      key={option.id}
      sx={Styles.UserRow(selected)}
      style={{ backgroundColor: selected ? colors.ghostWhite : colors.white, ...Styles.UserRowOverride }}
    >
      <Box component="div" sx={Styles.UserInner}>
        <Box component="div" sx={Styles.UserData}>
          <AvatarWithFallback src={option.avatarUrl} alt={`${option.firstName} ${option.lastName}`} />
          <Box>
            <Typography variant="body2">
              {option.firstName} {option.lastName}
            </Typography>
            <Typography variant="caption" textTransform="capitalize">
              {option.level?.toLowerCase()}
            </Typography>
          </Box>
        </Box>
        {getStatusIndicator()}
      </Box>
    </Box>
  );
};
