import { Box, Button, Dialog, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificatorType } from 'generated/webapp_gql';

import { Autocomplete } from './Autocomplete/Autocomplete';
import * as Styles from './SurveyPublicationModal.styles';
import { SurveyPublicationModalProps, SurveyReceiver } from './SurveyPublicationModal.types';

export const SurveyPublicationModal = ({
  onClose,
  modalOpened,
  surveyId,
  onSubmit,
  surveyReceivers = [],
}: SurveyPublicationModalProps) => {
  const { t } = useTranslation();
  const [sendMethod, setSendMethod] = useState<NotificatorType>(NotificatorType.Slack);
  const [emailReceivers, setEmailReceivers] = useState<SurveyReceiver[]>(
    surveyReceivers.filter((receiver) => receiver.notificator?.includes(NotificatorType.Email)),
  );
  const [slackReceivers, setSlackReceivers] = useState<SurveyReceiver[]>(
    surveyReceivers.filter((receiver) => receiver.notificator?.includes(NotificatorType.Slack)),
  );

  const autocompleteReceiversProps =
    sendMethod === NotificatorType.Email
      ? { currentReceivers: emailReceivers, setCurrentReceivers: setEmailReceivers }
      : { currentReceivers: slackReceivers, setCurrentReceivers: setSlackReceivers };

  const handleSetNotificator = (notificator: NotificatorType) => {
    setSendMethod(notificator);
  };

  const onModalSubmit = () => {
    onSubmit([...emailReceivers, ...slackReceivers]);
  };

  return (
    <Dialog
      fullWidth
      open={modalOpened}
      onClose={onClose}
      sx={Styles.Dialog}
      PaperProps={{
        sx: Styles.Paper,
      }}
    >
      <Typography variant="h5">{t('surveyBuilder.surveyPublicationModal.choosePeopleToSendSurveyTo')}</Typography>
      <Tabs
        value={sendMethod}
        sx={Styles.Tabs}
        onChange={(_, value) => handleSetNotificator(value)}
        variant="fullWidth"
        centered
      >
        <Tab
          value={NotificatorType.Slack}
          label={`${t('surveyBuilder.surveyPublicationModal.slack')} (${slackReceivers.length})`}
        />
        <Tab
          value={NotificatorType.Email}
          label={`${t('surveyBuilder.surveyPublicationModal.email')} (${emailReceivers.length})`}
        />
      </Tabs>
      <Autocomplete key={sendMethod} sendMethod={sendMethod} surveyId={surveyId} {...autocompleteReceiversProps} />
      <Box display="flex" gap={3} sx={Styles.ActionsWrapper}>
        <Button variant="outlined" onClick={onClose} fullWidth>
          {t('surveyBuilder.surveyPublicationModal.cancel')}
        </Button>
        <Button variant="contained" onClick={onModalSubmit} fullWidth>
          {t('surveyBuilder.surveyPublicationModal.submit')}
        </Button>
      </Box>
    </Dialog>
  );
};
