import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@mui/material';

import { QuestionsSection } from 'ui';

import * as Styles from './QuestionsSectionWrapper.styles';
import { QuestionsSectionWrapperProps } from './QuestionsSectionWrapper.types';
import { PublishedQuestions } from './publishedQuestions/PublishedQuestions';

export const QuestionsSectionWrapper = ({ sections }: QuestionsSectionWrapperProps) => {
  const { t } = useTranslation();

  if (!sections) {
    return null;
  }

  return (
    <Box sx={Styles.Sections}>
      {sections.map((section, index) => {
        if (!section) {
          return null;
        }
        return (
          <Paper elevation={1} key={index}>
            <QuestionsSection
              label={t('surveyBuilder.questions.section.label', { count: index + 1 })}
              sectionsAmount={sections.length}
            >
              <Box sx={Styles.SectionWrapper}>
                <Box sx={Styles.SectionHeader}>
                  <Typography variant="h5">{section.title}</Typography>
                  <Typography variant="subtitle2">
                    {t('surveyBuilder.questions.section.questionCount_interval', {
                      count: section.questions.length,
                      postProcess: 'interval',
                    })}
                  </Typography>
                </Box>
                {section.description && (
                  <Typography variant="body1" sx={Styles.Description}>
                    {section.description}
                  </Typography>
                )}
                <PublishedQuestions questions={section.questions} />
              </Box>
            </QuestionsSection>
          </Paper>
        );
      })}
    </Box>
  );
};
