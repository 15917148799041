import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type S3UploadUrlQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type S3UploadUrlQuery = { __typename?: 'Query', s3UploadUrl?: { __typename?: 'S3ImageData', imageName?: string | null, uploadUrl?: string | null } | null };


export const S3UploadUrlDocument = gql`
    query S3UploadUrl {
  s3UploadUrl {
    imageName
    uploadUrl
  }
}
    `;

/**
 * __useS3UploadUrlQuery__
 *
 * To run a query within a React component, call `useS3UploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useS3UploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS3UploadUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useS3UploadUrlQuery(baseOptions?: Apollo.QueryHookOptions<S3UploadUrlQuery, S3UploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<S3UploadUrlQuery, S3UploadUrlQueryVariables>(S3UploadUrlDocument, options);
      }
export function useS3UploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<S3UploadUrlQuery, S3UploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<S3UploadUrlQuery, S3UploadUrlQueryVariables>(S3UploadUrlDocument, options);
        }
export function useS3UploadUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<S3UploadUrlQuery, S3UploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<S3UploadUrlQuery, S3UploadUrlQueryVariables>(S3UploadUrlDocument, options);
        }
export type S3UploadUrlQueryHookResult = ReturnType<typeof useS3UploadUrlQuery>;
export type S3UploadUrlLazyQueryHookResult = ReturnType<typeof useS3UploadUrlLazyQuery>;
export type S3UploadUrlSuspenseQueryHookResult = ReturnType<typeof useS3UploadUrlSuspenseQuery>;
export type S3UploadUrlQueryResult = Apollo.QueryResult<S3UploadUrlQuery, S3UploadUrlQueryVariables>;