import { useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { min } from 'lodash';

import { useValidatedParams } from 'hooks';
import { useAllSurveyRespondentsQuery } from 'documents/allSurveyRespondents.generated';
import { SurveyResponse } from 'generated/webapp_gql';

type UseAllSurveyRespondentsFormattedData = { allRespondents: SurveyResponse[]; firstPublicationDate: Date };

export const useAllSurveyRespondents = (skip?: boolean) => {
  const { surveyId } = useValidatedParams(['surveyId']);
  const formattedDataRef = useRef<UseAllSurveyRespondentsFormattedData | undefined>(undefined);

  const { data, loading } = useAllSurveyRespondentsQuery({
    variables: {
      surveyId,
    },
    onError: (error) => toast.error(error.message),
    skip,
  });

  const formattedData = useMemo(() => {
    if (!data) {
      return formattedDataRef.current;
    }

    const reducedData = data?.survey?.responsesByDate?.reduce(
      (acc, curr) => ({
        allRespondents: [...(acc.allRespondents ?? []), ...(<SurveyResponse[]>curr?.respondents.items ?? [])],
        firstPublicationDate: min([acc.firstPublicationDate, curr?.sentAt]),
      }),
      <UseAllSurveyRespondentsFormattedData>{},
    );

    formattedDataRef.current = reducedData;

    return reducedData;
  }, [data]);

  return {
    allRespondents: formattedData?.allRespondents,
    firstPublicationDate: formattedData?.firstPublicationDate
      ? new Date(formattedData?.firstPublicationDate)
      : new Date(),
    areAllSurveyRespondentsLoading: loading,
  };
};
