import { useTranslation } from 'react-i18next';

import { BackButton, CardSidebar, Loader } from 'ui';
import { useEmployeeWithQuarterlyReport, useReadOnlyEmployee, useValidatedParams } from 'hooks';
import { ReportCard } from 'ui/reportCard/ReportCard';
import { EmployeeSection } from 'app/employeeDetails/employeeSection/EmployeeSection';

export const PreviewQuarterly = () => {
  const { t } = useTranslation();
  const { isEmployeeWithQuarterlyReportLoading, employeeData, quarterlyReportData } = useEmployeeWithQuarterlyReport();
  const { employeeId } = useValidatedParams(['employeeId']);
  const { isReadOnly } = useReadOnlyEmployee(employeeId);

  if (isEmployeeWithQuarterlyReportLoading) return <Loader />;

  if (!employeeData || !quarterlyReportData) return <div>{t('previewQuarterly.noData')}</div>;

  return (
    <CardSidebar button={<BackButton />} sidebar={<EmployeeSection employee={employeeData} />}>
      <ReportCard report={quarterlyReportData} variant="preview" isReadOnly={isReadOnly} />
    </CardSidebar>
  );
};
