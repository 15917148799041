import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveyTemplateQueryVariables = Types.Exact<{
  surveyTemplateId: Types.Scalars['ID']['input'];
}>;


export type SurveyTemplateQuery = { __typename?: 'Query', surveyTemplate?: { __typename?: 'SurveyTemplate', title: string, language: Types.Language, id: string, endImageName?: string | null, endImageUrl?: string | null, introImageName?: string | null, introImageUrl?: string | null, status: Types.SurveyTemplateStatus, creator?: { __typename?: 'Employee', fullName: string, avatarUrl?: string | null } | null, content?: { __typename?: 'SurveyContent', beginning?: string | null, conclusion?: string | null, sections: Array<{ __typename?: 'Section', title: string, description?: string | null, questions: Array<{ __typename?: 'SurveyQuestion', question: string, format: Types.SurveyQuestionFormat, answers?: any | null } | null> } | null> } | null } | null };


export const SurveyTemplateDocument = gql`
    query SurveyTemplate($surveyTemplateId: ID!) {
  surveyTemplate(id: $surveyTemplateId) {
    title
    language
    id
    endImageName
    endImageUrl
    introImageName
    introImageUrl
    status
    creator {
      fullName
      avatarUrl
    }
    content {
      beginning
      conclusion
      sections {
        title
        description
        questions {
          question
          format
          answers
        }
      }
    }
  }
}
    `;

/**
 * __useSurveyTemplateQuery__
 *
 * To run a query within a React component, call `useSurveyTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyTemplateQuery({
 *   variables: {
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *   },
 * });
 */
export function useSurveyTemplateQuery(baseOptions: Apollo.QueryHookOptions<SurveyTemplateQuery, SurveyTemplateQueryVariables> & ({ variables: SurveyTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyTemplateQuery, SurveyTemplateQueryVariables>(SurveyTemplateDocument, options);
      }
export function useSurveyTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyTemplateQuery, SurveyTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyTemplateQuery, SurveyTemplateQueryVariables>(SurveyTemplateDocument, options);
        }
export function useSurveyTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveyTemplateQuery, SurveyTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveyTemplateQuery, SurveyTemplateQueryVariables>(SurveyTemplateDocument, options);
        }
export type SurveyTemplateQueryHookResult = ReturnType<typeof useSurveyTemplateQuery>;
export type SurveyTemplateLazyQueryHookResult = ReturnType<typeof useSurveyTemplateLazyQuery>;
export type SurveyTemplateSuspenseQueryHookResult = ReturnType<typeof useSurveyTemplateSuspenseQuery>;
export type SurveyTemplateQueryResult = Apollo.QueryResult<SurveyTemplateQuery, SurveyTemplateQueryVariables>;