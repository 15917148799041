import { Box, Typography } from '@mui/material';

import { SectionEnum } from '../SurveyQuestions.enum';

import * as Styles from './SectionWithImage.styles';
import { SectionWithImageProps } from './SectionWithImage.types';

export const SectionWithImage = ({ label, imageUrl, sectionName, fieldValue }: SectionWithImageProps) => {
  return (
    <Box sx={Styles.Container(sectionName === SectionEnum.Beginning)}>
      <Box sx={Styles.TopSection}>
        <Typography sx={Styles.Label} variant="subtitle2">
          {label}
        </Typography>
      </Box>
      <Box sx={Styles.Children}>
        {imageUrl && <Box component="img" src={imageUrl} sx={Styles.UploadedImage} />}
        <Typography variant="body1" sx={Styles.SectionDescription}>
          {fieldValue}
        </Typography>
      </Box>
    </Box>
  );
};
