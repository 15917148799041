import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ErrorSvg } from 'assets/images/SurveyFormError.svg';
import { ReactComponent as UnauthorizedSvg } from 'assets/images/SurveyFormUnauthorized.svg';
import { ReactComponent as AnsweredSvg } from 'assets/images/SurveyFormAnswered.svg';
import { SurveyFormErrorCode, SurveyFormErrorCodes } from '../SurveyForm.enum';
import { createEnumChecker } from 'shared/utils/enum-checker';
import { TranslationsKeys } from 'i18n/i18n.types';
import { useReopenQuestionnaire } from 'hooks/useReopenQuestionnaire/useReopenQuestionnaire';

import * as Styles from './SurveyFormRedirect.styles';

export const SurveyFormRedirect = ({ type, urlHash }: { type: SurveyFormErrorCode | string; urlHash: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reopenQuestionnaire } = useReopenQuestionnaire();

  const isKnownError = createEnumChecker(SurveyFormErrorCodes);

  const translationMsg = isKnownError(type) ? type : 'other';

  const handleReopenQuestionnaire = async (urlHash: string) => {
    await reopenQuestionnaire({
      variables: {
        urlHash,
      },
    });
  };

  const handleReopenAndRedirect = async () => {
    await handleReopenQuestionnaire(urlHash);
    navigate(0);
  };

  return (
    <Box sx={Styles.Wrapper}>
      {!isKnownError(type) && <ErrorSvg />}
      {type === SurveyFormErrorCodes.Outdated && <UnauthorizedSvg />}
      {type === SurveyFormErrorCodes.AnsweredButOpen && <AnsweredSvg />}
      <Typography variant="h5" sx={Styles.Title}>
        {t(`surveyForm.${translationMsg}.title` as TranslationsKeys)}
      </Typography>
      <Typography variant="body1" sx={Styles.Description}>
        {t(`surveyForm.${translationMsg}.description` as TranslationsKeys)}
      </Typography>
      {type === SurveyFormErrorCodes.AnsweredButOpen && (
        <Button variant="contained" color="primary" onClick={handleReopenAndRedirect}>
          {t('surveyForm.answeredButOpen.openQuestionareBtn')}
        </Button>
      )}
    </Box>
  );
};
