import { Box, Button, Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingOverlay, TableRowItem } from 'ui';
import { TodoRowController } from 'context/todoRow/TodoRowController';

import * as Styles from './ToDoTable.styles';
import { ToDoTableProps } from './ToDoTable.types';
import { filterToDosByTypeName } from './ToDoTable.utils';

export const ToDoTable = ({
  onClick,
  toDoData,
  getOnArrowClickState,
  isReadOnly,
  pagination,
  seeMore,
  loading,
  withoutCheckbox,
  children,
  context,
  isEditable = true,
}: ToDoTableProps) => {
  const { t } = useTranslation();

  if (!toDoData) {
    return null;
  }

  const formattedData = filterToDosByTypeName(toDoData);

  return (
    <Box sx={Styles.TableWrapper}>
      {formattedData.map((toDo) => {
        return (
          <TodoRowController todoRowContextValue={context} key={toDo.id}>
            <TableRowItem
              id={toDo.id}
              content={toDo.content}
              status={toDo.toDoStatus}
              createdAt={toDo.createdAt}
              updatedAt={toDo.updatedAt}
              arrowClickState={getOnArrowClickState?.(toDo.parentId)}
              onClick={onClick}
              isReadOnly={isReadOnly}
              parentId={toDo.parentId}
              withoutCheckbox={withoutCheckbox}
              isEditable={!isReadOnly && isEditable}
            />
          </TodoRowController>
        );
      })}
      {loading ? (
        <Box sx={Styles.LoadingWrapper}>
          <LoadingOverlay />
        </Box>
      ) : null}
      {pagination && !!pagination.total ? (
        <Box sx={Styles.PaginationWrapper}>
          <Pagination
            shape="rounded"
            count={Math.ceil(pagination.total / pagination.pageSize)}
            page={pagination.current}
            onChange={pagination.handlePageChange}
          />
        </Box>
      ) : null}
      {children}
      {seeMore &&
      seeMore.totalAmount > 0 &&
      formattedData.length > 0 &&
      seeMore.totalAmount - formattedData.length > 0 ? (
        <Box sx={Styles.SeeMoreWrapper}>
          <Button variant="text" onClick={seeMore.redirect}>
            {t('toDoTable.moreButton', { amount: `+${seeMore.totalAmount - formattedData.length}` })}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
