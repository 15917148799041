import { Box, Slider as MuiSlider } from '@mui/material';

import * as Styles from './Slider.styles';
import { SliderProps } from './Slider.types';
import { SliderLabel } from './SliderLabel';

export function Slider({ min, max, maxLabel, minLabel, name, value: fieldValue, onChange }: SliderProps) {
  return (
    <Box sx={Styles.Container}>
      <MuiSlider
        step={1}
        valueLabelDisplay="on"
        sx={Styles.SliderInput}
        name={name}
        onChange={onChange}
        value={fieldValue}
        min={min}
        max={max}
      />
      <Box sx={Styles.LabelsContainer}>
        <SliderLabel value={min} label={minLabel} active={(fieldValue ?? 0) >= (min ?? 0)} />
        <SliderLabel value={max} label={maxLabel} active={(fieldValue ?? 0) >= (max ?? 0)} align="right" />
      </Box>
    </Box>
  );
}
