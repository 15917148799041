import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';
import { useDeleteSurveyReceiverMutation } from 'documents/deleteSurveyReceiver.generated';

import { SurveyRespondent } from './SurveyRespondents.types';

export const useSurveyRespondents = () => {
  const { t } = useTranslation();

  const [respondentToDelete, setRespondentToDelete] = useState<SurveyRespondent | null>(null);

  const [deleteSurveyReceiver, { loading: isDeletingSurveyReceiver }] = useDeleteSurveyReceiverMutation({
    refetchQueries: ['SurveyResponses'],
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: () => {
      toast.success(
        <Toast
          title={t('surveyBuilder.publicationSettings.deleteReceiverModal.successTitle')}
          message={t('surveyBuilder.publicationSettings.deleteReceiverModal.successContent', {
            fullName: respondentToDelete?.fullName || respondentToDelete?.email,
          })}
        />,
      );
      setRespondentToDelete(null);
    },
  });

  const openDeleteRespondentDialog = (respondent: SurveyRespondent) => {
    setRespondentToDelete(respondent);
  };

  const copySurveyLinkToClipboard = ({ surveyLink, email, fullName }: SurveyRespondent) => {
    navigator.clipboard.writeText(surveyLink);
    toast.success(
      <Toast
        message={t('surveyBuilder.publicationSettings.copySurveyLinkModal.successContent', {
          name: fullName || email,
        })}
      />,
    );
  };

  const closeDeleteRespondentDialog = () => {
    setRespondentToDelete(null);
  };

  const deleteSurveyRespondent = () => {
    if (respondentToDelete) {
      deleteSurveyReceiver({
        variables: {
          surveyResponseId: respondentToDelete.id,
        },
      });
    }
  };

  return {
    respondentToDelete,
    openDeleteRespondentDialog,
    closeDeleteRespondentDialog,
    deleteSurveyRespondent,
    isDeletingSurveyReceiver,
    copySurveyLinkToClipboard,
  };
};
