import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRedirectToEmployeeDetails } from 'hooks';
import { Toast } from 'ui';
import { useCreateQuarterlyReportMutation } from 'documents/createQuarterlyReport.generated';
import { EmployeeDetailsViewParam } from 'routing/AppRoute.enum';
import { UpdateReportsList } from 'hooks/utils/updateReportsList';
import { QuarterlyReport } from 'generated/webapp_gql';
import { getRefetchOptionsForEmployee } from '../../shared/utils/getRefetchOptionsForEmployee';

export const useCreateQuarterlyReport = (employeeId: number) => {
  const { t } = useTranslation();
  const { redirectToEmployeeDetails } = useRedirectToEmployeeDetails();

  const [createQuarterlyReport, { loading: isCreateQuarterlyReportLoading }] = useCreateQuarterlyReportMutation({
    refetchQueries: [getRefetchOptionsForEmployee(employeeId)],
    update: (cache, { data }) => {
      if (!data?.createQuarterlyReport) {
        return;
      }
      return new UpdateReportsList(cache, data.createQuarterlyReport as QuarterlyReport, employeeId).onCreate();
    },
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(
        <Toast
          title={t('quarterlyForm.toast.quarterlyReportHeading')}
          message={t('quarterlyForm.toast.newQuarterlyAdded')}
        />,
      );
      redirectToEmployeeDetails(employeeId, EmployeeDetailsViewParam.Quarterly);
    },
  });

  return { createQuarterlyReport, isCreateQuarterlyReportLoading };
};
