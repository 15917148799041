import { Box, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

import { getLongFormatDate } from 'shared/utils';
import { ReactComponent as EditIcon } from 'assets/images/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/DeleteIcon.svg';
import * as Styles from '../../SurveyTabs.styles';
import { SurveyTemplatesListProps } from '../../SurveyTemplatesTabs.types';
import { ConfirmationDialog } from 'ui';
import { SurveyListItem } from '../../components/SurveyListItem';

import { useSurveyTemplatesList } from './useSurveyTemplatesList';

export const SurveysTemplateList = ({ items, department }: SurveyTemplatesListProps) => {
  const { t } = useTranslation();

  const { closeModal, deleteTemplate, createNewTemplate, editTemplate, modalTemplateId } =
    useSurveyTemplatesList(department);
  const getActionButtons = (templateId: string) => (
    <Box>
      <IconButton aria-label="edit" sx={Styles.EditIconButton} onClick={editTemplate(templateId)}>
        <EditIcon />
      </IconButton>
      <IconButton aria-label="delete" sx={Styles.DeleteIconButton} onClick={deleteTemplate(templateId)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  return (
    <>
      <Box sx={Styles.SurveysListContainer}>
        {items &&
          items.map(
            (template) =>
              template && (
                <SurveyListItem
                  key={template.id}
                  onClick={() => editTemplate(template.id)}
                  title={template.title}
                  dateInfo={`${t('surveyTemplates.beforeDate')} ${getLongFormatDate(new Date(template.createdAt))}`}
                  actionsElements={getActionButtons(template.id)}
                />
              ),
          )}
      </Box>
      <Button onClick={createNewTemplate} variant="contained" startIcon={<AddIcon />}>
        {t('surveyTemplates.actionButton')}
      </Button>
      {typeof modalTemplateId === 'string' && (
        <ConfirmationDialog
          onClose={() => closeModal(false, modalTemplateId)}
          onConfirmation={() => closeModal(true, modalTemplateId)}
          content={t('surveyTemplates.deleteDialog.description')}
          open={true}
          title={t('surveyTemplates.deleteDialog.title')}
          alertText={t('modal.alertText')}
          alertSeverity={'warning'}
        />
      )}
    </>
  );
};
