import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, FormControlLabel, Switch, Tab, Tabs } from '@mui/material';
import { addWeeks, format, isPast } from 'date-fns';
import { useMemo, useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CloseSurveyDialog } from 'app/survey/surveyQuestions/closeSurveyDialog/CloseSurveyDialog';
import { SurveyStatus } from 'generated/webapp_gql';
import { useCreateSurvey, useEmployee, useSurveyResponses, useUpdateSurvey, useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { TabPanel, Toast } from 'ui';
import { CreateSurveyForm } from '../surveyBuilderLayout/SurveyBuilderLayout.types';

import * as Styles from './SurveyBuilderPublicationContainer.styles';
import { PublicationTabs, SurveyBuilderPublicationContainerProps } from './SurveyBuilderPublicationContainer.types';
import { SurveyPublicationModal } from './surveyPublicationModal/SurveyPublicationModal';
import type {
  SurveyPublicationModalProps,
  SurveyReceiver,
} from './surveyPublicationModal/SurveyPublicationModal.types';
import { SurveyPublicationSettings } from './surveyPublicationSettings/SurveyPublicationSettings';
import { SurveyRespondents } from './surveyRespondents/SurveyRespondents';
import { useCustomChangeSurveyStatus } from './useCustomChangeSurveyStatus';

const CLOSE_DATE_FORMAT = 'yyyy-MM-dd';
export const SurveyBuilderPublicationContainer = ({
  openPreview,
  changeSurveySwitchState,
  isClosed,
  isSent = false,
  isPublished = false,
}: SurveyBuilderPublicationContainerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<PublicationTabs>(PublicationTabs.Edit);

  const { surveyId } = useValidatedParams(['surveyId']);
  const { getValues, setValue, reset, formState } = useFormContext<CreateSurveyForm>();
  const [receiversModalOpened, setReceiversModalOpened] = useState(false);
  const [surveyStateModalOpened, setSurveyStateModalOpened] = useState(false);

  const { dirtyFields } = useFormState<CreateSurveyForm>();
  const areSurveySettingsDirty = dirtyFields.closeDate || dirtyFields.slackNotification || dirtyFields.sendDate;
  const watchedReceivers = useWatch<CreateSurveyForm>({ name: 'receivers' }) as SurveyReceiver[];

  const { updateSurvey, isUpdateSurveyLoading } = useUpdateSurvey({
    onCompleted: () => {
      setValue('receivers', []);
      if (watchedReceivers.length > 0) {
        toast.success(
          <Toast
            title={t('publishedSurvey.publishedToast.title')}
            message={t('publishedSurvey.publishedToast.subtitle')}
          />,
        );
      }
      if (areSurveySettingsDirty) {
        toast.success(
          <Toast
            title={t('publishedSurvey.updatedToast.title')}
            message={t('publishedSurvey.updatedToast.subtitle')}
          />,
        );
      }
    },
  });
  const { employeeData } = useEmployee();
  const { createSurveyMutation, isCreateSurveyLoading } = useCreateSurvey();

  const surveyStatusAfterChange = isClosed ? SurveyStatus.Opened : SurveyStatus.Closed;

  const { changeSurveyStatus } = useCustomChangeSurveyStatus({
    newSurveyStatus: surveyStatusAfterChange,
  });

  const { surveyData, isSurveyLoading } = useSurveyResponses(surveyId);

  const handleAcceptModal = async () => {
    const surveyStatusRes = await changeSurveyStatus({
      variables: {
        input: {
          surveyId,
          status: surveyStatusAfterChange,
          ...(surveyStatusAfterChange === SurveyStatus.Opened
            ? { closeDate: format(addWeeks(new Date(), 1), CLOSE_DATE_FORMAT) }
            : {}),
        },
      },
    });

    if (!surveyStatusRes.errors) {
      changeSurveySwitchState?.(!isClosed);
    }
    setSurveyStateModalOpened(false);
  };

  const handleCreateSurvey = async () => {
    const { sendDate, closeDate, surveyTemplateId, receivers, slackNotification } = getValues();

    const receiversWithNotificators = Object.values(
      receivers.reduce<Record<string, Pick<SurveyReceiver, 'email' | 'notificator'>>>(
        (acc, { email, notificator }) =>
          acc[email]
            ? { ...acc, [email]: { email, notificator: [...acc[email].notificator, ...notificator] } }
            : { ...acc, [email]: { email, notificator } },
        {},
      ),
    );

    if (!employeeData) {
      return;
    }

    if (surveyId) {
      const getUpdatedSendDate = (): Date | null => {
        if (!sendDate) {
          return new Date();
        }

        return isPast(sendDate) ? null : sendDate;
      };

      await updateSurvey({
        variables: {
          input: {
            surveyId,
            newSurveyReceivers: receiversWithNotificators,
            closeDate: closeDate ? format(closeDate, CLOSE_DATE_FORMAT) : undefined,
            sendDate: getUpdatedSendDate(),
            reminderDaysBeforeSurveyClose:
              formState.defaultValues?.slackNotification === slackNotification ? undefined : slackNotification || 0,
          },
        },
      });

      reset({}, { keepValues: true });

      return setReceiversModalOpened(false);
    }

    if (surveyTemplateId) {
      const { data, errors } = await createSurveyMutation({
        variables: {
          input: {
            surveyTemplateId: surveyTemplateId,
            concernedId: employeeData.id,
            receivers: receiversWithNotificators,
            sendDate,
            closeDate: format(closeDate, CLOSE_DATE_FORMAT),
            reminderDaysBeforeSurveyClose: slackNotification || 0,
          },
        },
      });

      if (!errors?.length && data?.createSurvey?.id) {
        navigate(`/${AppRoute.PublishedSurvey}/${employeeData.id}/${data.createSurvey.id}`, {
          state: { backRoute: `/${AppRoute.SurveyTemplates}/${employeeData.id}` },
        });
      }

      setReceiversModalOpened(false);
    }
  };

  const onSurveyModalClose = () => {
    setReceiversModalOpened(false);
  };

  const handleSurveyModalSubmit: SurveyPublicationModalProps['onSubmit'] = (receivers) => {
    setValue('receivers', receivers);
    onSurveyModalClose();
  };

  const totalResponses =
    useMemo(
      () =>
        surveyData?.survey?.responsesByDate?.reduce((respondentsCount, responseByDate) => {
          const responseCount = responseByDate?.respondents.total || 0;
          return respondentsCount + responseCount;
        }, 0),
      [surveyData?.survey?.responsesByDate],
    ) || 0;

  return (
    <>
      {isPublished && (
        <Tabs
          sx={Styles.TabsContainer}
          value={currentTab}
          onChange={(_, value) => {
            setCurrentTab(value);
          }}
          variant="fullWidth"
          aria-label="publication tabs"
        >
          <Tab value={PublicationTabs.Edit} label={t('surveyBuilder.publicationSettings.editPublication')} />
          <Tab
            value={PublicationTabs.Respondents}
            label={t('surveyBuilder.publicationSettings.respondentsList', {
              totalResponses,
            })}
          />
        </Tabs>
      )}
      <TabPanel value={currentTab} index={PublicationTabs.Edit}>
        {surveyId && (
          <FormControlLabel
            onClick={() => setSurveyStateModalOpened(true)}
            sx={Styles.SwitchWrapper}
            control={<Switch checked={!!isClosed} disabled={isClosed === undefined} />}
            label={t('surveyBuilder.questions.section.closeSurvey')}
          />
        )}
        <Box>
          <SurveyPublicationSettings
            disabled={isClosed}
            onAddReceiversClick={() => setReceiversModalOpened(true)}
            isSent={isSent}
          />
          {receiversModalOpened && (
            <SurveyPublicationModal
              surveyReceivers={watchedReceivers}
              modalOpened={receiversModalOpened}
              onClose={onSurveyModalClose}
              surveyId={surveyId}
              onSubmit={handleSurveyModalSubmit}
            />
          )}
        </Box>
        {!isClosed && (
          <Box sx={Styles.TopButtonsContainer}>
            <Button startIcon={<VisibilityIcon />} variant="text" onClick={openPreview}>
              {t('surveyBuilder.publicationSettings.preview')}
            </Button>
            {surveyId && !watchedReceivers.length ? (
              <Button
                variant="contained"
                onClick={handleCreateSurvey}
                disabled={isUpdateSurveyLoading || !areSurveySettingsDirty}
              >
                {t('surveyBuilder.publicationSettings.save')}
              </Button>
            ) : (
              <Button
                startIcon={<SendIcon />}
                variant="contained"
                onClick={handleCreateSurvey}
                disabled={isCreateSurveyLoading || isUpdateSurveyLoading || !watchedReceivers.length}
              >
                {t('surveyBuilder.publicationSettings.send')}
              </Button>
            )}
          </Box>
        )}
        <CloseSurveyDialog
          open={surveyStateModalOpened}
          changeToState={surveyStatusAfterChange}
          handleCloseModal={() => setSurveyStateModalOpened(false)}
          handleAcceptModal={handleAcceptModal}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={PublicationTabs.Respondents}>
        <SurveyRespondents surveyData={surveyData} isSurveyLoading={isSurveyLoading} />
      </TabPanel>
    </>
  );
};
