import { Menu } from '@mui/material';

import { spreadMultipleStyles } from 'shared/utils';

import { DropDownMenuProps } from './DropDownMenu.types';
import * as Styles from './DropDownMenu.styles';

export const DropDownMenu = ({ children, anchorEl, onMenuClose, sx, ...props }: DropDownMenuProps) => (
  <Menu
    transitionDuration={300}
    id="menu-image"
    transformOrigin={{
      horizontal: 'left',
      vertical: 'top',
    }}
    anchorOrigin={{
      horizontal: 'left',
      vertical: 'bottom',
    }}
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={onMenuClose}
    PaperProps={{ sx: spreadMultipleStyles(Styles.Menu, sx) }}
    BackdropProps={{ sx: Styles.Backdrop }}
    {...props}
  >
    {children}
  </Menu>
);
