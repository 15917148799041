import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
}>;


export type EmployeeQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, firstName: string, lastName: string, avatarUrl?: string | null, email: string, level?: string | null, department: string, currentProjects?: Array<string | null> | null, headFullName?: string | null, employmentType?: string | null, hiredDate: any, pieselyUrl?: string | null, luxUrl?: string | null, satisfactionLevel?: number | null, requestedBy?: Array<Types.AccessType | null> | null, supervisor?: { __typename?: 'Employee', firstName: string, lastName: string } | null, latestReceivedQuarterlyReport?: { __typename?: 'QuarterlyReport', goalsCollection?: { __typename?: 'GoalCollection', total: number, items: Array<{ __typename?: 'Goal', id: string, goalStatus?: Types.GoalStatus | null, content?: string | null } | null> } | null } | null } | null };


export const EmployeeDocument = gql`
    query employee($employeeId: Int!) {
  employee(id: $employeeId) {
    id
    firstName
    lastName
    avatarUrl
    email
    level
    department
    currentProjects
    headFullName
    supervisor {
      firstName
      lastName
    }
    employmentType
    hiredDate
    pieselyUrl
    luxUrl
    satisfactionLevel
    requestedBy
    latestReceivedQuarterlyReport {
      goalsCollection {
        items {
          id
          goalStatus
          content
        }
        total
      }
    }
  }
}
    `;

/**
 * __useEmployeeQuery__
 *
 * To run a query within a React component, call `useEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useEmployeeQuery(baseOptions: Apollo.QueryHookOptions<EmployeeQuery, EmployeeQueryVariables> & ({ variables: EmployeeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeQuery, EmployeeQueryVariables>(EmployeeDocument, options);
      }
export function useEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeQuery, EmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeQuery, EmployeeQueryVariables>(EmployeeDocument, options);
        }
export function useEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmployeeQuery, EmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeeQuery, EmployeeQueryVariables>(EmployeeDocument, options);
        }
export type EmployeeQueryHookResult = ReturnType<typeof useEmployeeQuery>;
export type EmployeeLazyQueryHookResult = ReturnType<typeof useEmployeeLazyQuery>;
export type EmployeeSuspenseQueryHookResult = ReturnType<typeof useEmployeeSuspenseQuery>;
export type EmployeeQueryResult = Apollo.QueryResult<EmployeeQuery, EmployeeQueryVariables>;