import { Styles } from 'shared/types';

export const LinearScaleWrapper: Styles = {
  marginBottom: 3,
  px: 1,
};

export const LinearScaleValuesWrapper: Styles = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',

  '.MuiFormControl-root': {
    width: '48px',
  },
};

export const LinearScaleLabelsWrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '170px',
};

export const LinearScaleInput: Styles = {
  borderBottom: '1px solid rgba(35, 41, 75, 0.42)',
};

export const LinearScaleInputValue: Styles = {
  '.MuiInput-input': {
    textAlign: 'center',
  },
};

export const LinearScaleValueSeparator: Styles = {
  marginTop: 1,
  mx: 1.25,
};
