import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Container: Styles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  p: 4,
  gap: 2,
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: colors.ghostWhite,
  border: `1px dashed ${colors.hawkesBlue}`,
  borderRadius: 1,
};

export const Message: Styles = {
  maxWidth: 400,
  textAlign: 'center',
};
