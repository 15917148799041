import React from 'react';
import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import { AvatarWithFallback, ItemTitleWithCheckbox, Loader, SmilingFacesRating } from 'ui';
import {
  SmilingFacesRatingType,
  SmilingFacesRatingValue,
} from 'ui/satisfactionScale/smilingFacesRating/SmilingFacesRating.types';
import { getStatus } from 'ui/cardChecklist/CardChecklist.utils';
import { Action } from 'generated/webapp_gql';
import { getLongFormatDate } from 'shared/utils';
import { useWeeklyOverview } from 'hooks';

import { ReactComponent as CalendarIcon } from './calendarIcon.svg';
import { WeeklyOverviewDialogProps } from './WeeklyOverviewDialog.types';
import * as Styles from './WeeklyOverviewDialog.styles';

export const WeeklyOverviewDialog = ({ employeeId, open, handleClose }: WeeklyOverviewDialogProps) => {
  const { weeklyOverview, isWeeklyOverviewLoading } = useWeeklyOverview(employeeId, !open);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: Styles.WeeklyOverviewPaper,
      }}
    >
      {isWeeklyOverviewLoading && (
        <Box sx={Styles.WeeklyOverviewLoaderWrapper}>
          <Loader />
        </Box>
      )}

      {weeklyOverview && (
        <Box>
          <Stack sx={Styles.WeeklyOverviewDialogHeader}>
            <Typography variant="h6">{t('employeesTable.weeklyOverviewDialog.title')}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack sx={Styles.WeeklyOverviewDialogContainer}>
            <Stack sx={Styles.WeeklyOverviewDateWrapper}>
              <CalendarIcon />
              <Typography variant="subtitle1">
                {getLongFormatDate(new Date(weeklyOverview.latestReceivedWeeklyFeedback?.realizationDate))}
              </Typography>
            </Stack>
            <Stack sx={Styles.WeeklyOverviewEmpoyeeWrapper}>
              <AvatarWithFallback size="s" alt={weeklyOverview.fullName} src={weeklyOverview.avatarUrl} />
              <Stack>
                <Typography variant="h6">{weeklyOverview.fullName}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {weeklyOverview.level}
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={Styles.WeeklyOverviewSatisfactionWrapper}>
              {weeklyOverview.latestReceivedWeeklyFeedback?.satisfactionScore && (
                <Stack sx={Styles.WeeklyOverviewSatisfactionCard}>
                  <Typography variant="subtitle1">{t('employeesTable.weeklyOverviewDialog.satisfaction')}</Typography>
                  <SmilingFacesRating
                    type="default"
                    value={
                      (weeklyOverview.latestReceivedWeeklyFeedback.satisfactionScore as SmilingFacesRatingType) ??
                      SmilingFacesRatingValue.Bad
                    }
                  />
                </Stack>
              )}
              {weeklyOverview.latestReceivedWeeklyFeedback?.projectSatisfactionScores &&
                weeklyOverview.latestReceivedWeeklyFeedback?.projectSatisfactionScores.map((score) => (
                  <Stack key={score?.project.id} sx={Styles.WeeklyOverviewSatisfactionCard}>
                    <Typography sx={Styles.WeeklyOverviewSatisfactionCardTitle}>
                      {score?.project.projectName}
                    </Typography>
                    <SmilingFacesRating
                      type="default"
                      value={(score?.satisfactionScore as SmilingFacesRatingType) ?? SmilingFacesRatingValue.Bad}
                    />
                  </Stack>
                ))}
            </Stack>

            {weeklyOverview.latestReceivedWeeklyFeedback?.summary && (
              <Stack sx={Styles.WeeklyOverviewSummaryWrapper}>
                <Typography variant="subtitle2">{t('employeesTable.weeklyOverviewDialog.summary')}</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  dangerouslySetInnerHTML={{ __html: weeklyOverview.latestReceivedWeeklyFeedback?.summary || '' }}
                />
              </Stack>
            )}

            {weeklyOverview.latestReceivedWeeklyFeedback?.actionsCollection?.items &&
              weeklyOverview.latestReceivedWeeklyFeedback?.actionsCollection?.items.length > 0 && (
                <Stack sx={Styles.WeeklyOverviewActionsWrapper}>
                  <Typography variant="subtitle2">{t('employeesTable.weeklyOverviewDialog.actions')}</Typography>
                  <Stack>
                    {weeklyOverview.latestReceivedWeeklyFeedback?.actionsCollection?.items.map((action) => (
                      <Stack key={action?.id} sx={Styles.WeeklyOverviewCheckboxWrapper}>
                        <ItemTitleWithCheckbox
                          withTooltip
                          type={getStatus(action as Action)}
                          content={action?.content || ''}
                          isReadOnly={true}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              )}
            <Button variant="outlined" sx={Styles.WeeklyOverviewCloseButton} onClick={handleClose}>
              {t('employeesTable.weeklyOverviewDialog.closeButton')}
            </Button>
          </Stack>
        </Box>
      )}
    </Dialog>
  );
};
