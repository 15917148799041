import { Styles } from 'shared/types';

export const Container: Styles = {
  margin: '0 auto',
  pt: 16,
  pb: 16,
};

export const WellDone: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const OutroText: Styles = {
  pt: 2,
  pb: 4,
  whiteSpace: 'pre-line',
};

export const OutroImage: Styles = {
  maxWidth: '80%',
  margin: '0 auto',
};
