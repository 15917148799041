import { Styles } from 'shared/types';

export const Container: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
};

export const Grid: Styles = {
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderRadius: 0,

  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },

  '& .MuiDataGrid-row': { cursor: 'pointer' },

  '& .MuiDataGrid-cell': {
    whiteSpace: 'unset',
  },

  '& .MuiDataGrid-iconSeparator': { display: 'none' },

  '& .MuiDataGrid-columnHeader': {
    color: 'text.primary',
    fontSize: 16,
    lineHeight: '28px',
    px: 2,
  },

  '& .MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
    width: 'auto',
  },

  '& .MuiDataGrid-columnHeader--sortable:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon': {
    opacity: 0.5,
    transform: 'rotate(180deg)',
  },

  '.MuiDataGrid-sortIcon': {
    color: 'text.secondary',
  },

  '& .MuiDataGrid-cell--textLeft': {
    px: 2,
  },

  '& .MuiDataGrid-cell--textRight': {
    pr: 0,
  },

  '& .MuiDataGrid-footerContainer': {
    justifyContent: 'center',
  },
};

export const GridNoData: Styles = {
  ...Grid,

  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },

  '& .MuiDataGrid-virtualScrollerContent': {
    maxHeight: 140,
  },

  '&.MuiDataGrid-root': {
    borderBottom: 'none',
  },
};

export const EastIcon: Styles = {
  color: 'primary.main',
};

export const CircleIcon: Styles = {
  width: 24,
  height: 24,
  pl: 0,
  pr: 2,

  '& path': {
    width: 8,
    height: 8,
  },
};

export const Accordion: Styles = {
  '& .MuiAccordionDetails-root': {
    px: 0,
    py: 1,
  },
  '& .MuiAccordionSummary-root': {
    px: 2,
    py: 1,
  },
  '& .MuiAccordionSummary-content': {
    m: 0,
  },
  '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none !important',
  },
};

export const DateWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};

export const Chip: Styles = {
  color: 'text.primary',
  fontWeight: 500,

  '& .MuiChip-label': { px: 1.25 },
};

export const ActionsTooltip: Styles = {
  '& .MuiTooltip-tooltip': {
    maxWidth: 500,
    whiteSpace: 'pre-line',
  },
};

export const EmployeeBox: Styles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};
