import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

import { getDragDropPlaceholderColors } from './UploadImageModal.utils';

export const FileUpload = (isDragAccept: boolean, isDragReject: boolean): Styles => {
  const dragDropColors = getDragDropPlaceholderColors(isDragAccept, isDragReject);

  return {
    p: 4,
    border: `1px dashed ${dragDropColors.borderColor}`,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 1,
    backgroundColor: dragDropColors.backgroundColor,
  };
};

export const Loader: Styles = {
  color: colors.disabledGray,
  ml: 1,
};
