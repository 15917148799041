import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AvatarWithFallback, ConfirmationDialog, Loader } from 'ui';
import { colors, getLongFormatDate } from 'shared/utils';
import { SurveyResponseStatus } from 'generated/webapp_gql';

import * as Styles from './SurveyRespondents.styles';
import { SurveyRespondentsProps } from './SurveyRespondents.types';
import { DeleteRespondentButton } from './deleteRespondentButton/DeleteRespondentButton';
import { useSurveyRespondents } from './useSurveyRespondents';
import { StatusInfo } from './statusInfo/StatusInfo';
import { Indicator } from './indicator/Indicator';
import { formatSurveyRespondents } from './SurveyRespondents.utils';
import { CopySurveyLinkButton } from './copySurveyLinkButton/CopySurveyLinkButton';

export const SurveyRespondents = ({ surveyData, isSurveyLoading }: SurveyRespondentsProps) => {
  const { t } = useTranslation();

  const {
    respondentToDelete,
    openDeleteRespondentDialog,
    closeDeleteRespondentDialog,
    deleteSurveyRespondent,
    isDeletingSurveyReceiver,
    copySurveyLinkToClipboard,
  } = useSurveyRespondents();

  const surveyRespondents = formatSurveyRespondents(surveyData);

  const getAcceptButtonText = () =>
    isDeletingSurveyReceiver ? (
      <>
        {t('surveyBuilder.publicationSettings.deleteReceiverModal.deleting')}
        <CircularProgress sx={Styles.Loader} size={24} />
      </>
    ) : (
      t('surveyBuilder.publicationSettings.deleteReceiverModal.accept')
    );

  if (isSurveyLoading) {
    return <Loader />;
  }

  if (surveyRespondents.length === 0) {
    return null;
  }

  return (
    <Box>
      {respondentToDelete && (
        <ConfirmationDialog
          open={true}
          isAcceptButtonActive={!isDeletingSurveyReceiver}
          secondaryButtonVariant="white"
          onClose={closeDeleteRespondentDialog}
          onConfirmation={deleteSurveyRespondent}
          title={t('surveyBuilder.publicationSettings.deleteReceiverModal.title')}
          content={t('surveyBuilder.publicationSettings.deleteReceiverModal.content', {
            fullName: respondentToDelete.fullName || respondentToDelete.email,
          })}
          cancelButtonText={t('surveyBuilder.publicationSettings.deleteReceiverModal.cancel')}
          acceptButtonText={getAcceptButtonText()}
          alertSeverity="warning"
          alertText={t('surveyBuilder.publicationSettings.deleteReceiverModal.alert')}
        />
      )}

      <StatusInfo />

      {surveyRespondents.map((respondent, index) => (
        <Box key={respondent?.id} sx={Styles.RespondentRow(index % 2 === 0)}>
          <Box sx={Styles.UserDetailsWrapper}>
            <AvatarWithFallback
              src={respondent.avatarUrl}
              alt={`${respondent.fullName || respondent.email[0].toUpperCase()}`}
              size="xs"
            />
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="body2" lineHeight={1.15}>
                {respondent.fullName}
              </Typography>
              <Typography variant="caption" color={colors.slateGray}>
                {respondent.email}
              </Typography>
            </Box>
          </Box>

          <Box sx={Styles.ActionsWrapper}>
            {respondent.sentAt && (
              <Typography variant="caption" color={colors.slateGray}>
                {t('surveyBuilder.publicationSettings.sent', {
                  date: getLongFormatDate(new Date(respondent.sentAt)),
                })}
              </Typography>
            )}

            {respondent.status === SurveyResponseStatus.Scheduled && (
              <Typography variant="caption" color={colors.slateGray}>
                {t('surveyBuilder.publicationSettings.scheduled', {
                  date: getLongFormatDate(new Date(surveyData?.survey?.upcomingNotificationsDispatch)),
                })}
              </Typography>
            )}

            {respondent.status === SurveyResponseStatus.Sending && (
              <Typography variant="caption" color={colors.slateGray}>
                {t('surveyBuilder.surveyPublicationModal.sending')}
              </Typography>
            )}

            <Indicator type={respondent.status} />

            <CopySurveyLinkButton
              respondentId={respondent.id}
              surveyLink={respondent.surveyLink}
              onClick={() => copySurveyLinkToClipboard(respondent)}
            />

            <DeleteRespondentButton
              status={respondent.status}
              respondentId={respondent.id}
              onClick={() => openDeleteRespondentDialog(respondent)}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
