import { useState } from 'react';

import { ClickTypeEnum } from 'ui/tableRowItem/TableRowItem.enum';
import { ModalType } from 'ui/modal/Modal.enum';

import { ModalState, UseModalProps } from './useModal.types';

export const useModal = <T>({ onConfirm, canceledStatus, doneStatus }: UseModalProps<T>) => {
  const [item, setItem] = useState<ModalState<T>>({ id: '', type: null, content: '', updatedActionStatus: null });

  const handleModalOpen = ({ id, type, content, updatedActionStatus }: ModalState<T>) =>
    setItem({
      id,
      type,
      content,
      updatedActionStatus,
    });

  const handleModalClose = (isConfirmed: boolean) => {
    if (isConfirmed) {
      onConfirm(item);
    }

    setItem({ id: '', type: null, content: '', updatedActionStatus: null });
  };

  const handleItemClick = (id: string, type: ClickTypeEnum, title?: string | null) => {
    switch (type) {
      case ClickTypeEnum.Delete:
        handleModalOpen({
          id,
          content: title as string,
          type: ModalType.Delete,
          updatedActionStatus: canceledStatus,
        });
        break;
      case ClickTypeEnum.Check:
        handleModalOpen({
          id,
          content: title as string,
          type: ModalType.Check,
          updatedActionStatus: doneStatus,
        });
        break;
      case ClickTypeEnum.More:
      default:
        break;
    }
  };

  return { item, handleItemClick, handleModalClose };
};
