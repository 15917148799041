import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Feedbacks: Styles = {
  position: 'relative',
};

export const Loader: Styles = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  backgroundColor: colors.ghostWhite,
  opacity: 0.6,
};

export const AccordionWrapper: Styles = {
  pt: 4,

  '.MuiAccordionSummary-root': {
    p: 0,
    pb: 2,
  },

  '.MuiAccordionDetails-root': {
    p: 0,
  },
};
