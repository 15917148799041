import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, Dialog, Divider, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { useEmployee, useMe, usePrompt, useReadOnlyEmployee, useValidatedParams } from 'hooks';
import { useValueOnWindowFocus } from 'hooks/useValueOnWindowFocus/useValueOnWindowFocus';
import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';
import { BackButton, CardSidebar, ControlledQuill, DatePicker, Loader, OptionalActions, SatisfactionScale } from 'ui';
import { LatestFeedbackSection } from '../../employeeDetails/employeeDetailsWeekly/latestFeedbackSection/LatestFeedbackSection';
import { EmployeeSection } from '../../employeeDetails/employeeSection/EmployeeSection';

import * as Styles from './WeeklyFeedbackForm.styles';
import {
  CreateWeeklyFeedbackFormData,
  UpdateWeeklyFeedbackFormData,
  WeeklyAction,
  WeeklyFeedbackFormProps,
} from './WeeklyFeedbackForm.types';

export const getWeeklyFeedbackMaxDate = () => new Date();

export const WeeklyFeedbackForm = ({ onSubmit, mutationLoading, action, giverId }: WeeklyFeedbackFormProps) => {
  const maxDate = useValueOnWindowFocus(getWeeklyFeedbackMaxDate);
  const navigate = useNavigate();
  const { employeeId } = useValidatedParams(['employeeId']);
  const { meData } = useMe();
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
  } = useFormContext<CreateWeeklyFeedbackFormData | UpdateWeeklyFeedbackFormData>();

  const { fields } = useFieldArray<
    CreateWeeklyFeedbackFormData | UpdateWeeklyFeedbackFormData,
    'projectsSatisfaction',
    'id'
  >({
    name: 'projectsSatisfaction',
  });

  const { employeeData, isEmployeeDataLoading } = useEmployee({
    onError: () => navigate(AppRoute.Home),
  });

  const { isReadOnly, isReadOnlyLoading } = useReadOnlyEmployee(employeeId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOmittingPrompt, setIsOmittingPrompt] = useState(false);

  usePrompt(t('weeklyForm.unsavedData'), isDirty && !isModalOpen && !isOmittingPrompt);

  const goToPrevious = () => navigate(`/${AppRoute.EmployeeDetails}/${employeeId}/${EmployeeDetailsViewParam.Weekly}`);

  const closeModal = () => setIsModalOpen(false);

  const isFeedbackGiver = (meId: number, giverId?: number) => {
    return giverId === meId;
  };

  const handleReturnOnDirty = () => {
    if (!isDirty) {
      return goToPrevious();
    }

    setIsModalOpen(true);
  };

  const isAllowedToSeeNotes = (action: WeeklyAction, actionPerformerId: number, weeklyFeedbackGiverId?: number) => {
    if (actionPerformerId || weeklyFeedbackGiverId) {
      return true;
    }
    return action !== WeeklyAction.UPDATE || isFeedbackGiver(actionPerformerId, weeklyFeedbackGiverId);
  };

  if (isEmployeeDataLoading || isReadOnlyLoading) return <Loader />;

  if (!employeeData) return null;

  if (isReadOnly) return <Navigate to={AppRoute.Home} replace={true} />;

  return (
    <CardSidebar
      button={<BackButton onClick={handleReturnOnDirty} />}
      sidebar={<EmployeeSection employee={employeeData} />}
    >
      <Box>
        <Typography sx={Styles.PageTitle}>{t('weeklyForm.title')}</Typography>
        <Paper sx={Styles.FeedbackForm}>
          <Box sx={Styles.SubmitWrapper}>
            <Controller
              name="realizationDate"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Box sx={Styles.DatePickerWrapper(!!error?.message)}>
                  <DatePicker maxDate={maxDate} onChange={onChange} value={value} />
                  {error?.message && (
                    <Typography sx={Styles.DatePickerMessage} variant="caption">
                      {error?.message}
                    </Typography>
                  )}
                </Box>
              )}
            />
          </Box>
          <Divider />
          <Box sx={Styles.ContentWrapper}>
            <Box component="form" onSubmit={onSubmit} id="feedback-form" noValidate sx={Styles.Form}>
              <ControlledQuill
                control={control}
                name="summary"
                placeholder={t('weeklyForm.feedbackInputPlaceholder')}
                label={t('weeklyForm.feedbackInputLabel')}
                readOnly={mutationLoading}
              />
              <SatisfactionScale control={control} name="satisfactionScore" />
              {fields.map((field, index) => (
                <SatisfactionScale
                  key={field.id}
                  control={control}
                  name={`projectsSatisfaction.${index}.projectSatisfactionScore`}
                  projectName={field.projectName}
                />
              ))}
              <Box>
                <OptionalActions name="actions" mutationLoading={mutationLoading} label={t('weeklyForm.actions')} />
              </Box>
              {isAllowedToSeeNotes(action, meData?.id || 0, giverId) && (
                <ControlledQuill
                  control={control}
                  placeholder={t('weeklyForm.notesInputPlaceholder')}
                  readOnly={mutationLoading}
                  label={t('weeklyForm.notesInputLabel')}
                  name="notes"
                />
              )}
              <Box sx={Styles.FormActionsWrapper}>
                <Button
                  disabled={mutationLoading}
                  startIcon={<HighlightOffIcon />}
                  variant="secondary"
                  onClick={handleReturnOnDirty}
                >
                  {t('weeklyForm.cancelBtn')}
                </Button>
                <Button
                  disabled={!isDirty || mutationLoading}
                  startIcon={<CheckIcon />}
                  variant="contained"
                  type="submit"
                  form="feedback-form"
                  onClick={() => {
                    setIsOmittingPrompt(true);
                  }}
                >
                  {t('weeklyForm.saveBtn')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <LatestFeedbackSection />
      <Dialog open={isModalOpen} onClose={closeModal} fullWidth>
        <Typography variant="h5" sx={Styles.ReturnTitle}>
          {t('weeklyForm.returnDialog.title')}
        </Typography>
        <Typography variant="body1" sx={Styles.ReturnDescription}>
          {t('weeklyForm.returnDialog.description')}
        </Typography>
        <Box sx={Styles.ReturnButtonWrapper}>
          <Button variant="outlined" fullWidth onClick={closeModal}>
            {t('weeklyForm.returnDialog.cancel')}
          </Button>
          <Button variant="contained" fullWidth onClick={goToPrevious}>
            {t('weeklyForm.returnDialog.accept')}
          </Button>
        </Box>
      </Dialog>
    </CardSidebar>
  );
};
