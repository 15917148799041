import { colors as mcolors } from '@mui/material';

import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Wrapper: Styles = {
  display: 'flex',
  borderBottom: `1px solid ${colors.lightGray}`,
  paddingLeft: '2px',
};

export const ActionInput: Styles = {
  '& .MuiInputBase-input::placeholder': {
    color: colors.slateGray,
    opacity: 1,
  },

  '& .MuiInputBase-input': {
    paddingLeft: 0,
  },

  '& .MuiOutlinedInput-root': {
    height: 51,
    fontSize: 14,
    color: colors.navy,
    borderRadius: 0,

    '& fieldset': {
      border: 'none',
    },

    '&:hover fieldset': {
      border: 'none',
    },

    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
};

export const SaveButton: Styles = {
  height: 51,
  width: 220,
};

export const CheckIcon: Styles = {
  marginRight: 1.5,
};

export const Checkbox: Styles = {
  height: 42,
  alignSelf: 'center',

  path: {
    fill: mcolors.common.black,
  },
};
