import { Box, LinearProgress } from '@mui/material';

import { SurveyProgressType } from '../PreviewSurveyQuestions.types';

import { SurveyProgressProps } from './SurveyProgress.types';
import * as Styles from './SurveyProgress.styles';

export const SurveyProgress = ({ surveyProgress, surveySectionsAmount }: SurveyProgressProps) => {
  const getProgressValue = (progress: SurveyProgressType, thisSection: number) => {
    if (progress.currentSection > thisSection) return 100;
    if (progress.currentSection < thisSection) return 0;

    return ((progress.currentQuestion + 1) / progress.totalQuestions) * 100;
  };

  return (
    <Box sx={Styles.Container}>
      {[...Array(surveySectionsAmount)].map((_, index) => {
        return (
          <LinearProgress
            key={index}
            sx={Styles.ProgressBar}
            variant="determinate"
            value={getProgressValue(surveyProgress, index)}
          />
        );
      })}
    </Box>
  );
};
