import { alpha } from '@mui/material';

export const MuiBackdrop = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      root: {
        backgroundColor: alpha(colors.navy, 0.8),
      },
    },
  };
};
