import { Survey } from 'generated/webapp_gql';
import { SurveyStatus } from 'generated/webapp_gql';

import { AllSurveysDataLoaderProps, AllSurveysDataLoaderReturn, SurveyStatusKeys } from './EmployeeSurveys.types';

export const allSurvyesDataLoader = ({
  allSurveysData,
  prevAllSurveysData,
  type,
}: AllSurveysDataLoaderProps): AllSurveysDataLoaderReturn => {
  const surveysData = allSurveysData?.[type.toLowerCase() as SurveyStatusKeys];
  const prevSurveysData = prevAllSurveysData?.[type.toLowerCase() as SurveyStatusKeys];

  if (!surveysData || (surveysData.items.length === 0 && surveysData.total > 0)) {
    return {
      items: prevSurveysData?.items as Survey[],
      total: surveysData?.total ?? prevSurveysData?.total ?? 0,
      loading: true,
    };
  }

  return {
    items: surveysData.items as Survey[],
    total: surveysData.total,
    loading: false,
  };
};

export const surveysTabsNames = [SurveyStatus.Opened, SurveyStatus.Closed];
