import { toast } from 'react-toastify';

import { useAllActionsOfEmployeeQuery } from 'documents/allActionsOfEmployee.generated';
import { useValidatedParams } from 'hooks';
import { ACTIONS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsWeekly/EmployeeDetailsWeekly';

import { UseAllActionsOfEmployeeProps } from './useAllActionsOfEmployee.types';

export const useAllActionsOfEmployee = ({ pages }: UseAllActionsOfEmployeeProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const { data, previousData } = useAllActionsOfEmployeeQuery({
    variables: {
      employeeId,
      canceledPagination: {
        offset: (pages.canceled - 1) * ACTIONS_LIST_PAGE_SIZE,
        limit: ACTIONS_LIST_PAGE_SIZE,
      },
      donePagination: {
        offset: (pages.done - 1) * ACTIONS_LIST_PAGE_SIZE,
        limit: ACTIONS_LIST_PAGE_SIZE,
      },
      inProgressPagination: {
        offset: (pages.inProgress - 1) * ACTIONS_LIST_PAGE_SIZE,
        limit: ACTIONS_LIST_PAGE_SIZE,
      },
    },
    onError: (error) => toast.error(error.message),
    fetchPolicy: 'cache-and-network',
  });

  return { allActionsData: data?.employee, prevAllActionsData: previousData?.employee };
};
