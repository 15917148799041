import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SurveyListItem } from '../../components/SurveyListItem';
import { getLongFormatDate } from '../../../../../shared/utils';
import * as Styles from '../../SurveyTabs.styles';
import { ReactComponent as CopyIcon } from 'assets/images/CopyIcon.svg';
import { ArchivedSurveyListProps } from '../../SurveyTemplatesTabs.types';
import { DuplicateSurveyDialog } from '../../sentTab/sentSurveysList/duplicateSurveyDialog/DuplicateSurveyDialog';
import { useManageSurveyModal } from '../../sentTab/sentSurveysList/useManageSurveyModal';

export const ArchivedSurveyList = ({ items = [], changeCurrentTab }: ArchivedSurveyListProps) => {
  const { t } = useTranslation();

  const { goToSurvey, handleSurveyAcceptModal, surveyModalId, handleSurveyCloseModal } = useManageSurveyModal();

  const getActionButtons = (surveyId: string) => (
    <Box>
      <Tooltip title={t('surveyTemplates.copyTooltip')} arrow placement="top">
        <IconButton aria-label="duplicate" sx={Styles.EditIconButton} onClick={handleSurveyAcceptModal(surveyId)}>
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <Box data-testid="surveysActiveList" sx={Styles.SurveysListContainer}>
      {items &&
        items.map((survey) => (
          <>
            <SurveyListItem
              key={survey.id}
              onClick={() => goToSurvey(survey.id)}
              title={survey.title}
              dateInfo={
                survey.latestSendDate &&
                `${t('surveyTemplates.lastSent')}: ${getLongFormatDate(new Date(survey.latestSendDate))}`
              }
              actionsElements={getActionButtons(survey.id)}
            />
            <DuplicateSurveyDialog
              id={surveyModalId}
              changeCurrentTab={changeCurrentTab}
              handleCloseModal={handleSurveyCloseModal}
            />
          </>
        ))}
    </Box>
  );
};
