import { Styles } from 'shared/types';

export const Title: Styles = {
  mb: 2,
};

export const Descripiton: Styles = {
  mb: 3,
};

export const ButtonWrapper: Styles = {
  display: 'flex',
  gap: 3,

  '& > *': {
    width: '100%',
  },
};
