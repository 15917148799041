import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useTranslation } from 'react-i18next';

import { WeeklyOverviewDialog } from './weeklyOverviewDialog/WeeklyOverviewDialog';
import { WeeklyOverviewProps } from './WeeklyOverview.types';

export const WeeklyOverview = ({ employeeId, weeklyAvailable }: WeeklyOverviewProps) => {
  const [isWeeklyOverviewDialogOpen, setIsWeeklyOverviewDialogOpen] = useState(false);
  const { t } = useTranslation();

  const closeWeeklyOverviewiDialog = () => {
    setIsWeeklyOverviewDialogOpen(false);
  };

  const openWeeklyOverviewiDialog = () => {
    setIsWeeklyOverviewDialogOpen(true);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={openWeeklyOverviewiDialog} disabled={!weeklyAvailable}>
        <Tooltip arrow placement="top" title={t('employeesTable.table.weeklyOverviewTooltip')}>
          <VisibilityOutlinedIcon />
        </Tooltip>
      </IconButton>
      <WeeklyOverviewDialog
        open={isWeeklyOverviewDialogOpen}
        handleClose={closeWeeklyOverviewiDialog}
        employeeId={employeeId}
      />
    </Box>
  );
};
