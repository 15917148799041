import { usePublishedSurveyEndImageLazyQuery } from 'documents/publishedSurveyEndImage.generated';
import { useSurveyTemplateEndImageLazyQuery } from 'documents/surveyTemplateEndImage.generated';
import { useValidatedParams } from 'hooks';

import { SurveyEndImage } from './useFetchSurveyEndImage.types';

export const useFetchSurveyEndImage = (): (() => Promise<SurveyEndImage>) => {
  const { surveyId } = useValidatedParams(['surveyId']);
  const { surveyTemplateId } = useValidatedParams(['surveyTemplateId']);

  const [getPublishedSurveyEndImage] = usePublishedSurveyEndImageLazyQuery({
    variables: {
      surveyId,
    },
    fetchPolicy: 'network-only',
  });

  const [getTemplateDataEndImage] = useSurveyTemplateEndImageLazyQuery({
    variables: {
      surveyTemplateId,
    },
    fetchPolicy: 'network-only',
  });

  if (surveyTemplateId) {
    return async () => {
      const response = await getTemplateDataEndImage({
        variables: {
          surveyTemplateId,
        },
      });

      return {
        endImageUrl: response.data?.surveyTemplate?.endImageUrl || undefined,
      };
    };
  }

  return async () => {
    const response = await getPublishedSurveyEndImage({
      variables: { surveyId },
    });

    return {
      endImageUrl: response.data?.survey?.surveyTemplate?.endImageUrl || undefined,
    };
  };
};
