import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const DefaultTextField: Styles = {
  fontSize: 14,
  lineHeight: '143%',
  letterSpacing: 0.15,
  width: '100%',
  whiteSpace: 'pre-line',

  '&:hover': {
    backgroundColor: colors.brightGray,
  },

  '&:has(.Mui-focused)': {
    backgroundColor: 'transparent',
  },

  '&&& .MuiInput-root': {
    '&::before': {
      display: 'none',
    },
  },

  '& .MuiInputLabel-root': {
    color: colors.navy,
  },
};
