import { Box, Typography } from '@mui/material';

import { ItemTitleWithCheckbox, Loader, Modal } from 'ui';
import { ClickTypeEnum } from 'ui/tableRowItem/TableRowItem.enum';

import { getStatus } from './CardChecklist.utils';
import * as Styles from './CardChecklist.styles';
import { CardChecklistProps } from './CardChecklist.types';

export const CardChecklist = ({
  items,
  isUpdateLoading,
  modal: { handleItemClick, item, handleModalClose },
  toDoType,
  label,
  isReadOnly,
}: CardChecklistProps) => {
  if (!items?.length) return null;

  return (
    <Box sx={Styles.CardSection}>
      <Typography variant="subtitle2" sx={Styles.CardSectionLabel}>
        {label}
      </Typography>

      {isUpdateLoading && (
        <Box sx={Styles.ItemsLoaderWrapper}>
          <Loader />
        </Box>
      )}

      {items.map(
        (item) =>
          item?.id && (
            <Box sx={Styles.CardSectionItem} key={item.id}>
              <ItemTitleWithCheckbox
                withTooltip
                onClick={() => handleItemClick(item.id, ClickTypeEnum.Check, item.content)}
                type={getStatus(item)}
                content={item.content}
                isReadOnly={isReadOnly}
              />
            </Box>
          ),
      )}
      {item.type && <Modal onClose={handleModalClose} type={item.type} content={item.content} toDoType={toDoType} />}
    </Box>
  );
};
