import { Box, Card, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useValidatedParams } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { getLongFormatDate } from 'shared/utils';
import { CardActionsChecklist, CardHeader, CardNote, CardSummary, SmilingFacesRating } from 'ui';
import {
  SmilingFacesRatingType,
  SmilingFacesRatingValue,
} from 'ui/satisfactionScale/smilingFacesRating/SmilingFacesRating.types';

import * as Styles from './FeedbackCard.styles';
import { FeedbackCardProps } from './FeedbackCard.types';

export const FeedbackCard = ({
  feedback: {
    id: feedbackId,
    summary,
    realizationDate,
    satisfactionScore,
    notes,
    actionsCollection,
    projectSatisfactionScores,
  },
  isReadOnly,
  hideActions,
  variant = 'default',
}: FeedbackCardProps) => {
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);

  return (
    <Card sx={Styles.CardWrapper}>
      <CardHeader
        editUrl={!isReadOnly && `/${AppRoute.EditFeedback}/${feedbackId}/${employeeId}`}
        previewUrl={`/${AppRoute.PreviewFeedback}/${feedbackId}/${employeeId}`}
        variant={variant}
        hideActions={hideActions}
      >
        <Typography sx={Styles.CardDate} variant="subtitle1">
          {realizationDate && getLongFormatDate(new Date(realizationDate))}
        </Typography>
      </CardHeader>
      <Divider sx={Styles.CardDivider} />
      {summary && <CardSummary summary={summary} />}
      <Box sx={Styles.CardSection}>
        <Typography variant="subtitle2" sx={Styles.CardSectionLabel}>
          {t('feedback.feedbackCard.wellBeing')}
        </Typography>
        <Box sx={Styles.CardSectionContent}>
          <SmilingFacesRating
            type="filled"
            value={(satisfactionScore as SmilingFacesRatingType) ?? SmilingFacesRatingValue.Bad}
          />
        </Box>
      </Box>
      <Box>
        {projectSatisfactionScores?.map(
          (score) =>
            score && (
              <Box sx={Styles.CardSection} key={score.id}>
                <Typography variant="subtitle2" sx={Styles.CardSectionLabel}>
                  {t('feedback.feedbackCard.projectWellBeing', { projectName: score.project?.projectName })}
                </Typography>
                <Box sx={Styles.CardSectionContent}>
                  <SmilingFacesRating
                    type="filled"
                    value={(score.satisfactionScore as SmilingFacesRatingType) ?? SmilingFacesRatingValue.Bad}
                  />
                </Box>
              </Box>
            ),
        )}
      </Box>
      <CardActionsChecklist actions={actionsCollection?.items} isReadOnly={isReadOnly} />
      <CardNote notes={notes} />
    </Card>
  );
};
