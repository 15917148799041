import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as Styles from '../SurveyAnswers.styles';

import { SurveyAnswersSummaryProps } from './SurveyAnswersSummary.types';

export const SurveyAnswersSummary = ({ questionsData, summaryData, goToRespondent }: SurveyAnswersSummaryProps) => {
  const { t } = useTranslation();
  const { openedCount, respondedEmails } = summaryData;
  const numberOfResponses = respondedEmails.length;

  return (
    <Box>
      <Box sx={Styles.SummaryWrapper}>
        <Box sx={Styles.SummaryItem} data-testid="survey-summary-tile">
          <Typography variant="h5">{openedCount + numberOfResponses}</Typography>
          <Typography variant="body2">{t('publishedSurvey.answersTab.countSummary.seen')}</Typography>
        </Box>
        <Box sx={Styles.SummaryItem} data-testid="survey-summary-tile">
          <Typography variant="h5">{numberOfResponses}</Typography>
          <Typography variant="body2">{t('publishedSurvey.answersTab.countSummary.answers')}</Typography>
        </Box>
      </Box>
      <Box sx={Styles.ScrollContainer}>
        <Box sx={Styles.QuestionWrapper}>
          <Typography variant="h6">{t('publishedSurvey.answersTab.respondents')}</Typography>
          <Box>
            <Typography variant="subtitle1" sx={Styles.EmailsSubtitle}>
              {t('publishedSurvey.answersTab.emailsListLabel')}
            </Typography>
            <Box sx={Styles.EmailsList}>
              {respondedEmails.map((email, index) => (
                <Tooltip
                  title={t('publishedSurvey.answersTab.goToRespondentTooltip')}
                  followCursor
                  arrow
                  key={index}
                  placement="top"
                >
                  <Button sx={Styles.EmailButton} onClick={() => goToRespondent(email)}>
                    <Typography variant="body1">{`${index + 1}. ${email}`}</Typography>
                  </Button>
                </Tooltip>
              ))}
            </Box>
          </Box>
        </Box>
        {questionsData.map(({ question, answers }, index) => (
          <Box sx={Styles.QuestionWrapper} key={index}>
            <Typography variant="h6">{`${index + 1}. ${question}`}</Typography>
            <Box>
              {answers.map((value, index) => (
                <Box key={index} sx={Styles.Answers}>
                  <Typography variant="body1" sx={Styles.AnswerTypography}>
                    {value?.answer.map((a, index) => <span key={index}>{a}</span>)}
                  </Typography>
                  <Typography variant="body2" sx={Styles.EmailTypography}>
                    {value?.email}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
