import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string,
    authority: process.env.REACT_APP_AZURE_AUTHORITY as string,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};
