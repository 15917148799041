import { Styles } from 'shared/types';
import { theme } from 'shared/utils';

export const CardHeader: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 48,
};

export const CardActions: Styles = { display: 'flex' };

export const CardDate: Styles = { color: theme.palette.text.primary };

export const CardActionButton: Styles = {
  '&& svg': {
    fontSize: 24,
  },
};

export const CardSection: Styles = { my: 2.5, position: 'relative' };
