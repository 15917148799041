import { Edit, RemoveRedEye } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as Styles from './CardHeader.styles';
import { CardHeaderProps } from './CardHeader.types';

export const CardHeader = ({ previewUrl, editUrl, variant, children, hideActions }: CardHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.CardHeader}>
      {children}
      {!hideActions && (
        <Box sx={Styles.CardActions}>
          {editUrl && (
            <Link to={editUrl}>
              <Button startIcon={<Edit />} variant="text" sx={Styles.CardActionButton}>
                {t('report.card.edit')}
              </Button>
            </Link>
          )}

          {variant !== 'preview' && (
            <Link to={previewUrl}>
              <Button
                startIcon={<RemoveRedEye />}
                variant="text"
                sx={Styles.CardActionButton}
                data-cy="reportCardPreviewButton"
              >
                {t('report.card.preview')}
              </Button>
            </Link>
          )}
        </Box>
      )}
    </Box>
  );
};
