import { ActionStatus, GoalStatus, SurveyStatus } from 'generated/webapp_gql';
import { TranslationsKeys } from 'i18n/i18n.types';

export const dateLabel: Record<ActionStatus | GoalStatus | SurveyStatus, TranslationsKeys> = {
  [ActionStatus.InProgress]: 'actionAccordion.addDate_interval',
  [ActionStatus.Done]: 'actionAccordion.doneDate',
  [ActionStatus.Canceled]: 'actionAccordion.cancelDate',
  [SurveyStatus.Closed]: 'actionAccordion.doneDate',
  [SurveyStatus.Opened]: 'actionAccordion.addDate_interval',
};
