import { GoalStatus } from 'generated/webapp_gql';
import { TranslationsKeys } from 'i18n/i18n.types';

export const goalsTabsNames = [GoalStatus.InProgress, GoalStatus.Canceled, GoalStatus.Done];

export const goalsTabsTranslations: Record<GoalStatus, TranslationsKeys> = {
  [GoalStatus.InProgress]: 'goalTabs.tab.inProgress',
  [GoalStatus.Done]: 'goalTabs.tab.done',
  [GoalStatus.Canceled]: 'goalTabs.tab.canceled',
};
