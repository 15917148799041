import { Box, Button, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CloseSurveyDialogProps } from './CloseSurveyDialog.types';
import * as Styles from './CloseSurveyDialog.styles';

export const CloseSurveyDialog = ({
  open,
  changeToState,
  handleCloseModal,
  handleAcceptModal,
}: CloseSurveyDialogProps) => {
  const { t } = useTranslation();

  const lowercaseStatus = changeToState.toLowerCase() as Lowercase<CloseSurveyDialogProps['changeToState']>;

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <Typography variant="h5" sx={Styles.Title}>
        {t(`surveyBuilder.questions.surveyState.title.${lowercaseStatus}`)}
      </Typography>
      <Typography variant="body1" sx={Styles.Descripiton}>
        {t(`surveyBuilder.questions.surveyState.description.${lowercaseStatus}`)}
      </Typography>
      <Box sx={Styles.ButtonWrapper}>
        <Button variant="outlined" onClick={handleCloseModal}>
          {t(`surveyBuilder.questions.surveyState.cancelButton`)}
        </Button>
        <Button variant="contained" onClick={handleAcceptModal}>
          {t(`surveyBuilder.questions.surveyState.acceptButton`)}
        </Button>
      </Box>
    </Dialog>
  );
};
