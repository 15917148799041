import { Navigate } from 'react-router-dom';
import type { ReactNode } from 'react';

import { useReadOnlyEmployee, useValidatedParams } from 'hooks';
import { Loader } from 'ui';

import { AppRoute, EmployeeDetailsViewParam } from './AppRoute.enum';

export const GuardedEmployeeRoute = ({ children }: { children: ReactNode }) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { isReadOnly, isReadOnlyLoading } = useReadOnlyEmployee(employeeId);

  if (isReadOnlyLoading) {
    return <Loader />;
  }

  if (isReadOnly) {
    return <Navigate to={`/${AppRoute.EmployeeDetails}/${employeeId}/${EmployeeDetailsViewParam.Quarterly}`} />;
  }

  return <>{children}</>;
};
