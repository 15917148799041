import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import WestArrow from '@mui/icons-material/West';
import { useTranslation } from 'react-i18next';

import { AppRoute } from 'routing/AppRoute.enum';
import { spreadMultipleStyles } from 'shared/utils';

import { BackButtonProps } from './BackButton.types';
import * as Styles from './BackButton.styles';

export const BackButton = ({ onClick, route, sx = [] }: BackButtonProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick ? onClick : () => navigate((route ? `/${route}` : -1) as AppRoute)}
      size="small"
      sx={spreadMultipleStyles(Styles.Button, sx)}
      disableRipple
      startIcon={<WestArrow />}
      data-cy="backButton"
    >
      <Box component="span" sx={Styles.Label}>
        {t('backButton')}
      </Box>
    </Button>
  );
};
