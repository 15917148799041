import { useEmployeeQuery } from 'documents/employee.generated';
import { AccessType } from 'generated/webapp_gql';

export const useReadOnlyEmployee = (employeeId: number) => {
  const { data, loading } = useEmployeeQuery({
    variables: {
      employeeId,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    isReadOnly: !data?.employee?.requestedBy?.some(
      (accessType) => accessType === AccessType.Supervisor || accessType === AccessType.SubcontractorsAdmin,
    ),
    isReadOnlyLoading: loading,
  };
};
