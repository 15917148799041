import { Box, Button, Dialog, Typography } from '@mui/material';

import * as Styles from './ConfirmModal.styles';
import { ConfirmModalProps } from './ConfirmModal.types';

export const ConfirmModal = ({ isOpen, handleCloseModal, handleAccept, translations }: ConfirmModalProps) => {
  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <Typography variant="h5" sx={Styles.Title}>
        {translations.title}
      </Typography>
      <Typography variant="body1" sx={Styles.Description}>
        {translations.description}
      </Typography>
      <Box sx={Styles.ButtonWrapper}>
        <Button variant="outlined" onClick={handleCloseModal}>
          {translations.cancel}
        </Button>
        <Button variant="contained" onClick={handleAccept}>
          {translations.accept}
        </Button>
      </Box>
    </Dialog>
  );
};
