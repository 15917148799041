import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateQuarterlyReportMutationVariables = Types.Exact<{
  createGoals: Types.CreateGoalsInput;
  updateQuarterlyReport: Types.UpdateQuarterlyReportInput;
}>;


export type UpdateQuarterlyReportMutation = { __typename?: 'Mutation', updateQuarterlyReport?: { __typename?: 'QuarterlyReport', id: string, summary: string, notes?: string | null, status?: Types.QuarterlyReportStatus | null, createdAt: any, updatedAt: any, realizationDate: any, giverId?: number | null, receiverId?: number | null, goalsCollection?: { __typename?: 'GoalCollection', items: Array<{ __typename?: 'Goal', id: string, content?: string | null, goalStatus?: Types.GoalStatus | null } | null> } | null } | null, createGoal?: Array<{ __typename?: 'Goal', id: string, content?: string | null, goalStatus?: Types.GoalStatus | null } | null> | null };


export const UpdateQuarterlyReportDocument = gql`
    mutation updateQuarterlyReport($createGoals: CreateGoalsInput!, $updateQuarterlyReport: UpdateQuarterlyReportInput!) {
  updateQuarterlyReport(input: $updateQuarterlyReport) {
    id
    summary
    notes
    status
    goalsCollection {
      items {
        id
        content
        goalStatus
      }
    }
    createdAt
    updatedAt
    realizationDate
    giverId
    receiverId
  }
  createGoal(input: $createGoals) {
    id
    content
    goalStatus
  }
}
    `;
export type UpdateQuarterlyReportMutationFn = Apollo.MutationFunction<UpdateQuarterlyReportMutation, UpdateQuarterlyReportMutationVariables>;

/**
 * __useUpdateQuarterlyReportMutation__
 *
 * To run a mutation, you first call `useUpdateQuarterlyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuarterlyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuarterlyReportMutation, { data, loading, error }] = useUpdateQuarterlyReportMutation({
 *   variables: {
 *      createGoals: // value for 'createGoals'
 *      updateQuarterlyReport: // value for 'updateQuarterlyReport'
 *   },
 * });
 */
export function useUpdateQuarterlyReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuarterlyReportMutation, UpdateQuarterlyReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuarterlyReportMutation, UpdateQuarterlyReportMutationVariables>(UpdateQuarterlyReportDocument, options);
      }
export type UpdateQuarterlyReportMutationHookResult = ReturnType<typeof useUpdateQuarterlyReportMutation>;
export type UpdateQuarterlyReportMutationResult = Apollo.MutationResult<UpdateQuarterlyReportMutation>;
export type UpdateQuarterlyReportMutationOptions = Apollo.BaseMutationOptions<UpdateQuarterlyReportMutation, UpdateQuarterlyReportMutationVariables>;