import { endOfDay } from 'date-fns';
import { array, date, lazy, number, object, string } from 'yup';

import i18n from 'i18n/i18n';

import { getWeeklyFeedbackMaxDate } from './WeeklyFeedbackForm';

const { t } = i18n;
const satisfactionScoreIsRequired = t('weeklyForm.errorMessage.satisfactionScoreIsRequired');

const satisfactionScoreValidation = number()
  .integer(satisfactionScoreIsRequired)
  .typeError(satisfactionScoreIsRequired)
  .min(1, satisfactionScoreIsRequired)
  .max(5, satisfactionScoreIsRequired)
  .required(satisfactionScoreIsRequired);

const weeklyFeedbackFormSchemaObject = {
  satisfactionScore: satisfactionScoreValidation,
  summary: string().trim().nullable(),
  actions: array().of(
    object().shape({
      content: string(),
    }),
  ),
  notes: string().trim().nullable(),
  realizationDate: lazy(() =>
    date()
      .typeError(t('weeklyForm.errorMessage.realizationDateInvalidType'))
      .nullable()
      .required(t('weeklyForm.errorMessage.realizationDateRequired'))
      .max(endOfDay(getWeeklyFeedbackMaxDate()), t('weeklyForm.errorMessage.realizationDateMaxDate')),
  ),
};

export const addWeeklyFeedbackFormSchema = object({
  ...weeklyFeedbackFormSchemaObject,
  projectsSatisfaction: array().of(
    object().shape({
      projectId: string().required(),
      projectSatisfactionScore: satisfactionScoreValidation,
    }),
  ),
});

export const getEditWeeklyFeedbackSchema = (actionsCount: number, projectScoresCount: number) =>
  object({
    ...weeklyFeedbackFormSchemaObject,
    actions: actionsCount ? array().of(object().shape({ content: string().min(1) })) : array(),
    projectsSatisfaction: projectScoresCount
      ? array()
          .of(
            object().shape({
              id: string(),
              projectSatisfactionScore: satisfactionScoreValidation,
            }),
          )
          .required()
          .length(projectScoresCount)
      : array(),
  });
