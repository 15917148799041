import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks';
import { useAllGoalsOfEmployeeQuery } from 'documents/allGoalsOfEmployee.generated';
import { GOALS_LIST_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';

import { UseAllGoalsOfEmployeeProps } from './useAllGoalsOfEmployee.types';

export const useAllGoalsOfEmployee = ({ pages }: UseAllGoalsOfEmployeeProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const { data, previousData, loading } = useAllGoalsOfEmployeeQuery({
    variables: {
      employeeId,
      canceledPagination: {
        offset: (pages.canceled - 1) * GOALS_LIST_PAGE_SIZE,
        limit: GOALS_LIST_PAGE_SIZE,
      },
      donePagination: {
        offset: (pages.done - 1) * GOALS_LIST_PAGE_SIZE,
        limit: GOALS_LIST_PAGE_SIZE,
      },
      inProgressPagination: {
        offset: (pages.inProgress - 1) * GOALS_LIST_PAGE_SIZE,
        limit: GOALS_LIST_PAGE_SIZE,
      },
    },
    onError: (error) => toast.error(error.message),
    fetchPolicy: 'cache-and-network',
  });

  return { allGoalsData: data?.employee, prevAllGoalsData: previousData?.employee, loading };
};
