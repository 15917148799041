import { QuerySurveysArgs, SurveyCollection } from 'generated/webapp_gql';

export const mergeSurveys = (
  existing: SurveyCollection | undefined,
  incoming: SurveyCollection,
  args: Omit<QuerySurveysArgs, 'employeeId'> | null,
): SurveyCollection => {
  if (!args || !args.surveyStatus || !args.pagination || typeof args.pagination?.offset !== 'number') {
    return incoming;
  }
  const mergedItems = existing ? existing.items.slice(0) : [];

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  incoming.items.forEach((item, index) => (mergedItems[args.pagination!.offset! + index] = item));

  return {
    ...incoming,
    items: mergedItems,
  };
};

export const readSurveys = (
  existing: SurveyCollection | undefined,
  args: Omit<QuerySurveysArgs, 'employeeId'> | null,
) => {
  if (
    !args ||
    !existing ||
    !args.surveyStatus ||
    !args.pagination ||
    typeof args.pagination.offset !== 'number' ||
    typeof args.pagination.limit !== 'number'
  ) {
    return;
  }

  const items = existing.items.slice(args.pagination.offset, args.pagination.offset + args.pagination.limit);

  if (!items) {
    return { ...existing, items: [] };
  }

  if (items.filter(Boolean).length > Math.min(existing.total - 1, 0)) {
    return { ...existing, items };
  }

  return { ...existing, items: [] };
};
