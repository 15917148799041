import { Styles } from 'shared/types';

export const Wrapper = {
  display: 'flex',
};

export const Sidebar = {
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
};

export const ChildrenWrapper: Styles = {
  p: ({ spacing }) => spacing(1.5, 3, 1.25),
  width: '100%',
};
