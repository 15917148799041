import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Paper, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Language, Section } from 'generated/webapp_gql';
import { useValidatedParams, useSurvey } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { BackButton, TabPanel, Tabs, Title } from 'ui';
import { SurveyEditorQuestions } from '../surveyEditorQuestions/SurveyEditorQuestions';
import { SurveyViewController } from 'context/surveyView/SurveyViewController';
import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { PublishedSurveyTab } from 'app/survey/surveyLayout/SurveyLayout.enum';

import * as Styles from './SurveyEditorLayout.styles';
import { defaultSection } from './SurveyEditorLayout.utils';
import { surveyFormSchema } from './SurveyEditorLayout.schema';
import { SurveyEditorTab } from './SurveyEditorLayout.enum';

export const SurveyEditorLayout = () => {
  const { employeeId, surveyId } = useValidatedParams(['surveyTemplateId', 'employeeId', 'surveyId']);
  const { t } = useTranslation();
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const navigate = useNavigate();

  const surveyEditorFormMethods = useForm<SurveyFormData>({
    resolver: yupResolver(surveyFormSchema),
    mode: 'all',
    defaultValues: {
      title: '',
      language: Language.Pl,
      beginning: t('surveyBuilder.questions.intro.defaultValue'),
      sections: [defaultSection],
      conclusion: t('surveyBuilder.questions.outro.defaultValue'),
    },
  });

  const goToPublicationPage = () => {
    navigate(`/${AppRoute.PublishedSurvey}/${employeeId}/${surveyId}?tab=${PublishedSurveyTab.Publication}`);
  };

  const { isSurveyLoading, template, title } = useSurvey();

  useEffect(() => {
    if (!isSurveyLoading) {
      surveyEditorFormMethods.reset({
        title: title ?? '',
        language: template?.language || Language.Pl,
        beginning: template?.content?.beginning ?? '',
        conclusion: template?.content?.conclusion ?? '',
        sections: (template?.content?.sections as Section[]) ?? [defaultSection],
        endImageName: template?.endImageName ?? undefined,
        endImageUrl: template?.endImageUrl,
        introImageName: template?.introImageName ?? undefined,
        introImageUrl: template?.introImageUrl,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveyLoading]);

  const handleBack = () => {
    if (isPreviewVisible) {
      return setIsPreviewVisible(false);
    }

    navigate(`/${AppRoute.PublishedSurvey}/${employeeId}/${surveyId}?tab=${PublishedSurveyTab.Publication}`);
  };

  return (
    <SurveyViewController isEditInputOnly={true}>
      <Paper sx={Styles.Container}>
        <Title button={<BackButton sx={Styles.BackButton} onClick={handleBack} />} sx={Styles.Title}>
          <Tabs value={SurveyEditorTab.Questions}>
            <Tab
              label={t('surveyBuilder.tabs.questions')}
              value={SurveyEditorTab.Questions}
              key={'surveyBuilder.tabs.questions'}
            />
          </Tabs>
        </Title>

        <Box sx={Styles.SurveyBuilderContainer}>
          <TabPanel
            value={SurveyEditorTab.Questions}
            index={SurveyEditorTab.Questions}
            key={'surveyBuilder.tabs.questions'}
          >
            <FormProvider {...surveyEditorFormMethods}>
              <SurveyEditorQuestions goToPublicationPage={goToPublicationPage} />
            </FormProvider>
          </TabPanel>
        </Box>
      </Paper>
    </SurveyViewController>
  );
};
