const ACCESS_TOKEN_KEY = 'accessToken';
const USERNAME = 'username';

export class AuthStorage {
  private _accessToken: string | null = null;
  private _username: string | null = null;

  constructor() {
    try {
      this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      this.username = localStorage.getItem(USERNAME);
    } catch (error) {
      this.accessToken = null;
      this.username = null;
    }
  }

  get accessToken(): string | null {
    return this._accessToken;
  }

  set accessToken(value: string | null) {
    this._accessToken = value;

    try {
      if (typeof value === 'string') {
        localStorage.setItem(ACCESS_TOKEN_KEY, value);
      } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
      }
    } catch (error) {}
  }

  get username(): string | null {
    return this._username;
  }

  set username(value: string | null) {
    this._username = value;

    try {
      if (typeof value === 'string') {
        localStorage.setItem(USERNAME, value);
      } else {
        localStorage.removeItem(USERNAME);
      }
    } catch (error) {}
  }
}

export const authStorage = new AuthStorage();
