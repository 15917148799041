import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const UserRow = (checked: boolean): Styles => ({
  marginBottom: '8px',
  border: ({ palette }) => (checked ? `1px solid ${palette.primary.main}` : '1px solid transparent'),
  borderRadius: '4px',

  '&.MuiAutocomplete-option[aria-disabled="true"]': {
    opacity: 1,
  },
});

export const UserRowOverride = {
  padding: '8px',
};

export const UserInner = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

export const UserData: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
};

export const CheckboxBase: Styles = {
  path: {
    fill: ({ palette }) => `${palette.primary.main}`,
  },
};

export const SentBadge: Styles = {
  display: 'flex',
  alignItems: 'center',
  color: colors.slateGray,
  paddingRight: 1,
};

export const SentBadgeText: Styles = {
  fontWeight: 600,
};

export const SendingBadgeText: Styles = {
  fontWeight: 600,
  marginY: 'auto',
  flexShrink: 0,
};

export const CheckIcon: Styles = {
  width: '16px',
  marginLeft: 0.5,
};
