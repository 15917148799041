import { Box, SvgIcon, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

import { ToastProps } from './Toast.types';
import * as Styles from './Toast.styles';

const icons = {
  error: ErrorIcon,
  warning: WarningIcon,
  success: CheckCircleIcon,
  info: InfoIcon,
  default: InfoIcon,
};

export const Toast = ({ closeToast, toastProps, title, message }: ToastProps) => {
  return (
    <Box sx={Styles.ToastContainer(Boolean(title))}>
      {toastProps && (
        <SvgIcon
          fontSize="small"
          sx={Styles.Icon}
          color={toastProps.type === 'default' ? 'info' : toastProps.type}
          component={icons[toastProps.type]}
        />
      )}
      {title && (
        <Typography variant="subtitle2" sx={Styles.Title}>
          {title}
        </Typography>
      )}
      <Typography variant="body2" sx={Styles.Message}>
        {message}
      </Typography>
      <CloseIcon fontSize="small" onClick={closeToast} sx={Styles.CloseIcon} />
    </Box>
  );
};
