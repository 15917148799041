import TextField from '@mui/material/TextField';
import { useState, KeyboardEvent } from 'react';
import { Box, Button, Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

import { useAddAction, useValidatedParams } from 'hooks';

import * as Styles from './ActionInput.styles';

export const ActionInput = () => {
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams(['employeeId']);
  const [actionName, setActionName] = useState('');

  const { createAction, isCreatingAction } = useAddAction({
    onSuccess: () => setActionName(''),
  });

  const handleCreateAction = () => {
    if (actionName) {
      createAction({
        variables: {
          input: {
            employeeId,
            action: {
              content: actionName,
            },
          },
        },
      });
    }
  };

  const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleCreateAction();
    }
  };

  return (
    <Box sx={Styles.Wrapper}>
      <Checkbox checked={false} sx={Styles.Checkbox} />

      <TextField
        placeholder={t('actions.inputPlaceholder')}
        value={actionName}
        onChange={(e) => setActionName(e.target.value)}
        disabled={isCreatingAction}
        onKeyPress={handleEnterPress}
        sx={Styles.ActionInput}
      />

      {actionName && (
        <Button sx={Styles.SaveButton} disabled={isCreatingAction} onClick={handleCreateAction}>
          <CheckIcon sx={Styles.CheckIcon} />
          {t('actions.saveAction')}
        </Button>
      )}
    </Box>
  );
};
