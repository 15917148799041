import { Typography } from '@mui/material';

import * as Styles from './Slider.styles';
import { SliderLabelProps } from './Slider.types';

export function SliderLabel({ active, align = 'left', label, value }: SliderLabelProps) {
  return (
    <Typography sx={Styles.SliderLabel(active, align)}>
      {value} {label && `(${label})`}
    </Typography>
  );
}
