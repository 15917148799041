import { DropzoneOptions } from 'react-dropzone';

export const dropzoneConfig: DropzoneOptions = {
  noClick: true,
  maxFiles: 1,
  multiple: false,
  accept: {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
  },
  maxSize: 3000000,
};
