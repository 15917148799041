import { SurveyResponseStatus } from 'generated/webapp_gql';

export const statuses = [
  {
    type: SurveyResponseStatus.Sent,
    name: 'surveyBuilder.publicationSettings.noAction',
  },
  {
    type: SurveyResponseStatus.Seen,
    name: 'surveyBuilder.publicationSettings.seen',
  },
  {
    type: SurveyResponseStatus.Answered,
    name: 'surveyBuilder.publicationSettings.responded',
  },
] as const;
