import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeWithWeeklyFeedbackQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  feedbackId: Types.Scalars['ID']['input'];
}>;


export type EmployeeWithWeeklyFeedbackQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, firstName: string, lastName: string, avatarUrl?: string | null, email: string, level?: string | null, department: string, currentProjects?: Array<string | null> | null, headFullName?: string | null, employmentType?: string | null, hiredDate: any, pieselyUrl?: string | null, luxUrl?: string | null, satisfactionLevel?: number | null, requestedBy?: Array<Types.AccessType | null> | null, supervisor?: { __typename?: 'Employee', firstName: string, lastName: string } | null } | null, weeklyFeedback?: { __typename?: 'WeeklyFeedback', id: string, satisfactionScore: number, summary?: string | null, notes?: string | null, realizationDate: any, receiverId?: number | null, projectSatisfactionScores?: Array<{ __typename?: 'ProjectSatisfaction', id: string, satisfactionScore: number, project: { __typename?: 'Project', projectName: string } } | null> | null, actionsCollection?: { __typename?: 'ActionCollection', items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null } | null> } | null } | null };


export const EmployeeWithWeeklyFeedbackDocument = gql`
    query employeeWithWeeklyFeedback($employeeId: Int!, $feedbackId: ID!) {
  employee(id: $employeeId) {
    id
    firstName
    lastName
    avatarUrl
    email
    level
    department
    currentProjects
    headFullName
    supervisor {
      firstName
      lastName
    }
    employmentType
    hiredDate
    pieselyUrl
    luxUrl
    satisfactionLevel
    requestedBy
  }
  weeklyFeedback(id: $feedbackId) {
    id
    satisfactionScore
    summary
    notes
    realizationDate
    projectSatisfactionScores {
      id
      satisfactionScore
      project {
        projectName
      }
    }
    actionsCollection {
      items {
        id
        content
        actionStatus
      }
    }
    receiverId
  }
}
    `;

/**
 * __useEmployeeWithWeeklyFeedbackQuery__
 *
 * To run a query within a React component, call `useEmployeeWithWeeklyFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeWithWeeklyFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeWithWeeklyFeedbackQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      feedbackId: // value for 'feedbackId'
 *   },
 * });
 */
export function useEmployeeWithWeeklyFeedbackQuery(baseOptions: Apollo.QueryHookOptions<EmployeeWithWeeklyFeedbackQuery, EmployeeWithWeeklyFeedbackQueryVariables> & ({ variables: EmployeeWithWeeklyFeedbackQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeWithWeeklyFeedbackQuery, EmployeeWithWeeklyFeedbackQueryVariables>(EmployeeWithWeeklyFeedbackDocument, options);
      }
export function useEmployeeWithWeeklyFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeWithWeeklyFeedbackQuery, EmployeeWithWeeklyFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeWithWeeklyFeedbackQuery, EmployeeWithWeeklyFeedbackQueryVariables>(EmployeeWithWeeklyFeedbackDocument, options);
        }
export function useEmployeeWithWeeklyFeedbackSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmployeeWithWeeklyFeedbackQuery, EmployeeWithWeeklyFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeeWithWeeklyFeedbackQuery, EmployeeWithWeeklyFeedbackQueryVariables>(EmployeeWithWeeklyFeedbackDocument, options);
        }
export type EmployeeWithWeeklyFeedbackQueryHookResult = ReturnType<typeof useEmployeeWithWeeklyFeedbackQuery>;
export type EmployeeWithWeeklyFeedbackLazyQueryHookResult = ReturnType<typeof useEmployeeWithWeeklyFeedbackLazyQuery>;
export type EmployeeWithWeeklyFeedbackSuspenseQueryHookResult = ReturnType<typeof useEmployeeWithWeeklyFeedbackSuspenseQuery>;
export type EmployeeWithWeeklyFeedbackQueryResult = Apollo.QueryResult<EmployeeWithWeeklyFeedbackQuery, EmployeeWithWeeklyFeedbackQueryVariables>;