import { Grid } from '@mui/material';

import { ReportCard } from 'ui';
import { useReadOnlyEmployee, useValidatedParams } from 'hooks';

import { ReportsProps } from './Reports.types';
import * as Styles from './Reports.styles';

export const Reports = ({ reports }: ReportsProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { isReadOnly } = useReadOnlyEmployee(employeeId);

  return (
    <Grid item sx={Styles.GridItem}>
      {reports.map((report) => (
        <ReportCard key={report.id} report={report} isReadOnly={isReadOnly} />
      ))}
    </Grid>
  );
};
