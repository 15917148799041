import { useSearchParams } from 'react-router-dom';

import { SurveySearchParams } from './useEmployeeSurveysSearchParams.types';

export const useEmployeeSurveysSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: SurveySearchParams = {
    opened: Number(searchParams.get('opened') || 1),
    closed: Number(searchParams.get('closed') || 1),
  };

  return { params, setSearchParams };
};
