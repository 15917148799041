import { useLocation, useSearchParams as reactRouterUseSearchParams } from 'react-router-dom';

import { DEFAULT_ACTION_TYPE } from 'app/employeeDetails/employeeDetailsWeekly/EmployeeDetailsWeekly';
import { DEFAULT_GOAL_TYPE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';
import { EmployeeDetailsViewParam } from 'routing/AppRoute.enum';
import { ActionStatus, GoalStatus } from 'generated/webapp_gql';

import { SearchParams } from './useEmployeeSearchParams.types';

export const useEmployeeSearchParams = () => {
  const [searchParams, setSearchParams] = reactRouterUseSearchParams();
  const location = useLocation();

  const defaultTask = location.pathname
    .split('/')
    .some((path) => path.toLowerCase() === EmployeeDetailsViewParam.Quarterly)
    ? DEFAULT_GOAL_TYPE
    : DEFAULT_ACTION_TYPE;

  const params: SearchParams = {
    page: Number(searchParams.get('page')) > 0 ? Number(searchParams.get('page')) : 1,
    'task-status': <ActionStatus | GoalStatus>searchParams.get('task-status') ?? defaultTask,
  };

  return { searchParams: params, setSearchParams };
};
