import { Paper } from '@mui/material';

import { MainContainerProps } from './MainContainer.types';

export const MainContainer = ({ children, topSection, sx }: MainContainerProps) => {
  return (
    <>
      {topSection}
      <Paper sx={[{ borderRadius: 2, p: 6 }, ...(Array.isArray(sx) ? sx : [sx])]}>{children}</Paper>
    </>
  );
};
