import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Wrapper: Styles = {
  py: 2.75,
  mt: 3,
  mb: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: colors.ghostWhite,
  borderRadius: 2,
  border: `1px dashed ${colors.hawkesBlue}`,
};

export const Emoji: Styles = {
  pb: 0.5,
};
