import { useEmployeeWeeklyCurrentProjectsQuery } from 'documents/employeeWeeklyCurrentProjects.generated';
import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';

export const useEmployeeWeeklyCurrentProjects = () => {
  const { employeeId } = useValidatedParams(['employeeId']);

  const { data, loading } = useEmployeeWeeklyCurrentProjectsQuery({
    variables: {
      employeeId,
    },
  });

  return {
    employeeCurrentProjectsData: data?.employee,
    isEmployeeCurrentProjectsLoading: loading,
  };
};
