import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const UserRow: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingX: 1,
  marginBottom: 2,
};

export const UserDetailsWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
};

export const RespondentRow = (isOdd: boolean): Styles => ({
  paddingY: 1,
  paddingX: 2,
  borderRadius: 0.5,
  height: 56,
  background: isOdd ? colors.ghostWhite : 'transparent',
  marginBottom: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ActionsWrapper: Styles = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,
};

export const Loader: Styles = {
  color: colors.disabledGray,
  marginLeft: 1,
};
