import ImageIcon from '@mui/icons-material/Image';
import { Box, Button, Typography } from '@mui/material';

import { ControlledTextField, ImageWithDropDown } from 'ui';
import { Section } from '../SurveyBuilderQuestions.enum';

import * as Styles from './SectionWithImage.styles';
import { SectionWithImageProps } from './SectionWithImage.types';
import { UploadImage } from './uploadImage/UploadImage';

export const SectionWithImage = ({
  label,
  addImageLabel,
  editImageLabel,
  addImageDescription,
  addImageSuccessMessage,
  image,
  imageSrc,
  setImage,
  isModalOpened,
  sectionName,
  setOpenedModal,
  control,
  updateImageName,
}: SectionWithImageProps) => {
  return (
    <Box sx={Styles.Container(sectionName === Section.Beginning)}>
      <Box sx={Styles.TopSection}>
        <Typography sx={Styles.Label} variant="subtitle2">
          {label}
        </Typography>

        {!imageSrc && (
          <Button onClick={() => setOpenedModal(sectionName)} startIcon={<ImageIcon />}>
            {addImageLabel}
          </Button>
        )}
      </Box>
      <Box sx={Styles.Children}>
        {imageSrc && !isModalOpened && (
          <ImageWithDropDown
            imagePath={imageSrc}
            onImageDelete={() => {
              setImage(null);
            }}
            open={() => setOpenedModal(sectionName)}
            changePhotoLabel={editImageLabel}
            imageSx={Styles.ImagePreview}
          />
        )}
        <ControlledTextField
          sx={Styles.ControlledTextField}
          formControlSx={Styles.FormControl}
          control={control}
          name={sectionName}
          variant="outlined"
        />
        {isModalOpened && (
          <UploadImage
            title={addImageLabel}
            description={addImageDescription}
            editLabel={editImageLabel}
            successMessage={addImageSuccessMessage}
            updateImageName={updateImageName}
            setImage={setImage}
            setOpenedModal={setOpenedModal}
            image={image}
          />
        )}
      </Box>
    </Box>
  );
};
