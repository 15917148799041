import { InternalRefetchQueriesInclude } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks';
import { Toast } from 'ui';
import { DepartmentsSentSurveysDocument } from 'documents/departmentSentSurveys.generated';
import { useUpdateSurveyMutation } from 'documents/updateSurvey.generated';
import { AllSurveysOfEmployeeDocument } from 'documents/allSurveysOfEmployee.generated';
import { TodoRowUpdate } from 'context/todoRow/TodoRowController.types';

import { UpdateSurveyTitlePayload } from './useUpdateSurveyTitle.types';

export const useUpdateSurveyTitle = (): TodoRowUpdate => {
  const { t } = useTranslation();
  const { employeeId } = useValidatedParams();

  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: AllSurveysOfEmployeeDocument,
      variables: {
        employeeId,
      },
    },

    {
      query: DepartmentsSentSurveysDocument,
      variables: {
        employeeId,
      },
    },
  ];

  const [updateSurveyTitle, { loading: isUpdateSurveyTitleLoading }] = useUpdateSurveyMutation({
    refetchQueries,
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      toast.success(<Toast title={t('survey.toast.heading')} message={t('survey.toast.surveyUpdated')} />);
    },
  });

  const handleUpdateSurveyTitle = ({ id, content }: UpdateSurveyTitlePayload) => {
    updateSurveyTitle({
      variables: { input: { surveyId: id, title: content } },
    });
  };

  return { onUpdateItem: handleUpdateSurveyTitle, isUpdateItemLoading: isUpdateSurveyTitleLoading };
};
