import { Box, Grid } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useEmployeeSearchParams, useSearchParamsState } from 'hooks';
import { Accordion, Loader, NoDataHandler, Pagination } from 'ui';
import { WEEKLY_FEEDBACKS_PAGE_SIZE } from '../EmployeeDetailsWeekly';
import { Feedbacks } from '../feedbacks/Feedbacks';

import * as Styles from './FeedbackSection.styles';
import { FeedbackSectionProps } from './FeedbackSection.types';

export const FeedbackSection = ({ data, prevData, loading }: FeedbackSectionProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  const { searchParams, setSearchParams } = useEmployeeSearchParams();
  const [activePage, setActivePage] = useSearchParamsState({ searchParams, setSearchParams, params: 'page' });

  const handlePageChange = (page: number) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    setActivePage(page);
  };

  const availableFeedbacks = data?.total || prevData?.total || 0;
  const feedbackPagesCount = Math.ceil(availableFeedbacks / WEEKLY_FEEDBACKS_PAGE_SIZE);

  const currentWeeklyFeedbacksData = loading ? prevData : data;

  if (loading && !currentWeeklyFeedbacksData) return <Loader />;

  if (!data && !currentWeeklyFeedbacksData) return null;

  return (
    <Grid ref={ref}>
      <Accordion
        title={`${t('feedback.sectionTitle.weekly')} (${currentWeeklyFeedbacksData?.total ?? 0})`}
        content={
          !!currentWeeklyFeedbacksData?.items.length ? (
            <Box sx={Styles.Feedbacks}>
              {loading && (
                <Box sx={Styles.Loader}>
                  <Loader />
                </Box>
              )}
              <Feedbacks feedbacks={currentWeeklyFeedbacksData.items} />
              <Box sx={Styles.Pagination}>
                <Pagination count={feedbackPagesCount} page={activePage} onChange={handlePageChange} />
              </Box>
            </Box>
          ) : (
            <NoDataHandler message={t('employeeDetails.feedbackNoData')} />
          )
        }
      ></Accordion>
    </Grid>
  );
};
