import { Styles } from 'shared/types';
import { colors, theme } from 'shared/utils';

export const CardWrapper: Styles = {
  px: 3,
  pt: 1,
  pb: 2,
  position: 'relative',
  mb: 3,
  backgroundColor: colors.ghostWhite,
};

export const CardDate: Styles = { color: theme.palette.text.primary };

export const CardSection: Styles = { my: 2.5, position: 'relative' };

export const CardSectionLabel: Styles = { color: theme.palette.text.primary };

export const CardSectionContent = {
  wordBreak: 'break-word',
  whiteSpace: 'pre-line',
};

export const CardSectionRating = {
  wordBreak: 'break-word',
};

export const CardDateError: Styles = { color: 'red' };

export const CardDivider: Styles = { my: 1 };

export const Alert: Styles = { width: 'auto', my: 3 };

export const CardSectionAction: Styles = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: 2,

  '&:hover': {
    backgroundColor: colors.brightGray,
  },
};

export const ActionsLoaderWrapper: Styles = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 1,
  backgroundColor: colors.ghostWhite,
  opacity: 0.8,

  div: {
    backgroundColor: 'transparent',

    span: {
      filter: 'blur(0)',
    },
  },
};
