import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActionsOfEmployeeQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  actionStatus: Types.ActionStatus;
  pagination?: Types.InputMaybe<Types.PaginationInput>;
  sort?: Types.InputMaybe<Types.SortByRealizationDateInput>;
}>;


export type ActionsOfEmployeeQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, in_progress?: number | null, done?: number | null, canceled?: number | null, actionsCollection?: { __typename?: 'ActionCollection', total: number, items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null, createdAt: any, realizationDate?: any | null, updatedAt: any, weeklyFeedbackId?: string | null } | null> } | null } | null };


export const ActionsOfEmployeeDocument = gql`
    query actionsOfEmployee($employeeId: Int!, $actionStatus: ActionStatus!, $pagination: PaginationInput, $sort: SortByRealizationDateInput) {
  employee(id: $employeeId) {
    id
    actionsCollection(
      actionStatus: $actionStatus
      pagination: $pagination
      sort: $sort
    ) {
      items {
        id
        content
        actionStatus
        createdAt
        realizationDate
        updatedAt
        weeklyFeedbackId
        updatedAt
      }
      total
    }
    in_progress: actionsInProgress
    done: actionsAmountByStatus(actionStatus: DONE)
    canceled: actionsAmountByStatus(actionStatus: CANCELED)
  }
}
    `;

/**
 * __useActionsOfEmployeeQuery__
 *
 * To run a query within a React component, call `useActionsOfEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionsOfEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionsOfEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      actionStatus: // value for 'actionStatus'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useActionsOfEmployeeQuery(baseOptions: Apollo.QueryHookOptions<ActionsOfEmployeeQuery, ActionsOfEmployeeQueryVariables> & ({ variables: ActionsOfEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionsOfEmployeeQuery, ActionsOfEmployeeQueryVariables>(ActionsOfEmployeeDocument, options);
      }
export function useActionsOfEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionsOfEmployeeQuery, ActionsOfEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionsOfEmployeeQuery, ActionsOfEmployeeQueryVariables>(ActionsOfEmployeeDocument, options);
        }
export function useActionsOfEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ActionsOfEmployeeQuery, ActionsOfEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActionsOfEmployeeQuery, ActionsOfEmployeeQueryVariables>(ActionsOfEmployeeDocument, options);
        }
export type ActionsOfEmployeeQueryHookResult = ReturnType<typeof useActionsOfEmployeeQuery>;
export type ActionsOfEmployeeLazyQueryHookResult = ReturnType<typeof useActionsOfEmployeeLazyQuery>;
export type ActionsOfEmployeeSuspenseQueryHookResult = ReturnType<typeof useActionsOfEmployeeSuspenseQuery>;
export type ActionsOfEmployeeQueryResult = Apollo.QueryResult<ActionsOfEmployeeQuery, ActionsOfEmployeeQueryVariables>;