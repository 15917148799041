import { Navigate } from 'react-router-dom';

import { useAuth } from 'hooks';
import { Loader } from 'ui';

import { AppRoute } from './AppRoute.enum';
import { UserRoute } from './UserRoute';

export const AuthenticatedRoute = () => {
  const { isAuthenticated, isAuthenticating } = useAuth();

  if (isAuthenticating) return <Loader />;

  if (!isAuthenticated) return <Navigate to={AppRoute.Login} />;

  return <UserRoute />;
};
