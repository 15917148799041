import { ApolloError, QueryHookOptions } from '@apollo/client';
import { toast } from 'react-toastify';

import { EmployeesQuery, EmployeesQueryVariables, useEmployeesQuery } from 'documents/employees.generated';
import { useDepartmentCodes } from 'hooks';
import { validateSortByParam, validateSortOrderParam } from 'hooks/utils';

import { UseEmployeesProps } from './useEmployees.types';

const ACTIONS_LIST_PAGE_SIZE = 3;

export const useEmployees = ({
  filters: { assignedToMe, searchText, filterByDepartments, isAdminMode },
  paginationFilters: { page, pageSize },
  sortFilters,
  withPendingActions,
  defaultSortBy,
}: UseEmployeesProps) => {
  const filterByDepartmentsCodes = useDepartmentCodes(filterByDepartments);

  const employeesQueryOptions: QueryHookOptions<EmployeesQuery> = {
    variables: <EmployeesQueryVariables>{
      assignedToMe,
      searchedValue: searchText,
      withPendingActions,
      filterByDepartments: filterByDepartmentsCodes,
      withAdminMode: isAdminMode,
      pagination: {
        limit: pageSize,
        offset: (page - 1 ?? 0) * pageSize,
      },
      actionsPagination: {
        limit: ACTIONS_LIST_PAGE_SIZE,
        offset: 0,
      },
      sort: {
        sortBy: validateSortByParam({
          param: sortFilters?.[0],
          defaultSortBy,
        }),
        sortOrder: validateSortOrderParam({
          param: sortFilters?.[1],
          defaultSortBy,
        }),
      },
    },
    onError: (error: ApolloError) => toast.error(error.message),
    fetchPolicy: 'cache-and-network',
  };
  const { data, previousData, loading } = useEmployeesQuery({ ...employeesQueryOptions, skip: isAdminMode });

  return {
    employeesData: data?.employees,
    prevEmployeesData: previousData?.employees,
    isEmployeesDataLoading: loading,
  };
};
