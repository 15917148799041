import { Box, Button, Dialog, Typography } from '@mui/material';
import { addDays, differenceInCalendarDays, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { DatePicker } from 'ui';

import * as Styles from './SurveyNotificationCustomDateDialog.styles';
import type { SurveyNotificationCustomDateDialogProps } from './SurveyNotificationCustomDateDialog.types';

export const SurveyNotificationCustomDateDialog = ({
  onClose,
  closeSurveyDate,
  onSubmit,
  selectedDate = 0,
}: SurveyNotificationCustomDateDialogProps) => {
  const { t } = useTranslation();

  const [notificationDate, setNotificationDate] = useState(selectedDate);
  const minDate = addDays(new Date(), 1);
  const maxDate = subDays(closeSurveyDate, 1);

  const onNotificationCustomDateChange = (customDate: Date | null) => {
    if (customDate) {
      setNotificationDate(differenceInCalendarDays(closeSurveyDate, customDate));
    }
  };

  const confirmNotificationTimeChange = () => {
    onSubmit(notificationDate);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth>
      <Box>
        <Typography variant="h5">
          {t('surveyBuilder.publicationSettings.slackNotificationSwitch.otherOption.title')}
        </Typography>
        <Box sx={Styles.DatePickerWrapper}>
          <Typography variant="body1" component="label" htmlFor="customNotificationDate">
            {t('surveyBuilder.publicationSettings.slackNotificationSwitch.otherOption.subtitle')}
          </Typography>
          <Box sx={Styles.NotificationDatePickerContainer}>
            <DatePicker
              id="customNotificationDate"
              onChange={onNotificationCustomDateChange}
              minDate={minDate}
              maxDate={maxDate}
              value={subDays(closeSurveyDate, notificationDate)}
            />
          </Box>
        </Box>
        <Box sx={Styles.ButtonWrapper}>
          <Button variant="outlined" onClick={onClose}>
            {t('surveyBuilder.publicationSettings.slackNotificationSwitch.otherOption.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={confirmNotificationTimeChange}
            disabled={
              isNaN(notificationDate) ||
              differenceInCalendarDays(closeSurveyDate, minDate) < notificationDate ||
              differenceInCalendarDays(closeSurveyDate, maxDate) > notificationDate
            }
          >
            {t('surveyBuilder.publicationSettings.slackNotificationSwitch.otherOption.accept')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
