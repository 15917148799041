import { alpha } from '@mui/material';

import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

const Divider: Styles = {
  position: 'absolute',
  content: "''",
  width: 'calc(100% + 8px)',
  height: 2,
  left: 0,
  backgroundImage: `linear-gradient(to right, ${colors.slateGray} 50%, transparent 0%)`,
  backgroundPosition: 'bottom',
  backgroundSize: '16px 1px',
  backgroundRepeat: 'repeat-x',
};

export const Container = (isIntro: boolean): Styles => {
  if (isIntro) {
    return {
      mb: 5,

      '&:after': {
        ...Divider,
        mt: 4,
      },
    };
  }

  return {
    mt: 5,

    '&:before': {
      ...Divider,
      mt: -5,
    },
  };
};

export const TopSection: Styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const Children: Styles = {
  position: 'relative',
};

export const Label: Styles = {
  backgroundColor: 'navy',
  color: 'white',
  borderRadius: '4px 4px 0 0',
  fontWeight: 700,
  px: 2,
  py: 1.5,
};

export const ControlledTextField: Styles = {
  fieldset: {
    border: 'none',
  },
  textarea: {
    zIndex: 1,
  },
};

export const FormControl: Styles = {
  position: 'static',

  '&& .MuiFormControl-root': {
    position: 'static',
  },

  '&& .MuiInputBase-root': {
    position: 'static',
  },

  '&& .MuiInputBase-root::after': {
    zIndex: 0,
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    border: `1px solid ${alpha(colors.navy, 0.23)}`,
    borderRadius: '0 4px 4px',
    display: 'block',
    visibility: 'visible',
  },

  '.MuiInputBase-root.Mui-focused:not(.Mui-error)::after': {
    borderColor: colors.blueberry,
  },

  '.MuiInputBase-root.Mui-error::after': {
    borderColor: colors.tomato,
  },

  '.MuiInputBase-root:hover:not(.Mui-focused):not(.Mui-error)::after': {
    borderColor: colors.navy,
  },
};

export const ImagePreview: Styles = {
  pt: 5,
  pb: 4,
};
