import { Grid } from '@mui/material';

import { useReadOnlyEmployee, useValidatedParams } from 'hooks';
import { FeedbackCard } from 'ui';

import * as Styles from './Feedbacks.styles';
import { FeedbacksProps } from './Feedbacks.types';

export const Feedbacks = ({ feedbacks, hideActions }: FeedbacksProps) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const { isReadOnly } = useReadOnlyEmployee(employeeId);

  if (!feedbacks.length) {
    return null;
  }

  return (
    <Grid item sx={Styles.GridItem}>
      {feedbacks.map((feedback) =>
        feedback ? (
          <FeedbackCard key={feedback.id} feedback={feedback} isReadOnly={isReadOnly} hideActions={hideActions} />
        ) : null,
      )}
    </Grid>
  );
};
