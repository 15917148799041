import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeWithQuarterlyReportQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  quarterlyReportId: Types.Scalars['ID']['input'];
}>;


export type EmployeeWithQuarterlyReportQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, firstName: string, lastName: string, avatarUrl?: string | null, email: string, level?: string | null, department: string, currentProjects?: Array<string | null> | null, headFullName?: string | null, employmentType?: string | null, hiredDate: any, pieselyUrl?: string | null, luxUrl?: string | null, satisfactionLevel?: number | null, requestedBy?: Array<Types.AccessType | null> | null, supervisor?: { __typename?: 'Employee', firstName: string, lastName: string } | null } | null, quarterlyReport?: { __typename?: 'QuarterlyReport', id: string, summary: string, notes?: string | null, realizationDate: any, status?: Types.QuarterlyReportStatus | null, receiverId?: number | null, goalsCollection?: { __typename?: 'GoalCollection', items: Array<{ __typename?: 'Goal', id: string, content?: string | null, createdAt: any, realizationDate?: any | null, goalStatus?: Types.GoalStatus | null, quarterlyReportId: string } | null> } | null } | null };


export const EmployeeWithQuarterlyReportDocument = gql`
    query employeeWithQuarterlyReport($employeeId: Int!, $quarterlyReportId: ID!) {
  employee(id: $employeeId) {
    id
    firstName
    lastName
    avatarUrl
    email
    level
    department
    currentProjects
    headFullName
    supervisor {
      firstName
      lastName
    }
    employmentType
    hiredDate
    pieselyUrl
    luxUrl
    satisfactionLevel
    requestedBy
  }
  quarterlyReport(id: $quarterlyReportId) {
    id
    summary
    notes
    realizationDate
    status
    goalsCollection {
      items {
        id
        content
        createdAt
        realizationDate
        goalStatus
        quarterlyReportId
      }
    }
    receiverId
  }
}
    `;

/**
 * __useEmployeeWithQuarterlyReportQuery__
 *
 * To run a query within a React component, call `useEmployeeWithQuarterlyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeWithQuarterlyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeWithQuarterlyReportQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      quarterlyReportId: // value for 'quarterlyReportId'
 *   },
 * });
 */
export function useEmployeeWithQuarterlyReportQuery(baseOptions: Apollo.QueryHookOptions<EmployeeWithQuarterlyReportQuery, EmployeeWithQuarterlyReportQueryVariables> & ({ variables: EmployeeWithQuarterlyReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeWithQuarterlyReportQuery, EmployeeWithQuarterlyReportQueryVariables>(EmployeeWithQuarterlyReportDocument, options);
      }
export function useEmployeeWithQuarterlyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeWithQuarterlyReportQuery, EmployeeWithQuarterlyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeWithQuarterlyReportQuery, EmployeeWithQuarterlyReportQueryVariables>(EmployeeWithQuarterlyReportDocument, options);
        }
export function useEmployeeWithQuarterlyReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmployeeWithQuarterlyReportQuery, EmployeeWithQuarterlyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmployeeWithQuarterlyReportQuery, EmployeeWithQuarterlyReportQueryVariables>(EmployeeWithQuarterlyReportDocument, options);
        }
export type EmployeeWithQuarterlyReportQueryHookResult = ReturnType<typeof useEmployeeWithQuarterlyReportQuery>;
export type EmployeeWithQuarterlyReportLazyQueryHookResult = ReturnType<typeof useEmployeeWithQuarterlyReportLazyQuery>;
export type EmployeeWithQuarterlyReportSuspenseQueryHookResult = ReturnType<typeof useEmployeeWithQuarterlyReportSuspenseQuery>;
export type EmployeeWithQuarterlyReportQueryResult = Apollo.QueryResult<EmployeeWithQuarterlyReportQuery, EmployeeWithQuarterlyReportQueryVariables>;