import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const TabsWrapper: Styles = {
  width: '100%',
};

export const Tab: Styles = {
  flex: 1,
};

export const TabPanel = (hidden: boolean): Styles => ({
  flex: 1,
  width: '100%',
  display: hidden ? 'none' : 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'auto',
});

export const TabsContentWrapper: Styles = {
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

export const ListTemplates: Styles = {
  flex: 1,
  maxHeight: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  alignItems: 'center',
  overflow: 'auto',
  paddingRight: '8px',
  paddingTop: '5px',

  '&::-webkit-scrollbar': {
    borderRadius: '8px',
    width: '8px',
    backgroundColor: colors.brightGray,
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundClip: 'padding-box',
    border: `2px solid ${colors.brightGray}`,
    backgroundColor: colors.slateGray,
  },
};

export const Template: Styles = {
  border: `1px solid ${colors.hawkesBlue}`,
  borderRadius: '4px',
  p: '16px',
  backgroundColor: 'common.white',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: colors.ghostWhite,
  },
};

export const TemplateText: Styles = {
  width: 440,
};

export const CreationDate: Styles = {
  color: colors.slateGray,
  mt: '4px',
};

const IconButton: Styles = {
  '& svg': {
    width: '24px',
    height: '24px',
  },
};

export const DeleteIconButton: Styles = {
  ...IconButton,
  '&:hover path': {
    stroke: colors.tomato,
  },
};

export const EditIconButton: Styles = {
  ...IconButton,
  '&:hover path': {
    fill: colors.blueberry,
  },
};

export const AssignmentReturnedIcon: Styles = {
  ...IconButton,
  '&:hover path': {
    fill: colors.blueberry,
  },
};

export const SurveysListContainer: Styles = {
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  flex: 1,

  ':hover': {
    cursor: 'pointer',
  },
};

export const SortSelect: Styles = {
  m: 1,
  minWidth: 220,
  borderRadius: '8px',
};
export const SortSelectContainer: Styles = {
  alignSelf: 'flex-end',
};
