import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Wrapper: Styles = {
  display: 'flex',
  flexDirection: 'column',
};

export const InputRow: Styles = {
  display: 'flex',
  alignItems: 'flex-start',
  mb: 2,
};

export const InputWrapper: Styles = { width: '100%' };

export const AnswersWrapper: Styles = {};

export const DragIconButton: Styles = {
  ml: -1,
  mr: 1,
  mt: 0.5,
};

export const DragIcon: Styles = {
  fontSize: '2rem',
  rotate: '90deg',
  color: colors.slateGray,
};

export const DeleteIconButton: Styles = {
  ml: 1,
  mr: -1,
  mt: 0.5,
  p: 1.5,

  ':hover': {
    cursor: 'pointer',
  },
};

export const DeleteIcon = {
  fontSize: '2rem',
  color: colors.navy,
};

const INPUT_PADDING = 2;

export const TextField: Styles = {
  width: 'auto',
  backgroundColor: colors.white,
  p: INPUT_PADDING,
  borderRadius: 1,
  border: 'none',

  div: {
    marginTop: '0 !important',
    paddingBottom: '0 !important',

    input: {
      paddingBottom: 0,
    },
  },

  textarea: {
    fontSize: 16,
  },

  ':hover': {
    backgroundColor: colors.ghostWhite,
  },

  '&:has(.Mui-focused)': {
    backgroundColor: colors.white,
    border: `1px solid ${colors.blueberry}`,
    p: INPUT_PADDING - 1 / 8,
    borderRadius: '4px 4px 0 0',
  },

  '&:has(.Mui-error)': {
    border: `1px solid ${colors.tomato}`,
    p: INPUT_PADDING - 1 / 8,
  },
};

export const FormControl: Styles = {
  whiteSpace: 'pre',

  '&:has(.Mui-focused) + .MuiAlert-root': {
    display: 'flex',
  },
};

export const Adornment: Styles = {
  whiteSpace: 'pre',
};

export const Alert: Styles = {
  borderRadius: '0 0 4px 4px',
  textAlign: 'left',
};

export const TextBold: Styles = { fontWeight: 700 };

export const AnswersFormatWrapper: Styles = {
  px: 5,
};
