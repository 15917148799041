import { alpha } from '@mui/material';

import { Styles } from 'shared/types';
import { theme } from 'shared/utils';

export const Label: Styles = {
  position: 'absolute',
  paddingLeft: '9px',
  lineHeight: '24px',
  transform: 'translate(0, 16px) scale(1)',

  '&.Size-medium': {
    transform: 'translate(0, 17.5px) scale(1)',
  },

  '&.MuiInputLabel-shrink': {
    transform: 'translate(0, -4px) scale(0.75)',
  },

  '&.MuiInputLabel-filled': {
    transform: 'translate(14px, 12px) scale(1)',
  },

  '&.MuiInputLabel-filled.Size-medium': {
    transform: 'translate(12px, 15.5px) scale(1)',
  },

  '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
    transform: 'translate(12px, 6px) scale(0.75)',
    lineHeight: '12px',
  },

  '&.MuiInputLabel-shrink.MuiInputLabel-filled.Size-medium': {
    transform: 'translate(12px, 9px) scale(0.75)',
  },

  '&.MuiInputLabel-outlined': {
    transform: 'translate(12px, 8px) scale(1)',
  },

  '&.MuiInputLabel-outlined.Size-medium': {
    transform: 'translate(12px, 16px) scale(1)',
  },

  '&.MuiInputLabel-shrink.MuiInputLabel-outlined': {
    transform: 'translate(16px, -9px) scale(0.75)',
  },

  '&.MuiInputLabel-shrink.MuiInputLabel-outlined.Size-medium': {
    transform: 'translate(17px, -9px) scale(0.75)',
  },

  '&:not(.MuiInputLabel-shrink)': {
    fontSize: 16,
  },

  '&.Mui-focused': {
    color: 'blueberry',
  },
};

export const LabelError: Styles = {
  ...Label,

  '&.MuiInputLabel-shrink': {
    color: 'tomato',
    transform: 'translate(0, -4px) scale(0.75)',
  },

  '&.MuiInputLabel-shrink.MuiInputLabel-filled': {
    lineHeight: '12px',
    transform: 'translate(12px, 6px) scale(0.75)',
  },

  '&.Mui-focused.MuiInputLabel-shrink': {
    color: 'tomato',
  },
};

export const Select: Styles = {
  '&.MuiFilledInput-root .MuiSelect-icon': {
    top: 15.5,
  },

  '&.MuiFilledInput-root.MuiInputBase-sizeSmall .MuiSelect-icon': {
    top: 12,
  },

  '&.MuiFilledInput-root.MuiFilledInput-underline': {
    p: 0,
    marginTop: 0,
  },

  '&.MuiFilledInput-root.MuiInputBase-adornedStart': {
    paddingLeft: 1.5,
  },

  '&.MuiFilledInput-root.Mui-focused:before': {
    borderWidth: 2,
    borderColor: 'blueberry',
  },

  '&.MuiFilledInput-root.Mui-error:before': {
    borderWidth: 2,
  },

  '&.MuiFilledInput-root + p.MuiFormHelperText-root': {
    paddingLeft: '14px',
  },

  '&.MuiOutlinedInput-root.MuiInputBase-adornedStart': {
    paddingLeft: 1.5,
  },

  '&.MuiOutlinedInput-root.MuiInputBase-root': {
    paddingBottom: 0,
    marginTop: 0,
  },

  '&.MuiOutlinedInput-root + p.MuiFormHelperText-root': {
    paddingLeft: 1.75,
  },

  '&.MuiInputBase-sizeSmall .MuiSelect-filled': {
    padding: '20px 32px 4px 12px',
    lineHeight: '24px',
  },

  '&.MuiInputBase-adornedStart': {
    alignItems: 'baseline',
    gap: '9px',
    color: alpha(theme.palette.text.primary, 0.54),
  },

  '&.MuiInputBase-adornedStart .MuiSelect-select': {
    color: theme.palette.text.primary,
  },

  '&.MuiInput-root .MuiSelect-select': {
    fontSize: 16,
  },

  '&.Mui-error + .MuiFormHelperText-root': {
    color: 'tomato',
  },

  '& .MuiSelect-standard': {
    py: 0,
    lineHeight: '24px',
  },

  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent',
  },

  '& .MuiSelect-icon': {
    top: 'auto',
  },

  '& .MuiSelect-filled': {
    padding: '24px 32px 8px 12px',
    lineHeight: '24px',
  },

  '& .MuiSelect-filled.MuiInputBase-inputAdornedStart': {
    paddingLeft: 0,
  },
};

export const HelperText: Styles = {
  mx: 0,
  lineHeight: '20px',
};
