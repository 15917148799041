import { TableUpdateRowHandlerContext } from './TodoRowContext';
import { TodoRowControllerProps } from './TodoRowController.types';

export const TodoRowController = ({ todoRowContextValue, children }: TodoRowControllerProps) => {
  return (
    <TableUpdateRowHandlerContext.Provider value={todoRowContextValue}>
      {children}
    </TableUpdateRowHandlerContext.Provider>
  );
};
