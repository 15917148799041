import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Title: Styles = {
  p: 0,
  mb: 2,
};

export const ButtonsContainer: Styles = {
  display: 'flex',
  gap: 3,
};

export const Button: Styles = {
  flexBasis: '50%',
};

export const WhiteSecondaryButton: Styles = {
  ...Button,
  background: colors.white,
  border: `1px solid ${colors.blueberry}`,
};

export const ContentContainer: Styles = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
};
