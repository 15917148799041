import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { useEmployeeQuery } from 'documents/employee.generated';

import { UseEmployeeParams, UseEmployeeReturn } from './useEmployee.types';

export const useEmployee = (params?: UseEmployeeParams): UseEmployeeReturn => {
  const { skip, onError } = params || {};
  const { employeeId } = useValidatedParams(['employeeId']);
  const { data, loading: isEmployeeDataLoading } = useEmployeeQuery({
    variables: {
      employeeId,
    },
    skip,
    onError: (error) => {
      toast.error(error.message);
      onError?.();
    },
  });

  const employeeData = data?.employee;
  return { employeeData, isEmployeeDataLoading };
};
