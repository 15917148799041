import { SentSurveyListItem, SurveysListTab } from '../SurveyTemplatesTabs.types';
import { SortVariables, SurveyTabProps } from '../SurveyTabs.types';
import { TabContainer } from '../components/TabContainer';
import { SurveySortingOptions } from '../SurveyTabs.utils';
import { useSurveyTabsSortingContext } from '../SurveyTabsSorting/useSurveyTabsSortingContext';

import { SentSurveysList } from './sentSurveysList/SentSurveysList';

type SentTabProps = {
  onChangeCurrentTab: (value: SurveysListTab) => void;
  items: SentSurveyListItem[];
} & SurveyTabProps;
export const SentTab = ({ onChangeCurrentTab, items, ...rest }: SentTabProps) => {
  const { setSortingParams } = useSurveyTabsSortingContext();

  const handleChangeSorting = (sortData: SortVariables) => {
    setSortingParams((prevTabsSortingData) => ({ ...prevTabsSortingData, [SurveysListTab.Sent]: sortData }));
  };

  return (
    <TabContainer sortingOptions={SurveySortingOptions} onChangeSorting={handleChangeSorting} {...rest}>
      <SentSurveysList changeCurrentTab={onChangeCurrentTab} items={items.filter(Boolean)} />
    </TabContainer>
  );
};
