import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from 'assets/images/DeleteIcon.svg';
import { SurveyResponseStatus } from 'generated/webapp_gql';

import * as Styles from './DeleteRespondentButton.styles';
import { DeleteRespondentButtonProps } from './DeleteRespondentButton.types';

export const DeleteRespondentButton = ({ status, respondentId, onClick }: DeleteRespondentButtonProps) => {
  const { t } = useTranslation();

  const isSurveyAnswered = [SurveyResponseStatus.Answered, SurveyResponseStatus.Reattempted].includes(status);

  return (
    <Tooltip
      role="tooltip"
      title={
        <Box whiteSpace="pre-line">
          {isSurveyAnswered
            ? t('surveyBuilder.publicationSettings.surveyAnswered')
            : t('surveyBuilder.publicationSettings.undoSurveySend')}
        </Box>
      }
      arrow
      placement="top"
    >
      <Box component="span">
        <IconButton
          data-testid={`${respondentId}Delete`}
          onClick={onClick}
          disabled={isSurveyAnswered}
          aria-label="delete"
          sx={Styles.DeleteButton}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
