import { ApolloProvider } from '@apollo/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';

import { client } from 'apollo-client';
import { AuthContextController } from 'context';
import { msalConfig } from 'msalConfig';
import { ScrollToTop, theme } from 'shared/utils';

import { AppProvidersProps } from './AppProviders.types';

export const publicClientApplication = new PublicClientApplication(msalConfig);

export const AppProviders = ({ children }: AppProvidersProps) => (
  <MsalProvider instance={publicClientApplication}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <AuthContextController>
          <Router>
            <ScrollToTop />
            {children}
          </Router>
        </AuthContextController>
      </ApolloProvider>
    </ThemeProvider>
  </MsalProvider>
);
