import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveyForRespondentQueryVariables = Types.Exact<{
  urlHash: Types.Scalars['String']['input'];
}>;


export type SurveyForRespondentQuery = { __typename?: 'Query', surveyForRespondent?: { __typename?: 'SurveyForRespondent', concernedAvatarUrl?: string | null, concernedFullName: string, surveyOwnerFullName: string, surveyOwnerAvatarUrl?: string | null, endImageUrl?: string | null, endImageName?: string | null, introImageUrl?: string | null, introImageName?: string | null, language: Types.Language, content: { __typename?: 'SurveyContent', beginning?: string | null, conclusion?: string | null, sections: Array<{ __typename?: 'Section', title: string, description?: string | null, questions: Array<{ __typename?: 'SurveyQuestion', question: string, format: Types.SurveyQuestionFormat, answers?: any | null } | null> } | null> }, response?: Array<{ __typename?: 'Answer', answer?: Array<string> | null, format: Types.SurveyQuestionFormat, question: string } | null> | null } | null };


export const SurveyForRespondentDocument = gql`
    query SurveyForRespondent($urlHash: String!) {
  surveyForRespondent(urlHash: $urlHash) {
    concernedAvatarUrl
    concernedFullName
    surveyOwnerFullName
    surveyOwnerAvatarUrl
    endImageUrl
    endImageName
    introImageUrl
    introImageName
    language
    content {
      beginning
      conclusion
      sections {
        title
        description
        questions {
          question
          format
          answers
        }
      }
    }
    response {
      answer
      format
      question
    }
  }
}
    `;

/**
 * __useSurveyForRespondentQuery__
 *
 * To run a query within a React component, call `useSurveyForRespondentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyForRespondentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyForRespondentQuery({
 *   variables: {
 *      urlHash: // value for 'urlHash'
 *   },
 * });
 */
export function useSurveyForRespondentQuery(baseOptions: Apollo.QueryHookOptions<SurveyForRespondentQuery, SurveyForRespondentQueryVariables> & ({ variables: SurveyForRespondentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyForRespondentQuery, SurveyForRespondentQueryVariables>(SurveyForRespondentDocument, options);
      }
export function useSurveyForRespondentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyForRespondentQuery, SurveyForRespondentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyForRespondentQuery, SurveyForRespondentQueryVariables>(SurveyForRespondentDocument, options);
        }
export function useSurveyForRespondentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveyForRespondentQuery, SurveyForRespondentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveyForRespondentQuery, SurveyForRespondentQueryVariables>(SurveyForRespondentDocument, options);
        }
export type SurveyForRespondentQueryHookResult = ReturnType<typeof useSurveyForRespondentQuery>;
export type SurveyForRespondentLazyQueryHookResult = ReturnType<typeof useSurveyForRespondentLazyQuery>;
export type SurveyForRespondentSuspenseQueryHookResult = ReturnType<typeof useSurveyForRespondentSuspenseQuery>;
export type SurveyForRespondentQueryResult = Apollo.QueryResult<SurveyForRespondentQuery, SurveyForRespondentQueryVariables>;