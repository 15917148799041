import { ApolloCache } from '@apollo/client';

import { Employee, Goal, QuarterlyReport, QuarterlyReportCollection } from 'generated/webapp_gql';
import {
  QuarterlyReportsDocument,
  QuarterlyReportsQuery,
  QuarterlyReportsQueryVariables,
} from 'documents/quarterlyReports.generated';
import { QUARTERLY_REPORTS_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';
import {
  EmployeeWithGoalsDocument,
  EmployeeWithGoalsQuery,
  EmployeeWithGoalsQueryVariables,
} from 'documents/employeeWithGoals.generated';

const DEFAULT_OFFSET = 0;

export class UpdateReportsList {
  private readonly cache: ApolloCache<unknown>;
  private readonly newReport: QuarterlyReport;
  private readonly employeeId: number;
  private readonly newGoals: Goal[] | undefined;

  constructor(cache: ApolloCache<unknown>, newReport: QuarterlyReport, employeeId: number, newGoals?: Goal[]) {
    this.cache = cache;
    this.newReport = newReport;
    this.employeeId = employeeId;
    this.newGoals = newGoals;
  }

  private getQueryOptions() {
    const baseQueryOptions = {
      query: QuarterlyReportsDocument,
      variables: <QuarterlyReportsQueryVariables>{
        employeeId: this.employeeId,
        pagination: {
          offset: DEFAULT_OFFSET,
          limit: QUARTERLY_REPORTS_PAGE_SIZE,
        },
      },
    };

    const employeeWithGoalsQueryOptions = {
      query: EmployeeWithGoalsDocument,
      variables: <EmployeeWithGoalsQueryVariables>{
        employeeId: this.employeeId,
      },
    };

    return {
      baseQueryOptions,
      employeeWithGoalsQueryOptions,
    };
  }

  private getUpdatedEmployeeGoals(employee: Employee) {
    const goalsWithDuplicates = [
      ...(employee.goalsCollection?.items ?? []),
      ...(this.newReport.goalsCollection?.items ?? []),
      ...(this.newGoals ?? []),
    ];

    return Array.from(
      goalsWithDuplicates
        .reduce<Map<Goal['id'], Goal>>((map, obj) => {
          if (!obj) {
            return map;
          }
          return map.set(obj.id, obj);
        }, new Map())
        .values(),
    );
  }

  private getCachedQuarterlyReports() {
    const { baseQueryOptions } = this.getQueryOptions();
    return <QuarterlyReportCollection>this.cache.readQuery<QuarterlyReportsQuery>(baseQueryOptions)?.quarterlyReports;
  }

  private modifyQuarterlyReportsCache({ ...args }: Partial<QuarterlyReportCollection>) {
    const { baseQueryOptions } = this.getQueryOptions();
    const cachedQuarterlyReports = this.getCachedQuarterlyReports();

    this.cache.writeQuery<QuarterlyReportsQuery>({
      ...baseQueryOptions,
      data: {
        quarterlyReports: {
          ...cachedQuarterlyReports,
          ...args,
        },
      },
    });
  }

  private updateEmployeeCache() {
    const { employeeWithGoalsQueryOptions } = this.getQueryOptions();

    this.cache.updateQuery<EmployeeWithGoalsQuery>(employeeWithGoalsQueryOptions, (data) => {
      if (!data || !data.employee) {
        return;
      }

      const goals = this.getUpdatedEmployeeGoals(<Employee>data.employee);

      return {
        employee: {
          ...data.employee,
          goalsCollection: data.employee.goalsCollection
            ? {
                ...data.employee.goalsCollection,
                items: goals,
              }
            : undefined,
        },
      };
    });
  }

  public onUpdate() {
    const cachedQuarterlyReports = this.getCachedQuarterlyReports();

    if (!cachedQuarterlyReports) {
      return;
    }

    const items = [...cachedQuarterlyReports.items];
    const updatedReportIndex = items.findIndex((feedback) => feedback?.id === this.newReport.id);

    items[updatedReportIndex] = {
      ...this.newReport,
      goalsCollection: this.newReport.goalsCollection
        ? {
            ...this.newReport.goalsCollection,
            items: [...(this.newReport.goalsCollection?.items ?? []), ...(this.newGoals as Goal[])],
          }
        : undefined,
    };

    items.sort((a, b) => new Date(b?.realizationDate).getTime() - new Date(a?.realizationDate).getTime());

    this.modifyQuarterlyReportsCache({ items });
    this.updateEmployeeCache();
  }

  public onCreate() {
    const cachedQuarterlyReports = this.getCachedQuarterlyReports();

    if (!cachedQuarterlyReports) {
      return;
    }

    const items = [this.newReport, ...cachedQuarterlyReports.items.slice(0, QUARTERLY_REPORTS_PAGE_SIZE - 1)].sort(
      (a, b) => new Date(b?.realizationDate).getTime() - new Date(a?.realizationDate).getTime(),
    );
    const total = cachedQuarterlyReports.total + 1;

    this.modifyQuarterlyReportsCache({ items, total });
    this.updateEmployeeCache();
  }
}
