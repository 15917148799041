import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Employees } from 'app/employees/Employees';
import { Login } from 'app/login/Login';
import { AddWeeklyFeedbackFormContainer } from 'app/weeklyFeedbackForm/addWeeklyFeedbackFormContainer/AddWeeklyFeedbackFormContainer';
import { EditWeeklyFeedbackFormContainer } from 'app/weeklyFeedbackForm/editWeeklyFeedbackFormContainer/EditWeeklyFeedbackFormContainer';
import { EmployeeActions } from 'app/employeeActions/EmployeeActions';
import { SurveyTemplates } from 'app/surveyTemplates/SurveyTemplates';
import { SurveyBuilderLayout } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout';
import { SurveyEditorLayout } from 'app/surveyEditor/surveyEditorLayout/SurveyEditorLayout';
import { EmployeeGoals } from 'app/employeeGoals/EmployeeGoals';
import { EmployeeDetails } from 'app/employeeDetails/EmployeeDetails';
import { PreviewWeekly } from 'app/previewWeekly/PreviewWeekly';
import { PreviewQuarterly } from 'app/previewQuarterly/PreviewQuarterly';
import { EditQuarterlyReportFormContainer } from 'app/quarterlyReportForm/editQuarterlyReportFormContainer/EditQuarterlyReportFormContainer';
import { AddQuarterlyReportFormContainer } from 'app/quarterlyReportForm/addQuarterlyReportFormContainer/AddQuarterlyReportFormContainer';
import { SurveyLayout } from 'app/survey/surveyLayout/SurveyLayout';
import { SurveyForm } from 'app/surveyForm/SurveyForm';
import { EmployeeSurveys } from 'app/employeeSurveys/EmployeeSurveys';

import { AppRoute, AppRouteParam } from './AppRoute.enum';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { GuardedEmployeeRoute } from './GuardedEmployeeRoute';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes = () => (
  <SentryRoutes>
    <Route path={AppRoute.Login} element={<Login />} />
    <Route path={AppRoute.SurveyForm} element={<SurveyForm />} />
    <Route path={AppRoute.Home} element={<AuthenticatedRoute />}>
      <Route index element={<Employees />} />
      <Route path={AppRoute.EmployeeDetails} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`${AppRouteParam.EmployeeId}/${AppRouteParam.EmployeeDetailsView}`}
          element={<EmployeeDetails />}
        />
      </Route>
      <Route path={AppRoute.PreviewFeedback} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route path={`${AppRouteParam.FeedbackId}/${AppRouteParam.EmployeeId}`} element={<PreviewWeekly />} />
      </Route>
      <Route path={AppRoute.AddFeedback} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route path={AppRouteParam.EmployeeId} element={<AddWeeklyFeedbackFormContainer />} />
      </Route>
      <Route path={AppRoute.EditFeedback} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`${AppRouteParam.FeedbackId}/${AppRouteParam.EmployeeId}`}
          element={<EditWeeklyFeedbackFormContainer />}
        />
      </Route>
      <Route path={AppRoute.PreviewReport} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route path={`${AppRouteParam.ReportId}/${AppRouteParam.EmployeeId}`} element={<PreviewQuarterly />} />
      </Route>
      <Route path={AppRoute.AddReport} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route path={AppRouteParam.EmployeeId} element={<AddQuarterlyReportFormContainer />} />
      </Route>
      <Route path={AppRoute.EditReport} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`${AppRouteParam.ReportId}/${AppRouteParam.EmployeeId}`}
          element={<EditQuarterlyReportFormContainer />}
        />
      </Route>
      <Route path={AppRoute.EmployeeActions} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route path={AppRouteParam.EmployeeId} element={<EmployeeActions />} />
      </Route>
      <Route path={AppRoute.EmployeeGoals} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route path={AppRouteParam.EmployeeId} element={<EmployeeGoals />} />
      </Route>
      <Route path={AppRoute.EmployeeSurveys} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={AppRouteParam.EmployeeId}
          element={
            <GuardedEmployeeRoute>
              <EmployeeSurveys />
            </GuardedEmployeeRoute>
          }
        />
      </Route>
      <Route path={AppRoute.SurveyTemplates} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={AppRouteParam.EmployeeId}
          element={
            <GuardedEmployeeRoute>
              <SurveyTemplates />
            </GuardedEmployeeRoute>
          }
        />
      </Route>
      <Route path={AppRoute.SurveyBuilder} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`${AppRouteParam.EmployeeId}/${AppRouteParam.SurveyTemplateId}`}
          element={
            <GuardedEmployeeRoute>
              <SurveyBuilderLayout />
            </GuardedEmployeeRoute>
          }
        />
      </Route>
      <Route path={AppRoute.SurveyEditor} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`employee/${AppRouteParam.EmployeeId}/survey-template/${AppRouteParam.SurveyTemplateId}/survey/${AppRouteParam.SurveyId}`}
          element={
            <GuardedEmployeeRoute>
              <SurveyEditorLayout />
            </GuardedEmployeeRoute>
          }
        />
      </Route>
      <Route path={AppRoute.PublishedSurvey} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`${AppRouteParam.EmployeeId}/${AppRouteParam.SurveyId}`}
          element={
            <GuardedEmployeeRoute>
              <SurveyLayout />
            </GuardedEmployeeRoute>
          }
        />
      </Route>
      <Route path={AppRoute.Survey} element={<Outlet />}>
        <Route index element={<Navigate to={AppRoute.Home} />} />
        <Route
          path={`${AppRouteParam.EmployeeId}/${AppRouteParam.SurveyTemplateId}`}
          element={
            <GuardedEmployeeRoute>
              <SurveyLayout />
            </GuardedEmployeeRoute>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to={AppRoute.Home} />} />
    </Route>
  </SentryRoutes>
);
