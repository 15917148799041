import { GridSortModel } from '@mui/x-data-grid';

import { UseSearchParamReturn } from 'hooks/useSearchParam/useSearchParam.types';
import { SortDirection } from 'shared/types/enums/SortDirection.enum';

export const handleSortModelChange =
  (onSortByChange: UseSearchParamReturn<[string, SortDirection] | null>[1]) => (gridSortModel: GridSortModel) => {
    const sortValues = gridSortModel[0];

    onSortByChange(sortValues ? <[string, SortDirection]>Object.values(sortValues) : null);
  };
