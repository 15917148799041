import { Dispatch, SetStateAction, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { Section } from 'app/surveyBuilder/surveyBuilderQuestions/SurveyBuilderQuestions.enum';
import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { FileToUpload } from 'ui/uploadImageModal/UploadImageModal.types';

export const useSurveyImages = (
  setFormValue: UseFormSetValue<SurveyFormData>,
  setForceUpdate: Dispatch<SetStateAction<number>>,
) => {
  const [openedModal, setOpenedModal] = useState<Section | null>(null);
  const [introImage, setIntroImage] = useState<FileToUpload | null>(null);
  const [outroImage, setOutroImage] = useState<FileToUpload | null>(null);

  const updateIntroImageName = (name?: string) => {
    setIntroImage(
      (prev) =>
        <FileToUpload>{
          ...prev,
          imageS3Name: name,
        },
    );
    setFormValue('introImageName', name);
  };

  const updateOutroImageName = (name: string) => {
    setOutroImage((prev) => <FileToUpload>{ ...prev, imageS3Name: name });
    setFormValue('endImageName', name);
  };

  const handleIntroImageChange = (img: FileToUpload | null) => {
    setIntroImage(img);
    setForceUpdate((prev) => prev + 1);
    setFormValue('introImageUrl', img?.preview, { shouldDirty: true });
    setFormValue('introImageName', img?.imageS3Name, { shouldDirty: true });
  };

  const handleOutroImageChange = (img: FileToUpload | null) => {
    setOutroImage(img);
    setForceUpdate((prev) => prev + 1);
    setFormValue('endImageUrl', img?.preview, { shouldDirty: true });
    setFormValue('endImageName', img?.imageS3Name, { shouldDirty: true });
  };

  return {
    openedModal,
    setOpenedModal,
    introImage,
    handleIntroImageChange,
    outroImage,
    handleOutroImageChange,
    updateIntroImageName,
    updateOutroImageName,
  };
};
