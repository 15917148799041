import { toast } from 'react-toastify';
import { getOperationName } from '@apollo/client/utilities';
import { useTranslation } from 'react-i18next';

import { useCreateActionMutation } from 'documents/createAction.generated';
import { EmployeeDetailsWeeklyDocument } from 'documents/employeeDetailsWeekly.generated';
import { Toast } from 'ui';

import { UseAddActionProps } from './useAddAction.types';

export const useAddAction = ({ onSuccess }: UseAddActionProps = {}) => {
  const { t } = useTranslation();

  const [createAction, { loading: isCreatingAction }] = useCreateActionMutation({
    onCompleted: () => {
      onSuccess?.();
      toast.success(<Toast title={t('actions.added')} message={t('actions.addedMessage')} />);
    },
    onError: (error) => toast.error(error.message),
    refetchQueries: [getOperationName(EmployeeDetailsWeeklyDocument) || ''],
  });

  return { isCreatingAction, createAction };
};
