import { toast } from 'react-toastify';

import { useAddSurveyResponseMutation } from 'documents/addSurveyResponse.generated';

export const useAddSurveyResponse = () => {
  const [addSurveyResponse, { loading }] = useAddSurveyResponseMutation({
    onError: (error) => toast.error(error.message),
  });

  return {
    addSurveyResponse,
    isAddSurveyResponseLoading: loading,
  };
};
