import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WeeklyFeedbackQueryVariables = Types.Exact<{
  feedbackId: Types.Scalars['ID']['input'];
}>;


export type WeeklyFeedbackQuery = { __typename?: 'Query', weeklyFeedback?: { __typename?: 'WeeklyFeedback', id: string, satisfactionScore: number, summary?: string | null, notes?: string | null, realizationDate: any, receiverId?: number | null, giverId?: number | null, projectSatisfactionScores?: Array<{ __typename?: 'ProjectSatisfaction', id: string, projectId: string, satisfactionScore: number, project: { __typename?: 'Project', projectName: string } } | null> | null, actionsCollection?: { __typename?: 'ActionCollection', items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null } | null> } | null } | null };


export const WeeklyFeedbackDocument = gql`
    query weeklyFeedback($feedbackId: ID!) {
  weeklyFeedback(id: $feedbackId) {
    id
    satisfactionScore
    summary
    notes
    realizationDate
    projectSatisfactionScores {
      id
      projectId
      satisfactionScore
      project {
        projectName
      }
    }
    actionsCollection {
      items {
        id
        content
        actionStatus
      }
    }
    receiverId
    giverId
  }
}
    `;

/**
 * __useWeeklyFeedbackQuery__
 *
 * To run a query within a React component, call `useWeeklyFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyFeedbackQuery({
 *   variables: {
 *      feedbackId: // value for 'feedbackId'
 *   },
 * });
 */
export function useWeeklyFeedbackQuery(baseOptions: Apollo.QueryHookOptions<WeeklyFeedbackQuery, WeeklyFeedbackQueryVariables> & ({ variables: WeeklyFeedbackQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeeklyFeedbackQuery, WeeklyFeedbackQueryVariables>(WeeklyFeedbackDocument, options);
      }
export function useWeeklyFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeeklyFeedbackQuery, WeeklyFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeeklyFeedbackQuery, WeeklyFeedbackQueryVariables>(WeeklyFeedbackDocument, options);
        }
export function useWeeklyFeedbackSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WeeklyFeedbackQuery, WeeklyFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WeeklyFeedbackQuery, WeeklyFeedbackQueryVariables>(WeeklyFeedbackDocument, options);
        }
export type WeeklyFeedbackQueryHookResult = ReturnType<typeof useWeeklyFeedbackQuery>;
export type WeeklyFeedbackLazyQueryHookResult = ReturnType<typeof useWeeklyFeedbackLazyQuery>;
export type WeeklyFeedbackSuspenseQueryHookResult = ReturnType<typeof useWeeklyFeedbackSuspenseQuery>;
export type WeeklyFeedbackQueryResult = Apollo.QueryResult<WeeklyFeedbackQuery, WeeklyFeedbackQueryVariables>;