export const MuiButtonGroup = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      root: {
        button: {
          borderColor: colors.navy,
          color: colors.navy,

          '&:hover': {
            borderColor: colors.navy,
          },

          '&.MuiButton-contained': {
            borderColor: colors.navy,
            backgroundColor: colors.navy,
            color: colors.white,
          },
        },
      },
      sizeSmall: {
        padding: '4px 10px',
      },
      sizeMedium: {
        padding: '6px 16px',
      },
      sizeLarge: {
        padding: '8px 22px',
      },
    },
  };
};
