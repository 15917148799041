import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid, GridCallbackDetails } from '@mui/x-data-grid';

import { Accordion, LoadingOverlay, NoEmployeesMessage } from 'ui';
import { AppRoute, EmployeeDetailsViewParam } from 'routing/AppRoute.enum';
import { useScrollToElement } from 'hooks';
import { getLocale } from 'i18n/i18n.utils';
import { SessionStorageKey } from 'shared/types';

import * as Styles from './EmployeesTable.styles';
import { EmployeesTableProps } from './EmployeesTable.types';
import {
  getEmployeesTableColumns,
  getReadOnlyEmployeesTableColumns,
} from './employeesTableColumns/employeesTableColumns';

export const PAGE_SIZE_OPTIONS = [10, 15, 30];

export const EmployeesTable = ({
  data,
  isLoading,
  accordionTitle,
  dataGridProps: { page, onPageChange, pageSize, onPageSizeChange, rowCount = 0, onSortModelChange },
  isPendingActionsColumnSortable,
  isReadOnly,
}: EmployeesTableProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const paginationRef = useRef<HTMLDivElement>(null);
  const { setShouldScroll } = useScrollToElement(paginationRef, data);

  return (
    <Accordion
      title={accordionTitle}
      sx={Styles.Accordion}
      content={
        <DataGrid
          rows={data ?? []}
          columns={
            isReadOnly ? getReadOnlyEmployeesTableColumns : getEmployeesTableColumns(isPendingActionsColumnSortable)
          }
          page={rowCount > 0 ? (page ?? 1) - 1 : 0}
          onPageChange={(nextPage: number, details: GridCallbackDetails) => {
            onPageChange?.(nextPage + 1, details);
            setShouldScroll(true);
          }}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          paginationMode="server"
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          rowCount={rowCount}
          loading={isLoading}
          sortingMode="server"
          onSortModelChange={onSortModelChange}
          onRowClick={(data) => {
            sessionStorage.setItem(SessionStorageKey.Search, search);
            navigate(`/${AppRoute.EmployeeDetails}/${data.row.id}/${EmployeeDetailsViewParam.Weekly}?page=1`);
          }}
          localeText={getLocale().dataGridLocaleText}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          disableColumnMenu
          sx={isLoading || (data && data.length !== 0) ? Styles.Grid : Styles.GridNoData}
          autoHeight
          headerHeight={60}
          rowHeight={72}
          components={{
            NoRowsOverlay: () => <NoEmployeesMessage />,
            LoadingOverlay: () => <LoadingOverlay />,
          }}
          componentsProps={{ pagination: { ref: paginationRef } }}
        />
      }
    />
  );
};
