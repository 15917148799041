import { ApolloClient, ApolloLink, concat, FieldFunctionOptions, HttpLink, InMemoryCache } from '@apollo/client';

import {
  ActionCollection,
  EmployeeActionsCollectionArgs,
  EmployeeGoalsCollectionArgs,
  GoalCollection,
  QueryEmployeesArgs,
  QuerySurveysArgs,
  SurveyCollection,
} from 'generated/webapp_gql';
import { msalConfig } from 'msalConfig';
import { publicClientApplication } from 'providers/AppProviders';
import { AppRoute } from 'routing/AppRoute.enum';
import { mergeSurveys, readSurveys } from 'shared/utils/typePolicies/surveysTypePolicies';

import { CustomFetchDeps, EmployeeRefCollection, FetchInput } from './apollo-client.types';
import { authStorage } from './context';

const abortController = new AbortController();

export const NOT_AUTHORIZED_MESSAGE = 'auth.not_authorized';

const fetchWithTokenRefresh = async (
  { fetch, window, authStorage }: CustomFetchDeps,
  uri: FetchInput,
  options?: RequestInit,
): Promise<Response> => {
  const initialFetchResponse = await fetch(uri, options);
  const initialFetchResponseJSON = await initialFetchResponse.clone().json();

  if (initialFetchResponseJSON?.errors?.[0].message !== NOT_AUTHORIZED_MESSAGE) {
    return initialFetchResponse;
  }

  if (!publicClientApplication.getAllAccounts()?.length) {
    authStorage.accessToken = null;
    authStorage.username = null;
    window.location.replace(`/${AppRoute.Login}`);
    return new Response(null, {
      status: 401,
    });
  }

  const account = publicClientApplication.getAllAccounts()[0];

  if (account) {
    const token = await publicClientApplication.acquireTokenSilent({
      scopes: [`${msalConfig.auth.clientId}/.default`],
      account: account,
    });
    authStorage.accessToken = token.accessToken;

    const newOptions = {
      ...options,
      headers: {
        ...options?.headers,
        authorization: `Bearer ${token.accessToken}`,
      },
    };
    return fetch(uri, newOptions);
  }

  return initialFetchResponse;
};

export const makeCustomFetch = (
  fetchFn: (uri: FetchInput, options?: RequestInit) => Promise<Response> = fetch,
  w = window,
  as = authStorage,
) => fetchWithTokenRefresh.bind(null, { fetch: fetchFn, window: w, authStorage: as });

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  fetch: makeCustomFetch(),
  fetchOptions: {
    mode: 'cors',
    signal: abortController.signal,
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = authStorage.accessToken;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    };
  });

  return forward(operation);
});

const getEmployeesItemsWithoutDuplicates = ({
  items,
  args,
  readField,
}: Pick<EmployeeRefCollection, 'items'> & Pick<FieldFunctionOptions<QueryEmployeesArgs>, 'args' | 'readField'>) => {
  const filteringCondition = (actionsInProgress: number | undefined) =>
    typeof actionsInProgress === 'number' &&
    (!!args?.withPendingActions ? actionsInProgress > 0 : actionsInProgress === 0);

  const dupesCheck = new Set();
  const itemsWithoutDupes = [];

  for (const item of items) {
    if (!item) {
      itemsWithoutDupes.push(undefined);
      continue;
    }

    const actionsInProgress = readField<number>({
      fieldName: 'actionsInProgress',
      from: item,
    });

    const isDuplicate = dupesCheck.has(item.__ref);

    if (isDuplicate || !filteringCondition(actionsInProgress)) {
      continue;
    }

    dupesCheck.add(item.__ref);

    itemsWithoutDupes.push(item);
  }

  return itemsWithoutDupes;
};

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          weeklyFeedback: {
            read(_, { args, toReference }) {
              if (!args) return;
              return toReference({
                __typename: 'WeeklyFeedback',
                id: args.id,
              });
            },
          },
          surveys: {
            keyArgs: ['surveyStatus', 'employeeId'],
            merge(
              existing: SurveyCollection | undefined,
              incoming: SurveyCollection,
              { args }: FieldFunctionOptions<Omit<QuerySurveysArgs, 'employeeId'>>,
            ) {
              return mergeSurveys(existing, incoming, args);
            },
            read(
              existing: SurveyCollection | undefined,
              { args }: FieldFunctionOptions<Omit<QuerySurveysArgs, 'employeeId'>>,
            ) {
              return readSurveys(existing, args);
            },
          },
          employees: {
            keyArgs: [
              'withPendingActions',
              'searchedValue',
              'withAdminMode',
              'withSubcontractorsMode',
              'filterByDepartments',
              'sort',
              'assignedToMe',
            ],
            merge(
              existing: EmployeeRefCollection | undefined,
              incoming: EmployeeRefCollection,
              { args, readField }: FieldFunctionOptions<QueryEmployeesArgs>,
            ) {
              if (args && typeof args.pagination?.offset !== 'number') {
                return incoming;
              }
              const mergedItems = existing ? existing.items.slice(0) : [];

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              incoming.items.forEach((item, index) => (mergedItems[args!.pagination!.offset! + index] = item));

              const mergedItemsWithoutDuplicates = args?.withAdminMode
                ? mergedItems
                : getEmployeesItemsWithoutDuplicates({
                    items: mergedItems,
                    args,
                    readField,
                  });

              return {
                ...incoming,
                items: mergedItemsWithoutDuplicates,
              };
            },
            read(
              existing: EmployeeRefCollection | undefined,
              { args, readField }: FieldFunctionOptions<QueryEmployeesArgs>,
            ) {
              if (
                !args ||
                !existing ||
                typeof args.pagination?.offset !== 'number' ||
                typeof args.pagination?.limit !== 'number'
              ) {
                return;
              }
              const { offset, limit } = args?.pagination;

              const items = existing.items?.slice(offset, offset + limit);

              if (!!args.withAdminMode) {
                return { ...existing, items };
              }

              if (items && items.filter(Boolean).length > Math.min(existing.total - 1, 0)) {
                const itemsWithoutDuplicates = getEmployeesItemsWithoutDuplicates({
                  items,
                  args,
                  readField,
                });

                return { ...existing, items: itemsWithoutDuplicates };
              }

              return { ...existing, items: [] };
            },
          },
        },
      },
      Employee: {
        fields: {
          goalsCollection: {
            keyArgs: ['goalStatus'],
            merge(
              existing: GoalCollection | undefined,
              incoming: GoalCollection,
              { args }: FieldFunctionOptions<EmployeeGoalsCollectionArgs>,
            ) {
              if (!args || !args.goalStatus || !args.pagination || typeof args.pagination?.offset !== 'number') {
                return incoming;
              }

              const mergedItems = existing ? existing.items.slice(0) : [];

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              incoming.items.forEach((item, index) => (mergedItems[args.pagination!.offset! + index] = item));

              return {
                ...incoming,
                items: mergedItems,
              };
            },
            read(
              existing: GoalCollection | undefined,
              { args }: FieldFunctionOptions<EmployeeGoalsCollectionArgs>,
            ): GoalCollection | void {
              if (
                !args ||
                !existing ||
                !args.goalStatus ||
                !args.pagination ||
                typeof args.pagination.offset !== 'number' ||
                typeof args.pagination.limit !== 'number'
              ) {
                return;
              }

              const items = existing.items.slice(
                args.pagination.offset,
                args.pagination.offset + args.pagination.limit,
              );

              if (!items) {
                return { ...existing, items: [] };
              }

              if (items.filter(Boolean).length > Math.min(existing.total - 1, 0)) {
                return { ...existing, items };
              }

              return { ...existing, items: [] };
            },
          },
          actionsCollection: {
            keyArgs: ['actionStatus'],
            merge(
              existing: ActionCollection | undefined,
              incoming: ActionCollection,
              { args }: FieldFunctionOptions<EmployeeActionsCollectionArgs>,
            ) {
              if (!args || !args.actionStatus || !args.pagination || typeof args.pagination?.offset !== 'number') {
                return incoming;
              }

              const mergedItems = existing
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  existing.items.slice(0)
                : [];

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              incoming.items.forEach((item, index) => (mergedItems[args.pagination!.offset! + index] = item));

              return {
                ...incoming,
                items: mergedItems,
              };
            },
            read(
              existing: ActionCollection | undefined,
              { args }: FieldFunctionOptions<EmployeeActionsCollectionArgs>,
            ): ActionCollection | void {
              if (
                !args ||
                !existing ||
                !args.actionStatus ||
                !args.pagination ||
                typeof args.pagination.offset !== 'number' ||
                typeof args.pagination.limit !== 'number'
              ) {
                return;
              }

              const items = existing.items.slice(
                args.pagination.offset,
                args.pagination.offset + args.pagination.limit,
              );

              if (!items) {
                return { ...existing, items: [] };
              }

              if (items.filter(Boolean).length > Math.min(existing.total - 1, 0)) {
                return { ...existing, items };
              }

              return { ...existing, items: [] };
            },
          },
        },
      },
    },
  }),
  link: concat(authMiddleware, httpLink),
});
