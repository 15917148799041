import { toast } from 'react-toastify';

import { useWeeklyFeedbackQuery } from 'documents/weeklyFeedback.generated';
import { WeeklyFeedback } from 'generated/webapp_gql';

export const useWeeklyFeedback = (feedbackId: string, options?: Parameters<typeof useWeeklyFeedbackQuery>[0]) => {
  const { data, loading: isWeeklyFeedbackLoading } = useWeeklyFeedbackQuery({
    variables: { feedbackId },
    onError: (error) => toast.error(error.message),
    ...options,
  });

  const weeklyFeedbackData = data?.weeklyFeedback as WeeklyFeedback;

  return {
    weeklyFeedbackData,
    isWeeklyFeedbackLoading,
  };
};
