import { SurveyResponseStatus } from 'generated/webapp_gql';
import { colors } from 'shared/utils';
import { TranslationsKeys } from 'i18n/i18n.types';

export const responseStatuses: Partial<
  Record<
    SurveyResponseStatus,
    {
      color: string;
      name: TranslationsKeys;
    }
  >
> = {
  [SurveyResponseStatus.Sent]: {
    color: colors.offlineGray,
    name: 'publishedSurvey.answersTab.receiversDialog.noAction',
  },
  [SurveyResponseStatus.Seen]: {
    color: colors.orange,
    name: 'publishedSurvey.answersTab.receiversDialog.read',
  },
  [SurveyResponseStatus.Answered]: {
    color: colors.green,
    name: 'publishedSurvey.answersTab.receiversDialog.answered',
  },
  [SurveyResponseStatus.Reattempted]: {
    color: colors.green,
    name: 'publishedSurvey.answersTab.receiversDialog.answered',
  },
};
