import { Box, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CollapseIcon } from 'assets/images/CollapseIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/DeleteIcon.svg';
import { ReactComponent as DragIndicatorIcon } from 'assets/images/DragIndicatorIcon.svg';
import { ReactComponent as ExpandIcon } from 'assets/images/ExpandIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/images/PlusIcon.svg';
import { RoundedIconButton } from 'ui';

import * as Styles from './QuestionsSection.styles';
import { QuestionsSectionProps } from './QuestionsSection.types';
import { RemoveSectionModal } from './removeSectionModal/RemoveSectionModal';

export const QuestionsSection = ({
  label,
  children,
  sectionsAmount,
  dragHandleProps,
  addSection,
  removeSection,
}: QuestionsSectionProps) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <>
      <Box sx={Styles.TopSection}>
        <Typography sx={Styles.Label} variant="subtitle2">
          {label}
        </Typography>
        {removeSection && addSection && dragHandleProps && (
          <Box sx={Styles.TopSectionButtons}>
            <Tooltip title={t('surveyBuilder.questions.removeSection')}>
              <Box>
                <RoundedIconButton
                  sx={Styles.DeleteIcon}
                  disabled={sectionsAmount === 1}
                  onClick={() => setIsModalOpened((state) => !state)}
                >
                  <DeleteIcon />
                </RoundedIconButton>
              </Box>
            </Tooltip>
            <Tooltip title={t('surveyBuilder.questions.collapseSection')}>
              <Box>
                <RoundedIconButton onClick={() => setCollapsed((state) => !state)}>
                  {collapsed ? <ExpandIcon /> : <CollapseIcon />}
                </RoundedIconButton>
              </Box>
            </Tooltip>
            <Tooltip title={t('surveyBuilder.questions.addSection')}>
              <Box>
                <RoundedIconButton onClick={addSection}>
                  <PlusIcon />
                </RoundedIconButton>
              </Box>
            </Tooltip>
            <Tooltip title={t('surveyBuilder.questions.moveSection')}>
              <Box sx={Styles.DragIndicatorIcon} {...dragHandleProps}>
                <DragIndicatorIcon />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box sx={Styles.ContentWrapper(collapsed)}>{children}</Box>
      {removeSection && isModalOpened && (
        <RemoveSectionModal onClose={() => setIsModalOpened(false)} removeSection={removeSection} />
      )}
    </>
  );
};
