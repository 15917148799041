import { SurveyTemplate } from 'generated/webapp_gql';

export enum SurveysListTab {
  Draft = 'Draft',
  Sent = 'Sent',
  Archived = 'Archived',
}

export type SurveyTemplateListItem = Pick<SurveyTemplate, 'title' | 'createdAt' | 'id'>;
export type SentSurveyListItem = Pick<SurveyTemplate, 'title' | 'latestSendDate' | 'id'>;
export type ArchivedSurveyListItem = Pick<SurveyTemplate, 'title' | 'latestSendDate' | 'id'>;

export type SurveyTemplatesListProps = {
  items?: SurveyTemplateListItem[];
  department: string;
};
export type SentSurveysListProps = {
  items?: SentSurveyListItem[];
  changeCurrentTab: (value: SurveysListTab) => void;
};

export type ArchivedSurveyListProps = {
  items?: ArchivedSurveyListItem[];
  changeCurrentTab: (value: SurveysListTab) => void;
};

export type TabsProps = {
  defaultTab: SurveysListTab;
};
