import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveyResponsesQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['ID']['input'];
}>;


export type SurveyResponsesQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', surveyTemplateId?: string | null, title: string, upcomingNotificationsDispatch?: any | null, responsesByDate?: Array<{ __typename?: 'ResponsesByDate', sentAt: any, respondents: { __typename?: 'SurveyRespondentsCollection', total: number, items: Array<{ __typename?: 'SurveyResponse', id: string, respondentEmail: any, status: Types.SurveyResponseStatus, surveyLink: string, respondent?: { __typename?: 'SurveyReceiver', firstName?: string | null, lastName?: string | null, avatarUrl?: string | null } | null } | null> } } | null> | null } | null };


export const SurveyResponsesDocument = gql`
    query SurveyResponses($surveyId: ID!) {
  survey(id: $surveyId) {
    surveyTemplateId
    title
    upcomingNotificationsDispatch
    responsesByDate {
      sentAt
      respondents {
        total
        items {
          id
          respondentEmail
          status
          surveyLink
          respondent {
            firstName
            lastName
            avatarUrl
          }
        }
      }
    }
  }
}
    `;

/**
 * __useSurveyResponsesQuery__
 *
 * To run a query within a React component, call `useSurveyResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyResponsesQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveyResponsesQuery(baseOptions: Apollo.QueryHookOptions<SurveyResponsesQuery, SurveyResponsesQueryVariables> & ({ variables: SurveyResponsesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyResponsesQuery, SurveyResponsesQueryVariables>(SurveyResponsesDocument, options);
      }
export function useSurveyResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyResponsesQuery, SurveyResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyResponsesQuery, SurveyResponsesQueryVariables>(SurveyResponsesDocument, options);
        }
export function useSurveyResponsesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveyResponsesQuery, SurveyResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveyResponsesQuery, SurveyResponsesQueryVariables>(SurveyResponsesDocument, options);
        }
export type SurveyResponsesQueryHookResult = ReturnType<typeof useSurveyResponsesQuery>;
export type SurveyResponsesLazyQueryHookResult = ReturnType<typeof useSurveyResponsesLazyQuery>;
export type SurveyResponsesSuspenseQueryHookResult = ReturnType<typeof useSurveyResponsesSuspenseQuery>;
export type SurveyResponsesQueryResult = Apollo.QueryResult<SurveyResponsesQuery, SurveyResponsesQueryVariables>;