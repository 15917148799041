import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AvailableSurveyTemplatesQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  sort?: Types.InputMaybe<Types.SortByCreatedAtInput>;
}>;


export type AvailableSurveyTemplatesQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', id: number, department: string, availableSurveyTemplatesCollection?: { __typename?: 'SurveyTemplateCollection', total?: number | null, items?: Array<{ __typename?: 'SurveyTemplate', createdAt: any, id: string, title: string } | null> | null } | null } | null };


export const AvailableSurveyTemplatesDocument = gql`
    query AvailableSurveyTemplates($employeeId: Int!, $sort: SortByCreatedAtInput) {
  employee(id: $employeeId) {
    availableSurveyTemplatesCollection(sort: $sort) {
      items {
        createdAt
        id
        title
      }
      total
    }
    id
    department
  }
}
    `;

/**
 * __useAvailableSurveyTemplatesQuery__
 *
 * To run a query within a React component, call `useAvailableSurveyTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSurveyTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSurveyTemplatesQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAvailableSurveyTemplatesQuery(baseOptions: Apollo.QueryHookOptions<AvailableSurveyTemplatesQuery, AvailableSurveyTemplatesQueryVariables> & ({ variables: AvailableSurveyTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableSurveyTemplatesQuery, AvailableSurveyTemplatesQueryVariables>(AvailableSurveyTemplatesDocument, options);
      }
export function useAvailableSurveyTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableSurveyTemplatesQuery, AvailableSurveyTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableSurveyTemplatesQuery, AvailableSurveyTemplatesQueryVariables>(AvailableSurveyTemplatesDocument, options);
        }
export function useAvailableSurveyTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AvailableSurveyTemplatesQuery, AvailableSurveyTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableSurveyTemplatesQuery, AvailableSurveyTemplatesQueryVariables>(AvailableSurveyTemplatesDocument, options);
        }
export type AvailableSurveyTemplatesQueryHookResult = ReturnType<typeof useAvailableSurveyTemplatesQuery>;
export type AvailableSurveyTemplatesLazyQueryHookResult = ReturnType<typeof useAvailableSurveyTemplatesLazyQuery>;
export type AvailableSurveyTemplatesSuspenseQueryHookResult = ReturnType<typeof useAvailableSurveyTemplatesSuspenseQuery>;
export type AvailableSurveyTemplatesQueryResult = Apollo.QueryResult<AvailableSurveyTemplatesQuery, AvailableSurveyTemplatesQueryVariables>;