import { toast } from 'react-toastify';

import { useValidatedParams } from 'hooks/useValidatedParams/useValidatedParams';
import { useQuarterlyReportsQuery } from 'documents/quarterlyReports.generated';
import { QUARTERLY_REPORTS_PAGE_SIZE } from 'app/employeeDetails/employeeDetailsQuarterly/EmployeeDetailsQuarterly';
import { QuarterlyReportCollection } from 'generated/webapp_gql';

export const useQuarterlyReports = (activePage: number) => {
  const { employeeId } = useValidatedParams(['employeeId']);
  const {
    data,
    loading: isQuarterlyReportsLoading,
    fetchMore: fetchQuarterlyReports,
    previousData: previousQuarterlyReportsData,
  } = useQuarterlyReportsQuery({
    variables: {
      employeeId,
      pagination: {
        limit: QUARTERLY_REPORTS_PAGE_SIZE,
        offset: (activePage - 1) * QUARTERLY_REPORTS_PAGE_SIZE,
      },
    },
    onError: (error) => toast.error(error.message),
  });

  return {
    quarterlyReportsData: data?.quarterlyReports as QuarterlyReportCollection,
    isQuarterlyReportsLoading,
    fetchQuarterlyReports,
    previousQuarterlyReportsData,
  };
};
