export const MuiTooltip = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      tooltip: {
        backgroundColor: colors.navy,
      },
      arrow: {
        color: colors.navy,
      },
    },
  };
};
