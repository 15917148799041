import { Styles } from 'shared/types';
import { colors } from 'shared/utils';

export const Indicator: Styles = {
  display: 'block',
  width: 8,
  height: 8,
  marginRight: 1,
  borderRadius: '50%',
};

export const NoAction: Styles = {
  backgroundColor: colors.disabledGray,
};

export const Seen: Styles = {
  backgroundColor: colors.orange,
};

export const Responded: Styles = {
  backgroundColor: colors.green,
};
