import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatedSurveyTemplatesQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
}>;


export type CreatedSurveyTemplatesQuery = { __typename?: 'Query', employee?: { __typename?: 'Employee', createdSurveyTemplatesCollection?: { __typename?: 'SurveyTemplateCollection', total?: number | null, items?: Array<{ __typename?: 'SurveyTemplate', title: string, createdAt: any, id: string } | null> | null } | null } | null };


export const CreatedSurveyTemplatesDocument = gql`
    query CreatedSurveyTemplates($employeeId: Int!) {
  employee(id: $employeeId) {
    createdSurveyTemplatesCollection {
      items {
        title
        createdAt
        id
      }
      total
    }
  }
}
    `;

/**
 * __useCreatedSurveyTemplatesQuery__
 *
 * To run a query within a React component, call `useCreatedSurveyTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatedSurveyTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatedSurveyTemplatesQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useCreatedSurveyTemplatesQuery(baseOptions: Apollo.QueryHookOptions<CreatedSurveyTemplatesQuery, CreatedSurveyTemplatesQueryVariables> & ({ variables: CreatedSurveyTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreatedSurveyTemplatesQuery, CreatedSurveyTemplatesQueryVariables>(CreatedSurveyTemplatesDocument, options);
      }
export function useCreatedSurveyTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatedSurveyTemplatesQuery, CreatedSurveyTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreatedSurveyTemplatesQuery, CreatedSurveyTemplatesQueryVariables>(CreatedSurveyTemplatesDocument, options);
        }
export function useCreatedSurveyTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreatedSurveyTemplatesQuery, CreatedSurveyTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreatedSurveyTemplatesQuery, CreatedSurveyTemplatesQueryVariables>(CreatedSurveyTemplatesDocument, options);
        }
export type CreatedSurveyTemplatesQueryHookResult = ReturnType<typeof useCreatedSurveyTemplatesQuery>;
export type CreatedSurveyTemplatesLazyQueryHookResult = ReturnType<typeof useCreatedSurveyTemplatesLazyQuery>;
export type CreatedSurveyTemplatesSuspenseQueryHookResult = ReturnType<typeof useCreatedSurveyTemplatesSuspenseQuery>;
export type CreatedSurveyTemplatesQueryResult = Apollo.QueryResult<CreatedSurveyTemplatesQuery, CreatedSurveyTemplatesQueryVariables>;