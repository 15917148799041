import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { CheckBoxOutlineBlank, Close, RadioButtonUnchecked } from '@mui/icons-material';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { Box, IconButton, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SurveyFormData } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.types';
import { validateAnswers } from 'app/surveyBuilder/surveyBuilderLayout/SurveyBuilderLayout.utils';
import { SurveyQuestionFormat } from 'generated/webapp_gql';
import { useTranslateSurvey } from 'hooks/useTranslateSurvey/useTranslateSurvey';
import { spreadMultipleStyles } from 'shared/utils';
import { ControlledTextField } from 'ui';
import { useSurveyView } from 'hooks/useSurveyView/useSurveyView';

import * as Styles from './Answers.styles';
import { AnswersProps } from './Answers.types';

export const MIN_ANSWERS_COUNT = 2;

export const Answers = ({ nestIndex, questionIndex, format }: AnswersProps) => {
  const { t } = useTranslation();
  const translateSurvey = useTranslateSurvey();
  const { isEditInputOnly } = useSurveyView();

  const { control, getValues } = useFormContext<SurveyFormData>();

  const { fields, append, move, remove } = useFieldArray({
    name: `sections.${nestIndex}.questions.${questionIndex}.answers`,
    control,
  });

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  const handleAppendAnswer = (inputIndex: number) => {
    if (inputIndex + 1 === fields.length) {
      append({}, { shouldFocus: false });
    }
  };

  const handleRemoveAnswer = (inputIndex: number) => {
    remove(inputIndex);
  };

  const getCurrentFieldValue = (inputIndex: number) => {
    return getValues(`sections.${nestIndex}.questions.${questionIndex}.answers.${inputIndex}.value`);
  };

  const answers = getValues(`sections.${nestIndex}.questions.${questionIndex}.answers`);
  const isValid = validateAnswers(answers);

  const answersInput = isEditInputOnly ? fields.slice(0, fields.length - 1) : fields;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="form-answers">
        {({ droppableProps, innerRef, placeholder }) => (
          <Box {...droppableProps} ref={innerRef} sx={Styles.AnswersWrapper}>
            {answersInput.map((input, inputIndex) => (
              <Draggable
                key={input.id}
                draggableId={`answer-${input.id}`}
                index={inputIndex}
                isDragDisabled={!getCurrentFieldValue(inputIndex)}
              >
                {({ draggableProps, dragHandleProps, innerRef }) => {
                  return (
                    <Box ref={innerRef} {...draggableProps} sx={Styles.AnswerRow}>
                      <IconButton
                        color="primary"
                        aria-label="Delete form"
                        {...dragHandleProps}
                        sx={Styles.DragHandle(!getCurrentFieldValue(inputIndex))}
                      >
                        <DragIndicator sx={spreadMultipleStyles(Styles.DragIcon, Styles.IconColor)} />
                      </IconButton>
                      {format === SurveyQuestionFormat.CloseEnded && <RadioButtonUnchecked sx={Styles.IconColor} />}
                      {format === SurveyQuestionFormat.MultipleChoice && <CheckBoxOutlineBlank sx={Styles.IconColor} />}
                      <ControlledTextField
                        name={`sections.${nestIndex}.questions.${questionIndex}.answers.${inputIndex}.value`}
                        control={control}
                        placeholder={translateSurvey('surveyBuilder.questions.question.addOption')}
                        sx={Styles.AnswerInput}
                        onKeyDown={() => handleAppendAnswer(inputIndex)}
                      />
                      {fields.length > MIN_ANSWERS_COUNT && inputIndex + 1 !== fields.length && !isEditInputOnly && (
                        <IconButton onClick={() => handleRemoveAnswer(inputIndex)}>
                          <Close sx={Styles.CloseIcon} />
                        </IconButton>
                      )}
                    </Box>
                  );
                }}
              </Draggable>
            ))}
            {placeholder}
            {!isValid && (
              <Typography variant="body2" color="error">
                {t('surveyBuilder.questions.question.atLeastTwoOptions')}
              </Typography>
            )}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
