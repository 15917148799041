import { Box, Button, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCopyPublishedSurveyTemplate } from 'hooks';
import { AppRoute } from 'routing/AppRoute.enum';
import { useValidatedParams } from 'hooks';

import { EditAsTemplateDialogProps } from './EditAsTemplateDialog.types';
import * as Styles from './EditAsTemplateDialog.styles';

export const EditAsTemplateDialog = ({ handleCloseModal, open, surveyTemplateId }: EditAsTemplateDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useValidatedParams(['employeeId']);

  const { copyPublishedSurveyTemplate } = useCopyPublishedSurveyTemplate();

  const handleCopyTemplate = async () => {
    if (surveyTemplateId) {
      const { data } = await copyPublishedSurveyTemplate({
        variables: { input: { id: surveyTemplateId } },
      });

      if (data?.copyPublishedSurveyTemplate?.id) {
        navigate(`/${AppRoute.SurveyBuilder}/${employeeId}/${data.copyPublishedSurveyTemplate.id}`);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <Typography variant="h5" sx={Styles.Title}>
        {t(`surveyBuilder.questions.editAsTemplateModal.title`)}
      </Typography>
      <Typography variant="body1" sx={Styles.Description}>
        {t(`surveyBuilder.questions.editAsTemplateModal.description`)}
      </Typography>
      <Box sx={Styles.ButtonWrapper}>
        <Button variant="outlined" onClick={handleCloseModal}>
          {t(`surveyBuilder.questions.surveyState.cancelButton`)}
        </Button>
        <Button variant="contained" onClick={handleCopyTemplate}>
          {t(`surveyBuilder.questions.surveyState.acceptButton`)}
        </Button>
      </Box>
    </Dialog>
  );
};
