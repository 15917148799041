export enum ClickTypeEnum {
  Check = 'check',
  Delete = 'delete',
  More = 'more',
}

export enum ActionsTabsEnum {
  Canceled = 'CANCELED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
}
