import { RefObject, useEffect, useState } from 'react';

const SCROLL_DEBOUNCE_TIME = 60;

const scrollToElement = (elementRef: RefObject<HTMLDivElement>) => {
  const current = elementRef.current;

  if (!current) {
    return;
  }

  current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
};

export const useScrollToElement = (elementRef: RefObject<HTMLDivElement>, changeIndicator: unknown) => {
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (shouldScroll) {
      setTimeout(() => {
        scrollToElement(elementRef);
      }, SCROLL_DEBOUNCE_TIME);

      setShouldScroll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeIndicator]);

  return { setShouldScroll };
};
