import * as Types from '../generated/webapp_gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWeeklyFeedbackMutationVariables = Types.Exact<{
  createActions: Types.CreateActionsInput;
  updateWeeklyFeedback: Types.UpdateWeeklyFeedbackInput;
}>;


export type UpdateWeeklyFeedbackMutation = { __typename?: 'Mutation', updateWeeklyFeedback?: { __typename?: 'WeeklyFeedback', id: string, satisfactionScore: number, summary?: string | null, notes?: string | null, createdAt: any, updatedAt: any, realizationDate: any, giverId?: number | null, receiverId?: number | null, actionsCollection?: { __typename?: 'ActionCollection', items: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null, updatedAt: any, realizationDate?: any | null, weeklyFeedbackId?: string | null, createdAt: any } | null> } | null, projectSatisfactionScores?: Array<{ __typename?: 'ProjectSatisfaction', id: string, projectId: string, satisfactionScore: number, project: { __typename?: 'Project', projectName: string } } | null> | null } | null, createAction?: Array<{ __typename?: 'Action', id: string, content?: string | null, actionStatus?: Types.ActionStatus | null, weeklyFeedbackId?: string | null, updatedAt: any, realizationDate?: any | null, createdAt: any } | null> | null };


export const UpdateWeeklyFeedbackDocument = gql`
    mutation UpdateWeeklyFeedback($createActions: CreateActionsInput!, $updateWeeklyFeedback: UpdateWeeklyFeedbackInput!) {
  updateWeeklyFeedback(input: $updateWeeklyFeedback) {
    id
    satisfactionScore
    summary
    notes
    actionsCollection {
      items {
        id
        content
        actionStatus
        updatedAt
        realizationDate
        weeklyFeedbackId
        createdAt
      }
    }
    projectSatisfactionScores {
      id
      projectId
      satisfactionScore
      project {
        projectName
      }
    }
    createdAt
    updatedAt
    realizationDate
    giverId
    receiverId
  }
  createAction(input: $createActions) {
    id
    content
    actionStatus
    weeklyFeedbackId
    updatedAt
    realizationDate
    createdAt
  }
}
    `;
export type UpdateWeeklyFeedbackMutationFn = Apollo.MutationFunction<UpdateWeeklyFeedbackMutation, UpdateWeeklyFeedbackMutationVariables>;

/**
 * __useUpdateWeeklyFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateWeeklyFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeeklyFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeeklyFeedbackMutation, { data, loading, error }] = useUpdateWeeklyFeedbackMutation({
 *   variables: {
 *      createActions: // value for 'createActions'
 *      updateWeeklyFeedback: // value for 'updateWeeklyFeedback'
 *   },
 * });
 */
export function useUpdateWeeklyFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWeeklyFeedbackMutation, UpdateWeeklyFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWeeklyFeedbackMutation, UpdateWeeklyFeedbackMutationVariables>(UpdateWeeklyFeedbackDocument, options);
      }
export type UpdateWeeklyFeedbackMutationHookResult = ReturnType<typeof useUpdateWeeklyFeedbackMutation>;
export type UpdateWeeklyFeedbackMutationResult = Apollo.MutationResult<UpdateWeeklyFeedbackMutation>;
export type UpdateWeeklyFeedbackMutationOptions = Apollo.BaseMutationOptions<UpdateWeeklyFeedbackMutation, UpdateWeeklyFeedbackMutationVariables>;