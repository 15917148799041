export const MuiAccordion = () => {
  return {
    styleOverrides: {
      root: {
        width: '100%',
        boxShadow: 'none',
        '&:before': {
          content: 'none',
        },
      },
    },
  };
};
