export const MuiChip = (colors: Record<string, string>) => {
  return {
    styleOverrides: {
      root: {
        color: colors.white,

        '&.MuiChip-colorWarning': {
          color: colors.navy,
        },
      },
      sizeSmall: {
        fontSize: 12,
        fontWeight: 'bold',
      },
      labelSmall: {
        padding: '3px 10px',
        lineHeight: 1.67,
      },
    },
  };
};
