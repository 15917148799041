import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as Styles from './CardSummary.styles';
import { CardSummaryProps } from './CardSummary.types';

export const CardSummary = ({ summary }: CardSummaryProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={Styles.CardSection}>
      <Typography variant="subtitle2" sx={Styles.CardSectionLabel}>
        {t('report.card.summary')}
      </Typography>
      <Typography variant="body2" sx={Styles.CardSectionContent} dangerouslySetInnerHTML={{ __html: summary || '' }} />
    </Box>
  );
};
