import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';
import { differenceInMilliseconds } from 'date-fns';
import EastIcon from '@mui/icons-material/East';

import i18n from 'i18n/i18n';
import { getLongFormatDate, isFeedbackOverdue } from 'shared/utils';
import {
  EmployeesTableUnsortableField,
  ReadOnlyEmployeesTableUnsortableField,
} from 'app/employees/employeesTable/EmployeesTableField.enum';
import { AvatarWithFallback, ErrorInfo } from 'ui';
import { Employee, EmployeeSortOptions } from 'generated/webapp_gql';
import * as Styles from '../EmployeesTable.styles';
import { CellMenu } from '../cellMenu/CellMenu';
import { WeeklyOverview } from '../weeklyOverview/WeeklyOverview';
import { VisitProfileButton } from '../visitProfileButton/VisitProfileButton';

import { isActionWithContent } from './employeesTableColumns.utils';
import { EmployeeActionsTooltipTitle } from './EmployeeActionsTooltipTitle/EmployeeActionsTooltipTitle';

const { t } = i18n;

const QUARTERLY_REMINDER_DAYS = 152;

export const getEmployeesTableColumns = (isPendingActionsColumnSortable: boolean): GridColDef[] => [
  {
    field: EmployeeSortOptions.FullName,
    headerName: t('employeesTable.header.fullName'),
    flex: 1,
    disableColumnMenu: true,
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => (
      <Stack direction="row" alignItems="center" spacing={2} data-cy="employeesListForwardButton">
        <Box>
          <AvatarWithFallback size="s" alt={row.fullName} src={row.avatarUrl} />
        </Box>
        <Typography variant="body2">{row.fullName}</Typography>
      </Stack>
    ),
  },
  {
    field: EmployeeSortOptions.ActionsInProgress,
    headerName: t('employeesTable.header.actionsToPerform'),
    flex: 1,
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => {
      const { actionsCollection, actionsInProgress } = row;

      if (actionsInProgress) {
        const actionsWithContent = (actionsCollection?.items || []).filter(isActionWithContent);

        return (
          <Tooltip
            PopperProps={{
              sx: Styles.ActionsTooltip,
            }}
            arrow
            title={<EmployeeActionsTooltipTitle actions={actionsWithContent} numberOfActions={actionsInProgress} />}
            placement="right"
          >
            <Chip
              label={<>{t('employeesTable.table.chip', { count: actionsInProgress })}</>}
              color="warning"
              size="medium"
              sx={Styles.Chip}
            />
          </Tooltip>
        );
      }

      const lastQuarterlyFeedbackDate = row?.latestReceivedQuarterlyReportRealizationDate;
      const shouldShowQuarterlyFeedbackReminder =
        lastQuarterlyFeedbackDate && isFeedbackOverdue(new Date(lastQuarterlyFeedbackDate), QUARTERLY_REMINDER_DAYS);

      const lastWeeklyFeedbackDate = row?.latestReceivedWeeklyFeedbackRealizationDate;
      const shouldShowWeeklyFeedbackReminder =
        !lastWeeklyFeedbackDate || isFeedbackOverdue(new Date(lastWeeklyFeedbackDate));

      if (shouldShowQuarterlyFeedbackReminder && shouldShowWeeklyFeedbackReminder) {
        return (
          <ErrorInfo
            content={t('employeesTable.table.haveWeeklyAndQuarterly')}
            icon={<CircleIcon color="error" sx={Styles.CircleIcon} />}
          />
        );
      }

      if (shouldShowQuarterlyFeedbackReminder) {
        return (
          <ErrorInfo
            content={t('employeesTable.table.haveAQuarterly')}
            icon={<CircleIcon color="error" sx={Styles.CircleIcon} />}
          />
        );
      }

      if (shouldShowWeeklyFeedbackReminder) {
        return (
          <ErrorInfo
            content={t('employeesTable.table.haveAWeekly')}
            icon={<CircleIcon color="error" sx={Styles.CircleIcon} />}
          />
        );
      }

      return '-';
    },
    valueGetter: ({ row }) => row?.actionsInProgress,
    sortable: isPendingActionsColumnSortable,
  },
  {
    field: EmployeeSortOptions.LatestReceivedWeeklyFeedbackRealizationDate,
    headerName: t('employeesTable.header.lastWeekly'),
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => {
      const lastWeeklyFeedbackDateString = row.latestReceivedWeeklyFeedbackRealizationDate;

      if (!lastWeeklyFeedbackDateString) {
        return (
          <ErrorInfo
            content={t('employeesTable.table.noWeekly')}
            icon={<ErrorIcon color="error" />}
            textColor="error"
          />
        );
      }

      const lastWeeklyFeedbackDate = new Date(lastWeeklyFeedbackDateString);
      const dateFormatted = getLongFormatDate(lastWeeklyFeedbackDate);

      if (isFeedbackOverdue(lastWeeklyFeedbackDate)) {
        return <ErrorInfo content={dateFormatted} icon={<ErrorIcon color="error" />} textColor="error" />;
      }

      return dateFormatted;
    },
    flex: 1,
    sortComparator: (v1, v2) => differenceInMilliseconds(new Date(v2), new Date(v1)),
    valueGetter: ({ row }) => row?.latestReceivedWeeklyFeedback?.realizationDate,
    filterable: false,
  },
  {
    field: EmployeeSortOptions.LatestReceivedQuarterlyReportRealizationDate,
    headerName: t('employeesTable.header.lastQuarterly'),
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => {
      const lastQuaterlyFeedbackDateString = row.latestReceivedQuarterlyReportRealizationDate;

      if (!lastQuaterlyFeedbackDateString) {
        return (
          <ErrorInfo
            content={t('employeesTable.table.noQuarterly')}
            icon={<ErrorIcon color="error" />}
            textColor="error"
          />
        );
      }

      const lastQuaterlyFeedbackDate = new Date(lastQuaterlyFeedbackDateString);
      const dateFormatted = getLongFormatDate(lastQuaterlyFeedbackDate);

      return dateFormatted;
    },
    flex: 1,
    sortComparator: (v1, v2) => differenceInMilliseconds(new Date(v2), new Date(v1)),
    valueGetter: ({ row }) => row?.latestReceivedQuarterlyReport?.realizationDate,
    filterable: false,
  },
  {
    field: EmployeesTableUnsortableField.ADD_REPORT,
    headerName: '',
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => (
      <CellMenu employeeId={row.id} supervisorId={row.supervisorId} isSubcontractor={row.employmentType === 'LTSH'} />
    ),
    width: 48,
    align: 'center',
    sortable: false,
  },
  {
    field: EmployeesTableUnsortableField.WEEKLY_OVERVIEW,
    headerName: '',
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => (
      <WeeklyOverview employeeId={row.id} weeklyAvailable={row.latestReceivedWeeklyFeedbackRealizationDate} />
    ),
    width: 48,
    align: 'center',
    sortable: false,
  },
  {
    field: EmployeesTableUnsortableField.VISIT_PROFILE,
    headerName: '',
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => <VisitProfileButton employeeId={row.id} />,
    width: 48,
    align: 'center',
    sortable: false,
  },
];

export const getReadOnlyEmployeesTableColumns: GridColDef[] = [
  {
    field: ReadOnlyEmployeesTableUnsortableField.AVATAR,
    headerName: '',
    width: 56,
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => (
      <AvatarWithFallback size="s" alt={`${row.firstName} ${row.lastName}`} src={row.avatarUrl} />
    ),
    sortable: false,
  },
  {
    field: EmployeeSortOptions.FirstName,
    headerName: t('employeesTable.header.firstName'),
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: EmployeeSortOptions.LastName,
    headerName: t('employeesTable.header.lastName'),
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: EmployeeSortOptions.Department,
    headerName: t('employeesTable.header.department'),
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: ReadOnlyEmployeesTableUnsortableField.CURRENT_PROJECTS,
    headerName: t('employeesTable.header.currentProject'),
    flex: 1,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.currentProjects,
    renderCell: ({ row }: GridValueGetterParams<never, Employee>) => {
      const { currentProjects } = row;

      return (
        currentProjects && (
          <Box sx={Styles.EmployeeBox}>
            <Typography variant="body2">{currentProjects[0]}</Typography>
            {currentProjects.length > 1 && (
              <Tooltip
                title={currentProjects.map((project, idx) =>
                  idx === 0 ? (
                    ''
                  ) : (
                    <Typography variant="body2" key={idx}>
                      {project}
                    </Typography>
                  ),
                )}
                placement="bottom"
                arrow
              >
                <Typography variant="body2" fontWeight={'bold'}>
                  {'+ ' + t('employeesTable.table.showMoreProjects', { count: currentProjects.length - 1 })}
                </Typography>
              </Tooltip>
            )}
          </Box>
        )
      );
    },
  },
  {
    field: ReadOnlyEmployeesTableUnsortableField.SETTINGS,
    headerName: '',
    renderCell: () => (
      <IconButton data-cy="employeesListForwardButton">
        <EastIcon sx={Styles.EastIcon} />
      </IconButton>
    ),
    width: 48,
    align: 'center',
    sortable: false,
  },
];
